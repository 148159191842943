import React, { useEffect, useRef, useState } from 'react'
import { Blue, Dark, DividerColor, Maroon, TextGray, White, btnBgColor, mainColor } from '../../Config/Color'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { getDataDashboardApplicant, updateBiodata, updateDataMasaStudi } from '../../Config/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../ToastyAlert/ErrorAlert'
import InfoAlert from '../../ToastyAlert/InfoAlert';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';

const MasaStudi = ({ biodata, validasiData, getDataValidasi }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [dataCompleteness, setDataCompleteness] = useState(0)
    const [switchButton, setSwitchButton] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [newData, setNewData] = useState({
        bulan: '',
        tahun: '',
        semesterSekarang: '',
        jenisMasaStudi: '',
        maksimalMasaStudi: ''
    })
    const [monthAndYear, setMonthAndYear] = useState(null)


    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            setIsLoading(false)
        }, 1500);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        // console.log(newData.bulan);
        // console.log(newData.tahun);
        // console.log(newData.jenisMasaStudi);
        // console.log(newData.semesterSekarang);

        const month = moment(monthAndYear).format('MM')
        const year = moment(monthAndYear).format('YYYY')

        console.log('year', year);
        console.log('month', month);

        setIsSubmitting(true)

        axios.put(`${updateDataMasaStudi}/${biodata.data?.personal_information_id}`, {
            entry_month_ptn: month,
            entry_year_ptn: year,
            type_study_period: newData.jenisMasaStudi,
            current_semester: newData.semesterSekarang,
            max_study_period: newData.maksimalMasaStudi
        }).then((response) => {
            console.log(response.data);
            setIsSubmitting(false)
            // getData()
            InfoAlert({
                message: 'Berhasil Update Data.',
                position: 'top-center'
            })
            getDataValidasi()
            setSwitchButton(false)
        }).catch((error) => {
            console.log(error.response.data);
            ErrorAlert({
                message: error.response.data.message,
                position: 'top-center'
            })
            setIsSubmitting(false)
        })
    }

    const getData = async () => {
        setNewData({
            jenisMasaStudi: biodata.data?.type_study_period,
            semesterSekarang: biodata.data?.current_semester,
            bulan: biodata.data?.entry_month_ptn,
            tahun: biodata.data?.entry_year_ptn,
            maksimalMasaStudi: biodata.data?.max_study_period,
        });
        const mergeMonthYear = `${biodata.data?.entry_year_ptn}-${biodata.data?.entry_month_ptn}-01`
        const getDate = new Date(mergeMonthYear)
        setMonthAndYear(getDate)
    };

    useEffect(() => {
        getData();
    }, [biodata]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

    };


    return (
        <>
            {/* Masa Studi */}
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : '500px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            Masa Studi
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {validasiData ? `${Math.round(validasiData)}%` : '%0'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '14px',
                                fonStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',

                            }}
                        >
                            Bulan dan Tahun Masuk Perguruan Tinggi
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'flex-start',
                                gap: '14px',
                                marginTop: '-10px',
                                width: '100%'
                            }}
                        >
                            <DatePicker
                                views={['year', 'month']}
                                minDate={new Date('2000-01-01')}
                                maxDate={new Date('2100-12-31')}
                                // label='Bulan dan Tahun Masuk Perguruan Tinggi'
                                // localeText={{ toolbarTitle: 'Title' }}
                                sx={{ gridColumn: 'span 2', padding: '0px', width: '100%', marginBottom: '16px' }}
                                slotProps={{
                                    textField: { variant: 'standard' },
                                    toolbar: {
                                        toolbarPlaceholder: '__',
                                        toolbarFormat: 'yyyy-mm-dd',
                                        hidden: true,
                                    },
                                }}
                                // slots={{
                                //   toolbar: CustomToolbarComponent
                                // }}
                                value={monthAndYear}
                                onChange={(newValue) => setMonthAndYear(newValue)}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Jenis Masa Studi"
                            placeholder='Masukkan jenis masa studi'
                            value={newData.jenisMasaStudi}
                            name="jenisMasaStudi"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '13px' : '16px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        {/* <FormControl variant="standard" fullWidth disabled={switchButton ? false : true} sx={{ marginBottom: '10px' }}>
                            <InputLabel id="asal-kabupaten-labelId">Pilih masa studi</InputLabel>
                            <Select
                                label="Jenis Masa Studi"
                                labelId="jenis-masa-studi-labelId"
                                id="jenis-masa-studi-Id"
                                defaultValue="None"
                                // value={asalKabupaten}
                                onChange={(e) => handleSelectKabupaten(e)}
                                required
                            >
                                <MenuItem value="None">None</MenuItem>
                            </Select>
                        </FormControl> */}
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Semester Sekarang"
                            placeholder='Masukkan semester sekarang'
                            value={newData.semesterSekarang}
                            name="semesterSekarang"
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '13px' : '16px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        {/* <FormControl variant="standard" fullWidth disabled={switchButton ? false : true} sx={{ marginBottom: '13px' }}>
                            <InputLabel id="asal-kabupaten-labelId">Pilih maksimal masa studi</InputLabel>
                            <Select
                                label="Maksimal Masa Studi"
                                labelId="maksimal-masa-studi-labelId"
                                id="maksimal-masa-studi-Id"
                                defaultValue="None"
                                // value={asalKabupaten}
                                onChange={(e) => handleSelectKabupaten(e)}
                                required
                            >
                                <MenuItem value="None">None</MenuItem>
                            </Select>
                        </FormControl> */}
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Maksimal Masa Studi"
                            placeholder='Masukkan maksimal masa studi'
                            value={newData.maksimalMasaStudi}
                            name="maksimalMasaStudi"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '13px' : '16px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                color: mainColor,
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                            disabled={isSubmitting || !switchButton ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Data'
                            }
                        </Button>
                    </Box>
                </form>
            </Card>
        </>
    )
}

export default MasaStudi