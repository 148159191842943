import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useState } from 'react';
import { Maroon, TextGray, mainColor } from '../../Config/Color';
import LogoEmail from '../../../assets/Logo/Logo_Email.png'
import { Icon } from '@iconify/react';
import { RegistrasiTahapI, activationEmail, activationEmailSUP, cekVerifikasiEmail, updateDataRegistrasiTahapI } from '../../Config/Api';
import axios from 'axios';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';


const EmailActiovationModalSUP = ({
    open,
    close,
    isMobile,
    isTablet,
    newData,
    emailVerificationData,
    countdownTimer,
    resetCountdown,
    verificationStatusTrigger,
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 290 : 350,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        p: 3,
    };

    const navigate = useNavigate()

    const [isLoadingBack, setIsLoadingBack] = useState(false)
    const [isLoadingSubmitData, setIsLoadingSubmitData] = useState(false)
    const [otp, setOtp] = useState('')


    useEffect(() => {
        if (countdownTimer === 0) {
            setOtp('')
        }
    }, [countdownTimer])



    const handleSubmitKonfirmasiEmail = async () => {
        // e.preventDefault();
        setIsLoadingSubmitData(true)

        console.log('otp', otp);
        // console.log('emailVerificationData', emailVerificationData);

        if (otp === emailVerificationData.verification_link) {
            await axios.put(`${activationEmailSUP}/${emailVerificationData.verification_email_id}`, {
                verification_link: otp
            }).then((response) => {
                console.log('response activationEmailSUP', response.data);
                if (response.data?.data?.verification_status) {
                    if (response.data?.message === "Email successfully verified") {
                        SuccessAlert({
                            message: 'Berhasil verifikasi email.',
                            position: 'top-center'
                        })
                        setTimeout(() => {
                            resetCountdown()
                            close()
                            setIsLoadingSubmitData(false)
                            verificationStatusTrigger()
                            setOtp('')
                            // navigate('/login')
                        }, 1500);
                    } else {
                        ErrorAlert({
                            message: response.data?.message === "Email already verified" ? 'Email sudah terverifikasi.' : undefined,
                            position: 'top-center'
                        })
                        setTimeout(() => {
                            resetCountdown()
                            close()
                            setIsLoadingSubmitData(false)
                            setOtp('')
                        }, 1500);
                    }
                }
            }).catch((error) => {
                console.log(error.response.data);
                setIsLoadingSubmitData(false)
            })
        } else if (otp !== emailVerificationData.verification_link) {
            ErrorAlert({
                message: 'Kode aktivasi salah.',
                position: 'top-center'
            })
            setIsLoadingSubmitData(false)
        }
        else {
            ErrorAlert({
                message: 'Kode aktivasi wajib diisi.',
                position: 'top-center'
            })
            setIsLoadingSubmitData(false)

        }

    }

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    return (
        <div>
            <Modal
                open={open}
                // onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '15px' : '16px',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center',
                            marginBottom: '10px'
                        }}
                    >
                        {emailVerificationData.type_otp === 'sup' && emailVerificationData.verification_status === false ?
                            <>
                                Silahkan isi kode aktivasi yang sebelumnya sudah kami kirimkan ke email{' '}
                                <span style={{ fontFamily: 'Jakarta Sans, sans-serif', fontStyle: 'italic', fontWeight: 'bold', color: mainColor }}>{newData.email}</span>.
                                Cek inbox atau spambox Anda.
                            </>
                            :
                            <>
                                Kami telah mengirimkan kode aktivasi ke email {' '}
                                <span style={{ fontFamily: 'Jakarta Sans, sans-serif', fontStyle: 'italic', fontWeight: 'bold', color: mainColor }}>{newData.email}</span>,
                                Masukkan kode untuk aktifkan email Anda.
                            </>
                        }
                    </Typography>
                    {/* <Icon icon='email' style={{ fontSize: '50px', color: mainColor }} /> */}
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '15px'
                        }}
                    >
                        <img src={LogoEmail} alt='logo-email' style={{ width: '90px', height: '90px' }} />
                    </Box>
                    <MuiOtpInput
                        value={otp}
                        length={5}
                        onChange={handleChange}
                        // validateChar={matchIsNumeric}
                        autoFocus
                        TextFieldsProps={{
                            // disabled: true,
                            size: 'small'
                        }}
                    />
                    <Box
                        sx={{
                            display: countdownTimer && countdownTimer < 1 ? 'none' : 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // backgroundColor: 'orange'
                            marginTop: '30px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center',
                                fontStyle: 'italic',
                                color: TextGray
                            }}
                        >
                            Aktivasi email sebelum {' '}
                            <span
                                style={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontWeight: 'bold',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    fontStyle: 'italic',
                                    color: mainColor,
                                }}
                            >
                                {countdownTimer && countdownTimer <= 0 ? '' : countdownTimer}
                            </span>
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: isMobile ? '30px' : '80px',
                            marginTop: "30px"
                        }}
                    >
                        <Button
                            fullWidth
                            size='medium'
                            variant='contained'
                            onClick={() => {
                                setIsLoadingBack(true)
                                // setIsLoadingSubmitData(true)
                                setTimeout(() => {
                                    resetCountdown()
                                    setIsLoadingBack(false)
                                    setIsLoadingSubmitData(false)
                                    setOtp('')
                                    close()
                                }, 1500);
                            }}
                            sx={{
                                backgroundColor: Maroon,
                                textTransform: 'none',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: Maroon,
                                },
                            }}
                            disabled={isLoadingBack ? true : false}
                        >
                            {isLoadingBack ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Kembali'
                            }
                        </Button>
                        <Button
                            fullWidth
                            size='medium'
                            variant='contained'
                            onClick={handleSubmitKonfirmasiEmail}
                            sx={{

                                backgroundColor: mainColor,
                                textTransform: 'none',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                            disabled={isLoadingSubmitData || isLoadingBack ? true : false}
                        >
                            {isLoadingSubmitData ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Aktivasi Email'
                            }

                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default EmailActiovationModalSUP