import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopMenu from '../../components/Header/TopMenu'
import { createInfoRekening, getDataDashboardApplicant, getDataRekening, updateBiodata, updateDataBioPekerjaandataOrangTua, updateDataBiodataOrangTua, updateDataKontakOrangTua, updateInfoRekening } from '../../components/Config/Api';
import { Blue, Dark, DividerColor, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color'
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert'
import InfoAlert from '../../components/ToastyAlert/InfoAlert'
import { ToastContainer } from 'react-toastify';
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import { listBank } from '../../components/Config/ListBank';

const DataRekening = () => {

  const navigate = useNavigate()

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [allData, setAllData] = useState(null)
  const [dataCompletenessDataRekening, setDataCompletenessDataRekening] = useState(100)
  const [switchButton, setSwitchButton] = useState(false)
  const [isLoadingPekerjaanOrangTua, setIsLoadingPekerjaanOrangTua] = useState(false)
  const [isLoadingKontakOrangTua, setIsLoadingKontakOrangTua] = useState(false)
  const [newData, setNewData] = useState({
    namaPemilikRek: '',
    noRek: ''
  })
  const [errorMessage, setErrorMessage] = useState(null)
  const [dataListBank, setDataListBank] = useState([])
  const [selectedBank, setSelectedBank] = useState(null)
  const [dataRekening, setDataRekening] = useState(null)
  const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
  const [isLoadingUbahData, setIsLoadingUbahData] = useState(false)
  const [isLoadingSimpanData, setIsLoadingSimpanData] = useState(false)

  const getData = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')

    let dataPersonalInformationId = ''

    if (getDataLogin) {
      const token = JSON.parse(getDataLogin).token
      const user_id = JSON.parse(getDataLogin).Id

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {
          // console.log(response.data?.data);
          setAllData(response.data?.data)
          dataPersonalInformationId = response.data?.data?.personal_information_id

        }).catch((error) => {
          console.log(error.response.data);

        })

      await axios.get(`${getDataRekening}/${dataPersonalInformationId}`)
        .then((response) => {
          console.log('response rekening', response.data?.data[0]);
          setNewData({
            namaPemilikRek: response.data?.data[0]?.name_account,
            noRek: response.data?.data[0]?.account_number
          })
          setSelectedBank(response.data?.data[0]?.name_bank)

          var dataRekening =
          {
            namaPemilikRek: response.data?.data[0]?.name_account,
            noRek: response.data?.data[0]?.account_number,
            selectedBank: response.data?.data[0]?.name_bank
          }

          const dataRekeningKeys = [
            'namaPemilikRek',
            'noRek',
            'selectedBank',
          ];
          const dataRekeningCompleteness = calculateCompleteness(dataRekening, dataRekeningKeys);
          setDataCompletenessDataRekening(dataRekeningCompleteness);

        }).catch((error) => {
          console.log('error rekening', error.response.data);
          if (error.response?.data?.message === 'Unauthenticated.') {
            handleLoginSession()
          }
          setErrorMessage(error.response?.data?.message)
        })

      const daftarbank = listBank.map(item => item.bank_name);
      setDataListBank(daftarbank)
    }
  }

  const calculateCompleteness = (data, keys) => {
    const totalDataPoints = keys.length;
    const filledDataPoints = keys.filter(key => data[key] !== null && data[key] !== undefined).length;
    const completenessPercentage = (filledDataPoints / totalDataPoints) * 100;
    return completenessPercentage.toFixed(4);
  };


  const handleGetDataRekening = async () => {

  }


  const handleLoginSession = () => {
    const loginSessionExp = localStorage.getItem('LogInfo')
    const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
    if (res) {
      setOpenLoginSessionModal(true)
      localStorage.removeItem('LogInfo')
    }

  }

  useEffect(() => {

    const dataLogin = localStorage.getItem('LogInfo')

    if (dataLogin) {
      handleLoginSession()
      getData()
      handleGetDataRekening()
    } else if (!dataLogin) {
      setOpenLoginSessionModal(true)
      setTimeout(() => {
        navigate('/login');
      }, 9000);
    }

  }, [])

  const handleCloseLoginSessionModal = () => {
    setOpenLoginSessionModal(false)
    navigate('/login');
  }


  const handleSwitchButton = () => {
    setIsLoadingUbahData(true)
    setTimeout(() => {
      setSwitchButton(!switchButton)
      getData()
      if (!newData.namaPemilikRek || !newData.noRek || !selectedBank) {
        setNewData({
          namaPemilikRek: '',
          noRek: ''
        })
        setSelectedBank('')
      }
      setIsLoadingUbahData(false)
    }, 1500);
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoadingSimpanData(true)
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const token = JSON.parse(getDataLogin).token

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      if (errorMessage === "Data tidak ditemukan") {
        // console.log('kondisi 1');
        await axios.post(`${createInfoRekening}`, {
          personal_information_id: allData.personal_information_id,
          name_account: newData.namaPemilikRek,
          name_bank: selectedBank,
          account_number: newData.noRek
        }).then((response) => {
          // console.log(response.data);
          setTimeout(() => {
            SuccessAlert({
              message: 'Berhasil menambah data',
              position: 'top-center'
            })
            getData()
            setIsLoadingSimpanData(false)
            setSwitchButton(false)
          }, 1500);
        }).catch((error) => {
          console.log(error.response.data);
          setIsLoadingSimpanData(false)
        })
      } else {
        // console.log('kondisi 2');
        await axios.put(`${updateInfoRekening}/${allData.personal_information_id}`, {
          name_account: newData.namaPemilikRek,
          name_bank: selectedBank,
          account_number: newData.noRek
        }).then((response) => {
          console.log(response.data);
          setTimeout(() => {
            SuccessAlert({
              message: 'Berhasil merubah data',
              position: 'top-center'
            })
            getData()
            setIsLoadingSimpanData(false)
            setSwitchButton(false)
          }, 1500);
        }).catch((error) => {
          console.log(error.response.data);
          setIsLoadingSimpanData(false)
        })
      }

    }
  }

  return (
    <>
      <TopMenu />
      <Grid
        container
        spacing={2}
        sx={{
          padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
          backgroundColor: bgColor,
          paddingBottom: '52px',
          height: '100%'
        }}
      >
        {/* <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '20px 0px 5px 0px' : '15px 0px 0px 0px',
          }}
        >
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Button
              onClick={handleClickBackToDashboard}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textTransform: 'none',
                padding: '0px',
                gap: '10px',
                // backgroundColor:'orange'
              }}
            >
              <Icon icon='ic:baseline-arrow-back' style={{ fontSize: '20px', color: mainColor }} />
              <Typography
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: mainColor,
                  fontSize: isMobile ? '13px' : '14px',
                  fonStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Kembali ke Dashboard
              </Typography>
            </Button>
          </Box>
        </Grid> */}
        {/* <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px 0px 10px 0px' : '10px 0px 0px 0px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              color: Dark,
              fontSize: isMobile ? '18px' : '20px',
              fonStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Data Orang Tua
          </Typography>
        </Grid> */}

        {
          Math.round(dataCompletenessDataRekening) >= 100 ?
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >
              <Card
                elevation={5}
                sx={{
                  display: 'none',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >

                </Typography>
              </Card>
            </Grid>
            :
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >
              <Card
                elevation={5}
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >
                  Data Rekening anda belum lengkap, segera lengkapi sebelum 1 November 2023
                </Typography>
              </Card>
            </Grid>
        }


        {/* Data Rekening */}
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px' : '10px 0px 0px 0px',
          }}
        >
          <Card
            elevation={3}
            sx={{
              // maxWidth: isMobile || isTablet ? '100%' : '384px',
              maxHeight: isMobile || isTablet ? '100%' : '538px',
              width: '100%',
              height: '538px',
              border: `1px solid ${DividerColor}`,
            }}
          >
            <Box
              sx={{
                padding: '16px',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: '#1E222F',
                  fontSize: isMobile ? '15px' : '16px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: isMobile ? 'normal' : '20.16px',
                }}
              >
                Data Rekening untuk Penerimaan Beasiswa
              </Typography>
              <Button
                onClick={handleSwitchButton}
                sx={{

                  color: switchButton ? '#606571' : mainColor,
                  textTransform: 'none',
                  padding: '0px'
                }}
                disabled={isLoadingUbahData ? true : false}
              >
                {isLoadingUbahData ?
                  <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '20px', color: mainColor }} />
                  :
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: isMobile ? '15px' : '16px',
                      fonStyle: 'normal',
                      fontWeight: switchButton ? '600' : '500',
                      lineHeight: '20.16px',
                      textAlign: 'right',
                      color: switchButton ? mainColor : TextGray,

                    }}
                  >
                    Ubah Data
                  </Typography>
                }
              </Button>
            </Box>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Divider
                variant="string"
                style={{
                  backgroundColor: DividerColor,
                  borderBottomWidth: 0.5,
                  // marginTop: "2px",
                }}
              />
            </Box>

            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  // backgroundColor: 'orange',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '100%',
                  marginTop: '30px',
                  padding: isMobile ? '0px 20px' : isTablet ? '0px 30px' : undefined
                }}
              >
                <Box
                  sx={{
                    // backgroundColor: 'blue',
                    display: 'flex',
                    flexDirection: 'column',
                    // justifyContent: 'center',
                    alignItems: 'center',
                    maxWidth: isMobile || isTablet ? '330px' : '402px',
                    width: '100%',
                    height: '100%',
                    gap: '20px'
                  }}
                >
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="Nama Pemilik Rekening"
                    placeholder='Esther Howard'
                    value={newData.namaPemilikRek}
                    name="namaPemilikRek"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    required
                    disabled={switchButton ? false : true}
                    sx={{
                      marginBottom: isMobile ? '5px' : '8px',
                      fontSize: isMobile ? '11px' : '12px',
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: TextGray,
                      },
                      "& .MuiFilledInput-root.Mui-disabled:hover": {
                        "& fieldset": {
                          borderBottomColor: mainColor,
                        },
                      },
                      "& .MuiInputLabel-root.Mui-disabled": {
                        color: mainColor,
                      },
                      "& .MuiFilledInput-underline:before": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiInputLabel-root": {
                        color: '#B3B3D4',
                        fontWeight: 'bold',
                        fontSize: '12px'
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: mainColor,
                      },
                      "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                        color: mainColor,
                      },
                      "& .MuiFilledInput-underline:hover:before": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:hover:after": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:focus": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:focus:before": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:focus:after": {
                        borderBottomColor: mainColor,
                      },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "& input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                    }}
                  />
                  <Box
                    sx={{
                      width: '100%',
                      // maxWidth: isMobile || isTablet ? '100%' : '100%',
                      // minWidth: isMobile ? '100%' : '40%'
                      // backgroundColor:'orange',
                      // margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 10px 0px'
                    }}
                  >
                    <Autocomplete
                      // disabled={verificationStatus ? false : true}
                      freeSolo
                      autoSelect // Aktifkan pemilihan otomatis saat mengetik
                      selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                      sx={{ marginBottom: '10px' }}
                      id="bank"
                      options={dataListBank.sort((a, b) => a.localeCompare(b))} // Mengurutkan opsi universitas secara alfabetis
                      getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                      value={selectedBank} // Mengikuti nilai yang dipilih
                      onChange={(event, newValue) => {
                        setSelectedBank(newValue); // Perbarui nilai selectedDistrict
                      }}
                      disabled={switchButton ? false : true}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant='standard'
                          label="Pilih Bank"
                          required
                          disabled={switchButton ? false : true}
                          InputProps={{
                            ...params.InputProps,
                            // type: 'search',
                            // sx: { margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 0px 0px' }
                          }}
                        />
                      )}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    label="No. Rekening"
                    placeholder=''
                    value={newData.noRek}
                    name="noRek"
                    onChange={(e) => handleChange(e)}
                    autoComplete="off"
                    onInput={(e) => {
                      // Filter out non-numeric characters
                      e.target.value = e.target.value.replace(/[^0-9]/g, '');
                    }}
                    required
                    disabled={switchButton ? false : true}
                    sx={{
                      marginBottom: isMobile ? '5px' : '8px',
                      fontSize: isMobile ? '11px' : '12px',
                      "& .MuiInputBase-input.Mui-disabled": {
                        WebkitTextFillColor: TextGray,
                      },
                      "& .MuiFilledInput-root.Mui-disabled:hover": {
                        "& fieldset": {
                          borderBottomColor: mainColor,
                        },
                      },
                      "& .MuiInputLabel-root.Mui-disabled": {
                        color: mainColor,
                      },
                      "& .MuiFilledInput-underline:before": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiInputLabel-root": {
                        color: '#B3B3D4',
                        fontWeight: 'bold',
                        fontSize: '12px'
                      },
                      "& .MuiInputLabel-root.Mui-focused": {
                        color: mainColor,
                      },
                      "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                        color: mainColor,
                      },
                      "& .MuiFilledInput-underline:hover:before": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:hover:after": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:focus": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:focus:before": {
                        borderBottomColor: mainColor,
                      },
                      "& .MuiFilledInput-underline:focus:after": {
                        borderBottomColor: mainColor,
                      },
                      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                      {
                        "-webkit-appearance": "none",
                        margin: 0,
                      },
                      "& input[type=number]": {
                        "-moz-appearance": "textfield",
                      },
                    }}
                  />
                  <Button
                    fullWidth
                    variant='contained'
                    size='medium'
                    type='submit'
                    sx={{
                      // minWidth: '350px',
                      textTransform: 'none',
                      backgroundColor: btnBgColor,
                      color: mainColor,
                      // padding: '10px 56px',
                      fontFamily: 'Plus Jakarta Sans',
                      fontSize: isMobile ? '13px' : '14px',
                      fonStyle: 'normal',
                      fontWeight: '600',
                      lineHeight: '22.68px',
                      '&:hover': {
                        color: 'white', // Change text color to white on hover
                        backgroundColor: mainColor,
                      },
                    }}
                    disabled={switchButton && !isLoadingSimpanData ? false : switchButton && isLoadingSimpanData ? true : true}
                  >
                    {isLoadingSimpanData ?
                      <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                      :
                      errorMessage === "Data tidak ditemukan" ? 'Simpan Data' : 'Simpan Perubahan'
                    }
                  </Button>
                </Box>
              </Box>
            </form>
          </Card>
        </Grid>


      </Grid>
      <LoginSessionModal
        open={openLoginSessionModal}
        close={handleCloseLoginSessionModal}
      />
      <ToastContainer />
    </>
  )
}

export default DataRekening