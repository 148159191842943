import { Box, Typography, capitalize } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Dark, TextGray, mainColor } from '../../Config/Color'
import axios from 'axios'
import { getHistoryActivity } from '../../Config/Api'

const Aktivitas = ({
    isMobile,
    isTablet
}) => {

    const [history, setHistory] = useState([])
    const [username, setUsername] = useState('')

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
            await axios.get(getHistoryActivity)
                .then((response) => {
                    console.log('getHistoryActivity', response.data);
                    setHistory(response.data)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const getDataUser = () => {
        const logInfo = localStorage.getItem('LogInfo')

        if (logInfo) {
            const userName = JSON.parse(logInfo).userName
            setUsername(userName)
            // console.log('username',userName);
        }
    }

    useEffect(() => {
        getData()
        getDataUser()
    }, [])


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                // backgroundColor:'orange'
                width: '100%',
                maxHeight: isMobile || isTablet ? '100%' : '350px',
                height: '350px',
                overflow: 'auto', // Aktifkan pengguliran vertikal
                // overflowX: 'hidden',
                '&::-webkit-scrollbar': {
                    width: '1px', // Adjust the width as needed
                },
                '&::-webkit-scrollbar-thumb': {
                    backgroundColor: '#fff', // Change this to your brand color
                    borderRadius: '6px',
                },
                '&::-webkit-scrollbar-track': {
                    backgroundColor: '#f1f1f1', // Change this to the background color
                    borderRadius: '6px',
                },

            }}
        >
            {history.length > 0 ?
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: '15px',
                        // width: '100%',
                        maxHeight: isMobile || isTablet ? '100%' : '310px',
                        height: '310px',
                        // backgroundColor: 'orange',
                        padding: '16px',
                    }}
                >
                    {history.map((data, index) => {
                        return (
                            <Box
                                sx={{
                                    width: '100%'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: mainColor,
                                            fontSize: '12px',
                                            fonStyle: 'normal',
                                            fontWeight: 'bolder',
                                            lineHeight: 'normal',
                                            marginBottom: '2px'
                                        }}
                                    >
                                        {data.role === 'user' ? capitalize(username) : capitalize(data.role)}
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: TextGray,
                                            fontSize: '12px',
                                            fonStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            marginBottom: '2px'
                                        }}
                                    >
                                        {data.waktu}
                                    </Typography>
                                </Box>

                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: 'black',
                                            fontSize: '14px',
                                            fonStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        {data.activity}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })}

                </Box>
                :
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // gap: '16px',
                        // width: '100%',
                        maxHeight: isMobile || isTablet ? '100%' : '310px',
                        height: '310px',
                        // backgroundColor: 'orange',
                        padding: '16px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Dark,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: '26px',
                        }}
                    >
                        Belum ada aktivitas layanan pada akun ini.
                    </Typography>
                </Box>
            }
        </Box>
    )
}

export default Aktivitas