import React, { useState, useRef, useEffect } from 'react';
import { Dark, DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { Typography, Box, Card, Button, TextareaAutosize, Divider, Checkbox, Grid, Autocomplete, TextField, InputAdornment } from '@mui/material'; // Import TextareaAutosize from MUI
import { logDOM } from '@testing-library/react';
import { Icon } from '@iconify/react';
import { useFormContext } from './FormContext';

import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import axios from 'axios';
import { RegistrasiTahapII_and_TahapIII, agreementQuestion, deletepersonalinformation, listDataUniversity } from '../../components/Config/Api';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert';
import IconCheck from '../../assets/Icon/check.png'
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import QRCode from 'react-qr-code';
import VerificationDataModal from '../../components/Modal/VerificationDataModal/VerificationDataModal';
import { encrypt, decrypt } from 'n-krypta';
import { secretKey } from '../../components/Config/SecretKey';
import { red } from '@mui/material/colors';
import LocationDetectorModal from '../../components/Modal/LocationDetector/LocationDetectorModal';
import LoadingData from '../../components/LoadingPage/LoadingData';
import FileToBase64 from '../../components/FileToBase64/FileToBase64';
import Base64ToFile from '../../components/Base64ToFile/Base64ToFile';

const FormTahap3 = ({
    isMobile,
    isTablet,
    backStep1,
    backStep2
}) => {

    const navigate = useNavigate()

    const [selectedImageTTD, setSelectedImageTTD] = useState(null);
    const [imageUrlTTD, setImageUrlTTD] = useState(null);
    const fileInputRefTTD = useRef(null);

    const [emailUser, setEmailUser] = useState('')
    const [listAgreement, setListAgreement] = useState(null)
    const [categoryBeasiswa, setCategoryBeasiswa] = useState(null)
    const [loadingSimpanData, setloadingSimpanData] = useState(false)
    const [answer, setAnswer] = useState(''); // State to manage the value of the textarea
    const [answer2, setAnswer2] = useState(''); // State to manage the value of the textarea
    const [userSignature, setUserSignature] = useState(''); // State to manage the value of the textarea
    const [qrSignature, setQrSignature] = useState(''); // State to manage the value of the textarea
    const [checkboxValues, setCheckboxValues] = useState({
        checked: false,
    });
    const [selectedUniverityNew, setSelectedUniverityNew] = useState('');
    const [selectedUniverity, setSelectedUniverity] = useState('');
    const [UniversityExist, setUniversityExist] = useState('');
    const [listUniversity, setListUniversity] = useState([])

    const [notificationSuccessRegister, setNotificationSuccessRegister] = useState('N')
    const [finishRegister, setFinishRegister] = useState('N')
    const [loadingKonfirmasiPendaftaran, setLoadingKonfirmasiPendaftaran] = useState(false)

    const [openVerificationDataModal, setOpenVerificationDataModal] = useState(false)
    const [openLocationDetectorModal, setOpenLocationDetectorModal] = useState(false)

    const [location, setLocation] = useState(null);
    const [permissionDenied, setPermissionDenied] = useState(false);
    const [permissionStatus, setPermissionStatus] = useState('');
    const [isButtonPermissionLoading, setIsButtonPermissionLoading] = useState(false);

    const maxCharactersAnswer1 = 300;
    const minCharactersAnswer1 = 300;
    const maxCharactersAnswer2 = 300;
    const minCharactersAnswer2 = 300;

    const [isDragging, setIsDragging] = useState({
        isDraggingDigitalTTD: false,
    });

    // const secretKey = 'key'

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const [isPageLoading, setIsPageLoading] = useState(false);

    useEffect(() => {
        if (listUniversity.length === 0) {
            setIsPageLoading(true)
            setTimeout(() => {
                setIsPageLoading(false)
            }, 2000);
        }
    }, [])

    const handleImageUpload = (fileInput) => {
        fileInput.current.click();
    };

    const handleRemoveImage = (removeImage) => {

        if (removeImage === 'user signature') {
            setSelectedImageTTD(null);
            setImageUrlTTD(null);
        }
    };

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingDigitalTTD: true
        })
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        setIsDragging({
            isDraggingDigitalTTD: false
        })
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingDigitalTTD: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleFileInputChange = (file, fileInputChange) => {
        // const file = event.target.files[0];
        // console.log(file);
        if (file) {
            // Check the file size
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                })
                // // Optionally, clear the file input
                // event.target.value = null;
                // return;
            } else {
                // Check the file type
                const fileType = file.type;
                const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                const allowedPdfTypes = ['application/pdf'];

                if (allowedImageTypes.includes(fileType)) {
                    // Handle Image File
                    if (fileInputChange === 'user signature') {
                        setSelectedImageTTD(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlTTD(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    // Handle PDF file
                    if (fileInputChange === 'user signature') {
                        setSelectedImageTTD(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlTTD(imageUrl);
                    }
                } else {
                    // Unsupported file type
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png, gif dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    // Helper function to convert base64 to File object



    const getAlreadyExistData = (token) => {

        const fileSelectedImageTTD = Base64ToFile(token.selectedImageTTDExist.base64, token.selectedImageTTDExist.name); // Adjust file name and type as needed
        setImageUrlTTD(token.selectedImageTTDExist.base64 ? token.selectedImageTTDExist.base64 : null);
        setSelectedImageTTD(fileSelectedImageTTD);

        // console.log('imageTTD', fileSelectedImageTTD);

        setAnswer(token.answer1Exist ?
            token.answer1Exist
            :
            ''
        )

        setAnswer2(token.answer2Exist ?
            token.answer2Exist
            :
            ''
        )

        setCheckboxValues({
            checked: token.checkboxValues.checked ?
                token.checkboxValues.checked
                :
                false
        })

        setUniversityExist(token.selectedUniversityExist ?
            token.selectedUniversityExist
            :
            ''
        )

        setEmailUser(token.userEmailExist ? token.userEmailExist : '')

        // setTimeout(() => {
        //     setOpenVerificationDataModal(
        //         decryptedString.answer1Exist &&
        //             decryptedString.answer2Exist &&
        //             decryptedString.checkboxValues.checked ?
        //             true
        //             :
        //             false
        //     )
        // }, 1500);
    }

    const data = localStorage.getItem('data')

    useEffect(() => {
        if (data) {
            const { token, dataStep3 } = JSON.parse(data)

            if (dataStep3) {
                getAlreadyExistData(dataStep3)
            }
        }
    }, [data])

    const getData = async () => {
        await axios.get(agreementQuestion)
            .then((response) => {
                // console.log(response.data);
                setCategoryBeasiswa(response.data[0].category_name);
                setListAgreement(response.data[0].descriptions);
            }).catch((error) => {
                console.log(error.response.data);
            });

        await axios.get(listDataUniversity)
            .then((response) => {
                // console.log('data universitas', response.data);
                const data = response.data?.data;
                // Filter data untuk menghilangkan yang kosong, karakter aneh, dan entri whitespace
                const universities = data.map(item => item.nama_pt)
                    .filter(name => name && name.trim() && !name.includes('\\'));
                const uniqueUniversities = Array.from(new Set(universities)); // Menghapus duplikat universitas
                setListUniversity(uniqueUniversities);
            }).catch((error) => {
                console.log(error.response.data);
            });
    };

    // console.log('data location', location);

    const askForLocationPermission = async () => {
        if (permissionStatus === 'denied' || permissionStatus === 'prompt') {
            setIsButtonPermissionLoading(true)
        }

        navigator.geolocation.getCurrentPosition(
            async (position) => {
                try {
                    const { latitude, longitude } = position.coords;
                    const locationData = await fetch(
                        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=id`
                    );
                    const locationJson = await locationData.json();
                    setLocation(locationJson);
                    // console.log('locationJson', locationJson,);
                    if (permissionStatus === 'denied' || permissionStatus === 'prompt') {
                        setIsButtonPermissionLoading(true)
                        setOpenLocationDetectorModal(false)
                        setIsButtonPermissionLoading(false)
                        SuccessAlert({
                            message: ' Berhasil memberikan akses lokasi',
                            position: 'top-center'
                        })
                        setloadingSimpanData(false)
                    }

                    // setOpenVerificationDataModal(true)
                    setPermissionStatus('')
                } catch (error) {
                    console.error('Error fetching location:', error);
                    setIsButtonPermissionLoading(false)
                }
            },
            (error) => {
                if (error.code === error.PERMISSION_DENIED) {
                    setPermissionDenied(true);
                }
                console.error('Error getting user location:', error);
                ErrorAlert({
                    message: 'Wajib memberikan akses lokasi',
                    position: 'top-center'
                })
                if (permissionStatus === 'denied' || permissionStatus === 'prompt') {
                    setIsButtonPermissionLoading(false)
                    setloadingSimpanData(false)
                }

            }
        );
    };

    // console.log(permissionDenied);

    // Check location permission status when component mounts
    const checkLocationPermission = async () => {
        try {
            const permission = await navigator.permissions.query({ name: 'geolocation' });
            // console.log(permission.state)
            if (permission.state === 'denied' || permission.state === 'prompt') {
                setOpenLocationDetectorModal(true)
            } else if (permission.state === 'granted') {
                askForLocationPermission()
            }
            setPermissionStatus(permission.state)
        } catch (error) {
            console.error('Error checking location permission:', error);
        }
    };

    useEffect(() => {
        checkLocationPermission()
        getData()
    }, [])



    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }


    const handleTextareaChange = (event) => {
        setAnswer(event.target.value);
    };

    const handleTextareaChange2 = (event) => {
        setAnswer2(event.target.value);
    };

    const handleCheckboxChange = (checked) => {
        setCheckboxValues((prevValues) => ({
            ...prevValues,
            [checked]: !prevValues[checked],
        }));
        // console.log(checkboxValues.checked);
    };

    const handleSubmitSuccessRegistered = () => {
        setLoadingKonfirmasiPendaftaran(true)
        setTimeout(() => {
            navigate('/login')
            setLoadingKonfirmasiPendaftaran(false)
        }, 1500);
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        setloadingSimpanData(true)

        // console.log('userFullName', userFullName);
        // console.log('userNIK', userNIK);
        // console.log('selectedImageNIK', selectedImageNIK);
        // console.log('userNoKK', userNoKK);
        // console.log('selectedImageKK', selectedImageKK);
        // console.log('userEmail', userEmail);
        // console.log('userMotherName', userMotherName);
        // console.log('userFatherName', userFatherName);
        // console.log('userWhatsappNumber', userWhatsappNumber);
        // console.log('selectedCertificate1', selectedCertificate1);
        // console.log('selectedCertificate2', selectedCertificate2);
        // console.log('selectedCertificate3', selectedCertificate3);
        // console.log('selectedToelf', selectedToelf);

        if (permissionStatus === 'prompt' || permissionStatus === 'denied') {
            setOpenLocationDetectorModal(true)
        } else {
            if (checkboxValues.checked &&
                answer !== '' &&
                answer2 !== '' &&
                selectedImageTTD &&
                answer.length > minCharactersAnswer1 &&
                answer2.length > minCharactersAnswer2
            ) {
                const data = localStorage.getItem('data')
                // console.log('selectedImageTTD', selectedImageTTD);
                const selectedImageTTDBase64 = selectedImageTTD ? await FileToBase64(selectedImageTTD) : null;

                let values = {
                    checkboxValues: checkboxValues,
                    answer1Exist: answer,
                    answer2Exist: answer2,
                    selectedUniversityExist: selectedUniverity,
                    userLocation: location ? `${location.city}, ${location.countryName}` : console.log('No location data'),
                    selectedImageTTDExist: {
                        lastModified: selectedImageTTD.lastModified,
                        lastModifiedDate: selectedImageTTD.lastModifiedDate,
                        name: selectedImageTTD.name,
                        type: selectedImageTTD.type,
                        size: selectedImageTTD.size,
                        webkitRelativePath: selectedImageTTD.webkitRelativePath,
                        url: selectedImageTTD ? URL.createObjectURL(selectedImageTTD) : null,
                        base64: selectedImageTTDBase64,
                    },
                }

                if (data) {
                    // Ambil data yang sudah ada dari localStorage
                    const existingData = JSON.parse(localStorage.getItem('data')) || {};

                    // Tambahkan data baru ke dalam objek existingData
                    existingData.dataStep3 = values;

                    // Simpan kembali objek yang telah diperbarui ke dalam localStorage
                    localStorage.setItem('data', JSON.stringify(existingData));
                }

                setTimeout(() => {
                    setOpenVerificationDataModal(true)
                    setloadingSimpanData(false)
                }, 1500);

            } else if (answer.length < minCharactersAnswer1 || answer2.length < minCharactersAnswer2) {
                ErrorAlert({
                    message: 'Jawaban minimal 300 karakter atau lebih',
                    position: 'top-center'
                })
                setloadingSimpanData(false)
            } else if (checkboxValues.checked === false) {
                ErrorAlert({
                    message: 'Wajib menyetujui peryantaan dibawah',
                    position: 'top-center'
                })
                setloadingSimpanData(false)
            } else if (selectedImageTTD === null) {
                ErrorAlert({
                    message: 'Wajib upload tanda tangan digital.',
                    position: 'top-center'
                })
                setloadingSimpanData(false)
            }
        }
    }


    return (
        notificationSuccessRegister === 'N' ?
            <form onSubmit={handleSubmit} style={{ overflow: 'hidden' }}>
                <Grid container>
                    <Grid item xs={12} md={12}>
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '16px',
                                fontStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                                marginBottom: '10px'
                            }}
                        >
                            Lengkapi Kuisioner
                        </Typography>
                    </Grid>

                    {/* Dropdown Kota Asal */}
                    <Grid item xs={12} md={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            textAlign: 'center',
                            // marginBottom: verificationStatus ? isMobile ? '24px' : '24px' : undefined,
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '20px',
                                width: isMobile || isTablet ? '100%' : '50%',
                                maxWidth: isMobile || isTablet ? '100%' : '450px',
                                // minWidth: isMobile ? '100%' : '40%'
                                // backgroundColor: 'orange',
                                margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 10px 0px'
                            }}
                        >
                            <Autocomplete
                                fullWidth
                                // disabled={verificationStatus ? false : true}
                                freeSolo
                                autoSelect // Aktifkan pemilihan otomatis saat mengetik
                                selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                                sx={{ marginBottom: '10px' }}
                                id="university"
                                options={listUniversity.sort((a, b) => a.localeCompare(b))} // Mengurutkan opsi universitas secara alfabetis
                                getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                                value={selectedUniverity} // Mengikuti nilai yang dipilih
                                onChange={(event, newValue) => {
                                    setSelectedUniverityNew(newValue); // Perbarui nilai selectedDistrict
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant='standard'
                                        label="Pilih Universitas"
                                        // placeholder='asdsad'
                                        required
                                        InputProps={{
                                            ...params.InputProps,
                                            startAdornment: listUniversity.length > 0 ? (
                                                setSelectedUniverity(selectedUniverityNew ? selectedUniverityNew : UniversityExist)
                                            )
                                                :
                                                (
                                                    <InputAdornment
                                                        sx={{
                                                            // marginLeft:'5px'
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                // color: '#1E222F',
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '14px',
                                                                lineHeight: '20px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                marginRight: '6px'
                                                            }}
                                                        >
                                                            Loading
                                                        </Typography>
                                                        <Icon icon='svg-spinners:3-dots-fade' width='20px' height='20px' />
                                                    </InputAdornment>
                                                ),
                                            // type: 'search',
                                            // sx: { margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 0px 0px' }
                                        }}
                                    />
                                )}
                                disabled={listUniversity.length > 0 ? false : true}
                            />
                            {/* <Typography>
                                Loading. . 
                            </Typography> */}
                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{
                                marginBottom: '24px'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    textAlign: 'justify',
                                    lineHeight: '20px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginBottom: '10px',
                                }}
                            >
                                Kenapa saudara(i) adalah kandidat yang tepat untuk menerima beasiswa atau bantuan ini? {<span style={{ color: red[700] }}>*</span>}
                            </Typography>
                            <TextareaAutosize
                                value={answer}
                                // maxLength={maxCharactersAnswer1}
                                minLength={minCharactersAnswer1}
                                onChange={handleTextareaChange}
                                placeholder="Tulis jawaban Anda di sini..."
                                style={{
                                    width: isMobile ? '94%' : '98%',
                                    height: '137px',
                                    padding: '8px',
                                    fontSize: '14px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    resize: 'vertical',
                                    lineHeight: '20px'
                                    // transition: `${mainColor} 0.3s ease-in-out`
                                }}
                                rowsMin={4} // You can adjust the number of rows as needed
                                required
                            />
                            {/* <Box sx={{ textAlign: 'left', fontSize: '12px', color: 'gray' }}>
                                {maxCharactersAnswer1 - answer.length} / {maxCharactersAnswer1}
                            </Box> */}
                            {answer.length < minCharactersAnswer1 && (
                                <Box
                                    sx={{
                                        textAlign: 'left', fontSize: '12px', color: red[700]
                                    }}
                                >
                                    Minimal {minCharactersAnswer1 - answer.length} karakter
                                </Box>
                            )}

                        </Box>
                    </Grid>

                    <Grid item xs={12} md={12}>
                        <Box
                            sx={{
                                marginBottom: '24px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    marginBottom: '10px',
                                    fontSize: '14px',
                                    textAlign: 'justify',
                                    lineHeight: '20px',
                                }}
                            >
                                Apa yang hendak saudara(i) kontribusikan untuk Papua Tengah setelah menyelesaikan kewajiban studi saudara(i) jika terpilih? {<span style={{ color: red[700] }}>*</span>}
                            </Typography>
                            <TextareaAutosize
                                value={answer2}
                                // maxLength={maxCharactersAnswer2}
                                minLength={minCharactersAnswer2}
                                onChange={handleTextareaChange2}
                                placeholder="Tulis jawaban Anda di sini..."
                                style={{
                                    width: isMobile ? '94%' : '98%',
                                    height: '137px',
                                    padding: '8px',
                                    fontSize: '14px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    resize: 'vertical',
                                    lineHeight: '20px'
                                }}
                                rowsMin={4} // You can adjust the number of rows as needed
                                required
                            />
                            {/* <Box sx={{ textAlign: 'left', fontSize: '12px', color: 'gray' }}>
                                {maxCharactersAnswer2 - answer2.length} / {maxCharactersAnswer2}
                            </Box> */}
                            {answer2.length < minCharactersAnswer2 && (
                                <Box
                                    sx={{
                                        textAlign: 'left', fontSize: '12px', color: red[700]
                                    }}
                                >
                                    Minimal {minCharactersAnswer2 - answer2.length} karakter
                                </Box>
                            )}
                        </Box>
                    </Grid>
                </Grid>

                <Grid item xs={12} md={12}>
                    <Card
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                padding: '16px',
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                    marginBottom: '10px',
                                    textAlign: 'justify'
                                }}
                            >
                                Dengan ini saya menyetujui, menyatakan dan menerima beberapa poin berikut ini:
                            </Typography>
                            <Box
                                sx={{
                                    marginTop: '16px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '20px'
                                }}
                            >
                                {listAgreement && listAgreement.map((data, index) => {
                                    // console.log('tes', data);
                                    return (
                                        <Box
                                            key={index}
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'flex-start',
                                                alignItems: 'flex-start',
                                                gap: '5px'
                                            }}
                                        >
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '22px',
                                                    marginBottom: '5px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                {data.number}.
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '22px',
                                                    marginBottom: '5px',
                                                    textAlign: 'justify'
                                                }}
                                            >
                                                {data.description}
                                            </Typography>
                                        </Box>
                                    )
                                })}

                            </Box>
                        </Box>

                        <Box
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Divider
                                variant="string"
                                style={{
                                    backgroundColor: "#fff",
                                    borderBottomWidth: 0.5,
                                    // marginTop: "2px",
                                }}
                            />
                        </Box>

                        {/* Signature */}
                        <Grid
                            container
                            // spacing={2}
                            xs={12}
                            sm={12}
                            sx={{
                                padding: '10px',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Grid item xs={12} sm={12}>
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '17,64px',
                                    }}
                                >
                                    Unggah Tanda Tangan
                                </Typography>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={{ marginTop: '5px', }}>
                                <Box
                                    sx={{
                                        maxWidth: isMobile ? '100%' : '286px',
                                        width: '100%',
                                        overflow: 'hidden',
                                        height: '140px',
                                        backgroundColor: '#fff',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        gap: '12px',
                                        position: 'relative',
                                        border: `solid 2px ${DividerColor}`,
                                        borderStyle: 'dashed',
                                    }}
                                    onDragOver={(e) => handleDragOver(e, 'user signature')}
                                    onDrop={(e) => handleDrop(e, 'user signature')}
                                    onDragLeave={handleDragLeave}
                                >
                                    {isDragging.isDraggingDigitalTTD && !isMobile ?
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '13px',
                                                fontStyle: 'normal',
                                                fontWeight: 'bold',
                                                lineHeight: '17,64px',
                                                textAlign: 'center',
                                                color: mainColor
                                            }}
                                        >
                                            Lepaskan untuk mengunggah
                                        </Typography>
                                        :
                                        selectedImageTTD && selectedImageTTD.type === 'application/pdf' ? (
                                            // Handle PDF display logic
                                            <>
                                                {handleShowPDF(selectedImageTTD.name)}
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '50%',
                                                        padding: '2px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleRemoveImage('user signature')}
                                                >
                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                </Box>
                                            </>
                                        ) : imageUrlTTD ? (
                                            // Handle image display logic
                                            <>
                                                <img
                                                    src={imageUrlTTD}
                                                    alt="selected-image"
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'contain',
                                                        borderRadius: '4px',
                                                    }}
                                                />
                                                <Box
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '50%',
                                                        padding: '2px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleRemoveImage('user signature')}
                                                >
                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                </Box>
                                            </>
                                        ) : (
                                            // Display upload options
                                            <>
                                                {!isMobile ?
                                                    <>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '13px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '17,64px',
                                                                textAlign: 'center'
                                                            }}
                                                        >
                                                            {imageUrlTTD ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                                fontSize: '11px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '17,64px',
                                                                color: TextGray
                                                            }}
                                                        >
                                                            atau
                                                        </Typography>
                                                    </>
                                                    :
                                                    undefined
                                                }
                                                <div
                                                    onClick={() => handleImageUpload(fileInputRefTTD)}
                                                    style={{
                                                        cursor: 'pointer',
                                                        padding: '6px 12px',
                                                        border: '1px solid #ccc',
                                                        borderRadius: '4px',
                                                        display: 'inline-block',
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '12px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        backgroundColor: bgColor
                                                    }}
                                                >
                                                    Pilih {imageUrlTTD ? 'Gambar' : 'Gambar/PDF'}
                                                </div>
                                            </>
                                        )
                                    }
                                    <input
                                        type="file"
                                        accept="image/*,.pdf"  // Accept both image and PDF files
                                        onChange={(e) => handleFileInputChange(e.target.files[0], 'user signature')}
                                        ref={fileInputRefTTD}
                                        style={{ display: 'none' }}
                                    />
                                </Box>
                            </Grid>

                            <Grid item xs={12} sm={4} sx={{ margin: isMobile ? '50px 0px 10px 0px' : undefined }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        gap: '5px'
                                    }}
                                >
                                    <QRCode
                                        value='testing'
                                        size={200}
                                        style={{
                                            height: "95px",
                                            maxWidth: "95px",
                                            width: "95px",
                                            marginBottom: '5px'
                                            // padding: '10px',
                                            // border: 'solid 2px #D9D9D9',
                                            // backgroundColor: brandColor,
                                            // borderRadius: '10px',
                                        }}
                                        viewBox={`0 0 256 256`}
                                    />
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            color: Dark,
                                        }}
                                    >
                                        Darrent Watts
                                    </Typography>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            color: Dark
                                        }}
                                    >
                                        {moment(new Date()).format('DD-MMMM-YYYY')}
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>

                        <Box
                            sx={{
                                width: '100%'
                            }}
                        >
                            <Divider
                                variant="string"
                                style={{
                                    backgroundColor: "#fff",
                                    borderBottomWidth: 0.5,
                                    // marginTop: "2px",
                                }}
                            />
                        </Box>

                        <Box
                            sx={{
                                // padding: '16px'
                                // display:'flex',
                                // flexDirection:'row',
                                // justifyContent:'flex-start',
                                // alignItems:'center'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '22px',
                                    marginBottom: '5px',
                                    textAlign: 'justify',
                                }}
                            >
                                <Checkbox
                                    checked={checkboxValues.checked}
                                    onChange={() => handleCheckboxChange('checked')}
                                // style={{ borderRadius: '50%' }}
                                />
                                Saya setuju dengan pernyataan diatas.
                            </Typography>
                        </Box>

                    </Card>
                </Grid>

                <Grid item sx={12} md={12}>
                    <Box
                        align='center'
                        sx={{
                            width: '100%',
                            marginTop: '26px',
                            marginBottom: '56px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // backgroundColor:'orange',
                        }}
                    >
                        <Button
                            // fullWidth
                            variant='contained'
                            size='medium'
                            type='submit'
                            sx={{
                                minWidth: '350px',
                                backgroundColor: btnBgColor,
                                color: mainColor,
                                padding: '10px 56px',
                                fontWeight: 'bold',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                            disabled={selectedUniverity || selectedUniverity !== '' ? false : true}
                        >
                            {loadingSimpanData ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Cek Data'
                            }
                        </Button>
                    </Box>
                </Grid>
                <LocationDetectorModal
                    open={openLocationDetectorModal}
                    close={() => setOpenLocationDetectorModal(false)}
                    askForLocationPermission={() => askForLocationPermission()}
                    location={location}
                    isLoading={isButtonPermissionLoading}
                />
                <VerificationDataModal
                    open={openVerificationDataModal}
                    openModal={() => setOpenVerificationDataModal(true)}
                    close={() => setOpenVerificationDataModal(false)}
                    notificationStatus={() => setNotificationSuccessRegister('Y')}
                    backStep1={() => backStep1()}
                    backStep2={() => backStep2()}
                />
                {isPageLoading ?
                    <LoadingData
                        open={isPageLoading}
                    // close={}
                    />
                    :
                    undefined
                }
            </form>
            :
            <Box
                align='center'
                sx={{
                    margin: '56px 0px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        gap: '24px',
                        width: isMobile ? '100%' : '60%'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '16px',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'center'
                        }}
                    >
                        Pendaftaran beasiswa berhasil, berkas telah tersimpan disistem dan akan diverifikasi oleh tim administrasi.
                    </Typography>
                    <img src={IconCheck} alt='logo-email' style={{ width: '80px', height: '80px' }} />
                    <Box
                        sx={{
                            padding: '10px',
                            borderRadius: '8px',
                            background: 'rgba(8, 46, 143, 0.20)',
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '16px',
                                // fontStyle:'italic',
                                fontWeight: '500',
                                lineHeight: '24px',
                                textAlign: 'center'
                            }}
                        >
                            Hasil verifikasi akan dikirimkan ke email {' '}
                            <span style={{
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '16px',
                                fontStyle: 'italic',
                                fontWeight: 'bold',
                                lineHeight: '24px',
                                color: mainColor
                            }}>
                                {emailUser}
                            </span>
                            {' '}
                            dalam kurun waktu 3x24 jam, pastikan untuk memeriksa email secara berkala.
                        </Typography>
                    </Box>
                    <Button
                        fullWidth
                        variant='contained'
                        size='medium'
                        onClick={handleSubmitSuccessRegistered}
                        sx={{
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            width: isMobile ? '100%' : '50%',
                            textTransform: 'none',
                            marginTop: '30px',
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor,
                            },
                        }}
                    >
                        {loadingKonfirmasiPendaftaran ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Login'
                        }
                    </Button>
                </Box>

            </Box>

    )
}

export default FormTahap3;
