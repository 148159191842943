import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import Registrasi from './pages/Registrasi/Registrasi';
import Login from './pages/Login/Login';
import DashboardApplicant from './pages/DashboardApplicant/DashboardApplicant';
import DataPribadi from './pages/DashboardApplicant/DataPribadi';
import OrangTua from './pages/DashboardApplicant/OrangTua';
import Pendidikan from './pages/DashboardApplicant/Pendidikan';
import Pengaduan from './pages/DashboardApplicant/Pengaduan';
import BerkasPersyaratan from './pages/DashboardApplicant/BerkasPersyaratan';
import EmailActivation from './components/activation/EmailActivation';
import RegisterClose from './components/Register/MaintanancePage/RegisterClose';
import LoadingPage from './components/LoadingPage/LoadingPage';
import FormExiting from './pages/Registrasi/Exiting/FormExiting';
import Agreement from './pages/DashboardApplicant/Agreement';
import Riwayat from './pages/DashboardApplicant/Riwayat';
import BeasiswaSUP from './pages/DashboardApplicant/BeasiswaSUP';
import DataRekening from './pages/DashboardApplicant/DataRekening';
import ForgetPassword from './pages/Login/ForgetPassword';
import ResetPassword from './pages/Login/ResetPassword';
import { useEffect, useState } from 'react';

// ----------------------------------------------------------------------

export default function Router() {

  const [status, setStatus] = useState('')

  useEffect(() => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      // console.log('sdad',JSON.parse(getDataLogin).sup);
      setStatus(JSON.parse(getDataLogin).sup)
    }
  }, [])


  const routes = useRoutes(
    status === 'nonsup' ?
      [
        {
          path: '/',
          element: <LoadingPage />,
        },
        {
          path: '/register',
          element: <Registrasi />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/dashboard-applicant',
          element: <DashboardApplicant />,
        },
        {
          path: '/data-pribadi',
          element: <DataPribadi />,
        },
        {
          path: '/orang-tua',
          element: <OrangTua />,
        },
        {
          path: '/pendidikan',
          element: <Pendidikan />,
        },
        {
          path: '/Pengaduan',
          element: <Pengaduan />,
        },
        {
          path: '/email-aktivasi',
          element: <EmailActivation />,
        },
        {
          path: '/berkas-persyaratan',
          element: <BerkasPersyaratan />,
        },
        {
          path: '/agreement',
          element: <Agreement />,
        },
        {
          path: '/pendaftaran-tutup',
          element: <RegisterClose />,
        },
        {
          path: '/verifikasisup',
          element: <FormExiting />,
        },
        {
          path: '/riwayat',
          element: <Riwayat />,
        },
        {
          path: '/data-rekening',
          element: <DataRekening />,
        },
        {
          path: '/lupa-kata-sandi',
          element: <ForgetPassword />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
        {
          path: '/beasiswa-sup',
          element: <BeasiswaSUP />,
        },
      ]
      :
      [
        {
          path: '/',
          element: <LoadingPage />,
        },
        {
          path: '/register',
          element: <Registrasi />,
        },
        {
          path: '/login',
          element: <Login />,
        },
        {
          path: '/dashboard-applicant',
          element: <DashboardApplicant />,
        },
        {
          path: '/data-pribadi',
          element: <DataPribadi />,
        },
        {
          path: '/orang-tua',
          element: <OrangTua />,
        },
        {
          path: '/pendidikan',
          element: <Pendidikan />,
        },
        {
          path: '/Pengaduan',
          element: <Pengaduan />,
        },
        {
          path: '/email-aktivasi',
          element: <EmailActivation />,
        },
        {
          path: '/berkas-persyaratan',
          element: <BerkasPersyaratan />,
        },
        {
          path: '/agreement',
          element: <Agreement />,
        },
        {
          path: '/pendaftaran-tutup',
          element: <RegisterClose />,
        },
        {
          path: '/verifikasisup',
          element: <FormExiting />,
        },
        {
          path: '/riwayat',
          element: <Riwayat />,
        },
        {
          path: '/beasiswa-sup',
          element: <BeasiswaSUP />,
        },
        {
          path: '/data-rekening',
          element: <DataRekening />,
        },
        {
          path: '/lupa-kata-sandi',
          element: <ForgetPassword />,
        },
        {
          path: '/reset-password',
          element: <ResetPassword />,
        },
      ]
  );

  return routes;
}
