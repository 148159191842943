import React, { useEffect, useState } from 'react';
import { Typography, Box, Card, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import BeasiswaBerprestasi from '../../components/Register/Category/BeasiswaBerprestasi';
import BeasiswaKeluargaMiskin from '../../components/Register/Category/BeasiswaKeluargaMiskin';
import { useFormContext } from './FormContext';
import { TextGray, mainColor } from '../../components/Config/Color';
import Kuisioner from '../../components/Register/Category/Kuisioner';
import HandleSessionTahap2 from '../../components/Session/HandleSessionTahap2';
import axios from 'axios';
import { deletepersonalinformation, getDataListActiveCategory } from '../../components/Config/Api';
import IkatanDinas from '../../components/Register/Category/IkatanDinas';
import { encrypt, decrypt } from 'n-krypta';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';
import { secretKey } from '../../components/Config/SecretKey';
import NotifTahapII from '../../components/Modal/NotifTahapII/NotifTahapII';
import { red } from '@mui/material/colors';
import LoadingData from '../../components/LoadingPage/LoadingData';
import NotifRefreshPage from '../../components/Modal/Notification/NotifRefreshPage';
import { useNavigate } from 'react-router-dom';


const FormTahap2 = ({ isMobile, isTablet, step3 }) => {
    // const secretKey = '@BCTdev2024WebPapua'
    const { updateFormData } = useFormContext();
    const { formData } = useFormContext();

    // Access formData properties as needed
    const {
        cekData,
        selectedCategoryExist,
        selectedJenjangPendidikanExist,
    } = formData;

    const navigate = useNavigate()

    const [activeCategory, setActiveCategory] = useState([]);
    const [selectedCategoryNew, setSelectedCategoryNew] = useState('Beasiswa Berprestasi');
    const [selectedJenjangPendidikanNew, setSelectedJenjangPendidikanNew] = useState('Strata 1(S1)');
    const [selectedCategory, setSelectedCategory] = useState('Beasiswa Berprestasi');
    const [selectedJenjangPendidikan, setSelectedJenjangPendidikan] = useState('Strata 1(S1)');
    const [openNotifModal, setOpenNotifModal] = useState(false)
    const [text, setText] = useState({
        label: '',
        message: ''
    })
    const [isPageLoading, setIsPageLoading] = useState(false);
    const [OpenNotifRefreshPage, setOpenNotifRefreshPage] = useState(false);

    const data = localStorage.getItem('data')

    useEffect(() => {
        // const data = localStorage.getItem('data')
        if (data) {
            if (JSON.parse(data).cekData === 'Ya') {
                setIsPageLoading(true)
                setTimeout(() => {
                    setIsPageLoading(false)
                }, 2500);
            }
        }

    }, [])

    // useEffect(() => {
    //     const item = localStorage.getItem('item');
    //     if (item) {
    //         const { personal_information_id } = JSON.parse(item);

    //         const handleBeforeUnload = (event) => {
    //             console.log('asd', personal_information_id);
    //             // Menggunakan sendBeacon dengan POST tetapi mengirimkan informasi metode DELETE
    //             // const url = `${deletepersonalinformation}/${personal_information_id}`;
    //             // const requestData = JSON.stringify({ _method: 'DELETE', personal_information_id });

    //             // // Mengirim data sebagai Blob dengan content-type application/json
    //             // const blob = new Blob([requestData], { type: 'application/json' });
    //             // navigator.sendBeacon(url, blob);
    //             const message = "Are you sure you want to leave? Your data will be lost.";
    //             event.preventDefault();
    //             event.returnValue = ''
    //             event.returnValue = message;
    //             return event.returnValue; // Some browsers require this line.
    //         };

    //         const handleUnload = () => {
    //             // Menggunakan sendBeacon dengan POST tetapi mengirimkan informasi metode DELETE
    //             const url = `${deletepersonalinformation}/${personal_information_id}`;
    //             const requestData = JSON.stringify({ _method: 'DELETE', personal_information_id });

    //             // Mengirim data sebagai Blob dengan content-type application/json
    //             const blob = new Blob([requestData], { type: 'application/json' });
    //             navigator.sendBeacon(url, blob);
    //             localStorage.removeItem('item');
    //             localStorage.removeItem('data');
    //         };

    //         window.addEventListener('beforeunload', handleBeforeUnload);
    //         window.addEventListener('unload', handleUnload);

    //         return () => {
    //             window.removeEventListener('beforeunload', handleBeforeUnload);
    //             window.removeEventListener('unload', handleUnload);
    //         };
    //     }
    // }, []);

    // useEffect(() => {
    //     const item = localStorage.getItem('item');

    //     if (item) {
    //         const parsedItem = JSON.parse(item);
    //         const personal_information_id = parsedItem.personal_information_id;
    //         const url = `${deletepersonalinformation}/${personal_information_id}`;

    //         const sendBeaconSuccess = navigator.sendBeacon(url, data);
    //         const handleVisibilityChange = () => {
    //             if (document.visibilityState === 'hidden') {
    //                 console.log('hapus data running', document.visibilityState);

    //                 const data = new Blob([], { type: 'application/json' });

    //                 if (sendBeaconSuccess) {
    //                     localStorage.removeItem('item');
    //                     localStorage.removeItem('data');
    //                 }
    //             }
    //         };

    //         window.addEventListener('visibilitychange', handleVisibilityChange);

    //         // Clean up the event listener when the component unmounts
    //         return () => {
    //             window.removeEventListener('visibilitychange', handleVisibilityChange);
    //         };
    //     }
    // }, []);



    // useEffect(() => {
    //     const item = localStorage.getItem('item');

    //     if (item) {
    //         console.log('aaaa', window.performance.navigation)
    //         if (window.performance.navigation.type > 0) {
    //             fetch(url, {
    //                 method: 'DELETE',
    //                 // headers: ... jika diperlukan
    //             }).then(response => {
    //                 console.log(response.data);
    //                 localStorage.removeItem('item');
    //                 localStorage.removeItem('data');
    //                 navigate('/register')
    //                 // Lakukan sesuatu setelah request berhasil (opsional)
    //             }).catch(error => {
    //                 console.error('Gagal melakukan request delete:', error);
    //             });
    //         }
    //     }
    // }, [])


    const getAlreadyExistData = (dataStep2, cekData) => {

        setSelectedCategory(dataStep2.selectedCategoryExist ? dataStep2.selectedCategoryExist : '')
        setSelectedJenjangPendidikan(dataStep2.selectedJenjangPendidikanExist ? dataStep2.selectedJenjangPendidikanExist : '')
        setSelectedCategoryNew(dataStep2.selectedCategoryExist ? dataStep2.selectedCategoryExist : '')
        setSelectedJenjangPendidikanNew(dataStep2.selectedJenjangPendidikanExist ? dataStep2.selectedJenjangPendidikanExist : '')

    }

    useEffect(() => {
        if (data) {
            const { dataStep2, cekData } = JSON.parse(data)
            // console.log('key', secretKey);
            if (dataStep2) {
                setTimeout(() => {
                    // const decryptedString = decrypt(dataStep2, secretKey)
                    console.log('dataStep2', dataStep2);
                    if (cekData === 'Ya' && dataStep2) {
                        getAlreadyExistData(dataStep2, cekData)
                    }
                }, 1000);
            }
        }
    }, [data]);

    const getDataCategory = async () => {
        const categories = new Set();
        await axios.get(getDataListActiveCategory)
            .then((response) => {
                // console.log('list category', response.data);
                const res = response.data;

                res.forEach(data => {
                    if (data.category !== "Siswa Unggul Papua") {
                        categories.add(JSON.stringify({ category: data.category, status: data.status }));
                    }
                });


                const data = Array.from(categories).map(item => JSON.parse(item))
                const currentActiveCategory = data.find(data => data.status === true);

                setActiveCategory(data)

                setSelectedCategory(currentActiveCategory ? currentActiveCategory.category : '')
                setSelectedCategoryNew(currentActiveCategory ? currentActiveCategory.category : '')

                // console.log('cekActiveCategory', currentActiveCategory);
            }).catch((error) => {
                console.log(error.response.data);
            });
    };

    useEffect(() => {
        getDataCategory()
    }, [])

    const handleCategoryClick = (item) => {
        // setSelectedCategory(item.category);
        // console.log('item', item);
        if (item.status) {
            setSelectedCategory(item.category);
        } else {
            ErrorAlert({
                message: `Pendaftaran ${item.category} belum dibuka`,
                position: 'top-center'
            })
        }
    };

    const handleJenjangPendidikan = (jenjangPendidikan) => {
        setSelectedJenjangPendidikan(jenjangPendidikan);
        updateFormData({ selectedJenjangPendidikanExist: jenjangPendidikan }); // Update selectedJenjangPendidikanExist in formData
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography
                    sx={{
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                    }}
                >
                    Pilih Kategori beasiswa
                </Typography>
                <Card
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        marginBottom: '24px',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid container item>
                            {activeCategory.map((item, index) => (
                                <Grid
                                    key={index}
                                    item
                                    xs={isMobile ? 12 : 6}
                                    onClick={() => {
                                        if (data) {
                                            const { dataStep2, cekData } = JSON.parse(data)
                                            if (dataStep2) {
                                                // const decryptedString = decrypt(token, secretKey)
                                                if (item.category !== selectedCategoryExist) {
                                                    if (item.status) {
                                                        console.log('tesss');
                                                        setSelectedCategoryNew(item)
                                                        setText({
                                                            label: 'Mengubah Kategori',
                                                            message:
                                                                <Typography
                                                                    sx={{
                                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                                        fontSize: isMobile ? '12px' : '14px',
                                                                        fontWeight: '500',
                                                                    }}
                                                                >
                                                                    Mengubah
                                                                    {' '}
                                                                    <span
                                                                        style={{
                                                                            // color: mainColor,
                                                                            fontWeight: 'bolder'
                                                                        }}
                                                                    >
                                                                        Kategori
                                                                    </span>
                                                                    {' '}
                                                                    akan
                                                                    {' '}
                                                                    <span
                                                                        style={{
                                                                            // color: mainColor,
                                                                            fontWeight: 'bolder'
                                                                        }}
                                                                    >
                                                                        Menghapus
                                                                    </span>
                                                                    {' '}
                                                                    semua data
                                                                    {' '}
                                                                    <span
                                                                        style={{
                                                                            color: mainColor,
                                                                            fontWeight: 'bold'
                                                                        }}
                                                                    >
                                                                        Tahap II
                                                                    </span>{' '}
                                                                    yang telah disimpan.
                                                                </Typography>
                                                        })
                                                        setOpenNotifModal(true)
                                                    } else {
                                                        ErrorAlert({
                                                            message: `${item.category} sedang tidak dibuka`,
                                                            position: 'top-center'
                                                        })
                                                    }

                                                }
                                            } else {
                                                handleCategoryClick(item)
                                            }
                                        } else {
                                            handleCategoryClick(item)
                                        }
                                    }}
                                    sx={{
                                        // padding: '14px',
                                    }}
                                >
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: isMobile ? 'flex-start' : 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                            padding: '16px',
                                            cursor: 'pointer',
                                            backgroundColor: selectedCategory === item.category ? mainColor : item.status === false ? 'white' : 'white',
                                        }}
                                    >
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: isMobile ? 'flex-start' : 'center',
                                                alignItems: 'center',
                                                gap: '8px',
                                            }}
                                        >
                                            {selectedCategory === item.category && (
                                                <Icon icon="icon-park-solid:check-one" style={{ fontSize: '18px', color: 'white' }} />
                                            )}
                                            <Typography
                                                sx={{
                                                    color: selectedCategory === item.category ? '#E6ECFC' : item.status === false ? TextGray : 'black',
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '14px',
                                                    fontWeight: selectedCategory === item.category ? 'bold' : '500',
                                                }}
                                            >
                                                {item.category}
                                            </Typography>
                                        </Box>
                                        {!item.status &&
                                            <Box
                                                sx={{
                                                    margin: '-4px 0px 0px -5px'
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        color: red[700],
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontWeight: 'bold',
                                                    }}
                                                >
                                                    Ditutup
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                </Grid>
                            ))}
                        </Grid>

                    </Grid>
                </Card>
            </Grid>


            {selectedCategory === 'Beasiswa Berprestasi' || selectedCategory === 'Bantuan Studi Akhir' ?
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px',
                        }}
                    >
                        Jenjang pendidikan yang akan ditempuh
                    </Typography>
                    <Card
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            marginBottom: '24px',
                        }}
                    >
                        {['Strata 1(S1)', 'Strata 2(S2)', 'Strata 3(S3)'].map((jenjangPendidikan, index) => (
                            <Grid
                                key={index}
                                item
                                xs={12}
                                md={isMobile ? 12 : 4}
                                onClick={() => {
                                    if (data) {
                                        const { dataStep2, cekData } = JSON.parse(data)
                                        if (dataStep2) {
                                            // console.log('asdsad', token);
                                            // const decryptedString = decrypt(token, secretKey)
                                            if (jenjangPendidikan !== selectedJenjangPendidikanExist) {
                                                // console.log('asdsad', jenjangPendidikan);
                                                // console.log('jenjangpendidikan 1');
                                                // console.log('asdsad',jenjangPendidikan);
                                                setSelectedJenjangPendidikanNew(jenjangPendidikan)
                                                // setSelectedCategoryNew(selectedCategoryExist)
                                                // setSelectedJenjangPendidikan(jenjangPendidikan)
                                                setText({
                                                    label: 'Mengubah Jenjang Pendidikan',
                                                    message: <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: isMobile ? '12px' : '14px',
                                                            fontWeight: '500',
                                                        }}
                                                    >
                                                        Mengubah
                                                        {' '}
                                                        <span
                                                            style={{
                                                                // color: mainColor,
                                                                fontWeight: 'bolder'
                                                            }}
                                                        >
                                                            Jenjang Pendidikan
                                                        </span>
                                                        {' '}
                                                        akan
                                                        {' '}
                                                        <span
                                                            style={{
                                                                // color: mainColor,
                                                                fontWeight: 'bolder'
                                                            }}
                                                        >
                                                            Menghapus
                                                        </span>
                                                        {' '}
                                                        semua data
                                                        {' '}
                                                        <span
                                                            style={{
                                                                color: mainColor,
                                                                fontWeight: 'bold'
                                                            }}
                                                        >
                                                            Tahap II
                                                        </span>{' '}
                                                        yang telah disimpan.
                                                    </Typography>
                                                })
                                                setOpenNotifModal(true)
                                            }
                                        } else {
                                            // console.log('jenjangpendidikan 2');
                                            handleJenjangPendidikan(jenjangPendidikan)
                                        }
                                    } else {
                                        // console.log('jenjangpendidikan 3');

                                        handleJenjangPendidikan(jenjangPendidikan)
                                    }
                                }}
                                sx={{
                                    padding: '14px',
                                    cursor: 'pointer',
                                    backgroundColor: selectedJenjangPendidikan === jenjangPendidikan ? mainColor : 'white',
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: isMobile ? 'flex-start' : 'center',
                                        alignItems: 'center',
                                        gap: '8px',
                                    }}
                                >
                                    {selectedJenjangPendidikan === jenjangPendidikan && (
                                        <Icon icon="icon-park-solid:check-one" style={{ fontSize: '18px', color: 'white' }} />
                                    )}
                                    <Typography
                                        sx={{
                                            color: selectedJenjangPendidikan === jenjangPendidikan ? '#E6ECFC' : 'black',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '14px',
                                            fontWeight: selectedJenjangPendidikan === jenjangPendidikan ? 'bold' : '500',
                                        }}
                                    >
                                        {jenjangPendidikan}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))}
                    </Card>
                </Grid>
                :
                undefined
            }

            <Grid item xs={12}>
                {selectedCategory === 'Beasiswa Berprestasi' ?
                    <BeasiswaBerprestasi
                        isMobile={isMobile}
                        isTablet={isTablet}
                        selectedCategory={selectedCategory}
                        selectedJenjangPendidikan={selectedJenjangPendidikan}
                        step3={step3}
                        activeCategory={activeCategory}
                    />
                    :
                    selectedCategory === 'Beasiswa Keluarga Miskin' ?
                        <BeasiswaKeluargaMiskin
                            isMobile={isMobile}
                            isTablet={isTablet}
                            selectedCategory={selectedCategory}
                            selectedJenjangPendidikan={selectedJenjangPendidikan}
                            activeCategory={activeCategory}
                            step3={step3}
                        />
                        :
                        selectedCategory === 'Ikatan Dinas' ?
                            <IkatanDinas
                                isMobile={isMobile}
                                isTablet={isTablet}
                                selectedCategory={selectedCategory}
                                selectedJenjangPendidikan={selectedJenjangPendidikan}
                                activeCategory={activeCategory}
                                step3={step3}
                            />

                            :
                            selectedCategory === 'Bantuan Studi Akhir' ?
                                <Kuisioner
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                    selectedCategory={selectedCategory}
                                    selectedJenjangPendidikan={selectedJenjangPendidikan}
                                    activeCategory={activeCategory}
                                    step3={step3}
                                />
                                :
                                undefined

                }
            </Grid>
            <NotifTahapII
                open={openNotifModal}
                close={() => setOpenNotifModal(false)}
                text={text}
                selectedCategory={selectedCategoryNew.category}
                selectedJenjangPendidikan={selectedJenjangPendidikanNew}
                defaultValueJenjangPendidikan={() => {
                    setSelectedCategory(selectedCategoryNew.category ? selectedCategoryNew.category : selectedCategory)
                    setSelectedJenjangPendidikan(selectedJenjangPendidikanNew ? selectedJenjangPendidikanNew : selectedJenjangPendidikan)
                }}
                updateFormData={() => {
                    let category = selectedCategoryNew.category ? selectedCategoryNew.category : selectedCategory
                    let jenjangPendidikan = selectedJenjangPendidikanNew
                    updateFormData({
                        selectedCategoryExist: category,
                        selectedJenjangPendidikanExist: selectedJenjangPendidikanNew ? selectedJenjangPendidikanNew : selectedJenjangPendidikan
                    })
                }}
            />
            <NotifRefreshPage
                open={OpenNotifRefreshPage}
                close={() => setOpenNotifRefreshPage(false)}
            />
            {isPageLoading ?
                <LoadingData
                    open={isPageLoading}
                // close={}
                />
                :
                undefined
            }
        </Grid>
    );
};

export default FormTahap2;
