import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, Grid, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Icon } from '@iconify/react';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import TooltipUploadGambar from '../../../assets/tooltip/uploadgambarkelas_x.png'
import { useFormContext } from '../../../pages/Registrasi/FormContext';
import CustomTooltip from '../../Modal/Tooltip/CustomTooltip';
import { decrypt } from 'n-krypta';
import { secretKey } from '../../Config/SecretKey';
import Base64ToFile from "../../Base64ToFile/Base64ToFile";
import FileToBase64 from "../../FileToBase64/FileToBase64";

const IkatanDinas = ({ isMobile, isTablet, selectedCategory, selectedJenjangPendidikan, step3 }) => {
    const { updateFormData } = useFormContext();

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImageXI, setSelectedImageXI] = useState(null);
    const [selectedImageXII, setSelectedImageXII] = useState(null);
    const [selectedCertificate1, setSelectedCertificate1] = useState(null);
    const [selectedCertificate2, setSelectedCertificate2] = useState(null);
    const [selectedCertificate3, setSelectedCertificate3] = useState(null);
    const [selectedToelf, setSelectedToelf] = useState(null);

    const [imageUrl, setImageUrl] = useState(null);
    const [imageUrlXI, setImageUrlXI] = useState(null);
    const [imageUrlXII, setImageUrlXII] = useState(null);
    const [imageUrlCertificate1, setImageUrlCertificate1] = useState(null);
    const [imageUrlCertificate2, setImageUrlCertificate2] = useState(null);
    const [imageUrlCertificate3, setImageUrlCertificate3] = useState(null);
    const [imageUrlToelf, setImageUrlToelf] = useState(null);

    const fileInputRef = useRef(null);
    const fileInputRefXI = useRef(null);
    const fileInputRefXII = useRef(null);
    const fileInputRefCertificate1 = useRef(null);
    const fileInputRefCertificate2 = useRef(null);
    const fileInputRefCertificate3 = useRef(null);
    const fileInputRefToelf = useRef(null);

    const [loadingSimpanData, setLoadingSimpanData] = useState(false)
    const [newData, setNewData] = useState({
        avg_sma: null
    })

    const [jurusan1, setJurusan1] = useState('');
    const [jurusan2, setJurusan2] = useState('');
    const [jurusan3, setJurusan3] = useState('');
    const [kategoriJurusan, setKategoriJurusan] = useState('');
    const [dataJurusanGuru, setDataJurusanGuru] = useState([
        {
            id: 1,
            label: 'Eksakta(matematika, fisika, kimia, biologi)',
            value: 'Eksakta'
        },
        {
            id: 2,
            label: 'Pendidikan Anak Usia Dini(PAUD)',
            value: 'Pendidikan Anak Usia Dini'
        },
        {
            id: 3,
            label: 'Pendidikan anak berkebutuhan khusus(Penempatan di SLB)',
            value: 'Pendidikan anak berkebutuhan khusus'
        },
        {
            id: 4,
            label: 'Pendidikan guru sekolah dasar(PGSD)',
            value: 'Pendidikan guru sekolah dasar'
        },
    ]);

    const [dataJurusanTenagaKesehatan, setDataJurusanTenagaKesehatan] = useState([
        {
            id: 1,
            label: 'Dokter',
            value: 'Dokter'
        },
        {
            id: 2,
            label: 'Perawat(Ners)',
            value: 'Perawat'
        },
        {
            id: 3,
            label: 'Bidan',
            value: 'Bidan'
        },
        {
            id: 4,
            label: 'Ahli Gizi',
            value: 'Ahli gizi'
        },
        {
            id: 5,
            label: 'Analisis Kesehatan',
            value: 'Analisis kesehatan'
        },
        {
            id: 6,
            label: 'Apoteker',
            value: 'Apoteker'
        },
    ]);

    const [errorAvgSMA, setErrorAvgSMA] = useState(null);

    const [selectedImageIjazahSMA, setSelectedImageIjazahSMA] = useState(null);
    const [imageUrlIjazahSMA, setImageUrlIjazahSMA] = useState(null);
    const fileInputRefIjazahSMA = useRef(null);

    const [isDragging, setIsDragging] = useState({
        isDraggingRaportX: false,
        isDraggingRaportXI: false,
        isDraggingRaportXII: false,
        isDraggingIjazahSMA: false,
        isDraggingCertificate1: false,
        isDraggingCertificate2: false,
        isDraggingCertificate3: false,
        isDraggingToefl: false,
    });

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const data = localStorage.getItem('data')


    const getAlreadyExistData = (token) => {

        setKategoriJurusan(token.kategoriJurusanExist || null);
        setJurusan1(token.jurusan1Exist || null);
        setJurusan2(token.jurusan2Exist || null);
        setJurusan3(token.jurusan3Exist || null);


        if (token?.selectedImageIjazahSMAExist) {
            const fileIjazahSMA = Base64ToFile(token.selectedImageIjazahSMAExist.base64, token.selectedImageIjazahSMAExist.name);
            setSelectedImageIjazahSMA(fileIjazahSMA);
            setImageUrlIjazahSMA(token.selectedImageIjazahSMAExist.base64 || null);
        }

        if (token?.selectedImageXExist) {
            const fileImageRaportX = Base64ToFile(token.selectedImageXExist.base64, token.selectedImageXExist.name);
            setSelectedImage(fileImageRaportX);
            setImageUrl(token.selectedImageXExist.base64 || null);
        }

        if (token?.selectedImageXIExist) {
            const fileImageRaportXI = Base64ToFile(token.selectedImageXIExist.base64, token.selectedImageXIExist.name);
            setSelectedImageXI(fileImageRaportXI);
            setImageUrlXI(token.selectedImageXIExist.base64 || null);
        }

        if (token?.selectedImageXIIExist) {
            const fileImageRaportXII = Base64ToFile(token.selectedImageXIIExist.base64, token.selectedImageXIIExist.name);
            setSelectedImageXII(fileImageRaportXII);
            setImageUrlXII(token.selectedImageXIIExist.base64 || null);
        }

        if (token?.selectedCertificate1Exist) {
            const fileSelectedCertificate1 = Base64ToFile(token.selectedCertificate1Exist.base64, token.selectedCertificate1Exist.name);
            setSelectedCertificate1(fileSelectedCertificate1);
            setImageUrlCertificate1(token.selectedCertificate1Exist.base64 || null);
        }

        if (token?.selectedCertificate2Exist) {
            const fileSelectedCertificate2 = Base64ToFile(token.selectedCertificate2Exist.base64, token.selectedCertificate2Exist.name);
            setSelectedCertificate2(fileSelectedCertificate2);
            setImageUrlCertificate2(token.selectedCertificate2Exist.base64 || null);
        }

        if (token?.selectedCertificate3Exist) {
            const fileSelectedCertificate3 = Base64ToFile(token.selectedCertificate3Exist.base64, token.selectedCertificate3Exist.name);
            setSelectedCertificate3(fileSelectedCertificate3);
            setImageUrlCertificate3(token.selectedCertificate3Exist.base64 || null);
        }

        if (token?.selectedToelfExist) {
            const fileSelectedToelf = Base64ToFile(token.selectedToelfExist.base64, token.selectedToelfExist.name);
            setSelectedToelf(fileSelectedToelf);
            setImageUrlToelf(token.selectedToelfExist.base64 || null);
        }
    }

    useEffect(() => {
        if (data) {
            const { dataStep2, cekData } = JSON.parse(data)
            if (dataStep2) {
                setTimeout(() => {
                    if (cekData === 'Ya' && dataStep2) {
                        getAlreadyExistData(dataStep2)
                    }
                }, 1500);
            }
        }
    }, [data]);


    const handleImageUpload = (fileInput) => {
        fileInput.current.click();
    };

    const handleRemoveImage = (removeImage) => {

        if (removeImage === 'raport x') {
            setSelectedImage(null);
            setImageUrl(null);
        } else if (removeImage === 'raport xi') {
            setSelectedImageXI(null);
            setImageUrlXI(null);
        } else if (removeImage === 'raport xii') {
            setSelectedImageXII(null);
            setImageUrlXII(null);
        } else if (removeImage === 'ijazah sma') {
            setSelectedImageIjazahSMA(null)
            setImageUrlIjazahSMA(null)
        } else if (removeImage === 'sertifikat 1') {
            setSelectedCertificate1(null)
            setImageUrlCertificate1(null)
        } else if (removeImage === 'sertifikat 2') {
            setSelectedCertificate2(null)
            setImageUrlCertificate2(null)
        } else if (removeImage === 'sertifikat 3') {
            setSelectedCertificate3(null)
            setImageUrlCertificate3(null)
        } else if (removeImage === 'toelf') {
            setSelectedToelf(null)
            setImageUrlToelf(null)
        }
    };

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        if (option === 'raport x') {
            setIsDragging({
                isDraggingRaportX: true
            })
        } else if (option === 'raport xi') {
            setIsDragging({
                isDraggingRaportXI: true
            })
        } else if (option === 'raport xii') {
            setIsDragging({
                isDraggingRaportXII: true
            })
        } else if (option === 'ijazah sma') {
            setIsDragging({
                isDraggingIjazahSMA: true
            })
        } else if (option === 'sertifikat 1') {
            setIsDragging({
                isDraggingCertificate1: true
            })
        } else if (option === 'sertifikat 2') {
            setIsDragging({
                isDraggingCertificate2: true
            })
        } else if (option === 'sertifikat 3') {
            setIsDragging({
                isDraggingCertificate3: true
            })
        } else if (option === 'toelf') {
            setIsDragging({
                isDraggingToefl: true
            })
        }
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'raport x') {
            setIsDragging({
                isDraggingRaportX: false
            })
        } else if (option === 'raport xi') {
            setIsDragging({
                isDraggingRaportXI: false
            })
        } else if (option === 'raport xii') {
            setIsDragging({
                isDraggingRaportXII: false
            })
        } else if (option === 'ijazah sma') {
            setIsDragging({
                isDraggingIjazahSMA: false
            })
        } else if (option === 'sertifikat 1') {
            setIsDragging({
                isDraggingCertificate1: false
            })
        } else if (option === 'sertifikat 2') {
            setIsDragging({
                isDraggingCertificate2: false
            })
        } else if (option === 'sertifikat 3') {
            setIsDragging({
                isDraggingCertificate3: false
            })
        } else if (option === 'toelf') {
            setIsDragging({
                isDraggingToefl: false
            })
        }
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingRaportX: false,
            isDraggingRaportXI: false,
            isDraggingRaportXII: false,
            isDraggingIjazahSMA: false,
            isDraggingCertificate1: false,
            isDraggingCertificate2: false,
            isDraggingCertificate3: false,
            isDraggingToefl: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleFileInputChange = (file, fileInputChange) => {
        // const file = event.target.files[0];
        // console.log(file);
        if (file) {
            // Check the file size
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                })
                // // Optionally, clear the file input
                // event.target.value = null;
                // return;
            } else {
                // Check the file type
                const fileType = file.type;
                const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                const allowedPdfTypes = ['application/pdf'];

                if (allowedImageTypes.includes(fileType)) {
                    // Handle Image File
                    if (fileInputChange === 'raport x') {
                        setSelectedImage(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrl(imageUrl);
                    } else if (fileInputChange === 'raport xi') {
                        setSelectedImageXI(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXI(imageUrl);
                    } else if (fileInputChange === 'raport xii') {
                        setSelectedImageXII(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXII(imageUrl);
                    } else if (fileInputChange === 'ijazah sma') {
                        setSelectedImageIjazahSMA(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlIjazahSMA(imageUrl)
                    } else if (fileInputChange === 'sertifikat 1') {
                        setSelectedCertificate1(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate1(imageUrl)
                    } else if (fileInputChange === 'sertifikat 2') {
                        setSelectedCertificate2(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate2(imageUrl)
                    } else if (fileInputChange === 'sertifikat 3') {
                        setSelectedCertificate3(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate3(imageUrl)
                    } else if (fileInputChange === 'toelf') {
                        setSelectedToelf(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlToelf(imageUrl)
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    // Handle PDF file
                    if (fileInputChange === 'raport x') {
                        setSelectedImage(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrl(imageUrl);
                    } else if (fileInputChange === 'raport xi') {
                        setSelectedImageXI(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXI(imageUrl);
                    } else if (fileInputChange === 'raport xii') {
                        setSelectedImageXII(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXII(imageUrl);
                    } else if (fileInputChange === 'ijazah sma') {
                        setSelectedImageIjazahSMA(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlIjazahSMA(imageUrl)
                    } else if (fileInputChange === 'sertifikat 1') {
                        setSelectedCertificate1(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate1(imageUrl)
                    } else if (fileInputChange === 'sertifikat 2') {
                        setSelectedCertificate2(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate2(imageUrl)
                    } else if (fileInputChange === 'sertifikat 3') {
                        setSelectedCertificate3(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlCertificate3(imageUrl)
                    } else if (fileInputChange === 'toelf') {
                        setSelectedToelf(file)
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlToelf(imageUrl)
                    }
                } else {
                    // Unsupported file type
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png, gif dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }



        }
    };


    const handleSubmit = async () => {

        setLoadingSimpanData(true)

        if (selectedCategory === null) {
            ErrorAlert({
                message: 'Kategori Beasiswa Wajib di Pilih',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedJenjangPendidikan === null) {
            ErrorAlert({
                message: 'Jenjang Pendidikan Wajib di Pilih',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImage === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Raport Kelas X',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageXI === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Raport Kelas XI',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageXII === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Raport Kelas XII',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedCertificate1 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat 1',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedCertificate2 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat 2',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedCertificate3 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat 3',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedToelf === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Sertifikat Toelf',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else {
            const selectedRaportXBase64 = selectedImage ? await FileToBase64(selectedImage) : null;
            const selectedRaportXIBase64 = selectedImageXI ? await FileToBase64(selectedImageXI) : null;
            const selectedRaportXIIBase64 = selectedImageXII ? await FileToBase64(selectedImageXII) : null;
            const selectedImageIjazahSMABase64 = selectedImageIjazahSMA ? await FileToBase64(selectedImageIjazahSMA) : null;
            const selectedCertificate1Base64 = selectedCertificate1 ? await FileToBase64(selectedCertificate1) : null;
            const selectedCertificate2Base64 = selectedCertificate2 ? await FileToBase64(selectedCertificate2) : null;
            const selectedCertificate3Base64 = selectedCertificate3 ? await FileToBase64(selectedCertificate3) : null;
            const selectedToelfBase64 = selectedToelf ? await FileToBase64(selectedToelf) : null;


            let values = {
                selectedCategoryExist: selectedCategory,
                selectedJenjangPendidikanExist: selectedJenjangPendidikan,
                kategoriJurusanExist: kategoriJurusan,
                jurusan1Exist: jurusan1,
                jurusan2Exist: jurusan2,
                jurusan3Exist: jurusan3,
                selectedImageXExist: {
                    lastModified: selectedImage.lastModified,
                    lastModifiedDate: selectedImage.lastModifiedDate,
                    name: selectedImage.name,
                    type: selectedImage.type,
                    size: selectedImage.size,
                    webkitRelativePath: selectedImage.webkitRelativePath,
                    url: selectedImage ? URL.createObjectURL(selectedImage) : null,
                    base64: selectedRaportXBase64
                },
                selectedImageXIExist: {
                    lastModified: selectedImageXI.lastModified,
                    lastModifiedDate: selectedImageXI.lastModifiedDate,
                    name: selectedImageXI.name,
                    type: selectedImageXI.type,
                    size: selectedImageXI.size,
                    webkitRelativePath: selectedImageXI.webkitRelativePath,
                    url: selectedImageXI ? URL.createObjectURL(selectedImageXI) : null,
                    base64: selectedRaportXIBase64
                },
                selectedImageXIIExist: {
                    lastModified: selectedImageXII.lastModified,
                    lastModifiedDate: selectedImageXII.lastModifiedDate,
                    name: selectedImageXII.name,
                    type: selectedImageXII.type,
                    size: selectedImageXII.size,
                    webkitRelativePath: selectedImageXII.webkitRelativePath,
                    url: selectedImageXII ? URL.createObjectURL(selectedImageXII) : null,
                    base64: selectedRaportXIIBase64
                },
                selectedImageIjazahSMAExist: {
                    lastModified: selectedImageIjazahSMA.lastModified,
                    lastModifiedDate: selectedImageIjazahSMA.lastModifiedDate,
                    name: selectedImageIjazahSMA.name,
                    type: selectedImageIjazahSMA.type,
                    size: selectedImageIjazahSMA.size,
                    webkitRelativePath: selectedImageIjazahSMA.webkitRelativePath,
                    url: selectedImageIjazahSMA ? URL.createObjectURL(selectedImageIjazahSMA) : null,
                    base64: selectedImageIjazahSMABase64
                },
                selectedCertificate1Exist: {
                    lastModified: selectedCertificate1.lastModified,
                    lastModifiedDate: selectedCertificate1.lastModifiedDate,
                    name: selectedCertificate1.name,
                    type: selectedCertificate1.type,
                    size: selectedCertificate1.size,
                    webkitRelativePath: selectedCertificate1.webkitRelativePath,
                    url: selectedCertificate1 ? URL.createObjectURL(selectedCertificate1) : null,
                    base64: selectedCertificate1Base64
                },
                selectedCertificate2Exist: {
                    lastModified: selectedCertificate2.lastModified,
                    lastModifiedDate: selectedCertificate2.lastModifiedDate,
                    name: selectedCertificate2.name,
                    type: selectedCertificate2.type,
                    size: selectedCertificate2.size,
                    webkitRelativePath: selectedCertificate2.webkitRelativePath,
                    url: selectedCertificate2 ? URL.createObjectURL(selectedCertificate2) : null,
                    base64: selectedCertificate2Base64
                },
                selectedCertificate3Exist: {
                    lastModified: selectedCertificate3.lastModified,
                    lastModifiedDate: selectedCertificate3.lastModifiedDate,
                    name: selectedCertificate3.name,
                    type: selectedCertificate3.type,
                    size: selectedCertificate3.size,
                    webkitRelativePath: selectedCertificate3.webkitRelativePath,
                    url: selectedCertificate3 ? URL.createObjectURL(selectedCertificate3) : null,
                    base64: selectedCertificate3Base64
                },
                selectedToelfExist: {
                    lastModified: selectedToelf.lastModified,
                    lastModifiedDate: selectedToelf.lastModifiedDate,
                    name: selectedToelf.name,
                    type: selectedToelf.type,
                    size: selectedToelf.size,
                    webkitRelativePath: selectedToelf.webkitRelativePath,
                    url: selectedToelf ? URL.createObjectURL(selectedToelf) : null,
                    base64: selectedToelfBase64
                },
            };

            // Ambil data yang sudah ada dari localStorage
            const existingData = JSON.parse(localStorage.getItem('data')) || {};

            // Tambahkan data baru ke dalam objek existingData
            existingData.dataStep2 = values;

            // Simpan kembali objek yang telah diperbarui ke dalam localStorage
            localStorage.setItem('data', JSON.stringify(existingData));


            setTimeout(() => {
                step3()
                setLoadingSimpanData(false)
            }, 2500);
        }
        // setLoadingSimpanData(false)
    }

    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        // Pengecekan apakah nilai yang diinput adalah string
        if (isNaN(value)) {
            // setErrorAvgSMA('Nilai rata-rata tidak valid.');
            return; // Keluar dari fungsi handleChange jika nilai tidak valid
        }

        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        // Pengecekan nilai rata-rata
        if (value !== '' && (value > 100 || value < 0)) {
            setErrorAvgSMA('Nilai rata-rata tidak valid.');
        } else if (value !== '' && value <= 69) {
            setErrorAvgSMA('Nilai rata-rata tidak memenuhi syarat.');
        } else {
            setErrorAvgSMA('');
        }
    }

    const handleSelectKategoriJurusan = (e, option) => {
        setKategoriJurusan(e.target.value)
        setJurusan1('')
        setJurusan2('')
        setJurusan3('')
    }

    const handleSelectJurusan1 = (e, option) => {
        console.log('handleSelectJurusan1', e.target.value);
        // const selectedValue = e.target.value;
        setJurusan1(e.target.value);
    }

    const handleSelectJurusan2 = (e, option) => {
        // const selectedValue = e.target.value;
        console.log('handleSelectJurusan2', e.target.value);
        setJurusan2(e.target.value);
    }

    const handleSelectJurusan3 = (e, option) => {
        // const selectedValue = e.target.value;
        console.log('handleSelectJurusan3', e.target.value);
        setJurusan3(e.target.value);
    }

    const filteredDataJurusanGuru1 = dataJurusanGuru.filter(data => data.value !== jurusan2 && data.value !== jurusan3);
    const filteredDataJurusanGuru2 = dataJurusanGuru.filter(data => data.value !== jurusan1 && data.value !== jurusan3);
    const filteredDataJurusanGuru3 = dataJurusanGuru.filter(data => data.value !== jurusan1 && data.value !== jurusan2);

    const filteredDataJurusanTenagaKesehatan1 = dataJurusanTenagaKesehatan.filter(data => data.value !== jurusan2 && data.value !== jurusan3);
    const filteredDataJurusanTenagaKesehatan2 = dataJurusanTenagaKesehatan.filter(data => data.value !== jurusan1 && data.value !== jurusan3);
    const filteredDataJurusanTenagaKesehatan3 = dataJurusanTenagaKesehatan.filter(data => data.value !== jurusan1 && data.value !== jurusan2);

    console.log(kategoriJurusan);

    return (
        <Grid container spacing={2} >
            <Grid item xs={12} md={12} >
                <Box
                    sx={{
                        marginBottom: '24px',
                        padding: '10px 16px',
                        backgroundColor: '#F8D70033',

                    }}
                >
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '14px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'justify'
                        }}
                    >
                        Kategori Ikatan Dinas diperuntukkan hanya untuk jenjang pendidikan Strata 1 (S1)
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        marginBottom: '12px',
                    }}
                >
                    Lengkapi Persyaratan
                </Typography>
            </Grid>

            {/* Kategori Jurusan*/}
            <Grid item xs={12} md={12} mb='5px'>
                <FormControl required variant="standard"
                    sx={{
                        maxWidth: '486px',
                        minWidth: '40%',
                        width: isMobile ? '100%' : '40%',
                    }}
                >
                    <InputLabel id="kategori-jurusan-labelId">Kategori</InputLabel>
                    <Select
                        label="Kategori"
                        labelId="kategori-jurusan-labelId"
                        id="kategori-jurusanId"
                        defaultValue={kategoriJurusan}
                        value={kategoriJurusan}
                        onChange={(e) => handleSelectKategoriJurusan(e)}
                        required
                    >
                        <MenuItem value='Guru'>
                            Guru
                        </MenuItem>
                        <MenuItem value='Tenaga Kesehatan'>
                            Tenaga Kesehatan
                        </MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            {kategoriJurusan ?
                <>
                    {/* Jurusan Prioritas 1 */}
                    <Grid item xs={12} md={12} mb='10px'>
                        <FormControl required variant="standard"
                            sx={{
                                maxWidth: '486px',
                                minWidth: '40%',
                                width: isMobile ? '100%' : '40%',
                            }}>
                            <InputLabel id="jurusan1-labelId">Jurusan Prioritas 1</InputLabel>
                            <Select
                                label="Jurusan Prioritas 1"
                                labelId="jurusan1-labelId"
                                id="jurusan1Id"
                                // defaultValue=" "
                                value={jurusan1}
                                onChange={(e) => handleSelectJurusan1(e)}
                                required
                            >
                                {kategoriJurusan === 'guru' || kategoriJurusan === 'Guru' ?
                                    filteredDataJurusanGuru1.map((data, index) => {
                                        return (
                                            <MenuItem value={data.value} key={data.id}>
                                                {data.label}
                                            </MenuItem>
                                        )
                                    })
                                    :
                                    filteredDataJurusanTenagaKesehatan1.map((data, index) => {
                                        return (
                                            <MenuItem value={data.value} key={data.id}>
                                                {data.label}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Jurusan Prioritas 2 */}
                    <Grid item xs={12} md={12} mb='10px'>
                        <FormControl required variant="standard"
                            sx={{
                                maxWidth: '486px',
                                minWidth: '40%',
                                width: isMobile ? '100%' : '40%',
                            }}
                        >
                            <InputLabel id="jurusan2-labelId">Jurusan Prioritas 2</InputLabel>
                            <Select
                                label="Jurusan Prioritas 2"
                                labelId="jurusan2-labelId"
                                id="jurusan2Id"
                                // defaultValue=" "
                                value={jurusan2}
                                onChange={(e) => handleSelectJurusan2(e)}
                                required
                                disabled={!jurusan1} // Disable if Jurusan 1 is not selected
                            >
                                {kategoriJurusan === 'guru' || kategoriJurusan === 'Guru' ?
                                    filteredDataJurusanGuru2.map((data, index) => {
                                        return (
                                            <MenuItem value={data.value} key={data.id}>
                                                {data.label}
                                            </MenuItem>
                                        )
                                    })
                                    :
                                    filteredDataJurusanTenagaKesehatan2.map((data, index) => {
                                        return (
                                            <MenuItem value={data.value} key={data.id}>
                                                {data.label}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>

                    {/* Jurusan Prioritas 3 */}
                    <Grid item xs={12} md={12} mb='18px'>
                        <FormControl required variant="standard"
                            sx={{
                                maxWidth: '486px',
                                minWidth: '40%',
                                width: isMobile ? '100%' : '40%',
                            }}
                        >
                            <InputLabel id="jurusan2-labelId">Jurusan Prioritas 3</InputLabel>
                            <Select
                                label="Jurusan Prioritas 3"
                                labelId="jurusan3-labelId"
                                id="jurusan3Id"
                                // defaultValue=" "
                                value={jurusan3}
                                onChange={(e) => handleSelectJurusan3(e)}
                                required
                                disabled={!jurusan2} // Disable if Jurusan 2 is not selected
                            >
                                {kategoriJurusan === 'guru' || kategoriJurusan === 'Guru' ?
                                    filteredDataJurusanGuru3.map((data, index) => {
                                        return (
                                            <MenuItem value={data.value} key={data.id}>
                                                {data.label}
                                            </MenuItem>
                                        )
                                    })
                                    :
                                    filteredDataJurusanTenagaKesehatan3.map((data, index) => {
                                        return (
                                            <MenuItem value={data.value} key={data.id}>
                                                {data.label}
                                            </MenuItem>
                                        )
                                    })
                                }
                            </Select>
                        </FormControl>
                    </Grid>
                </>
                :
                undefined
            }


            {/* Gambar Raport Kelas X */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                    }}
                >
                    Gambar Scan/Foto atau PDF Raport Kelas X (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'raport x')}
                        onDrop={(e) => handleDrop(e, 'raport x')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingRaportX && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImage && selectedImage.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImage.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport x')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrl ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrl}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport x')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrl ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRef)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrl ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'raport x')}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Gambar Raport Kelas XI */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '24px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Raport Kelas XI (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'raport xi')}
                        onDrop={(e) => handleDrop(e, 'raport xi')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingRaportXI && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageXI && selectedImageXI.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageXI.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xi')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlXI ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlXI}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xi')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlXI ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefXI)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlXI ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'raport xi')}
                            ref={fileInputRefXI}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Gambar Raport Kelas XII */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '24px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Raport Kelas XII (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'raport xii')}
                        onDrop={(e) => handleDrop(e, 'raport xii')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingRaportXII && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageXII && selectedImageXII.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageXII.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xii')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlXII ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlXII}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xii')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlXII ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefXII)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlXII ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'raport xii')}
                            ref={fileInputRefXII}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>



            {/* Berkas Ijazah SMA */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: "15px"
                    }}
                >
                    Gambar Scan/Foto atau PDF Ijazah SMA (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'ijazah sma')}
                        onDrop={(e) => handleDrop(e, 'ijazah sma')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingIjazahSMA && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageIjazahSMA && selectedImageIjazahSMA.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageIjazahSMA.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah sma')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlIjazahSMA ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlIjazahSMA}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah sma')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlIjazahSMA ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefIjazahSMA)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlIjazahSMA ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'ijazah sma')}
                            ref={fileInputRefIjazahSMA}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat 1 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: "15px"
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat 1 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'sertifikat 1')}
                        onDrop={(e) => handleDrop(e, 'sertifikat 1')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingCertificate1 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedCertificate1 && selectedCertificate1.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedCertificate1.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlCertificate1 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlCertificate1}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 1')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlCertificate1 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefCertificate1)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlCertificate1 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'sertifikat 1')}
                            ref={fileInputRefCertificate1}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat 2 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: "15px"
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat 2 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'sertifikat 2')}
                        onDrop={(e) => handleDrop(e, 'sertifikat 2')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingCertificate2 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedCertificate2 && selectedCertificate2.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedCertificate2.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlCertificate2 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlCertificate2}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 2')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlCertificate2 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefCertificate2)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlCertificate2 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }

                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'sertifikat 2')}
                            ref={fileInputRefCertificate2}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat 3 */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: "15px"
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat 3 (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'sertifikat 3')}
                        onDrop={(e) => handleDrop(e, 'sertifikat 3')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingCertificate3 && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedCertificate3 && selectedCertificate3.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedCertificate3.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 3')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlCertificate3 ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlCertificate3}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('sertifikat 3')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlCertificate3 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefCertificate3)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlCertificate3 ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'sertifikat 3')}
                            ref={fileInputRefCertificate3}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Sertifikat Toelf */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: "15px"
                    }}
                >
                    Gambar Scan/Foto atau PDF Sertifikat Toelf (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'toelf')}
                        onDrop={(e) => handleDrop(e, 'toelf')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingToefl && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedToelf && selectedToelf.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedToelf.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('toelf')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlToelf ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlToelf}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('toelf')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlToelf ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefToelf)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlToelf ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'toelf')}
                            ref={fileInputRefToelf}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Button Submit */}
            <Grid item xs={12} sm={12}>
                <Box
                    // fullWidth
                    // align='center'
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // width: '100%',
                        // maxWidth: isMobile ? '100%' : isTablet ? '352px' : '100%',
                        marginTop: '26px',
                        marginBottom: '56px',
                        // backgroundColor: 'orange',
                        // position: 'relative',
                    }}
                >

                    <Button
                        fullWidth
                        variant='contained'
                        size='medium'
                        onClick={() => handleSubmit()}
                        sx={{
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            padding: '10px 56px',
                            width: '100%',
                            maxWidth: isMobile ? '100%' : '384px',
                            textTransform: 'none',
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor,
                            },
                        }}
                    >
                        {loadingSimpanData ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                Simpan Data & Lanjutkan
                            </Typography>
                        }
                    </Button>

                </Box>
            </Grid>
        </Grid >
    )
}

export default IkatanDinas