import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, TextField } from '@mui/material';
import KuisionerS1 from './KuisionerS1';
import KuisionerS2 from './KuisionerS2';
import KuisionerS3 from './KuisionerS3';

const Kuisioner = ({ isMobile, isTablet, step3, selectedCategory, selectedJenjangPendidikan }) => {


    return (
        <>
            <Typography  sx={{ fontFamily: 'Jakarta Sans, sans-serif', marginBottom: '10px', fontSize: '16px', }}>
                Lengkapi Persyaratan
            </Typography>
            {selectedJenjangPendidikan === 'Strata 1(S1)' ?
                <KuisionerS1
                    isMobile={isMobile}
                    isTablet={isTablet}
                    step3={step3}
                    selectedCategory={selectedCategory}
                    selectedJenjangPendidikan={selectedJenjangPendidikan}
                />
                :
                selectedJenjangPendidikan === 'Strata 2(S2)' ?
                    <KuisionerS2
                        isMobile={isMobile}
                        isTablet={isTablet}
                        step3={step3}
                        selectedCategory={selectedCategory}
                        selectedJenjangPendidikan={selectedJenjangPendidikan}
                    />
                    :
                    selectedJenjangPendidikan === 'Strata 3(S3)' ?
                        <KuisionerS3
                            isMobile={isMobile}
                            isTablet={isTablet}
                            step3={step3}
                            selectedJenjangPendidikan={selectedJenjangPendidikan}
                            selectedCategory={selectedCategory}
                        />
                        :
                        undefined
            }

        </>
    );
};

export default Kuisioner;
