import './toastify.css';
import { toast } from 'react-toastify';

const ErrorAlert = ({message, position}) => {
    toast.error(message, {
      position: position,
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
}

export default ErrorAlert;