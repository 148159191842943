import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Modal,
    useMediaQuery,
    Button,
    Divider,
    TextareaAutosize,
    Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { RegistrasiTahapII_and_TahapIII, createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'
import { useFormContext } from "../../../pages/Registrasi/FormContext";

const Tahap2 = ({
    isMobile,
    isTablet,
    open,
    close,
    selectedCertificate1Exist,
    selectedCertificate1UrlExist,
    selectedCertificate2Exist,
    selectedCertificate2UrlExist,
    selectedCertificate3Exist,
    selectedCertificate3UrlExist,
    selectedImageIjazahSMAExist,
    selectedImageIjazahSMAUrlExist,
    selectedImageRaportXExist,
    selectedImageUrlRaportXExist,
    selectedImageRaportXIExist,
    selectedImageUrlRaportXIExist,
    selectedImageRaportXIIExist,
    selectedImageUrlRaportXIIExist,
    selectedToelfExist,
    selectedToelfUrlExist,
    dataStep2Exist,
}) => {

    // console.log('selectedCertificate1UrlExist', selectedCertificate1UrlExist);
    // console.log('selectedCertificate2UrlExist', selectedCertificate2UrlExist);
    // console.log('selectedCertificate3UrlExist', selectedCertificate3UrlExist);
    // console.log('selectedImageIjazahSMAUrlExist', selectedImageIjazahSMAUrlExist);
    // console.log('selectedToelfUrlExist', selectedToelfUrlExist);
    // console.log('selectedImageUrlRaportXExist', selectedImageUrlRaportXExist);
    // console.log('selectedImageUrlRaportXIExist', selectedImageUrlRaportXIExist);
    // console.log('selectedImageUrlRaportXIIExist', selectedImageUrlRaportXIIExist);

    return (
        <>
            {/* Column 1 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    marginBottom: '20px',
                    // backgroundColor: 'orange',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Kategori
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep2Exist ? dataStep2Exist.selectedCategoryExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Jenjang Pendidikan
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep2Exist.selectedJenjangPendidikanExist ? dataStep2Exist.selectedJenjangPendidikanExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Ijazah SMA
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageIjazahSMAExist && selectedImageIjazahSMAExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageIjazahSMAExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageIjazahSMAExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
            </Box>

            {/* Column 2 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    marginBottom: '20px',
                    // backgroundColor: 'orange',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Raport X
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageRaportXExist && selectedImageRaportXExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageRaportXExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageRaportXExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Raport XI
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageRaportXIExist && selectedImageRaportXIExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageRaportXIExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageRaportXIExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Raport XII
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageRaportXIIExist && selectedImageRaportXIIExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageRaportXIIExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageRaportXIIExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
            </Box>

            {/* Column 3 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    marginBottom: '20px',
                    // backgroundColor: 'orange',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat 1
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedCertificate1Exist && selectedCertificate1Exist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedCertificate1Exist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedCertificate1Exist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat 2
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedCertificate2Exist && selectedCertificate2Exist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedCertificate2Exist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedCertificate2Exist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat 3
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedCertificate3Exist && selectedCertificate3Exist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedCertificate3Exist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedCertificate3Exist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
            </Box>

            {/* Column 4 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: '10px',
                    marginBottom: '10px',
                    // backgroundColor: 'orange',
                    width: '100%'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat Toefl
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedToelfExist && selectedToelfExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedToelfExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedToelfExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    {/* <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat Toefl
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedToelf && selectedToelf.type === 'application/pdf' ?
                            <a
                                href={selectedToelf)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={selectedToelf)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography> */}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >

                </Box>
            </Box>
        </>
    )
}

export default Tahap2