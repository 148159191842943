import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, InputAdornment, TextField, useMediaQuery } from '@mui/material';
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from '../../Config/Color';
import { useState } from 'react';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { updatePassword } from '../../Config/Api';
import SuccessAlert from '../../ToastyAlert/SuccessAlert'
import ErrorAlert from '../../ToastyAlert/ErrorAlert'
import 'react-toastify/dist/ReactToastify.css';


const ChangePasswordModal = ({
    open,
    close,
    biodata
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [showPasswordLama, setShowPasswordLama] = useState(false);
    const [showPasswordBaru, setShowPasswordBaru] = useState(false);
    const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(false);

    const [isLoadingBackButton, setIsLoadingBackButton] = useState(false);

    const [newData, setNewData] = useState({
        KatasandiLama: '',
        KatasandiBaru: '',
        UlangiKatasandiBaru: ''
    })

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '330px' : '600px',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        // p: 2,
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    }

    const handleClickShowPasswordLama = () => {
        setShowPasswordLama(!showPasswordLama);
    };

    const handleClickShowPasswordBaru = () => {
        setShowPasswordBaru(!showPasswordBaru);
    };

    const handleClickShowConfirmationPassword = () => {
        setShowPasswordConfirmation(!showPasswordConfirmation);
    };

    const handleSubmit = async (e) => {
        e.preventDefault()

        setIsLoading(true)

        const loginInfo = localStorage.getItem('LogInfo')
        if (loginInfo) {
            const personal_information_id = JSON.parse(loginInfo).Id

            // console.log(newData.KatasandiBaru);
            // console.log(newData.UlangiKatasandiBaru);

            if (newData.KatasandiBaru !== newData.UlangiKatasandiBaru) {
                // console.log('kondisi 1');
                ErrorAlert({
                    message: 'Kata sandi baru tidak sesuai',
                    position: 'top-center'
                })
                setIsLoading(false)
            } else {
                // console.log('kondisi 2');
                await axios.put(`${updatePassword}/${personal_information_id}`, {
                    old_password: newData.KatasandiLama,
                    new_password: newData.KatasandiBaru,
                    confirm_password: newData.UlangiKatasandiBaru
                }).then((response) => {
                    console.log(response.data);
                    SuccessAlert({
                        message: 'Berhasil mengubah password.',
                        position: 'top-center'
                    })
                    close()
                    setIsLoading(false)
                    setNewData({
                        KatasandiBaru: '',
                        KatasandiLama: '',
                        UlangiKatasandiBaru: ''
                    })
                }).catch((error) => {
                    console.log(error.response.data);
                    ErrorAlert({
                        message: error.response.data.error === 'Old password is incorrect' ? 'Password lama salah.' : error.response.data.error,
                        position: 'top-center'
                    })
                    setIsLoading(false)
                })
            }
        }

    }

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            padding: '10px 15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                // color: mainColor,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '20.16px',
                            }}
                        >
                            My Profile
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            marginBottom: '20px'
                        }}
                    >
                        <Divider
                            variant="string"
                            style={{
                                backgroundColor: "#fff",
                                borderBottomWidth: 0.5,
                                // marginTop: "2px",
                            }}
                        />
                    </Box>
                    <form
                        onSubmit={handleSubmit}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                marginBottom: '100px'
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="standard"
                                type={showPasswordLama ? 'text' : 'password'}
                                label="Kata sandi lama"
                                placeholder='Kata sandi lama'
                                value={newData.KatasandiLama}
                                name="KatasandiLama"
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon
                                                onClick={handleClickShowPasswordLama}
                                                icon={showPasswordLama ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                // onInput={(e) => {
                                //     // Filter out non-numeric characters
                                //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                // }}
                                autoComplete="off"
                                // error={newData.nik === '' ? undefined : !!KTPError}
                                // helperText={KTPError}
                                required
                                sx={{
                                    marginBottom: isMobile ? '15px' : '24px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    width: isMobile ? '80%' : '50%',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                type={showPasswordBaru ? 'text' : 'password'}
                                label="Kata sandi baru"
                                placeholder='Kata sandi baru'
                                value={newData.KatasandiBaru}
                                name="KatasandiBaru"
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon
                                                onClick={handleClickShowPasswordBaru}
                                                icon={showPasswordBaru ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                // onInput={(e) => {
                                //     // Filter out non-numeric characters
                                //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                // }}
                                autoComplete="off"
                                // error={newData.nik === '' ? undefined : !!KTPError}
                                // helperText={KTPError}
                                required
                                sx={{
                                    marginBottom: isMobile ? '15px' : '24px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    width: isMobile ? '80%' : '50%',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                type={showPasswordConfirmation ? 'text' : 'password'}
                                label="Ulangi kata sandi"
                                placeholder='Ulangi kata sandi'
                                value={newData.UlangiKatasandiBaru}
                                name="UlangiKatasandiBaru"
                                onChange={(e) => handleChange(e)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon
                                                onClick={handleClickShowConfirmationPassword}
                                                icon={showPasswordConfirmation ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                // onInput={(e) => {
                                //     // Filter out non-numeric characters
                                //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                // }}
                                autoComplete="off"
                                // error={newData.nik === '' ? undefined : !!KTPError}
                                // helperText={KTPError}
                                required
                                sx={{
                                    marginBottom: isMobile ? '15px' : '24px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    width: isMobile ? '80%' : '50%',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: '100%',
                                // marginBottom: '20px'
                            }}
                        >
                            <Divider
                                variant="string"
                                style={{
                                    backgroundColor: "#fff",
                                    borderBottomWidth: 0.5,
                                    // marginTop: "2px",
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                padding: 2,
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '20px'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setIsLoadingBackButton(true)
                                    setTimeout(() => {
                                        setIsLoadingBackButton(false)
                                        close()
                                    }, 1000);
                                }}
                                variant="contained"
                                sx={{
                                    backgroundColor: DividerColor,
                                    color: Dark,
                                    textTransform: 'none',
                                    width: '100px',
                                    // fontWeight: 'bold',
                                    '&:hover': {
                                        // color: 'white', // Change text color to white on hover
                                        backgroundColor: DividerColor,
                                    },
                                }}
                            >
                                {isLoadingBackButton ?
                                    <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                    :
                                    'Batalkan'
                                }
                            </Button>
                            <Button
                                type="submit"
                                variant="contained"
                                sx={{
                                    backgroundColor: btnBgColor,
                                    color: Dark,
                                    textTransform: 'none',
                                    width: '150px',
                                    // fontWeight: 'bold',
                                    '&:hover': {
                                        // color: 'white', // Change text color to white on hover
                                        backgroundColor: btnBgColor,
                                    }
                                }}
                            >
                                {isLoading ?
                                    <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                    :
                                    'Simpan'
                                }
                            </Button>
                        </Box>
                    </form>
                </Box>
            </Modal>
        </div >
    );
}

export default ChangePasswordModal