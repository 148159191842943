import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { mainColor } from '../../Config/Color';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';



const ErrorModal = ({
    open,
    close,
    errorMessage,
    statusData,
    resetCekAccount
}) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const [isLoading, setIsLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '330px' : '400px',
        // minWidth: isMobile ? '330px' : '600px',
        width: '100%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        p: 2,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={() => {
                    resetCekAccount()
                    close()
                }}
            >
                <Box sx={style}>
                    <Icon icon='material-symbols:error' style={{ fontSize: '90px', color: statusData && statusData === 'already verified sup' ? mainColor : 'maroon' }} />
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '12px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            color: statusData && statusData === 'already verified sup' ? mainColor : 'maroon'
                        }}
                    >
                        {errorMessage.label}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '12px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                            color: 'black',
                            textAlign: 'center',
                            margin: '10px 0px',
                        }}
                    >
                        {errorMessage.message}
                    </Typography>
                    {statusData && statusData === 'already verified sup' ?
                        <Button
                            onClick={() => {
                                setIsLoading(true)
                                setTimeout(() => {
                                    navigate('/login')
                                    setIsLoading(false)
                                }, 1000);
                            }}
                            fullWidth
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: mainColor,
                                color: 'white',
                                padding: '5px',
                                borderRadius: '5px',
                                marginTop: '15px',
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: mainColor,
                                    color: 'white',
                                },
                            }}
                            disabled={isLoading ? true : false}
                        >
                            {isLoading ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Login'
                            }
                        </Button>
                        :
                        undefined
                    }

                </Box>
            </Modal>
        </div>
    );
}

export default ErrorModal