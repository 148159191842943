import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { btnBgColor, mainColor } from '../../Config/Color';
import { useState } from 'react';



const NotifRefreshPage = ({
    open,
    close,
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    // console.log('selectedCategory', selectedCategory);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '330px' : '400px',
        // minWidth: isMobile ? '330px' : '600px',
        width: '100%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        p: 2,
    };

    const [isLoading, setIsLoading] = useState(false)

    return (
        <Modal
            open={open}
            // onClose={close}
            sx={{
                margin:'0px 30px'
            }}
        >
            <Box sx={style}>
               asdasdsad
            </Box>
        </Modal>
    );
}

export default NotifRefreshPage