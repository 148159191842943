import { Card, Typography } from '@mui/material'
import React from 'react'
import { Dark, DividerColor } from '../../Config/Color'

const CustomTooltip = ({ message, padding, customWidth, customHeight }) => {
    return (
        <Card
            elevation={1}
            sx={{
                display: "flex",
                flexDirection: 'row',
                justifyContent: 'justify',
                alignItems: 'center',
                backgroundColor: DividerColor,
                width: '100%',
                height: '100%',
                maxHeight: customHeight ? customHeight : '52px',
                maxWidth: customWidth ? customWidth : '381px',
                // minWidth: '250px',
                padding: padding,
                borderRadius: '4px',

            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: '12px',
                    fontStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '18px',
                    color: Dark,
                    textAlign: 'left',
                }}
            >
                {message ? message : ''}
            </Typography>
        </Card>
    )
}

export default CustomTooltip