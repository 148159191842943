import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import TopMenu from '../../components/Header/TopMenu'
import { getDataDashboardApplicant, updateBiodata, updateDataBioPekerjaandataOrangTua, updateDataBiodataOrangTua, updateDataKontakOrangTua } from '../../components/Config/Api';
import { Blue, Dark, DividerColor, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color'
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert'
import InfoAlert from '../../components/ToastyAlert/InfoAlert'
import { ToastContainer } from 'react-toastify';
import LoginSessionModal from '../../components/Session/LoginSessionModal';

const OrangTua = () => {

  const navigate = useNavigate()

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [biodata, setBiodata] = useState('')
  const [dataCompletenessPekerjaanOrangTua, setDataCompletenessPekerjaanOrangTua] = useState(100)
  const [dataCompleteness, setDataCompleteness] = useState(100)
  const [dataCompletenessKontak, setDataCompletenessKontak] = useState(100)
  const [switchButtonBiodata, setSwitchButtonBiodata] = useState(false)
  const [switchButtonPekerjaan, setSwitchButtonPekerjaan] = useState(false)
  const [switchButtonKontak, setSwitchButtonKontak] = useState(false)
  const [asalKabupaten, setAsalKabupaten] = useState('')
  const [isLoadingBiodataOrangtua, setIsLoadingBiodataOrangtua] = useState(false)
  const [isLoadingSubmitData, setIsLoadingSubmitData] = useState({
    loadingBiodata: false,
    loadingPekerjaan: false,
    loadingKontak: false,
  })
  const [isLoadingPekerjaanOrangTua, setIsLoadingPekerjaanOrangTua] = useState(false)
  const [isLoadingKontakOrangTua, setIsLoadingKontakOrangTua] = useState(false)
  const [newData, setNewData] = useState({
    namaLengkapIbu: '',
    nikIbu: '',
    namaLengkapAyah: '',
    nikAyah: '',
    pekerjaanIbu: '',
    pekerjaanAyah: '',
    nomorWhatsappIbu: '',
    nomorWhatsappAyah: '',
  })
  const [selectedName, setSelectedName] = useState('');
  const [selectedImage, setSelectedImage] = useState(null);
  const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)

  const getData = async () => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {


          setBiodata(response.data)
          setNewData({
            namaLengkapAyah: response.data?.data?.father_name,
            namaLengkapIbu: response.data?.data?.mother_name,
            nikAyah: response.data?.data?.father_nik,
            nikIbu: response.data?.data?.mother_nik,
            pekerjaanAyah: response.data?.data?.father_occupation,
            pekerjaanIbu: response.data?.data?.mother_occupation,
            nomorWhatsappAyah: response.data?.data?.father_whatsapp,
            nomorWhatsappIbu: response.data?.data?.mother_whatsapp
          })

          handleValidasiData(response)

        }).catch((error) => {
          console.log(error.response.data);
        })
    }
  }

  const handleValidasiData = async (response) => {
    const getDataLogin = localStorage.getItem('LogInfo')
    if (getDataLogin) {
      const user_id = JSON.parse(getDataLogin).Id
      const token = JSON.parse(getDataLogin).token
      const email = JSON.parse(getDataLogin).email

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

      await axios.get(`${getDataDashboardApplicant}/${user_id}`)
        .then((response) => {

          if (response) {
            var getDataBiodata =
            {
              namaLengkapAyah: response.data?.data?.father_name,
              namaLengkapIbu: response.data?.data?.mother_name,
              nikAyah: response.data?.data?.father_nik,
              nikIbu: response.data?.data?.mother_nik,
            }

            var getDatasetPekerjaanOrangTua =
            {
              mother_occupation: response.data?.data?.mother_occupation,
              father_occupation: response.data?.data?.father_occupation,
            }

            var getDatasetKontakOrangTua =
            {
              mother_whatsapp: response.data?.data?.mother_whatsapp,
              father_whatsapp: response.data?.data?.father_whatsapp,
            }

            const biodataKeys = ['namaLengkapAyah', 'namaLengkapIbu', 'nikAyah', 'nikIbu'];
            const pekerjaanKeys = ['mother_occupation', 'father_occupation'];
            const kontakKeys = ['mother_whatsapp', 'father_whatsapp'];

            const biodataCompleteness = calculateCompleteness(getDataBiodata, biodataKeys);
            const pekerjaanCompleteness = calculateCompleteness(getDatasetPekerjaanOrangTua, pekerjaanKeys);
            const kontakCompleteness = calculateCompleteness(getDatasetKontakOrangTua, kontakKeys);

            setDataCompleteness(biodataCompleteness);
            setDataCompletenessPekerjaanOrangTua(pekerjaanCompleteness);
            setDataCompletenessKontak(kontakCompleteness);

          }
        }).catch((error) => {
          console.log(error.response.data);
        })
    }

  }

  const calculateCompleteness = (data, keys) => {
    const totalDataPoints = keys.length;
    const filledDataPoints = keys.filter(key => data[key] !== null && data[key] !== undefined).length;
    const completenessPercentage = (filledDataPoints / totalDataPoints) * 100;
    // console.log('completenessPercentage', completenessPercentage);
    // console.log('data', data);
    return completenessPercentage.toFixed(4);
  };

  const handleLoginSession = () => {
    const loginSessionExp = localStorage.getItem('LogInfo')
    const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
    if (res) {
      setOpenLoginSessionModal(true)
      localStorage.removeItem('LogInfo')
    }

  }

  useEffect(() => {

    const dataLogin = localStorage.getItem('LogInfo')

    if (dataLogin) {
      handleLoginSession()
      getData()
      handleValidasiData()
    } else if (!dataLogin) {
      setOpenLoginSessionModal(true)
      setTimeout(() => {
        navigate('/login');
      }, 9000);
    }

  }, [])

  const handleCloseLoginSessionModal = () => {
    setOpenLoginSessionModal(false)
    navigate('/login');
  }

  const handleSwitchButtonBiodataOrangTua = () => {
    setIsLoadingBiodataOrangtua(true)
    setTimeout(() => {
      setSwitchButtonBiodata(!switchButtonBiodata)
      setSwitchButtonKontak(false)
      setSwitchButtonPekerjaan(false)
      setSelectedName('')
      getData()
      setIsLoadingBiodataOrangtua(false)
    }, 1500);
  }

  const handleSwitchButtonPekerjaanOrangTua = () => {
    setIsLoadingPekerjaanOrangTua(true)
    setTimeout(() => {
      setSwitchButtonPekerjaan(!switchButtonPekerjaan)
      setSwitchButtonBiodata(false)
      setSwitchButtonKontak(false)
      setSelectedName('')
      getData()
      setIsLoadingPekerjaanOrangTua(false)
    }, 1500);
  }

  const handleSwitchButtonKontakOrangTua = () => {
    setIsLoadingKontakOrangTua(true)
    setTimeout(() => {
      setSwitchButtonKontak(!switchButtonKontak)
      setSwitchButtonBiodata(false)
      setSwitchButtonPekerjaan(false)
      setSelectedName('')
      getData()
      setIsLoadingKontakOrangTua(false)
    }, 1500);
  }


  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData(prev => {
      return { ...prev, [name]: value };
    });
    setSelectedName({
      name: name
    })
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const getDataLogin = localStorage.getItem('LogInfo')
    // const personal_information_id = JSON.parse(getDataLogin).userPersonalInformationId

    console.log('nomorWhatsappIbu', newData.nomorWhatsappIbu);
    console.log('nomorWhatsappAyah', newData.nomorWhatsappAyah);
    console.log('selectedName', selectedName);

    if (
      selectedName.name === 'namaLengkapIbu' ||
      selectedName.name === 'namaLengkapAyah' ||
      selectedName.name === 'nikIbu' ||
      selectedName.name === 'nikAyah'
    ) {
      setIsLoadingSubmitData({
        loadingBiodata: true
      })

      await axios.put(`${updateDataBiodataOrangTua}/${biodata.data.personal_information_id}`, {
        mother_name: newData.namaLengkapIbu,
        mother_nik: newData.nikIbu,
        father_name: newData.namaLengkapAyah,
        father_nik: newData.nikAyah
      }).then((response) => {
        setTimeout(() => {
          setSelectedName('')
          InfoAlert({
            message: 'Berhasil Update Data.',
            position: 'top-center'
          })
          setIsLoadingSubmitData({
            loadingBiodata: false
          })
          setSwitchButtonBiodata(false)
          handleValidasiData()
        }, 1500);
        console.log(response.data);

      }).catch((error) => {
        console.log(error.data);
      })

    } else if (selectedName.name === 'pekerjaanIbu' || selectedName.name === 'pekerjaanAyah') {

      setIsLoadingSubmitData({
        loadingPekerjaan: true
      })

      await axios.put(`${updateDataBioPekerjaandataOrangTua}/${biodata.data.personal_information_id}`, {
        father_occupation: newData.pekerjaanAyah,
        mother_occupation: newData.pekerjaanIbu
      }).then((response) => {
        console.log(response.data);
        setTimeout(() => {
          setSelectedName('')
          InfoAlert({
            message: 'Berhasil Update Data.',
            position: 'top-center'
          })
          setIsLoadingSubmitData({
            loadingPekerjaan: false
          })
          setSwitchButtonPekerjaan(false)
          handleValidasiData()
        }, 1500);

      }).catch((error) => {
        console.log(error.data);
      })

    } else if (selectedName.name === 'nomorWhatsappAyah' || selectedName.name === 'nomorWhatsappIbu') {
      setIsLoadingSubmitData({
        loadingKontak: true
      })
      await axios.put(`${updateDataKontakOrangTua}/${biodata.data.personal_information_id}`, {
        father_whatsapp: newData.nomorWhatsappAyah,
        mother_whatsapp: newData.nomorWhatsappIbu
      }).then((response) => {
        console.log(response.data);
        setTimeout(() => {
          setSelectedName('')
          InfoAlert({
            message: 'Berhasil Update Data.',
            position: 'top-center'
          })
          setIsLoadingSubmitData({
            loadingKontak: false
          })
          setSwitchButtonKontak(false)
          handleValidasiData()
        }, 1500);

      }).catch((error) => {
        console.log(error.data);
      })
    } else {
      ErrorAlert({
        message: 'Tidak ada perubahan data.',
        position: 'top-center'
      })
    }
    // console.log(newData.nik);
    // console.log(selectedImage);
  }

  const handleClickBackToDashboard = () => {
    navigate('/dashboard-applicant')
  }

  return (
    <>
      <TopMenu />
      <Grid
        container
        spacing={2}
        sx={{
          padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
          backgroundColor: bgColor,
          paddingBottom: '52px',
          height: '100%'
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '20px 0px 5px 0px' : '15px 0px 0px 0px',
          }}
        >
          <Box
            sx={{
              width: '100%'
            }}
          >
            <Button
              onClick={handleClickBackToDashboard}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                textTransform: 'none',
                padding: '0px',
                gap: '10px',
                // backgroundColor:'orange'
              }}
            >
              <Icon icon='ic:baseline-arrow-back' style={{ fontSize: '20px', color: mainColor }} />
              <Typography
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: mainColor,
                  fontSize: isMobile ? '13px' : '14px',
                  fonStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                }}
              >
                Kembali ke Dashboard
              </Typography>
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px 0px 10px 0px' : '10px 0px 0px 0px',
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              color: Dark,
              fontSize: isMobile ? '18px' : '20px',
              fonStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Data Orang Tua
          </Typography>
        </Grid>

        {
          Math.round(dataCompletenessPekerjaanOrangTua) &&
            Math.round(dataCompleteness) &&
            Math.round(dataCompletenessKontak) &&
            Math.round(dataCompletenessKontak) >= 100 ?
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >

              <Card
                elevation={5}
                sx={{
                  display: 'none',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >

                </Typography>
              </Card>
            </Grid>
            :
            <Grid
              item
              xs={12}
              md={12}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: isMobile ? 'center' : 'flex-start',
                // alignItems: 'center',
                margin: isMobile ? '0px' : '10px 0px 0px 0px',
              }}
            >

              <Card
                elevation={5}
                sx={{
                  display: 'flex',
                  flexDirection: isMobile ? 'column' : 'row',
                  justifyContent: isMobile ? 'center' : 'flex-start',
                  alignItems: 'center',
                  backgroundColor: Maroon,
                  padding: '10px',
                  gap: '10px',
                  maxWidth: '872px',
                  maxHeight: isMobile ? 'auto' : '44px',
                  // width:'100%'
                  // margin:'10px 0px'
                }}
              >
                <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: White,
                    fontSize: isMobile ? '13px' : '14px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: '22px',
                    textAlign: isMobile ? 'center' : 'unset'
                  }}
                >
                  Data orang tua anda belum lengkap, segera lengkapi sebelum 1 November 2023
                </Typography>
              </Card>
            </Grid>
        }


        {/* Biodata Orang Tua */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px' : '10px 0px 0px 0px',
          }}
        >
          <Card
            elevation={3}
            sx={{
              maxWidth: isMobile || isTablet ? '100%' : '384px',
              maxHeight: isMobile || isTablet ? '100%' : '503px',
              width: '100%',
              height: '100%',
              border: `1px solid ${DividerColor}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Biodata Orang Tua
                </Typography>
                <Box
                  sx={{
                    padding: '4px 8px',
                    backgroundColor: 'rgba(248, 215, 0, 0.20)',
                    borderRadius: '4px',
                    border: `1px solid ${DividerColor}`
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: Dark,
                      fontSize: '16px',
                      fonStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal',
                    }}
                  >
                    {dataCompleteness ? `${Math.round(dataCompleteness)}%` : '0%'}
                  </Typography>
                </Box>
              </Box>
              <Button
                onClick={handleSwitchButtonBiodataOrangTua}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: switchButtonBiodata ? TextGray : mainColor,
                  fontSize: '16px',
                  fonStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  textTransform: 'none',
                  padding: '0px'
                }}
                disabled={isLoadingSubmitData.loadingBiodata ? true : false}
              >
                {isLoadingBiodataOrangtua ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
              </Button>
            </Box>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Divider
                variant="string"
                style={{
                  backgroundColor: DividerColor,
                  borderBottomWidth: 0.5,
                  // marginTop: "2px",
                }}
              />
            </Box>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '16px',
                }}
              >
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Nama Ibu(Sesuai KK)"
                  placeholder='Masukkan nama ibu'
                  value={newData.namaLengkapIbu}
                  name="namaLengkapIbu"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  required
                  disabled={switchButtonBiodata ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="NIK Ibu"
                  placeholder='Masukkan NIK ibu'
                  value={newData.nikIbu}
                  name="nikIbu"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  onInput={(e) => {
                    // Filter out non-numeric characters
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  required
                  disabled={switchButtonBiodata ? false : true}
                  sx={{
                    flexShrink: switchButtonBiodata ? true : false,
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Nama Ayah(Sesuai KK)"
                  placeholder='Masukkan nama ayah'
                  value={newData.namaLengkapAyah}
                  name="namaLengkapAyah"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  required
                  disabled={switchButtonBiodata ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="NIK Ayah(Sesuai KK)"
                  placeholder='Masukkan NIK ayah'
                  value={newData.nikAyah}
                  name="nikAyah"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  onInput={(e) => {
                    // Filter out non-numeric characters
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  required
                  disabled={switchButtonBiodata ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <Button
                  fullWidth
                  type='submit'
                  variant='contained'
                  size='medium'
                  sx={{
                    backgroundColor: btnBgColor,
                    textTransform: 'none',
                    color: mainColor,
                    '&:hover': {
                      color: mainColor, // Change text color to white on hover
                      backgroundColor: btnBgColor
                    },
                  }}
                  disabled={isLoadingSubmitData.loadingBiodata || !switchButtonBiodata ? true : false}
                >
                  {
                    isLoadingSubmitData.loadingBiodata || isLoadingSubmitData.loadingPekerjaan || isLoadingSubmitData.loadingKontak ?
                      <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                      :
                      'Simpan Data'
                  }
                </Button>
              </Box>
            </form>
          </Card>
        </Grid>

        {/* Pekerjaan Orang Tua */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px' : '10px 0px 0px 0px',
          }}
        >
          <Card
            elevation={3}
            sx={{
              maxWidth: isMobile || isTablet ? '100%' : '384px',
              maxHeight: isMobile || isTablet ? '100%' : '280px',
              width: '100%',
              height: '100%',
              border: `1px solid ${DividerColor}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Pekerjaan Orang Tua
                </Typography>
                <Box
                  sx={{
                    padding: '4px 8px',
                    backgroundColor: 'rgba(248, 215, 0, 0.20)',
                    borderRadius: '4px',
                    border: `1px solid ${DividerColor}`
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: Dark,
                      fontSize: '16px',
                      fonStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal',
                    }}
                  >
                    {dataCompletenessPekerjaanOrangTua ? `${Math.round(dataCompletenessPekerjaanOrangTua)}%` : '0%'}
                  </Typography>
                </Box>
              </Box>
              <Button
                onClick={handleSwitchButtonPekerjaanOrangTua}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: switchButtonPekerjaan ? TextGray : mainColor,
                  fontSize: '16px',
                  fonStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  textTransform: 'none',
                  padding: '0px'
                }}
              >
                {isLoadingPekerjaanOrangTua ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
              </Button>
            </Box>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Divider
                variant="string"
                style={{
                  backgroundColor: DividerColor,
                  borderBottomWidth: 0.5,
                  // marginTop: "2px",
                }}
              />
            </Box>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '16px',
                }}
              >
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Pekerjaan Ibu"
                  placeholder='Masukkan pekerjaan ibu'
                  value={newData.pekerjaanIbu}
                  name="pekerjaanIbu"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  required
                  disabled={switchButtonPekerjaan ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Pekerjaan Ayah"
                  placeholder='Masukkan pekerjaan ayah'
                  value={newData.pekerjaanAyah}
                  name="pekerjaanAyah"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  required
                  disabled={switchButtonPekerjaan ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <Button
                  fullWidth
                  type='submit'
                  variant='contained'
                  size='medium'
                  sx={{
                    backgroundColor: btnBgColor,
                    textTransform: 'none',
                    color: mainColor,
                    '&:hover': {
                      color: mainColor, // Change text color to white on hover
                      backgroundColor: btnBgColor
                    },
                  }}
                  disabled={isLoadingSubmitData.loadingPekerjaan || !switchButtonPekerjaan ? true : false}
                >
                  {
                    isLoadingSubmitData.loadingPekerjaan ?
                      <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                      :
                      'Simpan Data'
                  }
                </Button>
              </Box>
            </form>
          </Card>
        </Grid>

        {/* Kontak Orang Tua */}
        <Grid
          item
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: isMobile ? 'center' : 'flex-start',
            // alignItems: 'center',
            margin: isMobile ? '0px' : '10px 0px 0px 0px',
          }}
        >
          <Card
            elevation={3}
            sx={{
              maxWidth: isMobile || isTablet ? '100%' : '384px',
              maxHeight: isMobile || isTablet ? '100%' : '280px',
              width: '100%',
              height: '100%',
              border: `1px solid ${DividerColor}`
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  gap: '10px'
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: Dark,
                    fontSize: '16px',
                    fonStyle: 'normal',
                    fontWeight: '500',
                    lineHeight: 'normal',
                  }}
                >
                  Kontak Orang Tua
                </Typography>
                <Box
                  sx={{
                    padding: '4px 8px',
                    backgroundColor: 'rgba(248, 215, 0, 0.20)',
                    borderRadius: '4px',
                    border: `1px solid ${DividerColor}`
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Plus Jakarta Sans',
                      color: Dark,
                      fontSize: '16px',
                      fonStyle: 'normal',
                      fontWeight: '500',
                      lineHeight: 'normal',
                    }}
                  >
                    {dataCompletenessKontak ? `${Math.round(dataCompletenessKontak)}%` : '0%'}
                  </Typography>
                </Box>
              </Box>
              <Button
                onClick={handleSwitchButtonKontakOrangTua}
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: switchButtonKontak ? TextGray : mainColor,
                  fontSize: '16px',
                  fonStyle: 'normal',
                  fontWeight: '600',
                  lineHeight: 'normal',
                  textTransform: 'none',
                  padding: '0px'
                }}
              >
                {isLoadingKontakOrangTua ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
              </Button>
            </Box>
            <Box
              sx={{
                width: '100%',
              }}
            >
              <Divider
                variant="string"
                style={{
                  backgroundColor: DividerColor,
                  borderBottomWidth: 0.5,
                  // marginTop: "2px",
                }}
              />
            </Box>
            <form onSubmit={handleSubmit}>
              <Box
                sx={{
                  padding: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'flex-start',
                  gap: '16px',
                }}
              >
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Nomor Whatsapp Ibu"
                  placeholder='Masukkan nomor whatsapp'
                  value={newData.nomorWhatsappIbu}
                  name="nomorWhatsappIbu"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  onInput={(e) => {
                    // Filter out non-numeric characters
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  required
                  disabled={switchButtonKontak ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  label="Nomor Whatsapp Ayah"
                  placeholder='Masukkan nomor whatsapp'
                  value={newData.nomorWhatsappAyah}
                  name="nomorWhatsappAyah"
                  onChange={(e) => handleChange(e)}
                  autoComplete="off"
                  onInput={(e) => {
                    // Filter out non-numeric characters
                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                  }}
                  required
                  disabled={switchButtonKontak ? false : true}
                  sx={{
                    marginBottom: isMobile ? '5px' : '8px',
                    fontSize: isMobile ? '11px' : '12px',
                    "& .MuiInputBase-input.Mui-disabled": {
                      WebkitTextFillColor: 'black',
                    },
                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                      "& fieldset": {
                        borderBottomColor: mainColor,
                      },
                    },
                    "& .MuiInputLabel-root.Mui-disabled": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiInputLabel-root": {
                      color: '#B3B3D4',
                      fontWeight: 'bold',
                      fontSize: '12px'
                    },
                    "& .MuiInputLabel-root.Mui-focused": {
                      color: mainColor,
                    },
                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                      color: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:hover:after": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:before": {
                      borderBottomColor: mainColor,
                    },
                    "& .MuiFilledInput-underline:focus:after": {
                      borderBottomColor: mainColor,
                    },
                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                    {
                      "-webkit-appearance": "none",
                      margin: 0,
                    },
                    "& input[type=number]": {
                      "-moz-appearance": "textfield",
                    },
                  }}
                />
                <Button
                  fullWidth
                  type='submit'
                  variant='contained'
                  size='medium'
                  sx={{
                    backgroundColor: btnBgColor,
                    textTransform: 'none',
                    color: mainColor,
                    '&:hover': {
                      color: mainColor, // Change text color to white on hover
                      backgroundColor: btnBgColor
                    },
                  }}
                  disabled={isLoadingSubmitData.loadingKontak || !switchButtonKontak ? true : false}
                >
                  {
                    isLoadingSubmitData.loadingKontak ?
                      <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                      :
                      'Simpan Data'
                  }
                </Button>
              </Box>
            </form>
          </Card>
        </Grid>
      </Grid>
      <LoginSessionModal
        open={openLoginSessionModal}
        close={handleCloseLoginSessionModal}
      />
      <ToastContainer />
    </>
  )
}

export default OrangTua