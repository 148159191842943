import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, useMediaQuery } from '@mui/material';
import { Dark, TextGray, btnBgColor, mainColor } from '../../Config/Color';
import { useState } from 'react';
import { Icon } from '@iconify/react';


const Profile = ({
    open,
    close,
    biodata
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '330px' : '459px',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '10px',
        boxShadow: 24,
        // p: 2,
    };

    return (
        <div>
            <Modal
                open={open}
                onClose={close}
            >
                <Box sx={style}>
                    <Box
                        sx={{
                            padding: '10px 15px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                // color: mainColor,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: '20.16px',
                            }}
                        >
                            My Profile
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            width: '100%',
                            marginBottom: '10px'
                        }}
                    >
                        <Divider
                            variant="string"
                            style={{
                                backgroundColor: "#fff",
                                borderBottomWidth: 0.5,
                                // marginTop: "2px",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: '10px 15px',
                            gap: '10px',
                            width: '100%',
                            marginBottom: '5px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%',
                                marginBottom: isMobile ? '10px' : undefined
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '17.64px',
                                    color: TextGray,
                                    marginBottom: '5px'
                                }}
                            >
                                Nama
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '20.16px',
                                    color: Dark
                                }}
                            >
                                {biodata.full_name}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '50%',
                                // marginBottom: isMobile ? '10px' : undefined
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '17.64px',
                                    color: TextGray,
                                    marginBottom: '5px'
                                }}
                            >
                                Email
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '20.16px',
                                    color: Dark
                                }}
                            >
                                {biodata.personal_email}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Baris 2 */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: '10px 15px',
                            gap: '10px',
                            width: '100%',
                            marginBottom: '5px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%',
                                marginBottom: isMobile ? '10px' : undefined
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '17.64px',
                                    color: TextGray,
                                    marginBottom: '5px'
                                }}
                            >
                                Kategori Bantuan
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '20.16px',
                                    color: Dark
                                }}
                            >
                                {biodata.category}
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                width: '50%'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '17.64px',
                                    color: TextGray,
                                    marginBottom: '5px'
                                }}
                            >
                                Nomor Whatsapp
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '20.16px',
                                    color: Dark
                                }}
                            >
                                {biodata.whatsapp_number}
                            </Typography>
                        </Box>
                    </Box>

                    {/* Baris 3 */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                            padding: '10px 15px',
                            gap: '10px',
                            width: '100%',
                            marginBottom: '5px'
                        }}
                    >
                        <Box
                            sx={{
                                width: '50%'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '17.64px',
                                    color: TextGray,
                                    marginBottom: '5px'
                                }}
                            >
                                NIK
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: mainColor,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '20.16px',
                                    color: Dark
                                }}
                            >
                                {biodata.nik_ktp}
                            </Typography>
                        </Box>
                    </Box>

                    <Box
                        sx={{
                            width: '100%',
                            // backgroundColor: 'orange',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            marginTop: '100px'
                        }}
                    >
                        <Button
                            onClick={() => {
                                setIsLoading(true)
                                setTimeout(() => {
                                    setIsLoading(false)
                                    close()
                                }, 1000);
                            }}
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                margin: '15px',
                                color: mainColor,
                                width: '172px',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                        >
                            {
                                isLoading ?
                                    <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                    :
                                    'Tutup'
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}

export default Profile