import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Modal,
    useMediaQuery,
    Button,
    Divider,
    TextareaAutosize,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'



const CreateComplaintModal = ({
    open,
    close,
    updateData,
    scrollToBottom
}) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [newData, setNewData] = useState({
        topikPengaduan: '',
    })
    const [pesanPengaduan, setPesanPengaduan] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)

    const navigate = useNavigate();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: isMobile ? 380 : 500,
        width: isMobile ? '100%' : 500,
        // minWidth: 450,
        height: 'auto',
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 24,
        // p: 2,
    };

    const handleTextareaChange2 = (event) => {
        setPesanPengaduan(event.target.value);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true)
        const userInfo = localStorage.getItem('LogInfo')

        if (userInfo) {
            const user_id = JSON.parse(userInfo).Id
            await axios.post(createTicket, {
                user_id: user_id,
                subject: newData.topikPengaduan,
                message: pesanPengaduan
            }).then((response) => {
                console.log(response.data);
                SuccessAlert({
                    message: 'Pengaduan berhasil terkirim.',
                    position: 'top-center'
                })
                close()
                setIsSubmitting(false)
                setPesanPengaduan('')
                setNewData({
                    topikPengaduan: ''
                })
                updateData()
                setTimeout(() => {
                    scrollToBottom()
                }, 1000);
            }).catch((error) => {
                console.log(error.response.data);
                setIsSubmitting(false)
            })
        }
    }

    return (
        <Modal
            open={open}
        // onClose={close}
        >
            <Box sx={style}>
                <Box
                    sx={{
                        // overflow:'hidden',
                        padding: 2
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: isMobile ? '16px' : '13px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Mulai Pengaduan
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%'
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: `2px solid ${DividerColor}`,
                            borderBottomWidth: 1,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: 2
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Topik Pengaduan"
                            placeholder='Masukkan topik pengaduan'
                            value={newData.topikPengaduan}
                            name="topikPengaduan"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            // disabled={switchButtonBiodata ? false : true}
                            sx={{
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: 'black',
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />

                        <Box
                            sx={{
                                marginTop: '16px'
                            }}
                        >
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                    marginBottom: '10px'
                                }}
                            >
                                Pesan
                            </Typography>
                            <TextareaAutosize
                                value={pesanPengaduan}
                                onChange={handleTextareaChange2}
                                placeholder="Masukkan pesan...."
                                style={{
                                    width: '100%',
                                    height: '137px',
                                    padding: '10px 0px 5px 5px',
                                    fontSize: '14px',
                                    border: `1px solid ${TextGray}`,
                                    borderRadius: '4px',
                                    resize: 'vertical'
                                }}
                                rowsMin={4} // You can adjust the number of rows as needed
                                required
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                gap: '10px',
                                background: 'rgba(242, 201, 76, 0.20)',
                                borderRadius: '8px',
                                padding: '10px 0px 10px 8px',
                                marginTop: '16px',
                                width: '100%'
                            }}
                        >
                            <Icon icon='lets-icons:check-fill' style={{ fontSize: '25px', color: TextGray }} />
                            <Typography
                                sx={{
                                    color: '#1E222F',
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '14px' : '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: 'normal',
                                }}
                            >
                                Pengajuan akan dijawab dalam kurun waktu 2x24 jam.
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Divider
                            variant="string"
                            style={{
                                backgroundColor: `2px solid ${DividerColor}`,
                                borderBottomWidth: 1,
                                // marginTop: "2px",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            padding: 2,
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: '20px'
                        }}
                    >
                        <Button
                            onClick={() => {
                                setPesanPengaduan('')
                                setNewData({
                                    topikPengaduan: ''
                                })
                                close()
                            }}
                            variant="contained"
                            sx={{
                                backgroundColor: DividerColor,
                                color: Dark,
                                textTransform: 'none',
                                // fontWeight: 'bold',
                                '&:hover': {
                                    // color: 'white', // Change text color to white on hover
                                    backgroundColor: DividerColor,
                                },
                            }}
                            disabled={isSubmitting ? true : false}
                        >
                            Batalkan
                        </Button>
                        <Button
                            type="submit"
                            variant="contained"
                            sx={{
                                backgroundColor: btnBgColor,
                                color: Dark,
                                textTransform: 'none',
                                width: isMobile ? '100%' : isTablet ? '150px' : '230px',
                                // fontWeight: 'bold',
                                '&:hover': {
                                    // color: 'white', // Change text color to white on hover
                                    backgroundColor: btnBgColor,
                                }
                            }}
                            disabled={isSubmitting ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan & Mulai Pengaduan'
                            }
                        </Button>
                    </Box>
                </form>
            </Box>
        </Modal>
    )
};

export default CreateComplaintModal;
