import { Box, Button, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { url } from '../../Config/Api'
import { Dark, DividerColor, TextGray, mainColor } from '../../Config/Color'
import { Icon } from '@iconify/react'
import example from '../../../assets/image/example.png'
import LightboxModal from '../../Modal/LightboxModal'

const Raport = ({
    rapor_class_x,
    rapor_class_xi,
    rapor_class_xii,
    scan_ijazah_sma,
    scan_sertifikat_1,
    scan_sertifikat_2,
    scan_sertifikat_3,
    scan_toefl,
    scan_ijazah_s1,
    scan_transkip_s1,
    scan_transkip_sma,
    scan_skkd,
    scan_kis,
    foto_house_1,
    foto_house_2,
    foto_house_3,
    student_id_number_scan,
    isMobile,
    isTablet
}) => {

    const [openImageModal, setOpenImageModal] = useState('')
    const [selectedImage, setSelectedImage] = useState('')

    const handleShowImage = (image, option) => {

        var imageUrl = `${url}${image}`
        const fileExtension = image ? image.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = image ? image.split('/').pop() : ''
        // console.log('tes', fileExtension);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }


        return (
            fileExtension === 'pdf' ?
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor: 'orange',
                        width: '200px',
                    }}
                >
                    <a
                        href={imageUrl}
                        // target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: "100%"
                        }}
                    >
                        <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '11px',
                                fonStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginBottom: '5px'
                            }}
                        >
                            {desiredString}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '11px',
                                fonStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                textAlign: 'center'
                            }}
                        >
                            Download PDF
                        </Typography>
                    </a>
                </Box>

                :
                <Box
                    sx={{
                        // backgroundColor: 'orange',
                        display: 'flex',
                        // flexDirection: option === 'rumah tinggal' ? 'row' : undefined,
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxWidth: option === 'rumah tinggal' ? '350px' : '572px',
                        maxHeight: option === 'rumah tinggal' ? '210px' : '403px',
                    }}
                >
                    <img
                        onClick={() => {
                            setSelectedImage(imageUrl)
                            setOpenImageModal(true)
                        }}
                        width='100%'
                        height='100%'
                        src={imageUrl}
                        alt="show-image"
                        style={{
                            objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                            borderRadius: '4px',
                            // border: `1px solid ${DividerColor}`,
                            // borderStyle:'dashed',
                            // borderColor: Dark,
                            // alignContent:'flex-start',
                            cursor: 'pointer'
                        }}
                    />
                </Box>
        )

    }

    return (
        <Grid
            container
            spacing={2}
            sx={{
                // padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                // backgroundColor: bgColor,
                // paddingBottom: '52px',
                // height: '100%'
                marginBottom: '20px'
            }}
        >

            {/* SKKD */}
            {scan_skkd ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Surat Keterangan Kepala Distrik/Kepala Kampung
                    </Typography>
                    {handleShowImage(scan_skkd)}
                </Grid>
                :
                undefined
            }

            {/* KIS */}
            {scan_kis ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Kartu Indonesia Sehat/Kartu Indonesia Pintar
                    </Typography>
                    {handleShowImage(scan_kis)}
                </Grid>
                :
                undefined
            }

            {/* Raport X */}
            {rapor_class_x ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Raport Kelas X
                    </Typography>
                    {handleShowImage(rapor_class_x)}
                </Grid>
                :
                undefined
            }


            {/* Raport XI */}
            {rapor_class_xi ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Raport Kelas XI
                    </Typography>
                    {handleShowImage(rapor_class_xi)}

                </Grid>
                :
                undefined
            }


            {/* Raport XII */}
            {rapor_class_xii ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Raport Kelas XII
                    </Typography>
                    {handleShowImage(rapor_class_xii)}
                </Grid>
                :
                undefined
            }


            {/* Ijazah SMA */}
            {scan_ijazah_sma ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Ijazah SMA
                    </Typography>
                    {handleShowImage(scan_ijazah_sma)}
                </Grid>
                :
                undefined
            }

            {/* Transkrip Nilai SMA */}
            {scan_transkip_sma ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Transkrip Nilai SMA
                    </Typography>
                    {handleShowImage(scan_transkip_sma)}
                </Grid>
                :
                undefined
            }

            {/* Ijazah S1 */}
            {scan_ijazah_s1 ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Ijazah S1
                    </Typography>
                    {handleShowImage(scan_ijazah_s1)}
                </Grid>
                :
                undefined
            }

            {/* Ijazah Transkrip Nilai S1 */}
            {scan_transkip_s1 ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Transkrip Nilai S1
                    </Typography>
                    {handleShowImage(scan_transkip_s1)}
                </Grid>
                :
                undefined
            }

            {/* Foto Rumah */}
            {foto_house_1 || foto_house_2 || foto_house_3 ?
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Foto Rumah Tinggal
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: '20px'
                        }}
                    >
                        {handleShowImage(foto_house_1, 'rumah tinggal')}
                        {handleShowImage(foto_house_2, 'rumah tinggal')}
                        {handleShowImage(foto_house_3, 'rumah tinggal')}
                    </Box>
                </Grid>
                :
                undefined
            }

            {/* ID Mahasiswa */}
            {student_id_number_scan ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        ID Student
                    </Typography>
                    {handleShowImage(student_id_number_scan)}
                </Grid>
                :
                undefined
            }

            {/* Sertifikat 1 */}
            {scan_sertifikat_1 ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Sertifikat 1
                    </Typography>
                    {handleShowImage(scan_sertifikat_1)}
                </Grid>
                :
                undefined
            }


            {/* Sertifikat 2 */}
            {scan_sertifikat_2 ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Sertifikat 2
                    </Typography>
                    {handleShowImage(scan_sertifikat_2)}
                </Grid>
                :
                undefined
            }


            {/* Sertifikat 3 */}
            {scan_sertifikat_3 ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Sertifikat 3
                    </Typography>
                    {handleShowImage(scan_sertifikat_3)}
                </Grid>
                :
                undefined
            }


            {/* Toefl */}
            {scan_toefl ?
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        // justifyContent: 'flex-start',
                        // alignItems:'flex-start',
                        // borderBottom: `2px solid ${DividerColor}`,
                        // borderRight: `2px solid ${DividerColor}`
                        // alignItems: 'center',
                        // backgroundColor: 'orange',
                        margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: '10px'
                        }}
                    >
                        Sertifikat Toefl
                    </Typography>
                    {handleShowImage(scan_toefl)}
                </Grid>
                :
                undefined
            }

            <LightboxModal
                open={openImageModal}
                close={() => setOpenImageModal(false)}
                imageUrl={selectedImage}
            />
        </Grid>
    )
}

export default Raport