import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, Box, Button, Card, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Dark, DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import { Icon } from '@iconify/react/dist/iconify.js';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { getDataDashboardApplicant, updateDataBiayaPendidikan, url } from '../../Config/Api';
import LightboxModal from '../../Modal/LightboxModal';
import { currencyList } from '../../Config/ListCurrency';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import InfoAlert from '../../ToastyAlert/InfoAlert';

const BiayaPendidikan = ({ completeData, handleExpired, dataCompletenessBiayaPendidikanAndHidup }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [switchButton, setSwitchButton] = useState(false)

    const [showImageTagihanKampus, setShowImageTagihanKampus] = useState('')
    const [showImagePembayaranKampus, setShowImagePembayaranKampus] = useState('')
    const [showImagePembayaranTempatTinggal, setShowImagePembayaranTempatTinggal] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)

    const [currentImageSKDN, setCurrentImageSKDN] = useState(null);
    const [selectedImageSKDN, setSelectedImageSKDN] = useState(null);
    const [imageUrlSKDN, setImageUrlSKDN] = useState(null);
    const fileInputRefSKDN = useRef(null);

    const [currentImageTagihanKampus, setCurrentImageTagihanKampus] = useState(null);
    const [selectedImageTagihanKampus, setSelectedImageTagihanKampus] = useState(null);
    const [imageUrlTagihanKampus, setImageUrlTagihanKampus] = useState(null);
    const fileInputRefTagihanKampus = useRef(null);

    const [currentImagePembayaranKampus, setCurrentImagePembayaranKampus] = useState(null);
    const [selectedImagePembayaranKampus, setSelectedImagePembayaranKampus] = useState(null);
    const [imageUrlPembayaranKampus, setImageUrlPembayaranKampus] = useState(null);
    const fileInputRefPembayaranKampus = useRef(null);

    const [currentImagePembayaranTempatTinggal, setCurrentImagePembayaranTempatTinggal] = useState(null);
    const [selectedImagePembayaranTempatTinggal, setSelectedImagePembayaranTempatTinggal] = useState(null);
    const [imageUrlPembayaranTempatTinggal, setImageUrlPembayaranTempatTinggal] = useState(null);
    const fileInputRefPembayaranTempatTinggal = useRef(null);

    const [selectedJenisTempatTinggal, setSelectedJenisTempatTinggal] = useState('Asrama')

    const [isDragging, setIsDragging] = useState({
        isDraggingPictureTagihanKampus: false,
        isDraggingPicturePembayaranKampus: false,
        isDraggingPicturePembayaranTempatTinggal: false,
        isDraggingPictureSKDN: false,
    });

    const [newData, setNewData] = useState({
        nominalBiayaPendidikan: '',
        nominalBiayaHidup: '',
        domisiliLuarNegeri: '',
    });

    const dataListCurrency = currencyList.map(currency => `${currency.symbol} - ${currency.name}`);

    const [selectedCurrencySymbol, setSelectedCurrencySymbol] = useState(null)

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const refreshData = async () => {
        // console.log(switchButton);
        if (switchButton) {
            const getDataLogin = localStorage.getItem('LogInfo')
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    // console.log('nominal_scholarship_living', response?.data?.data?.nominal_scholarship_living);
                    // console.log('nominal_scholarship_education', response?.data?.data?.nominal_scholarship_education);
                    // console.log('domicile_when_pursuing_education', response?.data?.data?.kota_domicile);
                    // console.log('type_of_residence', response?.data?.data?.tipe_tempat_tinggal);
                    // console.log('latest_campus_payment_proof', response?.data?.data?.latest_education_bill_proof);
                    // console.log('currency', response?.data?.data?.currency);
                    // console.log('upload_domicile_certificate', response?.data?.data?.upload_domicile_certificate);
                    setNewData({
                        nominalBiayaHidup: response?.data?.data?.nominal_scholarship_living,
                        nominalBiayaPendidikan: response?.data?.data?.nominal_scholarship_education,
                        domisiliLuarNegeri: response?.data?.data?.kota_domicile
                    })
                    setSelectedJenisTempatTinggal(response?.data?.data?.tipe_tempat_tinggal)
                    setCurrentImagePembayaranKampus(response?.data?.data?.latest_campus_payment_proof)
                    setCurrentImagePembayaranTempatTinggal(response?.data?.data?.housing_payments)
                    setCurrentImageTagihanKampus(response?.data?.data?.latest_education_bill_proof)
                    setSelectedCurrencySymbol(response?.data?.data?.currency)
                    setCurrentImageSKDN(response?.data?.data?.surat_domicile)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log(response.data);
                    // console.log('nominal_scholarship_living', response?.data?.data?.nominal_scholarship_living);
                    // console.log('nominal_scholarship_education', response?.data?.data?.nominal_scholarship_education);
                    // console.log('domicile_when_pursuing_education', response?.data?.data?.domicile_when_pursuing_education);
                    // console.log('type_of_residence', response?.data?.data?.type_of_residence);
                    // console.log('latest_campus_payment_proof', response?.data?.data?.latest_education_bill_proof);
                    // console.log('currency', response?.data?.data?.currency);
                    // console.log('upload_domicile_certificate', response?.data?.data?.upload_domicile_certificate);
                    setNewData({
                        nominalBiayaHidup: response?.data?.data?.nominal_scholarship_living,
                        nominalBiayaPendidikan: response?.data?.data?.nominal_scholarship_education,
                        domisiliLuarNegeri: response?.data?.data?.kota_domicile
                    })
                    setSelectedJenisTempatTinggal(response?.data?.data?.tipe_tempat_tinggal)
                    setCurrentImagePembayaranKampus(response?.data?.data?.latest_campus_payment_proof)
                    setCurrentImagePembayaranTempatTinggal(response?.data?.data?.housing_payments)
                    setCurrentImageTagihanKampus(response?.data?.data?.latest_education_bill_proof)
                    setSelectedCurrencySymbol(response?.data?.data?.currency)
                    setCurrentImageSKDN(response?.data?.data?.surat_domicile)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleImageUpload = (fileInputRef, option) => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e, option) => {
        e.preventDefault();
        // console.log('handleDragOver', e);
        if (option === 'tagihan kampus') {
            setIsDragging({
                isDraggingPictureTagihanKampus: true
            })
        } else if (option === 'pembayaran kampus') {
            setIsDragging({
                isDraggingPicturePembayaranKampus: true
            })
        } else if (option === 'tempat tinggal') {
            setIsDragging({
                isDraggingPicturePembayaranTempatTinggal: true
            })
        } else if (option === 'skdn') {
            setIsDragging({
                isDraggingPictureSKDN: true
            })
        }
    };

    const handleDrop = (e, option) => {
        e.preventDefault();
        // console.log('handledrop');
        const file = e.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'tagihan kampus') {
            setIsDragging({
                isDraggingPictureKRS: false
            })
        } else if (option === 'pembayaran kampus') {
            setIsDragging({
                isDraggingPicturePembayaranKampus: false
            })
        } else if (option === 'tempat tinggal') {
            setIsDragging({
                isDraggingPicturePembayaranTempatTinggal: false
            })
        } else if (option === 'skdn') {
            setIsDragging({
                isDraggingPictureSKDN: false
            })
        }
    };

    const handleDragLeave = (e, option) => {
        // console.log('dragleave');
        e.preventDefault();
        if (option === 'tagihan kampus') {
            setIsDragging({
                isDraggingPictureTagihanKampus: true
            })
        } else if (option === 'pembayaran kampus') {
            setIsDragging({
                isDraggingPicturePembayaranKampus: true
            })
        } else if (option === 'tempat tinggal') {
            setIsDragging({
                isDraggingPicturePembayaranTempatTinggal: true
            })
        } else if (option === 'skdn') {
            setIsDragging({
                isDraggingPictureSKDN: true
            })
        }
    };

    const handleFileInputChange = (file, option) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    if (option === 'tagihan kampus') {
                        setSelectedImageTagihanKampus(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlTagihanKampus(imageUrl);
                    } else if (option === 'pembayaran kampus') {
                        setSelectedImagePembayaranKampus(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlPembayaranKampus(imageUrl);
                    } else if (option === 'tempat tinggal') {
                        setSelectedImagePembayaranTempatTinggal(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlPembayaranTempatTinggal(imageUrl);
                    } else if (option === 'skdn') {
                        setSelectedImageSKDN(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlSKDN(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    if (option === 'tagihan kampus') {
                        setSelectedImageTagihanKampus(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlTagihanKampus(imageUrl);
                    } else if (option === 'pembayaran kampus') {
                        setSelectedImagePembayaranKampus(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlPembayaranKampus(imageUrl);
                    } else if (option === 'tempat tinggal') {
                        setSelectedImagePembayaranTempatTinggal(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlPembayaranTempatTinggal(imageUrl);
                    } else if (option === 'skdn') {
                        setSelectedImageSKDN(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlSKDN(imageUrl);
                    }
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleRemoveImage = (option) => {
        if (option === 'tagihan kampus') {
            setSelectedImageTagihanKampus(null);
            setImageUrlTagihanKampus(null);
            setCurrentImageTagihanKampus(null)
        } else if (option === 'pembayaran kampus') {
            setSelectedImagePembayaranKampus(null);
            setImageUrlPembayaranKampus(null);
            setCurrentImagePembayaranKampus(null)
        } else if (option === 'tempat tinggal') {
            setSelectedImagePembayaranTempatTinggal(null);
            setImageUrlPembayaranTempatTinggal(null);
            setCurrentImagePembayaranTempatTinggal(null)
        } else if (option === 'skdn') {
            setSelectedImageSKDN(null);
            setImageUrlSKDN(null);
            setCurrentImageSKDN(null)
        }
    };

    const handleShowPDF = (currentFile, imageUrl) => {
        const fileExtension = currentFile ? currentFile.type.split('/').pop().toLowerCase() : ''
        // console.log('handleShowPDF', currentFile);

        return (
            // Display PDF and download button
            fileExtension === 'pdf' ?
                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'none',
                        gap: '10px',

                        // backgroundColor:'orange'
                    }}
                >
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {currentFile.name}
                    </Typography>
                </Button>
                :
                <img
                    src={imageUrl}
                    alt="selected-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />

        )
    }

    const handleSelectedJenisTempatTinggal = (e) => {
        setSelectedJenisTempatTinggal(e.target.value)
    }

    const handleShowImage = (currentImage) => {
        // console.log('currentImage show image', currentImage);

        var image = `${url}${currentImage}`
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = currentImage ? currentImage.split('/').pop() : ''
        // console.log('tes', filename);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }

        return (
            fileExtension === 'pdf' ?
                // Display PDF and download button
                <>
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {desiredString ? desiredString : ''}
                    </Typography>
                </>
                :
                <img
                    src={image}
                    alt="show-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />
        )
    }

    const handleShowImageClick = (currentImage, option) => {
        // console.log('currentImage', currentImage);
        var image = `${url}${currentImage}`;
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''

        if (fileExtension === 'pdf') {
            // Extract filename from the URL
            const filename = currentImage ? currentImage.split('/').pop() : ''

            // Handle PDF file download with the correct filename
            const downloadLink = document.createElement('a');
            downloadLink.href = image;
            // downloadLink.target = '_blank';
            downloadLink.download = filename;
            downloadLink.click();
        } else {
            if (option === 'tagihan kampus') {
                setShowImageTagihanKampus(image)
                setOpenLightbox(true);
            } else if (option === 'pembayaran kampus') {
                setShowImagePembayaranKampus(image)
                setOpenLightbox(true);
            } else if (option === 'tempat tinggal') {
                setShowImagePembayaranTempatTinggal(image)
                setOpenLightbox(true);
            }
        }
    };

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            refreshData()
            setIsLoading(false)
        }, 1500);
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true)

        const personalID = completeData?.personal_information_id
        const formData = new FormData()

        if (
            selectedImagePembayaranKampus &&
            selectedImagePembayaranTempatTinggal &&
            selectedImageSKDN &&
            selectedImageTagihanKampus
        ) {
            formData.append('_method', 'PUT')
            formData.append('currency', selectedCurrencySymbol)
            formData.append('nominal_scholarship_education', newData.nominalBiayaPendidikan)
            formData.append('nominal_scholarship_living', newData.nominalBiayaHidup)
            formData.append('latest_education_bill_proof', selectedImageTagihanKampus)
            formData.append('latest_campus_payment_proof', selectedImagePembayaranKampus)
            formData.append('housing_payments', selectedImagePembayaranTempatTinggal)
            formData.append('upload_domicile_certificate', selectedImageSKDN)
            formData.append('type_of_residence', selectedJenisTempatTinggal)
            formData.append('domicile_when_pursuing_education', newData.domisiliLuarNegeri)
        } else {
            formData.append('_method', 'PUT')
            formData.append('currency', selectedCurrencySymbol)
            formData.append('nominal_scholarship_education', newData.nominalBiayaPendidikan)
            formData.append('nominal_scholarship_living', newData.nominalBiayaHidup)
            formData.append('type_of_residence', selectedJenisTempatTinggal)
            formData.append('domicile_when_pursuing_education', newData.domisiliLuarNegeri)
        }

        if (
            (
                selectedImagePembayaranKampus ||
                selectedImagePembayaranTempatTinggal ||
                selectedImageSKDN ||
                selectedImageTagihanKampus
            )
            ||
            (
                currentImagePembayaranKampus ||
                currentImagePembayaranTempatTinggal ||
                currentImageSKDN ||
                currentImageTagihanKampus
            )
        ) {
            // console.log(personalID);

            await axios.post(`${updateDataBiayaPendidikan}/${personalID}`, formData)
                .then((response) => {
                    console.log(response.data);
                    if (response?.data?.message === "Informasi fiancial berhasil diperbarui") {
                        refreshData()
                        setSwitchButton(false)
                        InfoAlert({
                            message: 'Berhasil mengubah data',
                            position: 'top-center'
                        })
                    } else {
                        ErrorAlert({
                            message: response?.data?.message,
                            position: 'top-center'
                        })
                    }
                }).catch((error) => {
                    console.log(error.response.data);
                    if (error?.response?.data?.message === 'Unauthenticated.') {
                        handleExpired()
                    } else {
                        ErrorAlert({
                            message: error?.response?.data?.message,
                            position: 'top-center'
                        })
                    }
                })
        } else if (selectedImageTagihanKampus === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Bukti Tagihan Kampus',
                position: 'top-center',
            });
        } else if (selectedImagePembayaranKampus === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Bukti Pembayaran Kampus',
                position: 'top-center',
            });
        } else if (selectedImagePembayaranTempatTinggal === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Bukti Pembayaran Tempat Tinggal',
                position: 'top-center',
            });
        } else if (selectedImageSKDN === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Bukti Surat Keterangan Domisili Luar Negeri',
                position: 'top-center',
            });
        }
        setIsSubmitting(false)
    }

    return (
        <Card
            elevation={3}
            sx={{
                maxWidth: isMobile || isTablet ? '100%' : '384px',
                maxHeight: isMobile || isTablet ? '100%' : '2000px',
                width: '100%',
                height: '100%',
                border: `1px solid ${DividerColor}`
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Dark,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Biaya Pendidikan & Hidup
                    </Typography>
                    <Box
                        sx={{
                            padding: '4px 8px',
                            backgroundColor: 'rgba(248, 215, 0, 0.20)',
                            borderRadius: '4px',
                            border: `1px solid ${DividerColor}`
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            {dataCompletenessBiayaPendidikanAndHidup ? `${Math.round(dataCompletenessBiayaPendidikanAndHidup)}%` : '0%'}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    onClick={handleSwitchButton}
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: switchButton ? TextGray : mainColor,
                        fontSize: '16px',
                        fonStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'none',
                        padding: '0px'
                    }}
                >
                    {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                </Button>
            </Box>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Divider
                    variant="string"
                    style={{
                        backgroundColor: DividerColor,
                        borderBottomWidth: 0.5,
                        // marginTop: "2px",
                    }}
                />
            </Box>

            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '16px',
                    }}
                >
                    {/* Dropdown Mata Uang */}
                    <Autocomplete
                        // disabled={verificationStatus ? false : true}
                        // fullWidth
                        freeSolo
                        autoSelect // Aktifkan pemilihan otomatis saat mengetik
                        selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                        sx={{
                            marginBottom: '10px',
                            width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                        }}
                        id="matauang"
                        options={dataListCurrency.sort((a, b) => a.localeCompare(b))} // Mengurutkan opsi universitas secara alfabetis
                        getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                        value={selectedCurrencySymbol} // Mengikuti nilai yang dipilih
                        onChange={(event, newValue) => {
                            setSelectedCurrencySymbol(newValue); // Perbarui nilai selectedDistrict
                        }}
                        disabled={switchButton ? false : true}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant='standard'
                                label="Pilih Mata Uang"
                                required
                                disabled={switchButton ? false : true}
                                InputProps={{
                                    ...params.InputProps,
                                    // type: 'search',
                                    // sx: { margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 0px 0px' }
                                }}
                            />
                        )}
                    />

                    {/* Nominal Biaya Pendidikan*/}
                    <TextField
                        // fullWidth
                        variant="standard"
                        type="text"
                        label="Nominal Biaya Pendidikan"
                        placeholder='Masukkan nominal biaya pendidikan'
                        value={newData.nominalBiayaPendidikan}
                        name="nominalBiayaPendidikan"
                        onChange={(e) => handleChange(e)}
                        onInput={(e) => {
                            // Filter out non-numeric characters
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        autoComplete="off"
                        required
                        disabled={switchButton ? false : true}
                        sx={{
                            marginBottom: isMobile ? '20px' : '15px',
                            fontSize: isMobile ? '11px' : '12px',
                            width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                            },
                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                "& fieldset": {
                                    borderBottomColor: mainColor,
                                },
                            },
                            "& .MuiInputLabel-root.Mui-disabled": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiInputLabel-root": {
                                color: '#B3B3D4',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: mainColor,
                            },
                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:after": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:after": {
                                borderBottomColor: mainColor,
                            },
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                                "-webkit-appearance": "none",
                                margin: 0,
                            },
                            "& input[type=number]": {
                                "-moz-appearance": "textfield",
                            },
                        }}
                    />

                    {/* Nominal Biaya Hidup*/}
                    <TextField
                        // fullWidth
                        variant="standard"
                        type="text"
                        label="Nominal Biaya Hidup"
                        placeholder='Masukkan nominal biaya hidup'
                        value={newData.nominalBiayaHidup}
                        name="nominalBiayaHidup"
                        onChange={(e) => handleChange(e)}
                        onInput={(e) => {
                            // Filter out non-numeric characters
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        autoComplete="off"
                        required
                        disabled={switchButton ? false : true}
                        sx={{
                            marginBottom: isMobile ? '20px' : '15px',
                            fontSize: isMobile ? '11px' : '12px',
                            width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                            },
                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                "& fieldset": {
                                    borderBottomColor: mainColor,
                                },
                            },
                            "& .MuiInputLabel-root.Mui-disabled": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiInputLabel-root": {
                                color: '#B3B3D4',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: mainColor,
                            },
                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:after": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:after": {
                                borderBottomColor: mainColor,
                            },
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                                "-webkit-appearance": "none",
                                margin: 0,
                            },
                            "& input[type=number]": {
                                "-moz-appearance": "textfield",
                            },
                        }}
                    />

                    {/* Dropdown Skala */}
                    <FormControl required variant="standard" fullWidth disabled={switchButton ? false : true} sx={{ marginBottom: '5px' }}>
                        <InputLabel id="jenis-tempat-tinggal-labelId">Pilih Jenis Tempat Tinggal</InputLabel>
                        <Select
                            label="Pilih Jenis Tempat Tinggal"
                            labelId="jenis-tempat-tinggal-labelId"
                            id="jenis-tempat-tinggal-Id"
                            defaultValue={selectedJenisTempatTinggal}
                            value={selectedJenisTempatTinggal}
                            onChange={(e) => handleSelectedJenisTempatTinggal(e)}
                            required
                        >
                            <MenuItem value='Asrama'>
                                Asrama
                            </MenuItem>
                            <MenuItem value='Apartment'>
                                Apartment
                            </MenuItem>
                            <MenuItem value='Homestay'>
                                Homestay
                            </MenuItem>
                            <MenuItem value='Hostel'>
                                Hostel
                            </MenuItem>
                            <MenuItem value='Rumah Kontrakan'>
                                Rumah Kontrakan
                            </MenuItem>
                            {/* {listKabupaten && listKabupaten.map((kabupaten, index) => (
                                <MenuItem key={index} value={kabupaten}>
                                    {kabupaten}
                                </MenuItem>
                            ))} */}
                        </Select>
                    </FormControl>

                    {/* Textfield domisili di luar negeri */}
                    <TextField
                        // fullWidth
                        variant="standard"
                        type="text"
                        label="Domisili Luar Negeri"
                        placeholder='Masukkan alamat domisili Anda'
                        value={newData.domisiliLuarNegeri}
                        name="domisiliLuarNegeri"
                        onChange={(e) => handleChange(e)}
                        // onInput={(e) => {
                        //     // Filter out non-numeric characters
                        //     e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        // }}
                        autoComplete="off"
                        required
                        disabled={switchButton ? false : true}
                        sx={{
                            marginBottom: isMobile ? '20px' : '15px',
                            fontSize: isMobile ? '11px' : '12px',
                            width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                            },
                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                "& fieldset": {
                                    borderBottomColor: mainColor,
                                },
                            },
                            "& .MuiInputLabel-root.Mui-disabled": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiInputLabel-root": {
                                color: '#B3B3D4',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: mainColor,
                            },
                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:after": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:after": {
                                borderBottomColor: mainColor,
                            },
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                                "-webkit-appearance": "none",
                                margin: 0,
                            },
                            "& input[type=number]": {
                                "-moz-appearance": "textfield",
                            },
                        }}
                    />

                    {/* Upload Bukti Tagihan Kampus Terbaru */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '21px',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Bukti Tagihan Kampus Terbaru(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '5px'
                        }}
                        onDragOver={(e) => handleDragOver(e, 'tagihan kampus')}
                        onDrop={(e) => handleDrop(e, 'tagihan kampus')}
                        onDragLeave={(e) => handleDragLeave(e, 'tagihan kampus')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPictureTagihanKampus && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlTagihanKampus || currentImageTagihanKampus ? (
                                    <>
                                        {currentImageTagihanKampus ?
                                            handleShowImage(currentImageTagihanKampus)
                                            :
                                            handleShowPDF(selectedImageTagihanKampus, imageUrlTagihanKampus)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('tagihan kampus')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImageTagihanKampus, 'tagihan kampus')}
                                            >
                                                {currentImageTagihanKampus && currentImageTagihanKampus.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefTagihanKampus, 'tagihan kampus')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'tagihan kampus')}
                                ref={fileInputRefTagihanKampus}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>

                    {/* Upload Data Pembayaran Kampus */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '21px',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Bukti Pembayaran Kampus Terbaru(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '5px'
                        }}
                        onDragOver={(e) => handleDragOver(e, 'pembayaran kampus')}
                        onDrop={(e) => handleDrop(e, 'pembayaran kampus')}
                        onDragLeave={(e) => handleDragLeave(e, 'pembayaran kampus')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPicturePembayaranKampus && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlPembayaranKampus || currentImagePembayaranKampus ? (
                                    <>
                                        {currentImagePembayaranKampus ?
                                            handleShowImage(currentImagePembayaranKampus)
                                            :
                                            handleShowPDF(selectedImagePembayaranKampus, imageUrlPembayaranKampus)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('pembayaran kampus')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImagePembayaranKampus, 'pembayaran kampus')}
                                            >
                                                {currentImagePembayaranKampus && currentImagePembayaranKampus.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefPembayaranKampus, 'pembayaran kampus')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'pembayaran kampus')}
                                ref={fileInputRefPembayaranKampus}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>

                    {/* Upload Data Pembayaran Tempat Tinggal */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '21px',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Bukti Pembayaran Tempat Tinggal(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '5px'
                        }}
                        onDragOver={(e) => handleDragOver(e, 'tempat tinggal')}
                        onDrop={(e) => handleDrop(e, 'tempat tinggal')}
                        onDragLeave={(e) => handleDragLeave(e, 'tempat tinggal')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPicturePembayaranTempatTinggal && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlPembayaranTempatTinggal || currentImagePembayaranTempatTinggal ? (
                                    <>
                                        {currentImagePembayaranTempatTinggal ?
                                            handleShowImage(currentImagePembayaranTempatTinggal)
                                            :
                                            handleShowPDF(selectedImagePembayaranTempatTinggal, imageUrlPembayaranTempatTinggal)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('tempat tinggal')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImagePembayaranTempatTinggal, 'tempat tinggal')}
                                            >
                                                {currentImagePembayaranTempatTinggal && currentImagePembayaranTempatTinggal.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefPembayaranTempatTinggal, 'tempat tinggal')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'tempat tinggal')}
                                ref={fileInputRefPembayaranTempatTinggal}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>

                    {/* Upload foto/gambar Surat Keterangan Domisili di Luar Negeri */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '21px',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Bukti Surat Keterangan Domisili di Luar Negeri(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '5px'
                        }}
                        onDragOver={(e) => handleDragOver(e, 'skdn')}
                        onDrop={(e) => handleDrop(e, 'skdn')}
                        onDragLeave={(e) => handleDragLeave(e, 'skdn')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPictureSKDN && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlSKDN || currentImageSKDN ? (
                                    <>
                                        {currentImageSKDN ?
                                            handleShowImage(currentImageSKDN)
                                            :
                                            handleShowPDF(selectedImageSKDN, imageUrlSKDN)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('skdn')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImageSKDN, 'skdn')}
                                            >
                                                {currentImageSKDN && currentImageSKDN.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefSKDN, 'skdn')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'skdn')}
                                ref={fileInputRefSKDN}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>



                    {/* Button Simpan Perubahan */}
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        size='medium'
                        sx={{
                            backgroundColor: btnBgColor,
                            textTransform: 'none',
                            color: mainColor,
                            '&:hover': {
                                color: mainColor, // Change text color to white on hover
                                backgroundColor: btnBgColor
                            },
                        }}
                        disabled={isSubmitting || !switchButton ? true : false}
                    >
                        {isSubmitting ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Simpan Perubahan'
                        }
                    </Button>
                </Box>
            </form>
        </Card>
    )
}

export default BiayaPendidikan