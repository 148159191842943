import React, { useEffect, useState } from 'react'
import TopMenu from '../../components/Header/TopMenu'
import { Blue, Dark, DividerColor, Green, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { getDataDashboardApplicant, updateBiodata } from '../../components/Config/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import Contact from '../../components/Dasboard/DataPribadi/Contact';
import KartuKeluarga from '../../components/Dasboard/DataPribadi/KartuKeluarga';
import KTP from '../../components/Dasboard/DataPribadi/KTP';
import Biodata from '../../components/Dasboard/DataPribadi/Biodata';

const DataPribadi = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
    const [biodata, setBiodata] = useState('')
    const [dataCompletenessBiodata, setDataCompletenessBiodata] = useState(100)
    const [dataCompletenessKTP, setDataCompletenessKTP] = useState(100)
    const [dataCompletenessKontak, setDataCompletenessKontak] = useState(100)
    const [dataCompletenessKK, setDataCompletenessKK] = useState(100)
    const [validasiAllData, setValidasiAllData] = useState(false)

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    setBiodata(response.data)

                    //Validasi presentase kelengkapan data biodata
                    var listDataBiodata =
                    {
                        full_name: response.data?.data?.full_name,
                        place_of_birth: response.data?.data?.place_of_birth,
                        date_of_birth: response.data?.data?.date_of_birth,
                        origin_district: response.data?.data?.origin_district,
                    }

                    const dataBiodataKeys = ['full_name', 'place_of_birth', 'date_of_birth', 'origin_district'];
                    const dataBiodataCompleteness = calculateCompleteness(listDataBiodata, dataBiodataKeys);
                    setDataCompletenessBiodata(dataBiodataCompleteness);

                    //Validasi presentase kelengkapan data KTP
                    var listDataKTP =
                    {
                        nik_ktp: response.data?.data?.nik_ktp,
                        ktp_scan: response.data?.data?.ktp_scan,
                    }

                    const dataKTPKeys = ['nik_ktp', 'ktp_scan'];
                    const dataKTPCompleteness = calculateCompleteness(listDataKTP, dataKTPKeys);
                    setDataCompletenessKTP(dataKTPCompleteness);

                    //Validasi presentase kelengkapan data Kontak
                    var listDataKontak =
                    {
                        whatsapp_number: response.data?.data?.whatsapp_number,
                        personal_email: response.data?.data?.personal_email,
                        student_email: response.data?.data?.student_email,
                    }

                    const dataKontakKeys = ['whatsapp_number', 'personal_email', 'student_email'];
                    const dataKontakCompleteness = calculateCompleteness(listDataKontak, dataKontakKeys);
                    setDataCompletenessKontak(dataKontakCompleteness);

                    //Validasi presentase kelengkapan data Kartu Keluarga
                    var listDataKK =
                    {
                        family_card_number: response.data?.data?.family_card_number,
                        family_card_scan: response.data?.data?.family_card_scan,
                    }

                    const dataKKKeys = ['family_card_number', 'family_card_scan'];
                    const dataKKCompleteness = calculateCompleteness(listDataKK, dataKKKeys);
                    setDataCompletenessKK(dataKKCompleteness);

                }).catch((error) => {
                    console.log(error.response.data);
                })
        }

    }

    const calculateCompleteness = (data, keys) => {
        const totalDataPoints = keys.length;
        const filledDataPoints = keys.filter(key => data[key] !== null && data[key] !== undefined).length;
        const completenessPercentage = (filledDataPoints / totalDataPoints) * 100;
        return completenessPercentage.toFixed(4);
    };

    const handleLoginSession = () => {
        const loginSessionExp = localStorage.getItem('LogInfo')
        const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
        if (res) {
            setOpenLoginSessionModal(true)
            localStorage.removeItem('LogInfo')
        }

    }

    useEffect(() => {

        const dataLogin = localStorage.getItem('LogInfo')

        if (dataLogin) {
            handleLoginSession()
            getData()
        } else if (!dataLogin) {
            setOpenLoginSessionModal(true)
            setTimeout(() => {
                navigate('/login');
            }, 9000);
        }

    }, [])

    const handleCloseLoginSessionModal = () => {
        setOpenLoginSessionModal(false)
        navigate('/login');
    }

    const handleValidasiDataBiodata = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    setBiodata(response.data)

                    //Validasi presentase kelengkapan data biodata
                    var listDataBiodata =
                    {
                        origin_district: response.data?.data?.origin_district,
                        full_name: response.data?.data?.full_name,
                        place_of_birth: response.data?.data?.place_of_birth,
                        date_of_birth: response.data?.data?.date_of_birth,
                    }

                    const dataBiodataKeys = ['origin_district', 'full_name', 'place_of_birth', 'date_of_birth'];
                    const dataBiodataCompleteness = calculateCompleteness(listDataBiodata, dataBiodataKeys);
                    setDataCompletenessBiodata(dataBiodataCompleteness);

                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const handleValidasiDataKTP = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    setBiodata(response.data)

                    //Validasi presentase kelengkapan data KTP
                    var listDataKTP =
                    {
                        nik_ktp: response.data?.data?.nik_ktp,
                        ktp_scan: response.data?.data?.ktp_scan,
                    }

                    const dataKTPKeys = ['nik_ktp', 'ktp_scan'];
                    const dataKTPCompleteness = calculateCompleteness(listDataKTP, dataKTPKeys);
                    setDataCompletenessKTP(dataKTPCompleteness);

                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const handleValidasiDataKontak = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    setBiodata(response.data)

                    //Validasi presentase kelengkapan data Kontak
                    var listDataKontak =
                    {
                        whatsapp_number: response.data?.data?.whatsapp_number,
                        personal_email: response.data?.data?.personal_email,
                        student_email: response.data?.data?.student_email,
                    }

                    const dataKontakKeys = ['whatsapp_number', 'personal_email', 'student_email'];
                    const dataKontakCompleteness = calculateCompleteness(listDataKontak, dataKontakKeys);
                    setDataCompletenessKontak(dataKontakCompleteness);

                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const handleValidasiDataKK = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    setBiodata(response.data)

                    //Validasi presentase kelengkapan data Kartu Keluarga
                    var listDataKK =
                    {
                        family_card_number: response.data?.data?.family_card_number,
                        family_card_scan: response.data?.data?.family_card_scan,
                    }


                    const dataKKKeys = ['family_card_number', 'family_card_scan'];
                    const dataKKCompleteness = calculateCompleteness(listDataKK, dataKKKeys);
                    setDataCompletenessKK(dataKKCompleteness);
                    console.log('listDataKK', listDataKK);

                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    return (
        <>
            <TopMenu />
            <Grid
                container
                spacing={2}
                sx={{
                    padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                    backgroundColor: bgColor,
                    paddingBottom: '52px'
                }}
            >
                {Math.round(dataCompletenessBiodata) && Math.round(dataCompletenessKTP) && Math.round(dataCompletenessKontak) && Math.round(dataCompletenessKK) >= 100 ?
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: 'none',
                            flexDirection: 'row',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            alignItems: 'center',
                            margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                        }}
                    >
                        <Card
                            elevation={5}
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: isMobile ? 'center' : 'flex-start',
                                alignItems: 'center',
                                backgroundColor: Green,
                                padding: '10px',
                                gap: '10px',
                                maxWidth: '872px',
                                maxHeight: isMobile ? 'auto' : '44px',
                                // width:'100%'
                                // margin:'10px 0px'
                            }}
                        >
                            <Icon icon="lets-icons:check-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: White,
                                    fontSize: isMobile ? '13px' : '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '22px',
                                    textAlign: isMobile ? 'center' : 'unset'
                                }}
                            >
                                
                            </Typography>
                        </Card>
                    </Grid>
                    :
                    <Grid
                        item
                        xs={12}
                        md={12}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            alignItems: 'center',
                            margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                        }}
                    >
                        <Card
                            elevation={5}
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                justifyContent: isMobile ? 'center' : 'flex-start',
                                alignItems: 'center',
                                backgroundColor: Maroon,
                                padding: '10px',
                                gap: '10px',
                                maxWidth: '872px',
                                maxHeight: isMobile ? 'auto' : '44px',
                                // width:'100%'
                                // margin:'10px 0px'
                            }}
                        >
                            <Icon icon="ph:x-circle-fill" style={{ fontSize: isMobile ? '40px' : '20px', color: 'white' }} />
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: White,
                                    fontSize: isMobile ? '13px' : '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '22px',
                                    textAlign: isMobile ? 'center' : 'unset'
                                }}
                            >
                                Data pribadi anda belum lengkap, segera lengkapi sebelum 1 November 2023
                            </Typography>
                        </Card>
                    </Grid>
                }

                {/* Biodata */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    // lg={}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: isMobile ? 'center' : 'flex-start',
                        // alignItems: 'center',
                        // margin: isMobile ? '10px 0px' : '10px 0px',
                        // backgroundColor: 'darkblue'
                    }}
                >
                    <Biodata
                        biodata={biodata}
                        getDataValidasi={() => handleValidasiDataBiodata()}
                        validasiData={dataCompletenessBiodata}
                    />
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={4}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: isMobile || isTablet ? '16px' : '24px',
                        // backgroundColor: 'orange',
                        // alignItems: 'flex-start',
                        // margin: isMobile ? '10px 0px' : '10px 0px',
                    }}
                >
                    {/* KTP */}
                    <Grid
                        item
                        xs={12}
                        md={12}
                        // lg={}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            // alignItems: 'center',
                            // margin: isMobile ? '10px 0px' : '10px 0px',
                            // backgroundColor: 'darkblue'
                        }}
                    >
                        <KTP
                            biodata={biodata}
                            validasiData={dataCompletenessKTP}
                            getDataValidasi={() => handleValidasiDataKTP()}
                        />
                    </Grid>

                    {/* KK */}
                    <Grid
                        item
                        xs={12}
                        md={12}
                        // lg={}
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: isMobile ? 'center' : 'flex-start',
                            // alignItems: 'center',
                            // margin: isMobile ? '10px 0px' : '10px 0px',
                            // backgroundColor: 'darkblue'
                        }}
                    >
                        <KartuKeluarga
                            biodata={biodata}
                            validasiData={dataCompletenessKK}
                            getDataValidasi={() => handleValidasiDataKK}
                        />
                    </Grid>
                </Grid>

                {/* Kontak */}
                <Grid
                    item
                    xs={12}
                    md={4}
                    // lg={}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: isMobile ? 'center' : 'flex-start',
                        // alignItems: 'center',
                        // margin: isMobile ? '10px 0px' : '10px 0px',
                        // backgroundColor: 'darkblue'
                    }}
                >
                    <Contact
                        biodata={biodata}
                        validasiData={dataCompletenessKontak}
                        getDataValidasi={() => handleValidasiDataKontak()}

                    />
                </Grid>

            </Grid >
            <LoginSessionModal
                open={openLoginSessionModal}
                close={handleCloseLoginSessionModal}
            />
            <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
        </>
    )
}

export default DataPribadi