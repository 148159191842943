import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { mainColor } from '../../Config/Color';
import { useState } from 'react';



const NotifTahapII = ({
    open,
    close,
    text,
    defaultValueJenjangPendidikan
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    // console.log('selectedCategory', selectedCategory);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '330px' : '400px',
        // minWidth: isMobile ? '330px' : '600px',
        width: '100%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        p: 2,
    };

    const [loadingUbahData, setLoadingUbahData] = useState(false)
    const [loadingBack, setLoadingBack] = useState(false)

    // var data = localStorage.getItem('data')

    const handleUbahData = () => {
        setLoadingUbahData(true)

        let cekData = 'Tidak'

        // Ambil data yang sudah ada dari localStorage
        const data = JSON.parse(localStorage.getItem('data')) || {};

        // Tambahkan data baru ke dalam objek existingData
        data.cekData = cekData;

        // Menghapus data 
        delete data.dataStep2;

        // Menyimpan kembali data ke localStorage
        localStorage.setItem('data', JSON.stringify(data));

        setTimeout(() => {
            defaultValueJenjangPendidikan()
            // if (
            //     selectedCategory === 'Beasiswa Keluarga Miskin' ||
            //     selectedCategory === 'Ikatan Dinas'
            // ) {
            //     console.log('running 1');
            //     // console.log(defaultValueJenjangPendidikan());
            //     // updateCategory() 
            //     defaultValueJenjangPendidikan()
            // } else {
            //     console.log('running 2');
            //     // console.log(updateJenjangPendidikan());
            //     updateJenjangPendidikan()
            // }
            // updateFormData()
            // console.log(selectedJenjangPendidikan);
            close()
            setLoadingUbahData(false)
        }, 1500);
    }

    return (
        <div>
            <Modal
                open={open}
            // onClose={close}
            >
                <Box sx={style}>
                    <Icon icon='material-symbols:error' style={{ fontSize: '90px', color: 'maroon' }} />
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '12px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            color: 'maroon'
                        }}
                    >
                        {text.label}
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '12px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                            color: 'black',
                            textAlign: 'center',
                            margin: '10px 0px',
                        }}
                    >
                        {text.message}
                    </Typography>
                    <Box
                        sx={{
                            marginTop: '20px',
                            // backgroundColor: 'orange',
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center'
                        }}
                    >
                        <Button
                            onClick={() => {
                                setLoadingBack(true)
                                setTimeout(() => {
                                    close()
                                    setLoadingBack(false)
                                }, 1500);
                            }}
                            variant='contained'
                            sx={{
                                width: '100%',
                                maxWidth: '100px',
                                textTransform: 'none',
                                backgroundColor: 'maroon',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: 'maroon',
                                },
                            }}
                            disabled={loadingBack || loadingUbahData ? true : false}
                        >
                            {loadingBack ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Batal
                                </Typography>
                            }
                        </Button>
                        <Button
                            onClick={handleUbahData}
                            variant='contained'
                            sx={{
                                backgroundColor: mainColor,
                                textTransform: 'none',
                                width: '100%',
                                maxWidth: '100px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                            disabled={loadingBack || loadingUbahData ? true : false}
                        >
                            {loadingUbahData ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                    }}
                                >
                                    Ubah
                                </Typography>
                            }
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default NotifTahapII