import { Autocomplete, Box, Button, Card, Grid, IconButton, TextField, Tooltip, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import Top from '../../../components/Register/Top/Top';
import Footer from '../../../components/Register/Footer/Footer';
import axios from 'axios';
import { CekSupDN, ExitingData, listDataKabupaten, RegistrasiTahapI, resendEmailApi, sendEmailVerifikasiSUP } from '../../../components/Config/Api';
import { Dark, DividerColor, Green, bgFooter, btnBgColor, mainColor } from '../../../components/Config/Color';
import CustomTooltip from '../../../components/Modal/Tooltip/CustomTooltip';
import { Icon } from '@iconify/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert';
import EmailActiovationModalSUP from '../../../components/Modal/EmailActivationModal/EmailActivationModalSUP';
import { useNavigate } from 'react-router-dom';
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert';
import ErrorModal from '../../../components/Modal/VerifikasiSupModal/ErrorModal';

const FormExiting = () => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const navigate = useNavigate()

    const [listUniversitas, setListUniversitas] = useState(new Set())
    const [listProgramStudi, setListProgramStudi] = useState(new Set())
    const [listCity, setListCity] = useState(new Set())
    const [listDistrict, setListDistrict] = useState(new Set())
    const [newData, setNewData] = useState({
        fullName: null,
        nik: null,
        email: null,
    })
    const [emailVerificationData, setEmailVerificationData] = useState('')
    const [selectedDistrict, setSelectedDistrict] = useState('');
    const [selectedCity, setSelectedCity] = useState('');
    const [selectedProgramStudi, setSelectedProgramStudi] = useState('');
    const [cekAccount, setCekAccount] = useState('');
    const [emailError, setEmailError] = useState('');
    const [KTPError, setKTPError] = useState('');

    const [loadingSimpanData, setLoadingSimpanData] = useState(false);
    const [loadingNextPage, setLoadingNextPage] = useState(false);
    const [loadingSendOtpEmail, setLoadingSendOtpEmail] = useState(false);

    const [openEmailActivationModal, setOpenEmailActivationModal] = useState(false)
    const [verificationStatus, setVerificationStatus] = useState(false)

    const [dataWilayahKabupaten91, setDataWilayahKabupaten91] = useState([]);
    const [dataWilayahKabupaten94, setDataWilayahKabupaten94] = useState([]);
    const [dataWilayah91, setDataWilayah91] = useState([]);
    const [dataWilayah94, setDataWilayah94] = useState([]);

    const [openErrorModal, setOpenErrorModal] = useState(false)
    const [successRegistered, setSuccessRegistered] = useState(false)
    const [errorMessage, setErrorMessage] = useState({
        message: '',
        label: ''
    })

    const getDataExiting = async () => {
        try {
            const response = await axios.get(ExitingData);
            const data = response.data;
            // console.log('data', data);

            // Ambil semua nama dari setiap objek dalam respons API
            // const universitas = data.map(item => item.nama_universitas);

            // Ambil semua nama universitas, kota dan asal kabupaten dari setiap objek dalam respons API
            const universitas = new Set(data.map(item => item.nama_universitas));
            const city = new Set(data.map(item => item.kota_studi));
            const district = new Set(data.map(item => item.asal_kabupaten));
            const programStudi = new Set(data.map(item => item.jurusan_program_studi));

            // console.log('programStudi', programStudi);

            // Simpan semua ke dalam state 
            setListUniversitas(prevData => new Set([...prevData, ...universitas]));
            setListCity(prevData => new Set([...prevData, ...city]));
            setListDistrict(prevData => new Set([...prevData, ...district]));
            setListProgramStudi(prevData => new Set([...prevData, ...programStudi]));


        } catch (error) {
            console.log(error.response.data);
        }
    }

    useEffect(() => {
        getDataExiting()
        getDataWilayah()
    }, [])

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [countdownTimer, setCountdownTimer] = useState(0)
    const timerId = useRef()

    useEffect(() => {
        timerId.current = setInterval(() => {
            setCountdownTimer(prev => prev - 1)
        }, 1000)
        return () => clearInterval(timerId.current)
    }, [countdownTimer])

    useEffect(() => {
        if (countdownTimer <= 0) {
            clearInterval(timerId.current)
        }
    }, [countdownTimer])

    useEffect(() => {
        // console.log('asdsad', countdownTimer);
        if (countdownTimer <= 0) {
            setOpenEmailActivationModal(false)
        }
    }, [countdownTimer])

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });
        // Validate NIK format
        if (name === 'nik') {
            const kodeProvinsi = value.substring(0, 2);
            const kodeKabupaten = value.substring(2, 4);
            // console.log(dataWilayah91);
            // console.log(dataWilayah94);
            if (value !== '' && (kodeProvinsi !== '94' && kodeProvinsi !== '91')) {
                setKTPError('KTP bukan dari provinsi Papua Tengah.');
            } else if (value !== '' && (kodeProvinsi === '91' && [kodeKabupaten].some(kode => !dataWilayahKabupaten91.includes(kode)))) {
                setKTPError('KTP tidak berasal dari kabupaten yang ada di Papua Tengah.');
            } else if (value !== '' && (kodeProvinsi === '94' && [kodeKabupaten].some(kode => !dataWilayahKabupaten94.includes(kode)))) {
                setKTPError('KTP tidak berasal dari kabupaten yang ada di Papua Tengah.');
            } else {
                setKTPError('');
            }
        } else if (name === 'email') {
            // Validate email format
            if (value !== '' && !validateEmail(value)) {
                setEmailError('Format Email Salah');
            } else {
                setEmailError('');
            }
        }
    };

    const getDataWilayah = async () => {
        let uniqueCodes91 = new Set();
        let uniqueCodes94 = new Set();
        let uniqueCodesWilayah91 = new Set();
        let uniqueCodesWilayah94 = new Set();
        await axios.get(`${listDataKabupaten}/${91}`)
            .then((response) => {
                // console.log('wilayah91', response.data);

                const res = response.data?.data
                // console.log(res);
                res.forEach((data) => {
                    uniqueCodes91.add(data.code.substring(3, 5));
                    uniqueCodesWilayah91.add(data.code.substring(0, 2));
                })

            }).catch((error) => {
                console.log(error.response.data);
            });

        await axios.get(`${listDataKabupaten}/${94}`)
            .then((response) => {
                // console.log('wilayah94', response.data);
                const res = response.data?.data
                res.forEach((data) => {
                    uniqueCodes94.add(data.code.substring(3, 5));
                    uniqueCodesWilayah94.add(data.code.substring(0, 2));
                });
                // console.log('res 94', res);
            }).catch((error) => {
                console.log(error.response.data);
            });

        // Konversi Set kembali ke array
        const uniqueArray91 = Array.from(uniqueCodes91);
        const uniqueArray94 = Array.from(uniqueCodes94);
        // const uniqueArrayWilayah91 = Array.from(uniqueCodesWilayah91);
        // const uniqueArrayWilayah94 = Array.from(uniqueCodesWilayah94);
        // console.log('uniqueArray91', uniqueArray91);
        // console.log('uniqueCodesWilayah91', uniqueCodesWilayah91);
        // console.log('uniqueArray94', uniqueArray94);
        // console.log('uniqueCodesWilayah94', uniqueCodesWilayah94);
        setDataWilayahKabupaten91(uniqueArray91)
        setDataWilayah91(uniqueCodesWilayah91)
        setDataWilayah94(uniqueCodesWilayah94)
        setDataWilayahKabupaten94(uniqueArray94)
    };

    const handleSubmit = async () => {
        // e.preventDefault();
        setLoadingSendOtpEmail(true);

        const kodeProvinsi = newData.nik ? newData.nik.substring(0, 2) : null;
        const kodeKabupaten = newData.nik ? newData.nik.substring(2, 4) : null;

        if (
            validateEmail(newData.email) &&
            newData.nik &&
            newData.fullName &&
            (kodeProvinsi === '91' && [kodeKabupaten].some(kode => dataWilayahKabupaten91.includes(kode))) ||
            (kodeProvinsi === '94' && [kodeKabupaten].some(kode => dataWilayahKabupaten94.includes(kode)))
        ) {
            await axios.post(sendEmailVerifikasiSUP, {
                nama: newData.fullName,
                email: newData.email,
            }).then((response) => {
                console.log(response.data.data);
                // console.log('first');

                setEmailVerificationData(response.data?.data)
                setOpenEmailActivationModal(true)
                setLoadingSendOtpEmail(false);
                setCountdownTimer(60)
            }).catch((error) => {
                console.log(error.response.data);
                if (error.response.data && error.response.data.message) {
                    // ErrorAlert({
                    //     message: error.response.data.message === "Email already exists." ? 'Email sudah terdaftar.' : error.response.data.message,
                    //     position: 'top-center'
                    // })
                    const dataEmail = error.response.data.data
                    console.log('dataEmail', dataEmail);
                    if (dataEmail.type_otp === 'sup' && dataEmail.verification_status === false) {
                        // setDataCekEmail(dataEmail)
                        setEmailVerificationData(dataEmail)
                        setOpenEmailActivationModal(true)
                        setLoadingSendOtpEmail(false);
                        setCountdownTimer(60)
                    } else {
                        setErrorMessage({
                            label: 'Email Sudah Terdaftar.',
                            message: 'Email Anda sudah berhasil diaktivasi, silahkan melengkapi data Beasiswa SUP.'
                        })
                        setOpenErrorModal(true)
                        setVerificationStatus(true)
                        setLoadingSendOtpEmail(false);
                    }
                }
                setLoadingSendOtpEmail(false);
            })
            setLoadingSendOtpEmail(false);
        } else if (!validateEmail(newData.email)) {
            ErrorAlert({
                message: 'Format email salah.',
                position: 'top-center',
            });
            setLoadingSendOtpEmail(false);
        } else if (newData.nik !== '' && newData.fullName !== '' && (kodeProvinsi === '94' && kodeProvinsi !== '91')) {
            ErrorAlert({
                message: 'KTP bukan dari provinsi Papua Tengah.',
                position: 'top-center'
            })
            setLoadingSendOtpEmail(false);
        } else if (
            newData.nik !== '' &&
            (
                kodeProvinsi === '94' && [kodeKabupaten].some(kode => !dataWilayahKabupaten94.includes(kode)) ||
                kodeProvinsi === '91' && [kodeKabupaten].some(kode => !dataWilayahKabupaten91.includes(kode))
            )
        ) {
            ErrorAlert({
                message: 'KTP bukan dari kabupaten yang ada di Papua Tengah.',
                position: 'top-center'
            })
            setLoadingSendOtpEmail(false);
        } else if (newData.nik === null || newData.nik === '') {
            ErrorAlert({
                message: 'NIK wajib diisi.',
                position: 'top-center'
            })
            setLoadingSendOtpEmail(false);
        } else if (newData.fullName === null || newData.fullName === '') {
            ErrorAlert({
                message: 'Nama lengkap wajib diisi.',
                position: 'top-center'
            })
            setLoadingSendOtpEmail(false);
        } else {
            ErrorAlert({
                message: KTPError ? KTPError : undefined,
                position: 'top-center'
            })
            setLoadingSendOtpEmail(false)
        }
    }

    const handleSubmitData = async (e) => {
        e.preventDefault()

        // console.log('handleSubmitData', emailVerificationData);
        setLoadingSimpanData(true)
        const formData = new FormData()

        formData.append('no_ktp', newData.nik)
        formData.append('nama', newData.fullName)
        formData.append('email', newData.email)
        formData.append('jurusan', selectedProgramStudi)
        formData.append('kabupaten', selectedDistrict)
        formData.append('kota_studi', selectedCity)
        // for (const entry of formData.entries()) {
        //     console.log(entry[0] + ': ' + entry[1]);
        // }
        if (verificationStatus) {
            console.log('verificationStatus');
            await axios.post(CekSupDN, formData)
                .then((response) => {
                    console.log('response', response.data);
                    if (response.data.message === 'true') {
                        SuccessAlert({
                            message: 'Berhasil menyimpan data.',
                            position: 'top-center'
                        })
                        setTimeout(() => {
                            // navigate('/login')
                            setCekAccount('')
                            setLoadingSimpanData(false)
                            setSuccessRegistered(true)
                        }, 2000);
                    } else if (response.data.message === "already verified sup") {
                        setCekAccount(response.data.message ? response.data.message : '')
                        setErrorMessage({
                            label: 'Data Sudah Terverifikasi.',
                            message: 'Anda sudah pernah verifikasi data Beasiswa SUP, silahkan lanjut ke halaman login.'
                        })
                        setOpenErrorModal(true)
                        setLoadingSimpanData(false)
                    }
                }).catch((error) => {
                    console.log(error.response);
                    if (error.response.data.message === "Data not found.") {
                        // ErrorAlert({
                        //     message: 'Data tidak ditemukan.',
                        //     position: 'top-center'
                        // })
                        setOpenErrorModal(true)
                        setErrorMessage({
                            label: 'Data Tidak Ditemukan',
                            message: 'Mohon maaf data yang anda input tidak terdaftar di sistem, coba periksa ulang inputan data dengan lebih teliti dan pastikan semua data sudah benar.'
                        })
                    } else if (error.response.data.message === 'false') {
                        setOpenErrorModal(true)
                        setErrorMessage({
                            label: 'Data Tidak Sesuai.',
                            message: 'Mohon maaf data yang anda input tidak benar, mohon diperiksa ulang inputan data Anda.'
                        })
                    } else if (error.response.data.messages && (error.response.data.messages.email || error.response.data.messages.nik_ktp)) {
                        if (
                            error.response.data.messages.email === 'The email has already been taken.' &&
                            error.response.data.messages.nik_ktp === "The nik ktp has already been taken."
                        ) {
                            setOpenErrorModal(true)
                            setErrorMessage({
                                label: 'Data sudah terdaftar.',
                                message: 'Nomor NIK dan Email yang Anda input sudah terdaftar.'
                            })
                        } else if (error.response.data.messages.email === 'The email has already been taken.') {
                            setOpenErrorModal(true)
                            setErrorMessage({
                                label: 'Email Sudah Terdaftar.',
                                message: 'Mohon maaf email yang anda input sudah terdaftar'
                            })
                        } else if (error.response.data.messages.nik_ktp === "The nik ktp has already been taken.") {
                            setOpenErrorModal(true)
                            setErrorMessage({
                                label: 'NIK Sudah Terdaftar.',
                                message: 'Mohon maaf NIK yang anda input sudah terdaftar.'
                            })
                        }
                    }

                    setLoadingSimpanData(false)
                })
        } else {
            ErrorAlert({
                message: 'Silahkan periksa email Anda untuk aktivasi email.',
                position: 'top-center'
            })
            setLoadingSimpanData(false)

        }
    }


    return (
        <form onSubmit={handleSubmitData}>
            <Top />
            <Grid
                container
                sx={{
                    // backgroundColor: bgColor,
                    margin: isMobile ? '50px 0px 30px 0px' : '90px 0px 90px 0px',
                    padding: isMobile ? '0px 10px' : isTablet ? '0px 50px' : '0px 0px',
                    height: 'auto', // Change height to auto
                    overflow: 'hidden',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <Card
                    elevation={5}
                    sx={{
                        padding: isMobile ? '20px 25px' : '20px 30px',
                        width: '100%',
                        height: '100%',
                        maxWidth: '850px',
                        // minWidth: '850px',
                        maxHeight: '800px',
                        // minHeight: '700px',
                        display: successRegistered ? 'flex' : 'unset',
                        justifyItems: 'center',
                        alignItems: 'center'
                    }}
                >
                    {successRegistered ?
                        <Box
                            // item
                            // xs={12}
                            // md={12}
                            align='center'
                            sx={{
                                // margin: isMobile ? '0px 0px 20px 0px' : '0px 0px 20px 0px',
                                height: '100%', // You can adjust the height of other content items if needed
                                // backgroundColor: 'blue',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '10px',
                                width: '100%'
                            }}
                        >
                            <Icon icon='icon-park-outline:success' style={{ fontSize: '80px', color: mainColor }} />
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '18px' : '22px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                    color: mainColor
                                }}
                            >
                                Pendaftaran Berhasil.
                            </Typography>
                            <Box
                                sx={{
                                    backgroundColor: '#082E8F33',
                                    padding: '10px 5px',
                                    borderRadius: '10px',
                                    width: '100%',
                                    maxWidth: '700px',
                                    margin: '10px 0px',
                                    // backgroundColor:'orange'
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        // fontSize: isMobile ? '12x' : '14px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: isMobile ? '20px' : '25px',
                                        color: '#1E222F',
                                    }}
                                >
                                    Pendaftaran Beasiswa SUP telah berhasil, informasi akun Anda telah kami kirim ke email{' '}
                                    <span
                                        style={{
                                            fontWeight: 'bold',
                                            color: mainColor
                                        }}
                                    >
                                        {newData.email}.
                                    </span>
                                </Typography>
                            </Box>

                            {/* Button Login */}
                            <Box
                                sx={{
                                    width: '100%',
                                    maxWidth: isMobile ? '400px' : '300px'
                                }}
                            >
                                <Button
                                    fullWidth
                                    size='medium'
                                    variant='contained'
                                    onClick={() => {
                                        setLoadingNextPage(true)
                                        setTimeout(() => {
                                            navigate('/login')
                                            setLoadingNextPage(false)
                                        }, 1500);
                                    }}
                                    sx={{

                                        backgroundColor: mainColor,
                                        textTransform: 'none',
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '12px',
                                        fontWeight: 'bold',
                                        '&:hover': {
                                            color: 'white', // Change text color to white on hover
                                            backgroundColor: mainColor,
                                        },
                                    }}
                                    disabled={loadingNextPage ? true : false}
                                >
                                    {loadingNextPage ?
                                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                        :
                                        'Login'
                                    }

                                </Button>
                            </Box>
                        </Box>
                        :
                        <>
                            <Grid
                                item
                                xs={12}
                                md={12}
                                sx={{
                                    margin: isMobile ? '0px 0px 20px 0px' : '0px 0px 20px 0px',
                                    height: '5%', // You can adjust the height of other content items if needed
                                    // backgroundColor: 'orange',

                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: isMobile ? '18px' : '22px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                            color: '#1E222F'
                                        }}
                                    >
                                        Verifikasi Data Penerima Beasiswa SUP
                                    </Typography>
                                </Box>
                            </Grid>

                            <Grid item xs={12} md={12} >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: isMobile ? '15px' : '16px',
                                        fontStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        // marginBottom: isMobile ? '12px' : '18px',
                                        textAlign: 'justify',
                                        letterSpacingL: '1.5px',
                                        lineHeight: '20px',
                                        margin: isMobile ? '0px 0px 20px 0px' : '0px 0px 20px 0px',
                                    }}
                                >
                                    Lengkapi  form berikut untuk melakukan verifikasi data Beasiswa Siswa Unggul Papua Tengah.
                                </Typography>
                            </Grid>

                            {/* Nama Lengkap */}
                            <Grid item xs={12} md={12}>
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40px'
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Masukkan Nama Lengkap(Sesuai KTP)"
                                        placeholder='Masukkan nama lengkap'
                                        value={newData.fullName}
                                        name="fullName"
                                        onChange={(e) => handleChange(e)}
                                        autoComplete="off"
                                        required
                                        // disabled={verificationStatus ? true : false}
                                        sx={{
                                            marginBottom: isMobile ? '24px' : '34px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "black",
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                        }}
                                    />
                                </Box>
                            </Grid>

                            {/* Nomor KTP */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    textAlign: 'center',
                                    gap: '24px'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%'
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Nomor Induk Kependudukan(NIK)"
                                        placeholder='Masukkan NIK'
                                        value={newData.nik}
                                        name="nik"
                                        onChange={(e) => handleChange(e)}
                                        onInput={(e) => {
                                            // Filter out non-numeric characters
                                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                        }}
                                        autoComplete="off"
                                        required
                                        error={newData.nik === '' ? undefined : !!KTPError}
                                        helperText={KTPError}
                                        // disabled={verificationStatus ? true : false}
                                        sx={{
                                            marginBottom: isMobile ? '24px' : '34px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: "black",
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px'
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: 'maroon',
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                            "& .MuiFormHelperText-root.Mui-error": {
                                                color: "maroon", // Merubah warna pesan error menjadi maroon
                                                fontWeight: 'bold',
                                                fontSize: '10.5px',
                                            },
                                        }}
                                    />
                                </Box>
                                {!isMobile && (
                                    <CustomTooltip
                                        message='Hanya menerima KTP dengan NIK terdaftar sebagai penduduk Provinsi Papua Tengah.'
                                        padding='5px 8px'
                                    />
                                )}
                            </Grid>

                            {/* Textfield Email */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    textAlign: 'center',
                                    gap: '24px'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%'
                                    }}
                                >
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        type="text"
                                        label="Email"
                                        placeholder='Masukkan email aktif'
                                        value={newData.email}
                                        name="email"
                                        onChange={(e) => handleChange(e)}
                                        error={newData.email === '' ? undefined : !!emailError}
                                        // helperText={emailError}
                                        autoComplete="off"
                                        required
                                        disabled={verificationStatus ? true : false}
                                        sx={{
                                            marginBottom: isMobile ? '3px' : '5px',
                                            fontSize: isMobile ? '14px' : '13px',
                                            "& .MuiInputBase-input.Mui-disabled": {
                                                WebkitTextFillColor: verificationStatus ? DividerColor : 'black',
                                            },
                                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                                "& fieldset": {
                                                    borderBottomColor: mainColor,
                                                },
                                            },
                                            "& .MuiInputLabel-root.Mui-disabled": {
                                                color: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiInputLabel-root": {
                                                color: '#B3B3D4',
                                                fontWeight: 'bold',
                                                fontSize: '13px'
                                            },
                                            "& .MuiInputLabel-root.Mui-focused": {
                                                color: mainColor,
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: 'maroon',
                                            },
                                            "& .MuiFilledInput-underline:hover:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:hover:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:before": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& .MuiFilledInput-underline:focus:after": {
                                                borderBottomColor: mainColor,
                                            },
                                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                            {
                                                "-webkit-appearance": "none",
                                                margin: 0,
                                            },
                                            "& input[type=number]": {
                                                "-moz-appearance": "textfield",
                                            },
                                            "& .MuiFormHelperText-root.Mui-error": {
                                                color: "maroon", // Merubah warna pesan error menjadi maroon
                                                fontWeight: 'bold',
                                                fontSize: '10.5px',
                                            },
                                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                                color: "maroon", // Warna label saat status error
                                            },
                                        }}
                                    />
                                </Box>
                                {!isMobile && (
                                    <CustomTooltip
                                        message='Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima dan mengirim email.'
                                        padding='5px 8px'
                                    />
                                )}
                            </Grid>

                            {/* Button Kirim OTP Email */}
                            <Grid item xs={12} md={12}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%',
                                        // marginBottom: isMobile ? '24px' : '34px',
                                        // marginTop: isMobile ? '8px' : '10px',
                                    }}
                                >
                                    {emailError ?
                                        <Typography
                                            sx={{
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '10.5px',
                                                fontStyle: 'normal',
                                                fontWeight: 'bold',
                                                lineHeight: 'normal',
                                                color: 'maroon',
                                            }}
                                        >
                                            {emailError}
                                        </Typography>
                                        :
                                        <Typography></Typography>
                                    }
                                    {newData.email ?
                                        <Button
                                            onClick={handleSubmit}
                                            disabled={emailError ? true : false}
                                            sx={{
                                                display: verificationStatus ? 'none' : 'unset',
                                                textTransform: 'none',
                                                fontSize: '12px',
                                                padding: '0px'
                                            }}
                                        >
                                            {loadingSendOtpEmail ?
                                                <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px' }} />
                                                :
                                                'Verifikasi Email'
                                            }
                                        </Button>
                                        :
                                        ''
                                    }

                                </Box>
                            </Grid>

                            {/* Warning Message Email */}
                            <Grid item xs={12} md={12}>
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%',
                                        backgroundColor: '#FFFDCB',
                                        borderRadius: '5px',
                                        marginBottom: isMobile ? '24px' : '34px',
                                        marginTop: isMobile ? '8px' : '10px',
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: isMobile ? '11px' : '13px',
                                            fontStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: isMobile ? '17px' : '20px',
                                            color: Dark,
                                            padding: isMobile ? '6px' : '8px',
                                            textAlign: 'justify'
                                        }}
                                    >
                                        Alamat email yang dimasukkan akan seterusnya digunakan sebagai
                                        basis data utama untuk penerimaan beasiswa Siswa Unggul Papua Tengah.
                                    </Typography>
                                </Box>
                            </Grid>

                            {/* Dropdown Asal Kabupaten */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    textAlign: 'center',
                                    gap: '24px',
                                    // marginBottom: verificationStatus ? isMobile ? '24px' : '24px' : undefined,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%'
                                    }}
                                >
                                    <Autocomplete
                                        disabled={verificationStatus ? false : true}
                                        freeSolo
                                        autoSelect // Aktifkan pemilihan otomatis saat mengetik
                                        selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                                        sx={{ marginBottom: '10px' }}
                                        id="district"
                                        options={[...listDistrict]} // Gunakan listDistrict sebagai opsi
                                        getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                                        value={selectedDistrict} // Mengikuti nilai yang dipilih
                                        onChange={(event, newValue) => {
                                            setSelectedDistrict(newValue); // Perbarui nilai selectedDistrict
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pilih Kabupaten Asal"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // type: 'search',
                                                    sx: { height: selectedDistrict !== '' ? '50px' : '48px' }
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            {/* Notif Asal Kabupaten */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    // display: verificationStatus ? 'none' : undefined,
                                    marginBottom: isMobile ? '24px' : '24px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: isMobile ? '10px' : '11px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        color: verificationStatus ? 'darkgreen' : 'maroon'
                                    }}
                                >
                                    {
                                        verificationStatus && (selectedDistrict === '' || selectedDistrict === null) ?
                                            'Silahkan pilih Kabupaten Asal.'
                                            :
                                            !verificationStatus ?
                                                'Kabupaten Asal dapat dipilih setelah Verifikasi Email.'
                                                :
                                                verificationStatus && (selectedDistrict !== '' || selectedDistrict !== null) ?
                                                    ''
                                                    :
                                                    undefined
                                    }
                                </Typography>
                            </Grid>

                            {/* Dropdown Kota Asal */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    textAlign: 'center',
                                    gap: '24px',
                                    // marginBottom: verificationStatus ? isMobile ? '24px' : '24px' : undefined,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%'
                                    }}
                                >
                                    <Autocomplete
                                        disabled={verificationStatus ? false : true}
                                        freeSolo
                                        autoSelect // Aktifkan pemilihan otomatis saat mengetik
                                        selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                                        sx={{ marginBottom: '10px' }}
                                        id="city"
                                        options={[...listCity]} // Gunakan listDistrict sebagai opsi
                                        getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                                        value={selectedCity} // Mengikuti nilai yang dipilih
                                        onChange={(event, newValue) => {
                                            setSelectedCity(newValue); // Perbarui nilai selectedDistrict
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pilih Kota Studi / Negara Studi"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // type: 'search',
                                                    sx: { height: selectedCity !== '' ? '50px' : '48px' }
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            {/* Notif Kota Studi */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    // display: verificationStatus ? 'none' : undefined,
                                    marginBottom: isMobile ? '24px' : '24px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: isMobile ? '10px' : '11px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        color: verificationStatus ? 'darkgreen' : 'maroon'
                                    }}
                                >
                                    {
                                        verificationStatus && (selectedCity === '' || selectedCity === null) ?
                                            'Silahkan pilih Kota Studi / Negara Studi.'
                                            :
                                            !verificationStatus ?
                                                'Kota Studi / Negara Studi dapat dipilih setelah Verifikasi Email.'
                                                :
                                                verificationStatus && (selectedCity !== '' || selectedCity !== null) ?
                                                    ''
                                                    :
                                                    undefined
                                    }
                                </Typography>
                            </Grid>

                            {/* Dropdown Program Studi */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    textAlign: 'center',
                                    gap: '24px',
                                    // marginBottom: verificationStatus ? isMobile ? '24px' : '24px' : undefined,
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%'
                                    }}
                                >
                                    <Autocomplete
                                        disabled={verificationStatus ? false : true}
                                        freeSolo
                                        autoSelect // Aktifkan pemilihan otomatis saat mengetik
                                        selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
                                        sx={{ marginBottom: '10px' }}
                                        id="programStudi"
                                        options={[...listProgramStudi]} // Gunakan listDistrict sebagai opsi
                                        getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
                                        value={selectedProgramStudi} // Mengikuti nilai yang dipilih
                                        onChange={(event, newValue) => {
                                            setSelectedProgramStudi(newValue); // Perbarui nilai selectedDistrict
                                            // console.log(newValue);
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Pilih Program Studi"
                                                InputProps={{
                                                    ...params.InputProps,
                                                    // type: 'search',
                                                    sx: { height: selectedProgramStudi !== '' ? '50px' : '48px' }
                                                }}
                                            />
                                        )}
                                    />
                                </Box>
                            </Grid>

                            {/* Notif Program Studi */}
                            <Grid item xs={12} md={12}
                                sx={{
                                    // display: verificationStatus ? 'none' : undefined,
                                    marginBottom: isMobile ? '24px' : '24px',
                                }}
                            >
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: isMobile ? '10px' : '11px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        color: verificationStatus ? 'darkgreen' : 'maroon'
                                    }}
                                >
                                    {
                                        verificationStatus && (selectedProgramStudi === '' || selectedProgramStudi === null) ?
                                            'Silahkan pilih Program Studi.'
                                            :
                                            !verificationStatus ?
                                                'Program Studi dapat dipilih setelah Verifikasi Email.'
                                                :
                                                verificationStatus && (selectedProgramStudi !== '' || selectedProgramStudi !== null) ?
                                                    ''
                                                    :
                                                    undefined
                                    }
                                </Typography>
                            </Grid>

                            {/* Button Save */}
                            <Grid xs={12} md={12}
                                sx={{
                                    marginBottom: isMobile ? '10px' : '10px',
                                    // marginTop: isMobile ? '20px' : '26px'
                                }}
                            >
                                <Box
                                    sx={{
                                        width: isMobile ? '100%' : '40%',
                                        maxWidth: '40%',
                                        minWidth: isMobile ? '100%' : '40%',
                                    }}
                                >
                                    <Button
                                        fullWidth
                                        variant='contained'
                                        size='small'
                                        type='submit'
                                        sx={{
                                            backgroundColor: btnBgColor,
                                            color: mainColor,
                                            fontWeight: 'bold',
                                            textTransform: 'none',
                                            '&:hover': {
                                                color: 'white', // Change text color to white on hover
                                                backgroundColor: mainColor,
                                            },
                                        }}
                                    // disabled={loadingSimpanData ? true : false}
                                    >
                                        {loadingSimpanData ?
                                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                            :
                                            'Simpan Data'
                                        }
                                    </Button>

                                </Box>
                            </Grid>
                        </>
                    }
                </Card>
            </Grid >
            <EmailActiovationModalSUP
                open={openEmailActivationModal}
                triggerOpenModal={() => setOpenEmailActivationModal(true)}
                close={() => setOpenEmailActivationModal(false)}
                isMobile={isMobile}
                isTablet={isTablet}
                newData={newData}
                emailVerificationData={emailVerificationData}
                // nextStep={nextStep}
                cekAccount={cekAccount}
                countdownTimer={countdownTimer}
                resetCountdown={() => setCountdownTimer(0)}
                verificationStatusTrigger={() => setVerificationStatus(true)}
            />
            <ErrorModal
                open={openErrorModal}
                close={() => setOpenErrorModal(false)}
                errorMessage={errorMessage}
                statusData={cekAccount}
                resetCekAccount={() => setCekAccount('')}
            />
            <Footer />
            <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
        </form>
    )
}

export default FormExiting