export const mainColor = '#112B6D'
export const bgFooter = '#082E8F'
export const btnBgColor = '#F8D700'
export const bgColor = '#E6ECFC'
export const Maroon = '#EB5757'
export const White = '#ffffff'
export const Blue = '#082E8F'
export const Green = '#27AE60'
export const Dark = '#1E222F'
export const DividerColor = '#BECAE8'
export const TextGray = '#606571'