import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, Divider, TextField, Typography, useMediaQuery } from '@mui/material';
import { Dark, DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import { Icon } from '@iconify/react/dist/iconify.js';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { getDataDashboardApplicant, updateDataAdmissionDocuments, url } from '../../Config/Api';
import LightboxModal from '../../Modal/LightboxModal';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import InfoAlert from '../../ToastyAlert/InfoAlert';

const AdmissionDocument = ({ completeData, handleExpired, dataCompletenessAdmissionDocument }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [switchButton, setSwitchButton] = useState(false)

    const [showImageLOA, setShowImageLOA] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)

    const [currentImageLOA, setCurrentImageLOA] = useState(null);
    const [selectedImageLOA, setSelectedImageLOA] = useState(null);
    const [imageUrlLOA, setImageUrlLOA] = useState(null);
    const fileInputRefLOA = useRef(null);

    const [isDragging, setIsDragging] = useState({
        isDraggingPictureLOA: false,
    });

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const refreshData = async () => {
        // console.log(switchButton);
        if (switchButton) {
            const getDataLogin = localStorage.getItem('LogInfo')
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    setCurrentImageLOA(response?.data?.data?.LOA)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log('asdsad', response?.data?.data);
                    setCurrentImageLOA(response?.data?.data?.LOA)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    };

    useEffect(() => {
        getData();
    }, []);


    const handleImageUpload = (fileInputRef, option) => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e, option) => {
        e.preventDefault();
        // console.log('handleDragOver', e);
        if (option === 'loa') {
            setIsDragging({
                isDraggingPictureLOA: true
            })
        }
    };

    const handleDrop = (e, option) => {
        e.preventDefault();
        // console.log('handledrop');
        const file = e.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'loa') {
            setIsDragging({
                isDraggingPictureLOA: false
            })
        }
    };

    const handleDragLeave = (e, option) => {
        // console.log('dragleave');
        e.preventDefault();
        if (option === 'loa') {
            setIsDragging({
                isDraggingPictureLOA: true
            })
        }
    };

    const handleFileInputChange = (file, option) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    if (option === 'loa') {
                        setSelectedImageLOA(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlLOA(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    if (option === 'loa') {
                        setSelectedImageLOA(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlLOA(imageUrl);
                    }
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleRemoveImage = (option) => {
        if (option === 'loa') {
            setSelectedImageLOA(null);
            setImageUrlLOA(null);
            setCurrentImageLOA(null)
        }
    };

    const handleShowPDF = (currentFile, imageUrl) => {
        const fileExtension = currentFile ? currentFile.type.split('/').pop().toLowerCase() : ''
        // console.log('handleShowPDF', currentFile);

        return (
            // Display PDF and download button
            fileExtension === 'pdf' ?
                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'none',
                        gap: '10px',

                        // backgroundColor:'orange'
                    }}
                >
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {currentFile.name}
                    </Typography>
                </Button>
                :
                <img
                    src={imageUrl}
                    alt="selected-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />

        )
    }


    const handleShowImage = (currentImage) => {
        // console.log('currentImage show image', currentImage);

        var image = `${url}${currentImage}`
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = currentImage ? currentImage.split('/').pop() : ''
        // console.log('tes', filename);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }

        return (
            fileExtension === 'pdf' ?
                // Display PDF and download button
                <>
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {desiredString ? desiredString : ''}
                    </Typography>
                </>
                :
                <img
                    src={image}
                    alt="show-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />
        )
    }

    const handleShowImageClick = (currentImage, option) => {
        // console.log('currentImage', currentImage);
        var image = `${url}${currentImage}`;
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''

        if (fileExtension === 'pdf') {
            // Extract filename from the URL
            const filename = currentImage ? currentImage.split('/').pop() : ''

            // Handle PDF file download with the correct filename
            const downloadLink = document.createElement('a');
            downloadLink.href = image;
            // downloadLink.target = '_blank';
            downloadLink.download = filename;
            downloadLink.click();
        } else {
            if (option === 'loa') {
                setShowImageLOA(image)
                setOpenLightbox(true);
            }
        }
    };

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            refreshData()
            setIsLoading(false)
        }, 1500);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const personalID = completeData?.personal_information_id
        // console.log(personalID);
        setIsSubmitting(true)

        const formData = new FormData()
        if (selectedImageLOA) {
            formData.append('_method', 'PUT')
            formData.append('scan_loa', selectedImageLOA)
        } else {
            formData.append('_method', 'PUT')
        }

        if (selectedImageLOA || currentImageLOA) {
            await axios.post(`${updateDataAdmissionDocuments}/${personalID}`, formData)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.message === "Informasi personal berhasil diperbarui") {
                        setSwitchButton(false)
                        refreshData()
                        InfoAlert({
                            message: 'Berhasil mengubah data',
                            position: 'top-center'
                        })
                    }
                }).catch((error) => {
                    console.log(error.response.data);
                    if (error?.response?.data?.message === 'Unauthenticated.') {
                        handleExpired()
                    }
                })
        } else {
            ErrorAlert({
                message: 'Silakan Upload Foto LOA',
                position: 'top-center',
            });
        }
        setIsSubmitting(false)
    }

    return (
        <>
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : '400px',
                    width: '100%',
                    height: isMobile ? '395px' : '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            Admission Documents
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {dataCompletenessAdmissionDocument ? `${Math.round(dataCompletenessAdmissionDocument)}%` : '0%'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>

                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        {/* Upload Study Letter of Acceptance */}
                        <Typography
                            sx={{
                                color: '#1E222F',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '21px',
                                // marginBottom: '10px',
                            }}
                        >
                            Gambar Scan/Foto Letter of Acceptance(Maks 1MB)
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                // flexDirection: 'row',
                                // gap: '24px',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                                marginTop: '-8px',
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: isMobile ? '100%' : '190px',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                marginBottom: '5px'
                            }}
                            onDragOver={(e) => handleDragOver(e, 'loa')}
                            onDrop={(e) => handleDrop(e, 'loa')}
                            onDragLeave={(e) => handleDragLeave(e, 'loa')}
                        >
                            <Box
                                sx={{
                                    maxWidth: isMobile ? '350px' : '347px',
                                    maxHeight: '190px',
                                    width: '100%',
                                    height: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px',
                                    position: 'relative',
                                }}
                            >
                                {isDragging.isDraggingPictureLOA && !isMobile && switchButton ?
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            lineHeight: '17,64px',
                                            textAlign: 'center',
                                            color: mainColor
                                        }}
                                    >
                                        Lepaskan untuk mengunggah
                                    </Typography>
                                    :
                                    imageUrlLOA || currentImageLOA ? (
                                        <>
                                            {currentImageLOA ?
                                                handleShowImage(currentImageLOA)
                                                :
                                                handleShowPDF(selectedImageLOA, imageUrlLOA)
                                            }
                                            {switchButton ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={(e) => handleRemoveImage('loa')}
                                                >
                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#fff',
                                                    }}
                                                    onClick={() => handleShowImageClick(currentImageLOA, 'loa')}
                                                >
                                                    {currentImageLOA && currentImageLOA.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                        :
                                                        <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                    }
                                                </Box>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {!isMobile ?
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '13px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Geser gambar atau PDF ke area ini
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            color: TextGray
                                                        }}
                                                    >
                                                        atau
                                                    </Typography>
                                                </>
                                                :
                                                undefined
                                            }
                                            <div
                                                onClick={() => handleImageUpload(fileInputRefLOA, 'loa')}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '6px 12px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    display: 'inline-block',
                                                    backgroundColor: switchButton ? undefined : bgColor,
                                                    color: switchButton ? undefined : DividerColor
                                                }}
                                            >
                                                Pilih Gambar/PDF
                                            </div>
                                        </>
                                    )}
                                <input
                                    type="file"
                                    accept="image/*,.pdf"   // Accept both image and PDF files
                                    onChange={(event) => handleFileInputChange(event.target.files[0], 'loa')}
                                    ref={fileInputRefLOA}
                                    style={{ display: 'none' }}
                                    disabled={switchButton ? false : true}
                                />
                            </Box>
                        </Box>

                        {/* Button Simpan Perubahan */}
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                color: mainColor,
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                            disabled={isSubmitting || !switchButton ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Perubahan'
                            }
                        </Button>
                    </Box>
                </form>
            </Card>
            <LightboxModal
                open={openLightbox}
                close={() => {
                    setOpenLightbox(false)
                    setShowImageLOA('')
                }}
                imageUrl={showImageLOA}
            />
        </>
    )
}

export default AdmissionDocument