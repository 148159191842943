import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Grid,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText,
    useMediaQuery,
    Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { DividerColor, Maroon, bgColor, mainColor } from '../Config/Color';
import { Icon } from '@iconify/react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navbar = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const location = useLocation();
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [isLoadingLogOut, setIsLoadingLogOut] = useState(false);

    const [status, setStatus] = useState('')

    useEffect(() => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            // console.log('sdad',JSON.parse(getDataLogin).sup);
            setStatus(JSON.parse(getDataLogin).sup)
        }
    }, [])

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleLogout = () => {
        setIsLoadingLogOut(true)
        setTimeout(() => {
            localStorage.removeItem('LogInfo')
            navigate('/login')
            setIsLoadingLogOut(false)
        }, 1500);
    }

    return (
        <>
            {isMobile ? (
                <>
                    <AppBar position="static" sx={{ backgroundColor: '#fff', borderBottom: '1px solid #ccc' }}>
                        <Grid
                            container
                            sx={{
                                padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                                backgroundColor: '#fff',
                            }}
                        >
                            {isMobile && (
                                <IconButton
                                    onClick={handleDrawerOpen}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <MenuIcon />
                                    <Typography
                                        sx={{
                                            fontWeight: 'bold'
                                        }}
                                    >
                                        Menu
                                    </Typography>
                                </IconButton>
                            )}
                        </Grid>
                    </AppBar>

                    {/* Drawer for mobile */}
                    <Drawer anchor="left" open={drawerOpen} onClose={handleDrawerClose}>
                        <Box
                            sx={{
                                padding: '10px 15px',
                                marginTop: '10px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '14px',
                                    fontWeight: 'bold',
                                    lineHeight: 'normal',
                                }}
                            >
                                Dashboard Menu
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'space-between',
                                alignItems: 'flex-start',
                                gap: '20px',
                                padding: '5px 25px 15px 25px',
                                backgroundColor: '#fff',
                                height: '100%'
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                    gap: '20px'
                                }}
                            >
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Icon icon="material-symbols:dashboard"
                                        style={{
                                            fontSize: '17px',
                                            alignSelf: 'center',
                                            color: location.pathname === '/dashboard-applicant' ? mainColor : '#606571',
                                        }}
                                    />
                                    <Typography
                                        component={RouterLink}
                                        to="/dashboard-applicant"
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
                                            color: location.pathname === '/dashboard-applicant' ? mainColor : '#606571',
                                            fontWeight: location.pathname === '/dashboard-applicant' ? 'bold' : 'normal',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: mainColor,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        Dashboard
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Icon icon="icomoon-free:profile"
                                        style={{
                                            fontSize: '16px',
                                            alignSelf: 'center',
                                            color: location.pathname === '/data-pribadi' ? mainColor : '#606571',
                                        }}
                                    />
                                    <Typography
                                        component={RouterLink}
                                        to="/data-pribadi"
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
                                            color: location.pathname === '/data-pribadi' ? mainColor : '#606571',
                                            fontWeight: location.pathname === '/data-pribadi' ? 'bold' : 'normal',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: mainColor,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        Data Pribadi
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Icon icon="subway:file-10"
                                        style={{
                                            fontSize: '16px',
                                            alignSelf: 'center',
                                            color: location.pathname === '/berkas-persyaratan' ? mainColor : '#606571',
                                        }}
                                    />
                                    <Typography
                                        component={RouterLink}
                                        to="/berkas-persyaratan"
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
                                            color: location.pathname === '/berkas-persyaratan' ? mainColor : '#606571',
                                            fontWeight: location.pathname === '/berkas-persyaratan' ? 'bold' : 'normal',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: mainColor,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        Berkas Persyaratan
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Icon icon="icon-park-solid:agreement"
                                        style={{
                                            fontSize: '16px',
                                            alignSelf: 'center',
                                            color: location.pathname === '/agreement' ? mainColor : '#606571',
                                        }}
                                    />
                                    <Typography
                                        component={RouterLink}
                                        to="/agreement"
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
                                            color: location.pathname === '/agreement' ? mainColor : '#606571',
                                            fontWeight: location.pathname === '/agreement' ? 'bold' : 'normal',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: mainColor,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        Agreement
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Icon icon="icon-park-solid:agreement"
                                        style={{
                                            fontSize: '16px',
                                            alignSelf: 'center',
                                            color: location.pathname === '/riwayat' ? mainColor : '#606571',
                                        }}
                                    />
                                    <Typography
                                        component={RouterLink}
                                        to="/riwayat"
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
                                            color: location.pathname === '/riwayat' ? mainColor : '#606571',
                                            fontWeight: location.pathname === '/riwayat' ? 'bold' : 'normal',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: mainColor,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        Riwayat
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        gap: '10px'
                                    }}
                                >
                                    <Icon icon="mdi:customer-service"
                                        style={{
                                            fontSize: '18px',
                                            alignSelf: 'center',
                                            color: location.pathname === '/pengaduan' ? mainColor : '#606571',
                                        }}
                                    />
                                    <Typography
                                        component={RouterLink}
                                        to="/pengaduan"
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            fontSize: isMobile ? '12px' : isTablet ? '14px' : '16px',
                                            color: location.pathname === '/pengaduan' ? mainColor : '#606571',
                                            fontWeight: location.pathname === '/pengaduan' ? 'bold' : 'normal',
                                            textDecoration: 'none',
                                            '&:hover': {
                                                color: mainColor,
                                                fontWeight: 'bold',
                                            },
                                        }}
                                    >
                                        Pengaduan
                                    </Typography>
                                    {status === 'nonsup' ?
                                        undefined
                                        :
                                        <Typography
                                            component={RouterLink}
                                            to="/beasiswa-sup"
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                fontSize: isTablet ? '12px' : '16px',
                                                color: location.pathname === '/beasiswa-sup' ? mainColor : '#606571',
                                                fontWeight: location.pathname === '/beasiswa-sup' ? 'bold' : 'normal',
                                                textDecoration: 'none',
                                                '&:hover': {
                                                    color: mainColor,
                                                    fontWeight: 'bold',
                                                },
                                            }}
                                        >
                                            Beasiswa SUP
                                        </Typography>
                                    }
                                </Box>
                            </Box>
                            <Button
                                onClick={handleLogout}
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    gap: '10px',
                                    textTransform: 'none',
                                    padding: '0px'
                                }}
                            >
                                {isLoadingLogOut ?
                                    <Icon icon="svg-spinners:3-dots-fade"
                                        style={{
                                            fontSize: '19px',
                                            alignSelf: 'center',
                                            color: Maroon
                                        }}
                                    />
                                    :
                                    <Icon icon="solar:logout-3-bold"
                                        style={{
                                            fontSize: '19px',
                                            alignSelf: 'center',
                                            color: Maroon
                                        }}
                                    />
                                }
                                <Typography
                                    sx={{
                                        color: Maroon,
                                        fontWeight: 'bold',
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: isMobile ? '13px' : isTablet ? '14px' : '16px',
                                        textDecoration: 'none',
                                        // '&:hover': {
                                        //     color: mainColor,
                                        //     fontWeight: 'bold',
                                        // },
                                    }}
                                >
                                    {isLoadingLogOut ? 'Loading' : 'Keluar'}
                                </Typography>
                            </Button>
                        </Box>
                    </Drawer>
                </>
            ) : (
                // AppBar for PC
                <AppBar position="static" sx={{ backgroundColor: '#fff', marginBottom: '10px' }}>
                    <Grid
                        container
                        sx={{
                            padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                            backgroundColor: '#fff',
                            borderTop: `1px solid ${DividerColor}`,
                            borderBottom: `2px solid ${DividerColor}`
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: isMobile ? 'column' : 'row',
                                gap: isMobile ? '10px' : '48px',
                                width: '100%',
                                alignItems: 'center',
                                justifyContent: 'flex-start',
                                // backgroundColor:'orange'
                            }}
                        >
                            <Typography
                                component={RouterLink}
                                to="/dashboard-applicant"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: isTablet ? '12px' : '16px',
                                    color: location.pathname === '/dashboard-applicant' ? mainColor : '#606571',
                                    fontWeight: location.pathname === '/dashboard-applicant' ? 'bold' : 'normal',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: mainColor,
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                Dashboard
                            </Typography>
                            <Typography
                                component={RouterLink}
                                to="/data-pribadi"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: isTablet ? '12px' : '16px',
                                    color: location.pathname === '/data-pribadi' ? mainColor : '#606571',
                                    fontWeight: location.pathname === '/data-pribadi' ? 'bold' : 'normal',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: mainColor,
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                Data Pribadi
                            </Typography>
                            <Typography
                                component={RouterLink}
                                to="/berkas-persyaratan"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: isTablet ? '12px' : '16px',
                                    color: location.pathname === '/berkas-persyaratan' ? mainColor : '#606571',
                                    fontWeight: location.pathname === '/berkas-persyaratan' ? 'bold' : 'normal',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: mainColor,
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                Berkas Persyaratan
                            </Typography>
                            <Typography
                                component={RouterLink}
                                to="/agreement"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: isTablet ? '12px' : '16px',
                                    color: location.pathname === '/agreement' ? mainColor : '#606571',
                                    fontWeight: location.pathname === '/agreement' ? 'bold' : 'normal',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: mainColor,
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                Agreement
                            </Typography>
                            <Typography
                                component={RouterLink}
                                to="/riwayat"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: isTablet ? '12px' : '16px',
                                    color: location.pathname === '/riwayat' ? mainColor : '#606571',
                                    fontWeight: location.pathname === '/riwayat' ? 'bold' : 'normal',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: mainColor,
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                Riwayat
                            </Typography>
                            <Typography
                                component={RouterLink}
                                to="/pengaduan"
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: isTablet ? '12px' : '16px',
                                    color: location.pathname === '/pengaduan' ? mainColor : '#606571',
                                    fontWeight: location.pathname === '/pengaduan' ? 'bold' : 'normal',
                                    textDecoration: 'none',
                                    '&:hover': {
                                        color: mainColor,
                                        fontWeight: 'bold',
                                    },
                                }}
                            >
                                Pengaduan
                            </Typography>
                            {status === 'nonsup' ?
                                undefined
                                :
                                <Typography
                                    component={RouterLink}
                                    to="/beasiswa-sup"
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: isTablet ? '12px' : '16px',
                                        color: location.pathname === '/beasiswa-sup' ? mainColor : '#606571',
                                        fontWeight: location.pathname === '/beasiswa-sup' ? 'bold' : 'normal',
                                        textDecoration: 'none',
                                        '&:hover': {
                                            color: mainColor,
                                            fontWeight: 'bold',
                                        },
                                    }}
                                >
                                    Beasiswa SUP
                                </Typography>
                            }
                            {/* Add similar styling for other links */}
                        </Box>
                    </Grid>
                    <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
                </AppBar>
            )}
        </>
    );
};

export default Navbar;
