export const listBank = [
    {
        id: 1,
        bank_name: 'Bank Mandiri',
        bank_code: '008'
    },
    {
        id: 2,
        bank_name: 'Bank Rakyat Indonesia(BRI)',
        bank_code: '002'
    },
    {
        id: 3,
        bank_name: 'Bank Negara Indonesia(BNI)',
        bank_code: '009'
    },
    {
        id: 4,
        bank_name: 'Bank Central Asia(BCA)',
        bank_code: '014'
    },
    {
        id: 5,
        bank_name: 'Bank Syariah Indonesia(BSI)',
        bank_code: '451'
    },
    {
        id: 6,
        bank_name: 'CIMB Niaga & CIMB Niaga Syariah',
        bank_code: '022'
    },
    {
        id: 7,
        bank_name: 'Bank Muamalat',
        bank_code: '147'
    },
    {
        id: 8,
        bank_name: 'Bank Danamon & Danamon Syariah',
        bank_code: '011'
    },
    {
        id: 9,
        bank_name: 'Bank Permata & Permata Syariah',
        bank_code: '013'
    },
    {
        id: 10,
        bank_name: 'Maybank Indonesia',
        bank_code: '016'
    },
    {
        id: 11,
        bank_name: 'Panin Bank',
        bank_code: '019'
    },
    {
        id: 12,
        bank_name: 'TMRW/UOB',
        bank_code: '023'
    },
    {
        id: 13,
        bank_name: 'OCBC NISP',
        bank_code: '028'
    },
    {
        id: 14,
        bank_name: 'Citibank',
        bank_code: '031'
    },
    {
        id: 15,
        bank_name: 'Bank Artha Graha Internasional',
        bank_code: '037'
    },
    {
        id: 16,
        bank_name: 'Bank of Tokyo Mitsubishi UFJ',
        bank_code: '042'
    },
    {
        id: 17,
        bank_name: 'DBS Indonesia',
        bank_code: '046'
    },
    {
        id: 18,
        bank_name: 'Standard Chartered Bank',
        bank_code: '050'
    },
    {
        id: 19,
        bank_name: 'Bank Capital Indonesia',
        bank_code: '095'
    },
    {
        id: 20,
        bank_name: 'ANZ Indonesia',
        bank_code: '061'
    },
    {
        id: 21,
        bank_name: 'Bank of China (Hong Kong) Limited',
        bank_code: '066'
    },
    {
        id: 22,
        bank_name: 'Bank Bumi Arta',
        bank_code: '068'
    },
    {
        id: 23,
        bank_name: 'HSBC Indonesia',
        bank_code: '076'
    },
    {
        id: 24,
        bank_name: 'Rabobank International Indonesia',
        bank_code: '088'
    },
    {
        id: 25,
        bank_name: 'Bank Mayapada',
        bank_code: '097'
    },
    {
        id: 26,
        bank_name: 'BJB',
        bank_code: '110'
    },
    {
        id: 27,
        bank_name: 'Bank DKI Jakarta',
        bank_code: '111'
    },
    {
        id: 28,
        bank_name: 'BPD DIY',
        bank_code: '112'
    },
    {
        id: 29,
        bank_name: 'Bank Jateng',
        bank_code: '113'
    },
    {
        id: 30,
        bank_name: 'Bank Jatim',
        bank_code: '114'
    },
    {
        id: 31,
        bank_name: 'Bank Jambi',
        bank_code: '115'
    },
    {
        id: 32,
        bank_name: 'Bank Sumut',
        bank_code: '116'
    },
    {
        id: 33,
        bank_name: 'Bank Sumbar (Bank Nagari)',
        bank_code: '117'
    },
    {
        id: 34,
        bank_name: 'Bank Riau Kepri',
        bank_code: '118'
    },
    {
        id: 35,
        bank_name: 'Bank Lampung',
        bank_code: '119'
    },
    {
        id: 36,
        bank_name: 'Bank Kalsel',
        bank_code: '120'
    },
    {
        id: 37,
        bank_name: 'Bank Kalbar',
        bank_code: '121'
    },
    {
        id: 38,
        bank_name: 'Bank Kaltim',
        bank_code: '122'
    },
    {
        id: 39,
        bank_name: 'Bank Kalteng',
        bank_code: '123'
    },
    {
        id: 40,
        bank_name: 'Bank Sulselbar',
        bank_code: '126'
    },
    {
        id: 41,
        bank_name: 'Bank SulutGo',
        bank_code: '125'
    },
    {
        id: 42,
        bank_name: 'Bank NTB Syariah',
        bank_code: '128'
    },
    {
        id: 43,
        bank_name: 'BPD Bali',
        bank_code: '129'
    },
    {
        id: 44,
        bank_name: 'Bank NTT',
        bank_code: '130'
    },
    {
        id: 45,
        bank_name: 'Bank Maluku',
        bank_code: '131'
    },
    {
        id: 46,
        bank_name: 'Bank Papua',
        bank_code: '132'
    },
    {
        id: 47,
        bank_name: 'Bank Bengkulu',
        bank_code: '133'
    },
    {
        id: 48,
        bank_name: 'Bank Sulteng',
        bank_code: '134'
    },
    {
        id: 49,
        bank_name: 'Bank Sultra',
        bank_code: '135'
    },
    {
        id: 50,
        bank_name: 'Bank Nusantara Parahyangan',
        bank_code: '145'
    },
    {
        id: 51,
        bank_name: 'Bank of India Indonesia',
        bank_code: '146'
    },
    {
        id: 52,
        bank_name: 'Bank Mestika Dharma',
        bank_code: '151'
    },
    {
        id: 53,
        bank_name: 'Bank Sinarmas',
        bank_code: '153'
    },
    {
        id: 54,
        bank_name: 'Bank Maspion Indonesia',
        bank_code: '157'
    },
    {
        id: 55,
        bank_name: 'Bank Ganesha',
        bank_code: '161'
    },
    {
        id: 56,
        bank_name: 'ICBC Indonesia',
        bank_code: '164'
    },
    {
        id: 57,
        bank_name: 'BQNB Indonesia',
        bank_code: '166'
    },
    {
        id: 58,
        bank_name: 'BTN/BTN Syariah',
        bank_code: '200'
    },
    {
        id: 59,
        bank_name: 'Bank Woori Saudara',
        bank_code: '212'
    },
    {
        id: 60,
        bank_name: 'BTPN',
        bank_code: '213'
    },
    {
        id: 61,
        bank_name: 'Bank BTPN Syariah',
        bank_code: '405'
    },
    {
        id: 62,
        bank_name: 'BJB Syariah',
        bank_code: '614'
    },
    {
        id: 63,
        bank_name: 'Bank Mega',
        bank_code: '426'
    },
    {
        id: 64,
        bank_name: 'Wokee/Bukopin',
        bank_code: '441'
    },
    {
        id: 65,
        bank_name: 'Bank Jasa Jakarta',
        bank_code: '472'
    },
    {
        id: 66,
        bank_name: 'LINE Bank/KEB Hana',
        bank_code: '484'
    },
    {
        id: 67,
        bank_name: 'Motion/MNC Bank',
        bank_code: '485'
    },
    {
        id: 68,
        bank_name: 'BRI Agroniaga',
        bank_code: '494'
    },
    {
        id: 69,
        bank_name: 'SBI Indonesia',
        bank_code: '498'
    },
    {
        id: 70,
        bank_name: 'Blu/BCA Digital',
        bank_code: '501'
    },
    {
        id: 71,
        bank_name: 'Nobu (Nationalnobu) Bank',
        bank_code: '503'
    },
    {
        id: 72,
        bank_name: 'Bank Mega Syariah',
        bank_code: '506'
    },
    {
        id: 73,
        bank_name: 'Bank Ina Perdana',
        bank_code: '513'
    },
    {
        id: 74,
        bank_name: 'Bank Sahabat Sampoerna',
        bank_code: '517'
    },
    {
        id: 75,
        bank_name: 'Seabank/Bank BKE',
        bank_code: '520'
    },
    {
        id: 76,
        bank_name: 'Bank Central Asia(BCA) Syariah',
        bank_code: '536'
    },
    {
        id: 77,
        bank_name: 'Jago/Artos',
        bank_code: '537'
    },
    {
        id: 78,
        bank_name: 'Bank Mayora Indonesia',
        bank_code: '542'
    },
    {
        id: 79,
        bank_name: 'Bank Index Selindo',
        bank_code: '548'
    },
    {
        id: 80,
        bank_name: 'Bank Victoria International',
        bank_code: '566'
    },
    {
        id: 81,
        bank_name: 'Bank IBK Indonesia',
        bank_code: '558'
    },
    {
        id: 82,
        bank_name: 'CTBC (Chinatrust) Indonesia',
        bank_code: '559'
    },
    // {
    //     id: 83,
    //     bank_name: 'Bank Kaltim',
    //     bank_code: '562'
    // },
    {
        id: 84,
        bank_name: 'Commonwealth Bank',
        bank_code: '950'
    },
    {
        id: 85,
        bank_name: 'Bank Victoria Syariah',
        bank_code: '566'
    },
    {
        id: 86,
        bank_name: 'BPD Banten',
        bank_code: '057'
    },
    {
        id: 87,
        bank_name: 'Panin Dubai Syariah',
        bank_code: '523'
    },
    {
        id: 88,
        bank_name: 'Bank Aceh Syariah',
        bank_code: '955'
    },
    {
        id: 89,
        bank_name: 'Bank Antardaerah',
        bank_code: '057'
    },
    {
        id: 90,
        bank_name: 'Bank China Construction Bank Indonesia',
        bank_code: '014'
    },
    {
        id: 91,
        bank_name: 'Bank CNB (Centratama Nasional Bank)',
        bank_code: '095'
    },
    {
        id: 92,
        bank_name: 'Bank Dinar Indonesia',
        bank_code: '152'
    },
    {
        id: 93,
        bank_name: 'BPR EKA (Bank Eka)',
        bank_code: '130'
    },
    {
        id: 94,
        bank_name: 'Allo Bank/Bank Harda Internasional',
        bank_code: '567'
    },
    {
        id: 95,
        bank_name: 'BANK MANTAP (Mandiri Taspen)',
        bank_code: '564'
    },
    {
        id: 96,
        bank_name: 'Bank Multi Arta Sentosa (Bank MAS)',
        bank_code: '548'
    },
    {
        id: 97,
        bank_name: 'Bank Prima Master',
        bank_code: '520'
    },
];
