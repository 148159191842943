import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { useFormContext } from '../../../pages/Registrasi/FormContext';
// import { DividerColor, btnBgColor, mainColor } from '../../Config/Color';
// import TooltipImageMax2MB from '../../../assets/tooltip/uploadgambarmax2mb.png'
import TooltipUploadGambar from '../../../assets/tooltip/uploadgambarkelas_x.png'
import CustomTooltip from '../../Modal/Tooltip/CustomTooltip';
import { secretKey } from '../../Config/SecretKey';
import { decrypt } from 'n-krypta';
import FileToBase64 from "../../FileToBase64/FileToBase64";
import Base64ToFile from '../../Base64ToFile/Base64ToFile';

const BeasiswaKeluargaMiskin = ({ isMobile, isTablet, selectedCategory, selectedJenjangPendidikan, step3 }) => {
    const { updateFormData } = useFormContext();
    const [selectedImageBKM, setSelectedImageBKM] = useState(null);
    const [imageUrlBKM, setImageUrlBKM] = useState(null);
    const fileInputRef = useRef(null);

    const [selectedImageBKMKIS, setSelectedImageBKMKIS] = useState(null);
    const [imageUrlBKMKIS, setImageUrlBKMKIS] = useState(null);
    const fileInputRefKIS = useRef(null);

    const [loadingSimpanData, setLoadingSimpanData] = useState(false)

    const [selectedImageX, setSelectedImageX] = useState(null);
    const [selectedImageXI, setSelectedImageXI] = useState(null);
    const [selectedImageXII, setSelectedImageXII] = useState(null);
    const [imageUrlX, setImageUrlX] = useState(null);
    const [imageUrlXI, setImageUrlXI] = useState(null);
    const [imageUrlXII, setImageUrlXII] = useState(null);
    const [selectedImageHouse1, setSelectedImageHouse1] = useState(null);
    const [selectedImageHouse2, setSelectedImageHouse2] = useState(null);
    const [selectedImageHouse3, setSelectedImageHouse3] = useState(null);
    const [imageUrlHouse1, setImageUrlHouse1] = useState(null);
    const [imageUrlHouse2, setImageUrlHouse2] = useState(null);
    const [imageUrlHouse3, setImageUrlHouse3] = useState(null);
    const fileInputRefX = useRef(null);
    const fileInputRefXI = useRef(null);
    const fileInputRefXII = useRef(null);
    const fileInputRefHouse1 = useRef(null);
    const fileInputRefHouse2 = useRef(null);
    const fileInputRefHouse3 = useRef(null);

    const [selectedImageTranskripNilaiSMA, setSelectedImageTranskripNilaiSMA] = useState(null);
    const [imageUrlTranskripNilaiSMA, setImageUrlTranskripNilaiSMA] = useState(null);
    const fileInputRefTranskripNilaiSMA = useRef(null);

    const [selectedImageIjazahSMA, setSelectedImageIjazahSMA] = useState(null);
    const [imageUrlIjazahSMA, setImageUrlIjazahSMA] = useState(null);
    const fileInputRefIjazahSMA = useRef(null);

    const [isDragging, setIsDragging] = useState({
        isDraggingBKM: false,
        isDraggingKIS: false,
        isDraggingRaportX: false,
        isDraggingRaportXI: false,
        isDraggingRaportXII: false,
        isDraggingIjazahSMA: false,
        isDraggingTranskripNilaiSMA: false,
        isDraggingHouse1: false,
        isDraggingHouse2: false,
        isDraggingHouse3: false,
    });

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const handleImageUpload = (fileinput) => {
        fileinput.current.click();
    };

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        if (option === 'surat keterangan') {
            setIsDragging({
                isDraggingBKM: true
            })
        } else if (option === 'kartu kis') {
            setIsDragging({
                isDraggingKIS: true
            })
        } else if (option === 'raport x') {
            setIsDragging({
                isDraggingRaportX: true
            })
        } else if (option === 'raport xi') {
            setIsDragging({
                isDraggingRaportXI: true
            })
        } else if (option === 'raport xii') {
            setIsDragging({
                isDraggingRaportXII: true
            })
        } else if (option === 'ijazah sma') {
            setIsDragging({
                isDraggingIjazahSMA: true
            })
        } else if (option === 'transkrip nilai') {
            setIsDragging({
                isDraggingTranskripNilaiSMA: true
            })
        } else if (option === 'rumah 1') {
            setIsDragging({
                isDraggingHouse1: true
            })
        } else if (option === 'rumah 2') {
            setIsDragging({
                isDraggingHouse2: true
            })
        } else if (option === 'rumah 3') {
            setIsDragging({
                isDraggingHouse3: true
            })
        }
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'surat keterangan') {
            setIsDragging({
                isDraggingBKM: false
            })
        } else if (option === 'kartu kis') {
            setIsDragging({
                isDraggingKIS: false
            })
        } else if (option === 'raport x') {
            setIsDragging({
                isDraggingRaportX: false
            })
        } else if (option === 'raport xi') {
            setIsDragging({
                isDraggingRaportXI: false
            })
        } else if (option === 'raport xii') {
            setIsDragging({
                isDraggingRaportXII: false
            })
        } else if (option === 'ijazah sma') {
            setIsDragging({
                isDraggingIjazahSMA: false
            })
        } else if (option === 'transkrip nilai') {
            setIsDragging({
                isDraggingTranskripNilaiSMA: false
            })
        } else if (option === 'rumah 1') {
            setIsDragging({
                isDraggingHouse1: false
            })
        } else if (option === 'rumah 2') {
            setIsDragging({
                isDraggingHouse2: false
            })
        } else if (option === 'rumah 3') {
            setIsDragging({
                isDraggingHouse3: false
            })
        }
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingBKM: false,
            isDraggingKIS: false,
            isDraggingRaportX: false,
            isDraggingRaportXI: false,
            isDraggingRaportXII: false,
            isDraggingIjazahSMA: false,
            isDraggingTranskripNilaiSMA: false,
            isDraggingHouse1: false,
            isDraggingHouse2: false,
            isDraggingHouse3: false,
        }) // Set state menjadi false saat dragleave
    };

    const handleFileInputChange = (file, fileInputChange) => {
        // const file = event.target.files[0];
        // console.log(fileInputChange);
        if (file) {
            // Check the file size
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                })
                // // Optionally, clear the file input
                // event.target.value = null;
                // return;
            } else {
                // Check the file type
                const fileType = file.type;
                const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
                const allowedPdfTypes = ['application/pdf'];

                if (allowedImageTypes.includes(fileType)) {
                    // Handle Image File
                    if (fileInputChange === 'surat keterangan') {
                        setSelectedImageBKM(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlBKM(imageUrl);
                    } else if (fileInputChange === 'raport x') {
                        setSelectedImageX(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlX(imageUrl);
                    } else if (fileInputChange === 'raport xi') {
                        setSelectedImageXI(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXI(imageUrl);
                    } else if (fileInputChange === 'raport xii') {
                        setSelectedImageXII(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXII(imageUrl);
                    } else if (fileInputChange === 'kartu kis') {
                        setSelectedImageBKMKIS(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlBKMKIS(imageUrl);
                    } else if (fileInputChange === 'rumah 1') {
                        setSelectedImageHouse1(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlHouse1(imageUrl);
                    } else if (fileInputChange === 'rumah 2') {
                        setSelectedImageHouse2(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlHouse2(imageUrl);
                    } else if (fileInputChange === 'rumah 3') {
                        setSelectedImageHouse3(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlHouse3(imageUrl);
                    } else if (fileInputChange === 'transkrip nilai') {
                        setSelectedImageTranskripNilaiSMA(file);
                        const imageUrlTranskripNilaiSMA = URL.createObjectURL(file);
                        setImageUrlTranskripNilaiSMA(imageUrlTranskripNilaiSMA);
                    } else if (fileInputChange === 'ijazah sma') {
                        setSelectedImageIjazahSMA(file);
                        const imageUrlIjazahSMA = URL.createObjectURL(file);
                        setImageUrlIjazahSMA(imageUrlIjazahSMA);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    // Handle PDF file
                    if (fileInputChange === 'surat keterangan') {
                        setSelectedImageBKM(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlBKM(imageUrl);
                    } else if (fileInputChange === 'raport x') {
                        setSelectedImageX(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlX(imageUrl);
                    } else if (fileInputChange === 'raport xi') {
                        setSelectedImageXI(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXI(imageUrl);
                    } else if (fileInputChange === 'raport xii') {
                        setSelectedImageXII(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlXII(imageUrl);
                    } else if (fileInputChange === 'kartu kis') {
                        setSelectedImageBKMKIS(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlBKMKIS(imageUrl);
                    } else if (fileInputChange === 'rumah 1') {
                        setSelectedImageHouse1(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlHouse1(imageUrl);
                    } else if (fileInputChange === 'rumah 2') {
                        setSelectedImageHouse2(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlHouse2(imageUrl);
                    } else if (fileInputChange === 'rumah 3') {
                        setSelectedImageHouse3(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlHouse3(imageUrl);
                    } else if (fileInputChange === 'transkrip nilai') {
                        setSelectedImageTranskripNilaiSMA(file);
                        const imageUrlTranskripNilaiSMA = URL.createObjectURL(file);
                        setImageUrlTranskripNilaiSMA(imageUrlTranskripNilaiSMA);
                    } else if (fileInputChange === 'ijazah sma') {
                        setSelectedImageIjazahSMA(file);
                        const imageUrlIjazahSMA = URL.createObjectURL(file);
                        setImageUrlIjazahSMA(imageUrlIjazahSMA);
                    }
                } else {
                    // Unsupported file type
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png, gif dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }



        }
    };

    const handleRemoveImage = (removeImage) => {
        if (removeImage === 'surat keterangan') {
            setSelectedImageBKM(null);
            setImageUrlBKM(null);
        } else if (removeImage === 'kartu kis') {
            setSelectedImageBKMKIS(null);
            setImageUrlBKMKIS(null);
        } else if (removeImage === 'rumah 1') {
            setSelectedImageHouse1(null);
            setImageUrlHouse1(null);
        } else if (removeImage === 'rumah 2') {
            setSelectedImageHouse2(null);
            setImageUrlHouse2(null);
        } else if (removeImage === 'rumah 3') {
            setSelectedImageHouse3(null);
            setImageUrlHouse3(null);
        } else if (removeImage === 'raport x') {
            setSelectedImageX(null);
            setImageUrlX(null);
        } else if (removeImage === 'raport xi') {
            setSelectedImageXI(null);
            setImageUrlXI(null);
        } else if (removeImage === 'raport xii') {
            setSelectedImageXII(null);
            setImageUrlXII(null);
        } else if (removeImage === 'transkrip nilai') {
            setSelectedImageTranskripNilaiSMA(null);
            setImageUrlTranskripNilaiSMA(null);
        } else if (removeImage === 'ijazah sma') {
            setSelectedImageIjazahSMA(null);
            setImageUrlIjazahSMA(null);
        }
    };

    const getAlreadyExistData = (decryptedString) => {

        if (decryptedString?.selectedImageBKMExist) {
            const fileSelectedImageBKMExist = Base64ToFile(decryptedString.selectedImageBKMExist.base64, decryptedString.selectedImageBKMExist.name);
            setSelectedImageBKM(fileSelectedImageBKMExist);
            setImageUrlBKM(decryptedString.selectedImageBKMExist.base64 || null);
        }

        if (decryptedString?.selectedImageBKMKISExist) {
            const fileselectedImageBKMKISExist = Base64ToFile(decryptedString.selectedImageBKMKISExist.base64, decryptedString.selectedImageBKMKISExist.name);
            setSelectedImageBKMKIS(fileselectedImageBKMKISExist);
            setImageUrlBKMKIS(decryptedString.selectedImageBKMKISExist.base64 || null);
        }

        if (decryptedString?.selectedImageXExist) {
            const fileSelectedImageXExist = Base64ToFile(decryptedString.selectedImageXExist.base64, decryptedString.selectedImageXExist.name);
            setSelectedImageX(fileSelectedImageXExist);
            setImageUrlX(decryptedString.selectedImageXExist.base64 || null);
        }

        if (decryptedString?.selectedImageXIExist) {
            const fileSelectedImageXIExist = Base64ToFile(decryptedString.selectedImageXIExist.base64, decryptedString.selectedImageXIExist.name);
            setSelectedImageXI(fileSelectedImageXIExist);
            setImageUrlXI(decryptedString.selectedImageXIExist.base64 || null);
        }

        if (decryptedString?.selectedImageXIIExist) {
            const fileSelectedImageXIIExist = Base64ToFile(decryptedString.selectedImageXIIExist.base64, decryptedString.selectedImageXIIExist.name);
            setSelectedImageXII(fileSelectedImageXIIExist);
            setImageUrlXII(decryptedString.selectedImageXIIExist.base64 || null);
        }

        if (decryptedString?.selectedImageIjazahSMAExist) {
            const fileSelectedImageIjazahSMAExist = Base64ToFile(decryptedString.selectedImageIjazahSMAExist.base64, decryptedString.selectedImageIjazahSMAExist.name);
            setSelectedImageIjazahSMA(fileSelectedImageIjazahSMAExist);
            setImageUrlIjazahSMA(decryptedString.selectedImageIjazahSMAExist.base64 || null);
        }

        if (decryptedString?.selectedImageTranskripNilaiSMAExist) {
            const fileSelectedImageTranskripNilaiSMAExist = Base64ToFile(decryptedString.selectedImageTranskripNilaiSMAExist.base64, decryptedString.selectedImageTranskripNilaiSMAExist.name);
            setSelectedImageTranskripNilaiSMA(fileSelectedImageTranskripNilaiSMAExist);
            setImageUrlTranskripNilaiSMA(decryptedString.selectedImageTranskripNilaiSMAExist.base64 || null);
        }

        if (decryptedString?.selectedImageHouse1Exist) {
            const fileSelectedImageHouse1Exist = Base64ToFile(decryptedString.selectedImageHouse1Exist.base64, decryptedString.selectedImageHouse1Exist.name);
            setSelectedImageHouse1(fileSelectedImageHouse1Exist);
            setImageUrlHouse1(decryptedString.selectedImageHouse1Exist.base64 || null);
        }

        if (decryptedString?.selectedImageHouse2Exist) {
            const fileSelectedImageHouse2Exist = Base64ToFile(decryptedString.selectedImageHouse2Exist.base64, decryptedString.selectedImageHouse2Exist.name);
            setSelectedImageHouse2(fileSelectedImageHouse2Exist);
            setImageUrlHouse2(decryptedString.selectedImageHouse2Exist.base64 || null);
        }

        if (decryptedString?.selectedImageHouse3Exist) {
            const fileSelectedImageHouse3Exist = Base64ToFile(decryptedString.selectedImageHouse3Exist.base64, decryptedString.selectedImageHouse3Exist.name);
            setSelectedImageHouse3(fileSelectedImageHouse3Exist);
            setImageUrlHouse3(decryptedString.selectedImageHouse3Exist.base64 || null);
        }
    }



    const data = localStorage.getItem('data')

    useEffect(() => {
        if (data) {
            const { dataStep2, cekData } = JSON.parse(data)
            if (dataStep2) {
                setTimeout(() => {
                    if (cekData === 'Ya' && dataStep2) {
                        getAlreadyExistData(dataStep2)
                    }
                }, 1500);
            }
        }
    }, [data]);

    const handleShowPDF = (fileName) => {
        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleSubmit = async () => {
        // console.log('selectedImageBKM', selectedImageBKM);
        // console.log('selectedImageBKMKIS', selectedImageBKMKIS);
        // console.log('selectedImageHouse1', selectedImageHouse1);
        // console.log('selectedImageHouse2', selectedImageHouse2);
        // console.log('selectedImageHouse3', selectedImageHouse3);

        setLoadingSimpanData(true)

        if (selectedImageBKM === null) {
            ErrorAlert({
                message: 'Wajib Upload Surat Keterangan Kepala Distrik/Kepala Kampung Miskin.',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageBKMKIS === null) {
            ErrorAlert({
                message: 'Wajib Upload Kartu Indonesia Sehat (KIS)/Kartu Indonesia Pintar (KIP)',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageBKMKIS === null) {
            ErrorAlert({
                message: 'Wajib Upload Kartu Indonesia Sehat (KIS)/Kartu Indonesia Pintar (KIP)',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageX === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Raport Kelas X',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageXI === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Raport Kelas XI',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageXII === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Raport Kelas XII',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageTranskripNilaiSMA === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Transkrip Nilai',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageIjazahSMA === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Ijazah SMA',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else if (selectedImageHouse1 === null || selectedImageHouse2 === null || selectedImageHouse3 === null) {
            ErrorAlert({
                message: 'Silahkan Upload File Gambar/PDF Kondisi Rumah Tinggal',
                position: 'top-center'
            })
            setLoadingSimpanData(false)
        } else {

            const selectedImageIjazahSMABase64 = selectedImageIjazahSMA ? await FileToBase64(selectedImageIjazahSMA) : null;
            const selectedImageRaportXBase64 = selectedImageX ? await FileToBase64(selectedImageX) : null;
            const selectedImageRaportXIBase64 = selectedImageXI ? await FileToBase64(selectedImageXI) : null;
            const selectedImageRaportXIIBase64 = selectedImageXII ? await FileToBase64(selectedImageXII) : null;
            const selectedImageBKMBase64 = selectedImageBKM ? await FileToBase64(selectedImageBKM) : null;
            const selectedImageBKMKISBase64 = selectedImageBKMKIS ? await FileToBase64(selectedImageBKMKIS) : null;
            const selectedImageHouse1Base64 = selectedImageHouse1 ? await FileToBase64(selectedImageHouse1) : null;
            const selectedImageHouse2Base64 = selectedImageHouse2 ? await FileToBase64(selectedImageHouse2) : null;
            const selectedImageHouse3Base64 = selectedImageHouse3 ? await FileToBase64(selectedImageHouse3) : null;
            const selectedImageTranskripNilaiSMABase64 = selectedImageTranskripNilaiSMA ? await FileToBase64(selectedImageTranskripNilaiSMA) : null;

            let values = {
                selectedCategoryExist: selectedCategory,
                selectedJenjangPendidikanExist: selectedJenjangPendidikan,
                selectedImageBKMExist: {
                    lastModified: selectedImageBKM.lastModified,
                    lastModifiedDate: selectedImageBKM.lastModifiedDate,
                    name: selectedImageBKM.name,
                    type: selectedImageBKM.type,
                    size: selectedImageBKM.size,
                    webkitRelativePath: selectedImageBKM.webkitRelativePath,
                    url: selectedImageBKM ? URL.createObjectURL(selectedImageBKM) : null,
                    base64: selectedImageBKMBase64
                },
                selectedImageBKMKISExist: {
                    lastModified: selectedImageBKMKIS.lastModified,
                    lastModifiedDate: selectedImageBKMKIS.lastModifiedDate,
                    name: selectedImageBKMKIS.name,
                    type: selectedImageBKMKIS.type,
                    size: selectedImageBKMKIS.size,
                    webkitRelativePath: selectedImageBKMKIS.webkitRelativePath,
                    url: selectedImageBKMKIS ? URL.createObjectURL(selectedImageBKMKIS) : null,
                    base64: selectedImageBKMKISBase64
                },
                selectedImageXExist: {
                    lastModified: selectedImageX.lastModified,
                    lastModifiedDate: selectedImageX.lastModifiedDate,
                    name: selectedImageX.name,
                    type: selectedImageX.type,
                    size: selectedImageX.size,
                    webkitRelativePath: selectedImageX.webkitRelativePath,
                    url: selectedImageX ? URL.createObjectURL(selectedImageX) : null,
                    base64: selectedImageRaportXBase64
                },
                selectedImageXIExist: {
                    lastModified: selectedImageXI.lastModified,
                    lastModifiedDate: selectedImageXI.lastModifiedDate,
                    name: selectedImageXI.name,
                    type: selectedImageXI.type,
                    size: selectedImageXI.size,
                    webkitRelativePath: selectedImageXI.webkitRelativePath,
                    url: selectedImageXI ? URL.createObjectURL(selectedImageXI) : null,
                    base64: selectedImageRaportXIBase64
                },
                selectedImageXIIExist: {
                    lastModified: selectedImageXII.lastModified,
                    lastModifiedDate: selectedImageXII.lastModifiedDate,
                    name: selectedImageXII.name,
                    type: selectedImageXII.type,
                    size: selectedImageXII.size,
                    webkitRelativePath: selectedImageXII.webkitRelativePath,
                    url: selectedImageXII ? URL.createObjectURL(selectedImageXII) : null,
                    base64: selectedImageRaportXIIBase64
                },
                selectedImageIjazahSMAExist: {
                    lastModified: selectedImageIjazahSMA.lastModified,
                    lastModifiedDate: selectedImageIjazahSMA.lastModifiedDate,
                    name: selectedImageIjazahSMA.name,
                    type: selectedImageIjazahSMA.type,
                    size: selectedImageIjazahSMA.size,
                    webkitRelativePath: selectedImageIjazahSMA.webkitRelativePath,
                    url: selectedImageIjazahSMA ? URL.createObjectURL(selectedImageIjazahSMA) : null,
                    base64: selectedImageIjazahSMABase64
                },
                selectedImageTranskripNilaiSMAExist: {
                    lastModified: selectedImageTranskripNilaiSMA.lastModified,
                    lastModifiedDate: selectedImageTranskripNilaiSMA.lastModifiedDate,
                    name: selectedImageTranskripNilaiSMA.name,
                    type: selectedImageTranskripNilaiSMA.type,
                    size: selectedImageTranskripNilaiSMA.size,
                    webkitRelativePath: selectedImageTranskripNilaiSMA.webkitRelativePath,
                    url: selectedImageTranskripNilaiSMA ? URL.createObjectURL(selectedImageTranskripNilaiSMA) : null,
                    base64: selectedImageTranskripNilaiSMABase64
                },
                selectedImageHouse1Exist: {
                    lastModified: selectedImageHouse1.lastModified,
                    lastModifiedDate: selectedImageHouse1.lastModifiedDate,
                    name: selectedImageHouse1.name,
                    type: selectedImageHouse1.type,
                    size: selectedImageHouse1.size,
                    webkitRelativePath: selectedImageHouse1.webkitRelativePath,
                    url: selectedImageHouse1 ? URL.createObjectURL(selectedImageHouse1) : null,
                    base64: selectedImageHouse1Base64
                },
                selectedImageHouse2Exist: {
                    lastModified: selectedImageHouse2.lastModified,
                    lastModifiedDate: selectedImageHouse2.lastModifiedDate,
                    name: selectedImageHouse2.name,
                    type: selectedImageHouse2.type,
                    size: selectedImageHouse2.size,
                    webkitRelativePath: selectedImageHouse2.webkitRelativePath,
                    url: selectedImageHouse2 ? URL.createObjectURL(selectedImageHouse2) : null,
                    base64: selectedImageHouse2Base64
                },
                selectedImageHouse3Exist: {
                    lastModified: selectedImageHouse3.lastModified,
                    lastModifiedDate: selectedImageHouse3.lastModifiedDate,
                    name: selectedImageHouse3.name,
                    type: selectedImageHouse3.type,
                    size: selectedImageHouse3.size,
                    webkitRelativePath: selectedImageHouse3.webkitRelativePath,
                    url: selectedImageHouse3 ? URL.createObjectURL(selectedImageHouse3) : null,
                    base64: selectedImageHouse3Base64
                },
            };

            // Ambil data yang sudah ada dari localStorage
            const existingData = JSON.parse(localStorage.getItem('data')) || {};

            // Tambahkan data baru ke dalam objek existingData
            existingData.dataStep2 = values;

            // Simpan kembali objek yang telah diperbarui ke dalam localStorage
            localStorage.setItem('data', JSON.stringify(existingData));


            setTimeout(() => {
                step3()
                setLoadingSimpanData(false)
            }, 2500);
        }
    }

    return (
        <Grid container sx={{ overflow: 'hidden' }}>

            <Grid item xs={12} md={12}>
                <Box
                    sx={{
                        marginBottom: '24px',
                        padding: '10px 16px',
                        backgroundColor: '#F8D70033'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '14px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '24px',
                            textAlign: 'justify'
                        }}
                    >
                        Kategori beasiswa keluarga miskin atau miskin ekstrim diperuntukkan hanya untuk jenjang pendidikan Strata 1 (S1)
                    </Typography>
                </Box>
                <Typography
                    sx={{
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: 'normal',
                        marginBottom: '12px',
                    }}
                >
                    Lengkapi Persyaratan
                </Typography>

            </Grid>

            {/* Image upload for surat keterangan Kepala Distrik/Kepala Kampung Miskin */}
            <Grid item xs={12} md={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: isMobile ? '12px' : '14px',
                        lineHeight: '18px',
                        textAlign: 'justify',
                        marginBottom: '10px',
                    }}
                >
                    Gambar scan/foto  surat keterangan Kepala Distrik/Kepala Kampung Miskin (Maks 1 MB).
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'surat keterangan')}
                        onDrop={(e) => handleDrop(e, 'surat keterangan')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingBKM && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageBKM && selectedImageBKM.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageBKM.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('surat keterangan')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlBKM ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlBKM}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('surat keterangan')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlBKM ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRef)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlBKM ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'surat keterangan')}
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>



            {/* Image upload for Kartu Indonesia Sehat (KIS) / Kartu Indonesia Pintar (KIP) */}
            <Typography
                sx={{
                    color: '#1E222F',
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: isMobile ? '12px' : '14px',
                    lineHeight: '18px',
                    textAlign: 'justify',
                    fontStyle: 'normal',
                    fontWeight: '400',
                    marginBottom: '10px',
                    marginTop: '24px'
                }}
            >
                Gambar scan/foto Kartu Indonesia Sehat (KIS) / Kartu Indonesia Pintar (KIP) (Maks 1 MB).
            </Typography>
            <Grid container>
                <Grid item xs={12} md={12}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '24px',
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '486px',
                                minWidth: '40%',
                                width: isMobile ? '100%' : '40%',
                                height: '190px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                            }}
                            onDragOver={(e) => handleDragOver(e, 'kartu kis')}
                            onDrop={(e) => handleDrop(e, 'kartu kis')}
                            onDragLeave={handleDragLeave}
                        >
                            {isDragging.isDraggingKIS && !isMobile ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                selectedImageBKMKIS && selectedImageBKMKIS.type === 'application/pdf' ? (
                                    // Handle PDF display logic
                                    <>
                                        {handleShowPDF(selectedImageBKMKIS.name)}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('kartu kis')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : imageUrlBKMKIS ? (
                                    // Handle image display logic
                                    <>
                                        <img
                                            src={imageUrlBKMKIS}
                                            alt="selected-image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('kartu kis')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : (
                                    // Display upload options
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {imageUrlBKMKIS ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefKIS)}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '17,64px',
                                                backgroundColor: bgColor
                                            }}
                                        >
                                            Pilih {imageUrlBKMKIS ? 'Gambar' : 'Gambar/PDF'}
                                        </div>
                                    </>
                                )
                            }
                            <input
                                type="file"
                                accept="image/*,.pdf"  // Accept both image and PDF files
                                onChange={(e) => handleFileInputChange(e.target.files[0], 'kartu kis')}
                                ref={fileInputRefKIS}
                                style={{ display: 'none' }}
                            />
                        </Box>
                        {!isMobile && (
                            <CustomTooltip
                                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                padding='8px'
                                customWidth='281px'
                            />
                        )}
                    </Box>
                </Grid>
            </Grid>

            {/* Gambar Raport Kelas X */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '24px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Raport Kelas X (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'raport x')}
                        onDrop={(e) => handleDrop(e, 'raport x')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingRaportX && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageX && selectedImageX.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageX.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport x')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlX ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlX}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport x')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlX ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefX)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlX ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )}
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'raport x')}
                            ref={fileInputRefX}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Gambar Raport Kelas XI */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '24px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Raport Kelas XI (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'raport xi')}
                        onDrop={(e) => handleDrop(e, 'raport xi')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingRaportXI && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageXI && selectedImageXI.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageXI.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xi')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlXI ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlXI}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xi')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlXI ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefXI)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlXI ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'raport xi')}
                            ref={fileInputRefXI}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Gambar Raport Kelas XII */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '24px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Raport Kelas XII (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'raport xii')}
                        onDrop={(e) => handleDrop(e, 'raport xii')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingRaportXII && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageXII && selectedImageXII.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageXII.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xii')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlXII ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlXII}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('raport xii')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlXII ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefXII)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlXII ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'raport xii')}
                            ref={fileInputRefXII}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Ijazah SMA */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: "15px"
                    }}
                >
                    Gambar Scan/Foto atau PDF Ijazah SMA (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'ijazah sma')}
                        onDrop={(e) => handleDrop(e, 'ijazah sma')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingIjazahSMA && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageIjazahSMA && selectedImageIjazahSMA.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageIjazahSMA.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah sma')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlIjazahSMA ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlIjazahSMA}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('ijazah sma')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlIjazahSMA ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefIjazahSMA)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlIjazahSMA ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )
                        }
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'ijazah sma')}
                            ref={fileInputRefIjazahSMA}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Berkas Transkrip Nilai */}
            <Grid item xs={12} sm={12}>
                <Typography
                    sx={{
                        color: '#1E222F',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '12px' : '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                        marginBottom: '10px',
                        marginTop: '24px'
                    }}
                >
                    Gambar Scan/Foto atau PDF Transkrip Nilai SMA (Maks 1 MB)
                </Typography>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '24px',
                    }}
                >
                    <Box
                        sx={{
                            maxWidth: '486px',
                            minWidth: '40%',
                            width: isMobile ? '100%' : '40%',
                            height: '190px',
                            backgroundColor: '#fff',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '12px',
                            position: 'relative',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                        }}
                        onDragOver={(e) => handleDragOver(e, 'transkrip nilai')}
                        onDrop={(e) => handleDrop(e, 'transkrip nilai')}
                        onDragLeave={handleDragLeave}
                    >
                        {isDragging.isDraggingTranskripNilaiSMA && !isMobile ?
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontStyle: 'normal',
                                    fontWeight: 'bold',
                                    lineHeight: '17,64px',
                                    textAlign: 'center',
                                    color: mainColor
                                }}
                            >
                                Lepaskan untuk mengunggah
                            </Typography>
                            :
                            selectedImageTranskripNilaiSMA && selectedImageTranskripNilaiSMA.type === 'application/pdf' ? (
                                // Handle PDF display logic
                                <>
                                    {handleShowPDF(selectedImageTranskripNilaiSMA.name)}
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('transkrip nilai')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : imageUrlTranskripNilaiSMA ? (
                                // Handle image display logic
                                <>
                                    <img
                                        src={imageUrlTranskripNilaiSMA}
                                        alt="selected-image"
                                        style={{
                                            width: '100%',
                                            height: '100%',
                                            objectFit: 'contain',
                                            borderRadius: '4px',
                                        }}
                                    />
                                    <Box
                                        sx={{
                                            position: 'absolute',
                                            top: '4px',
                                            right: '4px',
                                            backgroundColor: '#fff',
                                            borderRadius: '50%',
                                            padding: '2px',
                                            cursor: 'pointer',
                                        }}
                                        onClick={() => handleRemoveImage('transkrip nilai')}
                                    >
                                        <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                    </Box>
                                </>
                            ) : (
                                // Display upload options
                                <>
                                    {!isMobile ?
                                        <>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '13px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    textAlign: 'center'
                                                }}
                                            >
                                                {imageUrlTranskripNilaiSMA ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                            </Typography>
                                            <Typography
                                                sx={{
                                                    fontFamily: 'Jakarta Sans, sans-serif',
                                                    fontSize: '11px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '17,64px',
                                                    color: TextGray
                                                }}
                                            >
                                                atau
                                            </Typography>
                                        </>
                                        :
                                        undefined
                                    }
                                    <div
                                        onClick={() => handleImageUpload(fileInputRefTranskripNilaiSMA)}
                                        style={{
                                            cursor: 'pointer',
                                            padding: '6px 12px',
                                            border: '1px solid #ccc',
                                            borderRadius: '4px',
                                            display: 'inline-block',
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '12px',
                                            fontStyle: 'normal',
                                            fontWeight: '400',
                                            lineHeight: '17,64px',
                                            backgroundColor: bgColor
                                        }}
                                    >
                                        Pilih {imageUrlTranskripNilaiSMA ? 'Gambar' : 'Gambar/PDF'}
                                    </div>
                                </>
                            )}
                        <input
                            type="file"
                            accept="image/*,.pdf"  // Accept both image and PDF files
                            onChange={(e) => handleFileInputChange(e.target.files[0], 'transkrip nilai')}
                            ref={fileInputRefTranskripNilaiSMA}
                            style={{ display: 'none' }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                            padding='8px'
                            customWidth='281px'
                        />
                    )}
                </Box>
            </Grid>

            {/* Image upload for Gambar foto kondisi rumah tinggal */}
            <Grid container>
                {/* Grid items for each house image upload */}
                <Box
                    sx={{
                        marginTop: '24px',
                        width: '100%'
                    }}
                >
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '12px' : '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            marginBottom: '10px',

                        }}
                    >
                        Gambar foto kondisi rumah tinggal (Maks 1 MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            gap: '24px',
                            width: '100%'
                        }}
                    >
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '330px',
                                height: '180px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                            }}
                            onDragOver={(e) => handleDragOver(e, 'rumah 1')}
                            onDrop={(e) => handleDrop(e, 'rumah 1')}
                            onDragLeave={handleDragLeave}
                        >
                            {isDragging.isDraggingHouse1 && !isMobile ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                selectedImageHouse1 && selectedImageHouse1.type === 'application/pdf' ? (
                                    // Handle PDF display logic
                                    <>
                                        {handleShowPDF(selectedImageHouse1.name)}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('rumah 1')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : imageUrlHouse1 ? (
                                    // Handle image display logic
                                    <>
                                        <img
                                            src={imageUrlHouse1}
                                            alt="selected-image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('rumah 1')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : (
                                    // Display upload options
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {imageUrlHouse1 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefHouse1)}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '11px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '17,64px',
                                                backgroundColor: bgColor
                                            }}
                                        >
                                            Pilih {imageUrlHouse1 ? 'Gambar' : 'Gambar/PDF'}
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"  // Accept both image and PDF files
                                onChange={(e) => handleFileInputChange(e.target.files[0], 'rumah 1')}
                                ref={fileInputRefHouse1}
                                style={{ display: 'none' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '330px',
                                height: '180px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                            }}
                            onDragOver={(e) => handleDragOver(e, 'rumah 2')}
                            onDrop={(e) => handleDrop(e, 'rumah 2')}
                            onDragLeave={handleDragLeave}
                        >
                            {isDragging.isDraggingHouse2 && !isMobile ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                selectedImageHouse2 && selectedImageHouse2.type === 'application/pdf' ? (
                                    // Handle PDF display logic
                                    <>
                                        {handleShowPDF(selectedImageHouse2.name)}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('rumah 2')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : imageUrlHouse2 ? (
                                    // Handle image display logic
                                    <>
                                        <img
                                            src={imageUrlHouse2}
                                            alt="selected-image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('rumah 2')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : (
                                    // Display upload options
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {imageUrlHouse2 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefHouse2)}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '11px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '17,64px',
                                                backgroundColor: bgColor
                                            }}
                                        >
                                            Pilih {imageUrlHouse2 ? 'Gambar' : 'Gambar/PDF'}
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"  // Accept both image and PDF files
                                onChange={(e) => handleFileInputChange(e.target.files[0], 'rumah 2')}
                                ref={fileInputRefHouse2}
                                style={{ display: 'none' }}
                            />
                        </Box>
                        <Box
                            sx={{
                                width: isMobile ? '100%' : '330px',
                                height: '180px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                            }}
                            onDragOver={(e) => handleDragOver(e, 'rumah 3')}
                            onDrop={(e) => handleDrop(e, 'rumah 3')}
                            onDragLeave={handleDragLeave}
                        >
                            {isDragging.isDraggingHouse3 && !isMobile ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                selectedImageHouse3 && selectedImageHouse3.type === 'application/pdf' ? (
                                    // Handle PDF display logic
                                    <>
                                        {handleShowPDF(selectedImageHouse3.name)}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('rumah 3')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : imageUrlHouse3 ? (
                                    // Handle image display logic
                                    <>
                                        <img
                                            src={imageUrlHouse3}
                                            alt="selected-image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handleRemoveImage('rumah 3')}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : (
                                    // Display upload options
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {imageUrlHouse3 ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefHouse3)}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '11px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '17,64px',
                                                backgroundColor: bgColor
                                            }}
                                        >
                                            Pilih {imageUrlHouse3 ? 'Gambar' : 'Gambar/PDF'}
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"  // Accept both image and PDF files
                                onChange={(e) => handleFileInputChange(e.target.files[0], 'rumah 3')}
                                ref={fileInputRefHouse3}
                                style={{ display: 'none' }}
                            />
                        </Box>
                    </Box>
                </Box>
            </Grid>

            {/* Button for saving data */}
            <Box
                align='center'
                sx={{
                    width: '100%',
                    marginTop: '24px',
                    marginBottom: '26px',
                }}>
                <Button
                    // fullWidth
                    variant='contained'
                    size='medium'
                    onClick={() => handleSubmit()}
                    sx={{
                        minWidth: '350px',
                        fontSize: isMobile ? '12px' : undefined,
                        backgroundColor: btnBgColor,
                        color: mainColor,
                        padding: '10px 76px',
                        // fontWeight:'bold',
                        '&:hover': {
                            color: 'white', // Change text color to white on hover
                            backgroundColor: mainColor,
                            // fontWeight:'bold',
                        },
                    }}
                >
                    {loadingSimpanData ?
                        <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                        :
                        'Simpan Data & Lanjutkan'
                    }
                </Button>
            </Box>
        </Grid>
    )
}

export default BeasiswaKeluargaMiskin