import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import LightboxModal from '../../Modal/LightboxModal'
import { url } from '../../Config/Api'
import { Dark, Green, Maroon, TextGray, mainColor } from '../../Config/Color'
import { Icon } from '@iconify/react'
import { blue } from '@mui/material/colors'

const CategoryIkatanDinas = ({ dataIkatanDinasCompleteness, isMobile, isTablet, biodata }) => {

    const [imageRaporX, setImageRaporX] = useState('')
    const [imageRaporXI, setImageRaporXI] = useState('')
    const [imageRaporXII, setImageRaporXII] = useState('')
    const [ijazahSMA, setIjazahSMA] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')


    const handleShowImage = (value) => {
        // let value = null
        var imageUrl = `${url}${value}`
        // console.log('asdasdsad', imageUrl);

        const fileExtension = value ? value.split('.').pop().toLowerCase() : ""
        // console.log('tes', fileExtension);

        return (
            <>
                {value ?
                    fileExtension === 'pdf' ?
                        // Display PDF and download button
                        <Button
                            sx={{
                                padding: '0px',
                                margin: '0px',
                                // backgroundColor:'orange',
                                textTransform: 'none'
                            }}
                        >
                            <a
                                href={imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: blue,
                                    fontSize: '13px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textDecoration: 'underline',
                                    textAlign: 'left'
                                }}
                            >
                                Unduh PDF
                            </a>

                        </Button>
                        :
                        fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' ?
                            <>
                                <Button
                                    onClick={() => {
                                        setSelectedImage(imageUrl)
                                        setOpenLightbox(true)
                                    }}
                                    sx={{
                                        padding: '0px',
                                        margin: '0px',
                                        // backgroundColor:'orange',
                                        textTransform: 'none',
                                        color: mainColor,
                                        fontFamily: 'Plus Jakarta Sans',
                                        // color: blue,
                                        fontSize: '13px',
                                        fonStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        textDecoration: 'underline',
                                        textAlign: 'left'
                                    }}
                                >
                                    Lihat Gambar
                                </Button>
                            </>
                            :
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    wordWrap: 'break-word', // Menambahkan word-wrap untuk memecah kata
                                    whiteSpace: 'pre-line' // Mengatur white-space untuk mempertahankan spasi dan pemecahan baris
                                }}
                            >
                                {value}
                            </Typography>
                    :
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: value ? Green : Maroon,
                            fontSize: '13px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        {value ? 'Memenuhi syarat' : 'Belum memenuhi syarat'}
                    </Typography>
                }
            </>
        )
    }

    return (
        <Grid
            container
            spacing={2}
            sx={{
                padding: '16px',
            }}
        >
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        // gap:'1px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Jurusan Prioritas 1
                    </Typography>
                    {handleShowImage(biodata.selected_majors_1)}
                </Box>
            </Grid>
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        // gap:'1px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Jurusan Prioritas 2
                    </Typography>
                    {handleShowImage(biodata.selected_majors_2)}
                </Box>
            </Grid>
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        // gap:'1px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Jurusan Prioritas 3
                    </Typography>
                    {handleShowImage(biodata.selected_majors_3)}
                </Box>
            </Grid>

            {/* Ijazah SMA */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Ijazah SMA
                    </Typography>
                    {handleShowImage(biodata.scan_ijazah_sma)}
                </Box>
            </Grid>

            {/* Raport Class X */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Raport Class X
                    </Typography>
                    {handleShowImage(biodata.rapor_class_x)}
                </Box>
            </Grid>

            {/* Raport Class XI */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Raport Class XI
                    </Typography>
                    {handleShowImage(biodata.rapor_class_xi)}
                </Box>
            </Grid>

            {/* Raport Class XII */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Raport Class XI
                    </Typography>
                    {handleShowImage(biodata.rapor_class_xii)}
                </Box>
            </Grid>

            {/* Sertifikat 1 */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat 1
                    </Typography>
                    {handleShowImage(biodata.scan_sertifikat_1)}
                </Box>
            </Grid>

            {/* Sertifikat 2 */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat 2
                    </Typography>
                    {handleShowImage(biodata.scan_sertifikat_2)}
                </Box>
            </Grid>

            {/* Sertifikat 3 */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat 3
                    </Typography>
                    {handleShowImage(biodata.scan_sertifikat_3)}
                </Box>
            </Grid>

            {/* Toefl 3 */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Sertifikat Toefl
                    </Typography>
                    {handleShowImage(biodata.rapor_class_x)}
                </Box>
            </Grid>
            <LightboxModal
                open={openLightbox}
                close={() => {
                    setOpenLightbox(false)
                }}
                imageUrl={selectedImage}
            // option={option}
            />
        </Grid>
    )
}

export default CategoryIkatanDinas