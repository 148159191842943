import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { bgColor, btnBgColor, mainColor } from '../../Config/Color';
import Top from '../Top/Top';
import Footer from '../Footer/Footer';
import { Icon } from '@iconify/react';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';

const RegisterClose = () => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const [isLoading, setIsLoading] = useState(false)


    const handleClickMasukAkun = () => {
        setIsLoading(true)
        setTimeout(() => {
            setIsLoading(false)
            navigate('/login')
        }, 1500);
    }

    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    overflow: 'hidden',
                    // backgroundColor: 'orange'
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            // variant='outlined'
                            onClick={() => {
                                window.location.href = 'https://homedisdikpt.parkways.id/'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickMasukAkun}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: '150px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                    // disabled={isLoading ? true : false}
                    >
                        {isLoading ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Masuk Akun'
                        }
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop: isMobile ? '30px' : isTablet ? '30px' : '60px',
                        marginBottom:'50px'
                        // backgroundColor: ' blue'
                    }}
                >
                    <Box
                        sx={{
                            // backgroundColor: 'pink',
                            maxWidth: isMobile ? '100%' : '586px',
                            width: '100%'
                        }}
                    >
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '22px',
                                    fontWeight: '500',
                                    lineHeight: '27.72px',
                                    textAlign: 'center'
                                }}
                            >
                                Pendaftaran Tutup
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                marginTop: '20px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '28px',
                                    textAlign: 'center',

                                }}
                            >
                                Mohon maaf, pendaftaran bantuan pendidikan Dinas Pendidikan dan
                                Kebudayaan Prov. Papua Tengah telah ditutup. Silakan untuk masuk
                                akun apabila kamu telah mendaftar sebelumnya.
                            </Typography>
                        </Box>

                        <Box
                            sx={{
                                marginTop: '20px'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '16px',
                                    fontWeight: '400',
                                    lineHeight: '28px',
                                    textAlign: 'center',

                                }}
                            >
                                Untuk informasi terupdate silakan cek secara berkala di website
                                Dinas Pendidikan & Kebudayaan Prov. Papua Tengah.
                            </Typography>
                        </Box>
                    </Box>

                </Grid>
            </Grid>
            <Footer />
        </>
    )
}

export default RegisterClose