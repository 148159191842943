import React, { useEffect, useState } from 'react'
import { Blue, Dark, DividerColor, Maroon, TextGray, White, btnBgColor, mainColor } from '../../Config/Color'
import { Autocomplete, Box, Button, Card, Divider, FormControl, FormControlLabel, FormLabel, Grid, InputAdornment, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { getDataDashboardApplicant, listDataUniversity, updateBiodata, updateDataUniversitas } from '../../Config/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../ToastyAlert/ErrorAlert'
import InfoAlert from '../../ToastyAlert/InfoAlert';
import SuccessAlert from '../../ToastyAlert/SuccessAlert'

const DataUniversitas = ({ biodata, validasiData, getDataValidasi }) => {

  const navigate = useNavigate()

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [selectedUniverityNew, setSelectedUniverityNew] = useState('');
  const [selectedUniverity, setSelectedUniverity] = useState('');
  const [UniversityExist, setUniversityExist] = useState('');
  const [listUniversity, setListUniversity] = useState([])

  const [switchButton, setSwitchButton] = useState(false)
  // const [dataCompleteness, setDataCompleteness] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [newData, setNewData] = useState({
    namaPerguruanTinggi: '',
    fakultas: '',
    programStudi: '',
  })
  const [selectedJenjangPendidikan, setSelectedJenjangPendidikan] = useState('')

  const jenjangPendidikan = [
    {
      data: 'Strata 1(S1)',
      label: 'S1'
    },
    {
      data: 'Strata 2(S2)',
      label: 'S2'
    },
    {
      data: 'Strata 3(S3)',
      label: 'S3'
    },
  ]

  const getData = async () => {

    setNewData({
      fakultas: biodata.data?.faculty_name,
      programStudi: biodata.data?.study_program,
    });
    setUniversityExist(
      biodata.data?.name_of_college ?
        biodata.data?.name_of_college
        :
        ''
    )
    // console.log('tess',);
    setSelectedJenjangPendidikan(biodata.data?.education_level)

    await axios.get(listDataUniversity)
      .then((response) => {
        console.log('data universitas', response.data);
        const data = response.data?.data;
        // Filter data untuk menghilangkan yang kosong, karakter aneh, dan entri whitespace
        const universities = data.map(item => item.nama_pt)
          .filter(name => name && name.trim() && !name.includes('\\'));
        const uniqueUniversities = Array.from(new Set(universities)); // Menghapus duplikat universitas
        setListUniversity(uniqueUniversities);
      }).catch((error) => {
        console.log(error.response.data);
      });
  };

  useEffect(() => {
    getData();
  }, [biodata]);


  const handleChangeJenjangPendidikan = (event) => {
    setSelectedJenjangPendidikan(event.target.value)
    console.log(event.target.value);
  };

  const handleSwitchButton = () => {
    setIsLoading(true)
    setTimeout(() => {
      setSwitchButton(!switchButton)
      setIsLoading(false)
    }, 1500);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsSubmitting(true)

    await axios.post(`${updateDataUniversitas}`, {
      personal_information_id: biodata.data.personal_information_id,
      name_of_college: selectedUniverity,
      faculty_name: newData.fakultas,
      study_program: newData.programStudi,
      educational_level: selectedJenjangPendidikan,
      education_level: selectedJenjangPendidikan
    }).then((response) => {
      setIsSubmitting(false)
      console.log(response.data);
      InfoAlert({
        message: 'Berhasil Update Data',
        position: 'top-center'
      })
      getDataValidasi()
      setSwitchButton(false)
    }).catch((error) => {
      setIsSubmitting(false)
      console.log(error.response.data);
      if (error.response.data.messages) {
        ErrorAlert({
          message: error.response.data.messages.educational_level,
          position: 'top-center'
        })
      }
    })

  }


  return (
    <>
      {/* Data Universitas */}
      <Card
        elevation={3}
        sx={{
          maxWidth: isMobile || isTablet ? '100%' : '384px',
          maxHeight: isMobile || isTablet ? '100%' : '440px',
          width: '100%',
          height: '100%',
          border: `1px solid ${DividerColor}`
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '16px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'center',
              gap: '10px'
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: Dark,
                fontSize: '16px',
                fonStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
              }}
            >
              Data Universitas
            </Typography>
            <Box
              sx={{
                padding: '4px 8px',
                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                borderRadius: '4px',
                border: `1px solid ${DividerColor}`
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Plus Jakarta Sans',
                  color: Dark,
                  fontSize: '16px',
                  fonStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                }}
              >
                {validasiData ? `${Math.round(validasiData)}%` : '0%'}
              </Typography>
            </Box>
          </Box>
          <Button
            onClick={handleSwitchButton}
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              color: switchButton ? TextGray : mainColor,
              fontSize: '16px',
              fonStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
              textTransform: 'none',
              padding: '0px'
            }}
          >
            {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
          </Button>
        </Box>
        <Box
          sx={{
            width: '100%',
          }}
        >
          <Divider
            variant="string"
            style={{
              backgroundColor: DividerColor,
              borderBottomWidth: 0.5,
              // marginTop: "2px",
            }}
          />
        </Box>
        <form onSubmit={handleSubmit}>
          <Box
            sx={{
              padding: '16px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'flex-start',
              gap: '16px',
            }}
          >
            <Autocomplete
              fullWidth
              // disabled={verificationStatus ? false : true}
              freeSolo
              autoSelect // Aktifkan pemilihan otomatis saat mengetik
              selectOnFocus // Aktifkan pemilihan saat fokus dengan tombol panah bawah
              sx={{ marginBottom: '10px' }}
              id="university"
              options={listUniversity.sort((a, b) => a.localeCompare(b))} // Mengurutkan opsi universitas secara alfabetis
              getOptionLabel={(option) => option} // Anda hanya perlu menggunakan nilai district itu sendiri sebagai label
              value={selectedUniverity} // Mengikuti nilai yang dipilih
              onChange={(event, newValue) => {
                setSelectedUniverityNew(newValue); // Perbarui nilai selectedDistrict
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant='standard'
                  label="Pilih Universitas"
                  // placeholder='asdsad'
                  required
                  InputProps={{
                    ...params.InputProps,
                    startAdornment: listUniversity.length > 0 ? (
                      setSelectedUniverity(selectedUniverityNew ? selectedUniverityNew : UniversityExist)
                    )
                      :
                      (
                        <InputAdornment
                          sx={{
                            // marginLeft:'5px'
                          }}
                        >
                          <Typography
                            sx={{
                              // color: '#1E222F',
                              fontFamily: 'Jakarta Sans, sans-serif',
                              fontSize: '14px',
                              lineHeight: '20px',
                              fontStyle: 'normal',
                              fontWeight: '400',
                              marginRight: '6px'
                            }}
                          >
                            Loading
                          </Typography>
                          <Icon icon='svg-spinners:3-dots-fade' width='20px' height='20px' />
                        </InputAdornment>
                      ),
                    // type: 'search',
                    // sx: { margin: selectedUniverity ? '15px 0px 10px 0px' : '0px 0px 0px 0px' }
                  }}
                />
              )}
              disabled={listUniversity.length > 0 && switchButton ? false : true}
            />

            <TextField
              fullWidth
              variant="standard"
              type="text"
              label="Fakultas"
              placeholder='Masukkan fakultas'
              value={newData.fakultas}
              name="fakultas"
              onChange={(e) => handleChange(e)}
              autoComplete="off"
              required
              disabled={switchButton ? false : true}
              sx={{
                marginBottom: isMobile ? '13px' : '16px',
                fontSize: isMobile ? '11px' : '12px',
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
                "& .MuiFilledInput-root.Mui-disabled:hover": {
                  "& fieldset": {
                    borderBottomColor: mainColor,
                  },
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: mainColor,
                },
                "& .MuiFilledInput-underline:before": {
                  borderBottomColor: mainColor,
                },
                "& .MuiInputLabel-root": {
                  color: '#B3B3D4',
                  fontWeight: 'bold',
                  fontSize: '12px'
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: mainColor,
                },
                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                  color: mainColor,
                },
                "& .MuiFilledInput-underline:hover:before": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:hover:after": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:focus": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:focus:before": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:focus:after": {
                  borderBottomColor: mainColor,
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
              }}
            />
            <TextField
              fullWidth
              variant="standard"
              type="text"
              label="Program Studi"
              placeholder='Masukkan program studi'
              value={newData.programStudi}
              name="programStudi"
              onChange={(e) => handleChange(e)}
              autoComplete="off"
              required
              disabled={switchButton ? false : true}
              sx={{
                marginBottom: isMobile ? '13px' : '16px',
                fontSize: isMobile ? '11px' : '12px',
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: "black",
                },
                "& .MuiFilledInput-root.Mui-disabled:hover": {
                  "& fieldset": {
                    borderBottomColor: mainColor,
                  },
                },
                "& .MuiInputLabel-root.Mui-disabled": {
                  color: mainColor,
                },
                "& .MuiFilledInput-underline:before": {
                  borderBottomColor: mainColor,
                },
                "& .MuiInputLabel-root": {
                  color: '#B3B3D4',
                  fontWeight: 'bold',
                  fontSize: '12px'
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: mainColor,
                },
                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                  color: mainColor,
                },
                "& .MuiFilledInput-underline:hover:before": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:hover:after": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:focus": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:focus:before": {
                  borderBottomColor: mainColor,
                },
                "& .MuiFilledInput-underline:focus:after": {
                  borderBottomColor: mainColor,
                },
                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                {
                  "-webkit-appearance": "none",
                  margin: 0,
                },
                "& input[type=number]": {
                  "-moz-appearance": "textfield",
                },
              }}
            />
            <FormControl fullWidth required disabled={switchButton ? false : true}>
              <FormLabel sx={{ fontSize: '12px', color: '#404040' }}>Jenjang Pendidikan</FormLabel>
              <RadioGroup
                row
                aria-labelledby="radio-label"
                value={selectedJenjangPendidikan}
                name="radio-buttons-group"
                onChange={handleChangeJenjangPendidikan}
                sx={{
                  fontSize: '12px'
                }}
              >
                {jenjangPendidikan.map((data, index) => {
                  return (
                    <FormControlLabel
                      key={index}
                      value={data.data}
                      control={<Radio size="small" />}
                      label={<span style={{ fontSize: '14px' }}>{data.label}</span>}
                    />
                  )
                })}
              </RadioGroup>
            </FormControl>
            <Button
              fullWidth
              type='submit'
              variant='contained'
              size='medium'
              sx={{
                backgroundColor: btnBgColor,
                textTransform: 'none',
                color: mainColor,
                '&:hover': {
                  color: mainColor, // Change text color to white on hover
                  backgroundColor: btnBgColor
                },
              }}
              disabled={isSubmitting || !switchButton ? true : false}
            >
              {isSubmitting ?
                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                :
                'Simpan Data'
              }
            </Button>
          </Box>
        </form>
      </Card>
    </>
  )
}

export default DataUniversitas