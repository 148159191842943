import React, { useEffect, useRef, useState } from 'react';
import { Dark, DividerColor, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { Grid, useMediaQuery, Typography, Box, Button, Card, TextField, Tooltip, IconButton, ClickAwayListener, InputAdornment, Popper, Paper, Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import fullNameTooltip from '../../assets/tooltip/fullname.png'
import NIK from '../../assets/tooltip/NIK.png'
import KartuKeluarga from '../../assets/tooltip/kartukeluarga.png'
import NamaAyah from '../../assets/tooltip/namaayah.png'
import NamaIbu from '../../assets/tooltip/namaibu.png'
import Email from '../../assets/tooltip/email.png'
import Whatsapp from '../../assets/tooltip/whatsapp.png'
import Password from '../../assets/tooltip/katasandi.png'
import Footer from '../../components/Register/Footer/Footer';
import LogoEmail from '../../assets/Logo/Logo_Email.png'
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert'
import axios from 'axios';
import { RegistrasiTahapI, cekVerifikasiEmail, dataKodeWilayah91, listDataKabupaten, resendEmailApi, sendEmailVerifikasi, updateDataRegistrasiTahapI } from '../../components/Config/Api';
import HandleSessionTahap1 from '../../components/Session/HandleSessionTahap1';
import TooltipUploadGambar from '../../assets/tooltip/upload_gambar_KTP&KK.png'
import RectangleIcon from '../../assets/tooltip/Rectangle 24.png'
import Countdown from '../../components/Session/Countdown';
import { useFormContext } from './FormContext';
import EmailActivationModal from '../../components/Modal/EmailActivationModal/EmailActivationModal';
import CustomTooltip from '../../components/Modal/Tooltip/CustomTooltip';
import { encrypt, decrypt } from 'n-krypta';
import { secretKey } from '../../components/Config/SecretKey';
import LoadingData from '../../components/LoadingPage/LoadingData';
import { wilayah91 } from '../../components/Config/Wilayah91';
// import 'fontsource-plus-jakarta-sans';

const Form = ({
    isMobile,
    isTablet,
    nextStep,
    step3
}) => {
    // const secretKey = '@BCTdev2024WebPapua'

    const [isPageLoading, setIsPageLoading] = useState(false);
    const [openPopperNIK, setOpenPopperNIK] = useState(false);
    const [openPopperKK, setOpenPopperKK] = useState(false);
    const [openPopperDaddy, setOpenPopperDaddy] = useState(false);
    const [openPopperMommy, setOpenPopperMommy] = useState(false);
    const [openPopperEmail, setOpenPopperEmail] = useState(false);
    const [openPopperWhatsapp, setOpenPopperWhatsapp] = useState(false);
    const [openPopperPassword, setOpenPopperPassword] = useState(false);
    const [emailSendNotification, setEmailSendNotification] = useState('N')
    const [loadingSimpanData, setLoadingSimpanData] = useState(false)
    const [loadingBackTahap1, setLoadingBackTahap1] = useState(false)
    const [openEmailActivationModal, setOpenEmailActivationModal] = useState(false)
    const [resendEmail, setResendEmail] = useState(false)
    const [loadingKonfirmasiEmailActivation, setLoadingKonfirmasiEmailActivation] = useState(false)

    const [selectedImageNIK, setSelectedImageNIK] = useState(null);
    const [imageUrlNIK, setImageUrlNIK] = useState(null);

    const [selectedImageKK, setSelectedImageKK] = useState(null);
    const [imageUrlKK, setImageUrlKK] = useState(null);

    const fileInputRefNIK = useRef(null);
    const fileInputRefKK = useRef(null);
    const anchorRefNIK = useRef(null);
    const anchorRefKK = useRef(null);
    const anchorRefDaddy = useRef(null);
    const anchorRefMommy = useRef(null);
    const anchorRefEmail = useRef(null);
    const anchorRefWhatsapp = useRef(null);
    const anchorRefKatasandi = useRef(null);

    const data = localStorage.getItem('data')

    const MAX_IMAGE_SIZE_MB = 1;

    const [newData, setNewData] = useState({
        fullName: '',
        nik: '',
        kk: '',
        daddyFullName: '',
        mommyFullName: '',
        email: '',
        whatsapp: '',
        password: '',
        konfirmasiPassword: '',
    })

    const [emailError, setEmailError] = useState('');
    const [KTPError, setKTPError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [konfirmasiPassword, setKonfirmasiPassword] = useState('');
    const [autoFocus, setAutoFocus] = useState({
        ktp: false,
        email: false,
        fotoKtp: false,
        fotokk: false,
        email: false,
        password: false,
        konfirmasiPassword: false
    });

    const [countdownTimer, setCountdownTimer] = useState(0)
    const [latitude, setLatitude] = useState(null);
    const [longitude, setLongitude] = useState(null);
    const [error, setError] = useState(null);
    const [statusEmail, setStatusEmail] = useState('');
    const timerId = useRef()

    const [errorLocation, setErrorLocation] = useState(null);
    const [dataWilayahKabupaten91, setDataWilayahKabupaten91] = useState([]);
    const [dataWilayah91, setDataWilayah91] = useState([]);
    const [dataWilayahKabupaten94, setDataWilayahKabupaten94] = useState([]);
    const [dataWilayah94, setDataWilayah94] = useState([]);

    const [errorMessage, setErrorMessage] = useState('');

    const [isDragging, setIsDragging] = useState({
        isDraggingNIK: false,
        isDraggingKK: false,
    });

    useEffect(() => {
        const dataRegistered = localStorage.getItem('data')
        if (dataRegistered) {
            const { cekData, dataStep1, dataStep2, dataStep3, bypass } = JSON.parse(dataRegistered)
            setIsPageLoading(true)

            if (cekData === 'Tidak' && dataStep1) {
                setTimeout(() => {
                    nextStep()
                    setIsPageLoading(false)
                }, 2500);
            } else if (cekData === 'Tidak' && dataStep2) {
                setTimeout(() => {
                    step3()
                    setIsPageLoading(false)
                }, 2500);
            } else if (cekData === 'Ya' && bypass === 'tahap1') {
                setTimeout(() => {
                    setIsPageLoading(false)
                }, 2500);
            } else if (cekData === 'Ya' && bypass === 'tahap2') {
                if (dataStep1 && dataStep3) {
                    setTimeout(() => {
                        nextStep()
                        setIsPageLoading(false)
                    }, 2500);
                }
            }

            // if (cekData === 'Ya') {
            //     if (bypass === 'tahap1') {
            //         setTimeout(() => {
            //             setIsPageLoading(false)
            //         }, 2500);
            //     } else if (bypass === 'tahap2' && dataStep1) {
            //         setTimeout(() => {
            //             nextStep()
            //             setIsPageLoading(false)
            //         }, 2500);
            //     } else if (dataStep1 && dataStep2 && dataStep3) {
            //         setTimeout(() => {
            //             step3()
            //             setIsPageLoading(false)
            //         }, 2500);
            //     }
            // } else if (cekData === 'Tidak' && (dataStep2 || dataStep3)) {
            //     if (dataStep2) {
            //         setTimeout(() => {
            //             step3()
            //             setIsPageLoading(false)
            //         }, 2500);
            //     } else {
            //         setTimeout(() => {
            //             nextStep()
            //             setIsPageLoading(false)
            //         }, 2500);
            //     }

            // } else if (cekData === 'Tidak' && dataStep1) {
            //     setTimeout(() => {
            //         nextStep()
            //         setIsPageLoading(false)
            //     }, 2500);
            // }
        }

    }, [])

    useEffect(() => {
        timerId.current = setInterval(() => {
            setCountdownTimer(prev => prev - 1)
        }, 1000)
        return () => clearInterval(timerId.current)
    }, [countdownTimer])

    useEffect(() => {
        if (countdownTimer <= 0) {
            clearInterval(timerId.current)
        }
    }, [countdownTimer])

    useEffect(() => {
        // console.log('asdsad', countdownTimer);
        if (countdownTimer <= 0) {
            setOpenEmailActivationModal(false)
        }
    }, [countdownTimer])


    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleImageUploadNIK = () => {
        fileInputRefNIK.current.click();
    };

    const handleImageUploadKK = () => {
        fileInputRefKK.current.click();
    };

    const handleFileInputChangeNIK = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto/Pdf 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageNIK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlNIK(imageUrl);

                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageNIK(file)
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlNIK(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleFileInputChangeKK = (file) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    setSelectedImageKK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlKK(imageUrl);
                } else if (allowedPdfTypes.includes(fileType)) {
                    setSelectedImageKK(file);
                    const imageUrl = URL.createObjectURL(file);
                    setImageUrlKK(imageUrl);
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleRemoveImageNIK = () => {
        setSelectedImageNIK(null);
        setImageUrlNIK(null);
    };

    const handleRemoveImageKK = () => {
        setSelectedImageKK(null);
        setImageUrlKK(null);
    };

    // Helper function to convert base64 to File object
    const base64ToFile = (base64String, fileName) => {
        const [header, data] = base64String.split(',');
        const mime = header.match(/:(.*?);/)[1];
        const binary = atob(data);
        const array = [];
        for (let i = 0; i < binary.length; i++) {
            array.push(binary.charCodeAt(i));
        }
        const uint8Array = new Uint8Array(array);
        return new File([uint8Array], fileName, { type: mime });
    };

    const getAlreadyExistData = (token) => {
        setNewData({
            fullName: token.userFullNameExist ? token.userFullNameExist : null,
            nik: token.userNIKExist ? token.userNIKExist : null,
            kk: token.userNoKKExist ? token.userNoKKExist : null,
            daddyFullName: token.userFatherNameExist ? token.userFatherNameExist : null,
            mommyFullName: token.userMotherNameExist ? token.userMotherNameExist : null,
            email: token.userEmailExist ? token.userEmailExist : null,
            whatsapp: token.userWhatsappNumberExist ? token.userWhatsappNumberExist : null,
            password: token.userPasswordExist ? token.userPasswordExist : null,
            konfirmasiPassword: token.userConfirmPasswordExist ? token.userConfirmPasswordExist : null,
        })

        if (token.selectedImageNIKExist?.base64) {
            // Convert base64 to File and set state
            const fileNIK = base64ToFile(token.selectedImageNIKExist.base64, token.selectedImageNIKExist.name); // Adjust file name and type as needed
            setImageUrlNIK(token.selectedImageNIKExist.base64 ? token.selectedImageNIKExist.base64 : null);
            setSelectedImageNIK(fileNIK);
        } else {
            setSelectedImageNIK(null);
        }

        if (token.selectedImageKKExist?.base64) {
            // Convert base64 to File and set state
            const fileKK = base64ToFile(token.selectedImageKKExist.base64, token.selectedImageKKExist.name); // Adjust file name and type as needed
            setSelectedImageKK(fileKK);
            setImageUrlKK(token.selectedImageKKExist.base64 ? token.selectedImageKKExist.base64 : null);
        } else {
            setSelectedImageKK(null);
        }



    }

    useEffect(() => {
        if (data) {
            const { dataStep1, cekData } = JSON.parse(data)
            // console.log('key', secretKey);
            if (dataStep1) {
                setTimeout(() => {
                    setStatusEmail(cekData)
                    if (cekData === 'Ya' && dataStep1) {
                        getAlreadyExistData(dataStep1)
                    }
                }, 1500);
            }
        }
    }, [data]);


    const getDataWilayah = async () => {
        let uniqueCodes91 = new Set();
        let uniqueCodes94 = new Set();

        await axios.get(`${listDataKabupaten}/${91}`)
            .then((response) => {
                // console.log('wilayah91', response.data);

                const res = response.data?.data
                // console.log(res);
                res.forEach((data) => {
                    uniqueCodes91.add(data.code.substring(3, 5));
                })

            }).catch((error) => {
                console.log(error.response.data);
            });

        await axios.get(`${listDataKabupaten}/${94}`)
            .then((response) => {
                // console.log('wilayah94', response.data);
                const res = response.data?.data
                res.forEach((data) => {
                    uniqueCodes94.add(data.code.substring(3, 5));
                });
                // console.log('res 94', res);
            }).catch((error) => {
                console.log(error.response.data);
            });

        // Konversi Set kembali ke array
        const uniqueArray91 = Array.from(uniqueCodes91);
        const uniqueArray94 = Array.from(uniqueCodes94);
        // console.log('uniqueArray91', uniqueArray91);
        // console.log('uniqueArray94', uniqueArray94);
        setDataWilayahKabupaten91(uniqueArray91)
        setDataWilayahKabupaten94(uniqueArray94)
    };

    useEffect(() => {
        getDataWilayah()
    }, []);


    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        // Validate NIK format
        if (name === 'nik') {
            const kodeProvinsi = value.substring(0, 2);
            const kodeKabupaten = value.substring(2, 4);
            if (value !== '' && (kodeProvinsi !== '94' && kodeProvinsi !== '91')) {
                setKTPError('KTP bukan dari provinsi Papua Tengah.');
            } else if (value !== '' && (kodeProvinsi === '91' && [kodeKabupaten].some(kode => !dataWilayahKabupaten91.includes(kode)))) {
                setKTPError('KTP tidak berasal dari kabupaten yang ada di Papua Tengah.');
            } else if (value !== '' && (kodeProvinsi === '94' && [kodeKabupaten].some(kode => !dataWilayahKabupaten94.includes(kode)))) {
                setKTPError('KTP tidak berasal dari kabupaten yang ada di Papua Tengah.');
            } else {
                setKTPError('');
            }
        } else if (name === 'email') {
            // Validate email format
            if (value !== '' && !validateEmail(value)) {
                setEmailError('Format Email Salah');
            } else {
                setEmailError('');
            }
        } else if (name === 'password') {
            // Validasi password
            if (value !== '') {
                // Cek apakah password memiliki huruf besar, huruf kecil, dan simbol
                const hasUpperCase = /[A-Z]/.test(value);
                const hasLowerCase = /[a-z]/.test(value);
                const hasSymbol = /\W/.test(value);
                const hasNumber = /\d/.test(value);

                // Jika password tidak memenuhi semua syarat, set error
                if (!hasUpperCase) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu huruf besar');
                } else if (!hasLowerCase) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu huruf kecil');
                } else if (!hasNumber) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu angka');
                } else if (!hasSymbol) {
                    setPasswordError('Kata sandi harus mengandung setidaknya satu simbol');
                } else {
                    // Jika password memenuhi semua syarat, hapus error
                    setPasswordError('');
                }
            } else {
                // Jika password kosong, hapus error
                setPasswordError('');
            }
        } else if (name === 'konfirmasiPassword') {
            if (value !== '' && newData.password !== value) {
                setKonfirmasiPassword('Konfirmasi password salah.');
            } else {
                setKonfirmasiPassword('');
            }
        }
    };

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoadingSimpanData(true)
        // alert('clicked')
        const kodeProvinsi = newData.nik.substring(0, 2);
        const kodeKabupaten = newData.nik.substring(2, 4);

        if (
            validateEmail(newData.email) &&
            (newData.konfirmasiPassword === newData.password) &&
            /[A-Z]/.test(newData.password) &&
            /[a-z]/.test(newData.password) &&
            /\d/.test(newData.password) &&
            /\W/.test(newData.password) &&
            selectedImageKK &&
            selectedImageNIK &&
            (kodeProvinsi === '91' && [kodeKabupaten].some(kode => dataWilayahKabupaten91.includes(kode))) ||
            (kodeProvinsi === '94' && [kodeKabupaten].some(kode => dataWilayahKabupaten94.includes(kode)))
        ) {

            if (data) {
                const { cekData, dataStep1, emailVerifData } = JSON.parse(data)

                if (cekData === 'Ya') {
                    const formData = new FormData();
                    formData.append('_method', 'PUT')
                    formData.append('user_id', emailVerifData.userId)
                    formData.append('full_name', newData.fullName)
                    formData.append('whatsapp_number', newData.whatsapp)
                    formData.append('personal_email', newData.email)
                    formData.append('nik_ktp', newData.nik)
                    formData.append('ktp_scan', selectedImageNIK)
                    formData.append('family_card_number', newData.kk)
                    formData.append('family_card_scan', selectedImageKK)
                    formData.append('mother_name', newData.mommyFullName)
                    formData.append('father_name', newData.daddyFullName)
                    formData.append('password', newData.password)
                    formData.append('password2', newData.konfirmasiPassword)

                    // Mencetak nilai FormData menggunakan console.log
                    // for (let pair of formData.entries()) {
                    //     console.log(pair[0] + ': ' + pair[1]);
                    // }
                    try {
                        const response = await axios.post(`${updateDataRegistrasiTahapI}/${dataStep1.userPersonalInformationId}`, formData, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        });

                        if (response.status >= 200 && response.status < 300) {
                            console.log('updpersonalinformation', response.data);
                            const nikBase64 = selectedImageNIK ? await fileToBase64(selectedImageNIK) : null;
                            const kkBase64 = selectedImageKK ? await fileToBase64(selectedImageKK) : null;
                            let values = {
                                userEmailExist: newData.email,
                                userNIKExist: newData.nik,
                                userFullNameExist: newData.fullName,
                                userNoKKExist: newData.kk,
                                userFatherNameExist: newData.daddyFullName,
                                userMotherNameExist: newData.mommyFullName,
                                userWhatsappNumberExist: newData.whatsapp,
                                userPasswordExist: newData.password,
                                userConfirmPasswordExist: newData.konfirmasiPassword,
                                userPersonalInformationId: response.data.data.personal_information_id,
                                selectedImageNIKExist: {
                                    lastModified: selectedImageNIK.lastModified,
                                    lastModifiedDate: selectedImageNIK.lastModifiedDate,
                                    name: selectedImageNIK.name,
                                    type: selectedImageNIK.type,
                                    size: selectedImageNIK.size,
                                    webkitRelativePath: selectedImageNIK.webkitRelativePath,
                                    url: selectedImageNIK ? URL.createObjectURL(selectedImageNIK) : null,
                                    base64: nikBase64,
                                },
                                selectedImageKKExist: {
                                    lastModified: selectedImageKK.lastModified,
                                    lastModifiedDate: selectedImageKK.lastModifiedDate,
                                    name: selectedImageKK.name,
                                    type: selectedImageKK.type,
                                    size: selectedImageKK.size,
                                    webkitRelativePath: selectedImageKK.webkitRelativePath,
                                    url: selectedImageKK ? URL.createObjectURL(selectedImageKK) : null,
                                    base64: kkBase64,
                                },
                            }

                            // Ambil data yang sudah ada dari localStorage
                            const existingData = JSON.parse(localStorage.getItem('data')) || {};

                            // Tambahkan data baru ke dalam objek existingData
                            // existingData.cekData = 'Tidak';
                            // existingData.bypass = '';
                            existingData.dataStep1 = values;

                            // Simpan kembali objek yang telah diperbarui ke dalam localStorage
                            localStorage.setItem('data', JSON.stringify(existingData));

                            SuccessAlert({
                                message: 'Berhasil merubah data.',
                                position: 'top-center'
                            })

                            setTimeout(() => {
                                setLoadingSimpanData(false);
                                step3()
                            }, 3000);

                        } else {
                            // Handle non-successful response status
                            console.error(`Unexpected response status: ${response.status}`);
                            setLoadingSimpanData(false);
                        }
                    } catch (error) {
                        console.error(error.response.data.messages);
                        setLoadingSimpanData(false);
                        // close()
                    }
                } else {
                    await axios.post(sendEmailVerifikasi, {
                        name: newData.fullName,
                        email: newData.email
                    }).then((response) => {
                        console.log('sendEmailVerifikasi', response.data);

                        let values = {
                            userId: response.data.data.verification_email_id,
                            verification_link: response.data.data.verification_link,
                            verification_status: response.data.data.verification_status,
                            type_otp: response.data.data.type_otp,
                        }

                        localStorage.setItem('data', JSON.stringify({
                            emailVerifData: values
                        }));

                        setLoadingSimpanData(false)
                        setOpenEmailActivationModal(true)
                    }).catch((error) => {
                        setLoadingSimpanData(false);
                        console.log(error.response.data);
                        // ErrorAlert({
                        //     message: error?.response?.data?.messages?.email === 'The email has already been taken.' ? 'Email sudah terdaftar' : error.response.data?.messages?.email,
                        //     position: 'top-center'
                        // })
                        if (error?.response?.data?.messages?.email === 'The email has already been taken.') {
                            setOpenEmailActivationModal(true)
                            setErrorMessage(error?.response?.data?.messages?.email)
                        }
                    })
                }
            } else {
                await axios.post(sendEmailVerifikasi, {
                    name: newData.fullName,
                    email: newData.email
                }).then((response) => {
                    console.log('sendEmailVerifikasi', response.data);

                    let values = {
                        userId: response.data.data.verification_email_id,
                        verification_link: response.data.data.verification_link,
                        verification_status: response.data.data.verification_status,
                        type_otp: response.data.data.type_otp,
                    }

                    localStorage.setItem('data', JSON.stringify({
                        emailVerifData: values
                    }));

                    setLoadingSimpanData(false)
                    setOpenEmailActivationModal(true)
                }).catch((error) => {
                    setLoadingSimpanData(false);
                    console.log(error.response.data);
                    // ErrorAlert({
                    //     message: error?.response?.data?.messages?.email === 'The email has already been taken.' ? 'Email sudah terdaftar' : error.response.data?.messages?.email,
                    //     position: 'top-center'
                    // })
                    if (error?.response?.data?.messages?.email === 'The email has already been taken.') {
                        setOpenEmailActivationModal(true)
                        setErrorMessage(error?.response?.data?.messages?.email)
                    }
                })
            }

        } else if (!validateEmail(newData.email)) {
            ErrorAlert({
                message: 'Format email salah.',
                position: 'top-center',
            });
            setAutoFocus({
                email: true
            })
            setLoadingSimpanData(false);
        } else if (newData.nik !== '' && (kodeProvinsi !== '94' && kodeProvinsi !== '91')) {
            ErrorAlert({
                message: 'KTP bukan dari provinsi Papua Tengah.',
                position: 'top-center'
            })
            setLoadingSimpanData(false);
        } else if (
            newData.nik !== '' &&
            (
                kodeProvinsi === '94' && [kodeKabupaten].some(kode => !dataWilayahKabupaten94.includes(kode)) ||
                kodeProvinsi === '91' && [kodeKabupaten].some(kode => !dataWilayahKabupaten91.includes(kode))
            )
        ) {
            ErrorAlert({
                message: 'KTP bukan dari kabupaten yang ada di Papua Tengah.',
                position: 'top-center'
            })
            setLoadingSimpanData(false);
        } else if (!/[A-Z]/.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu huruf besar.',
                position: 'top-center',
            });
            setAutoFocus({
                password: true
            })
            setLoadingSimpanData(false);
        } else if (!/[a-z]/.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu huruf kecil.',
                position: 'top-center',
            });
            setAutoFocus({
                password: true
            })
            setLoadingSimpanData(false);
        } else if (!/\d/.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu angka.',
                position: 'top-center',
            });
            setLoadingSimpanData(false);
        } else if (!/\W/.test(newData.password)) {
            ErrorAlert({
                message: 'Kata sandi harus mengandung setidaknya satu simbol.',
                position: 'top-center',
            });
            setAutoFocus({
                password: true
            })
            setLoadingSimpanData(false);
        } else if (newData.konfirmasiPassword !== newData.password) {
            ErrorAlert({
                message: 'Konfirmasi password tidak sesuai',
                position: 'top-center',
            });
            setAutoFocus({
                password: true
            })
            setLoadingSimpanData(false);
        } else if (selectedImageNIK === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto/Scan Gambar KTP.',
                position: 'top-center',
            });
            setAutoFocus({
                fotoKtp: true
            })
            setLoadingSimpanData(false);
        } else if (selectedImageKK === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto/Scan Gambar KK.',
                position: 'top-center',
            });
            setAutoFocus({
                fotokk: true
            })
            setLoadingSimpanData(false);
        }
    };

    const handleShowPDF = (fileName) => {

        return (
            <>
                <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: mainColor,
                        fontSize: '11px',
                        fonStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        cursor: 'pointer',
                        textAlign: 'center',
                        marginTop: '-10px'
                    }}
                >
                    {fileName}
                </Typography>
            </>
        )
    }

    const handleDragOverKK = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingKK: true,
        })
    };

    const handleDropKK = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeKK(file);
        setIsDragging({
            isDraggingKK: false
        })
    };

    const handleDragOverNIK = (event) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingNIK: true
        })
    };

    const handleDropNIK = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChangeNIK(file);
        setIsDragging({
            isDraggingNIK: false
        })
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingNIK: false,
            isDraggingKK: false
        }) // Set state menjadi false saat dragleave
    };

    return (
        // emailSendNotification === 'N' ?
        <form onSubmit={handleSubmit}>
            <Grid container >
                <Grid item xs={12} md={12}>
                    {/* {errorLocation ? (
                        <p>Error: {errorLocation}</p>
                    ) : (
                        <p>
                            Latitude: {latitude}, Longitude: {longitude}
                        </p>
                    )} */}
                    <Typography
                        sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: isMobile ? '15px' : '16px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            marginBottom: isMobile ? '12px' : '24px',
                            textAlign: 'justify',
                            letterSpacingL: '1.5px',
                            lineHeight: '20px'
                        }}
                    >
                        Lengkapi form berikut untuk melakukan pendaftaran akun.
                    </Typography>
                </Grid>

                {/* Nama Lengkap */}
                <Grid item xs={12} md={12} >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40px'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Masukkan Nama Lengkap(Sesuai KTP)"
                            placeholder='Masukkan nama lengkap'
                            value={newData.fullName}
                            name="fullName"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            sx={{
                                marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px',
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                </Grid>


                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nomor Induk Kependudukan(NIK)"
                            placeholder='Masukkan NIK'
                            value={newData.nik}
                            name="nik"
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            error={newData.nik === '' ? undefined : !!KTPError}
                            helperText={KTPError}
                            required
                            autoFocus={autoFocus.ktp}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperNIK(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefNIK}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperNIK}
                                            anchorEl={anchorRefNIK.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999, }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px'
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperNIK(false)}>
                                                            <Tooltip title="Hanya menerima KTP dengan NIK yang terdaftar sebagai penduduk Provinsi Papua Tengah." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        Hanya menerima KTP dengan NIK yang terdaftar sebagai penduduk Provinsi Papua Tengah.
                                                                    </Typography>
                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                // marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Hanya menerima KTP dengan NIK terdaftar sebagai penduduk Provinsi Papua Tengah.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>



                {/* Upload Gambar KTP */}
                <Grid item xs={12} sm={12} mb='24px'>
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            marginBottom: '10px',
                            marginTop: '34px'
                        }}
                    >
                        Gambar Scan/Foto atau PDF NIK (Maks 1 MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '24px',
                            width: '100%',
                            // backgroundColor: 'orange'
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '486px',
                                minWidth: '40%',
                                width: isMobile ? '100%' : '40%',
                                height: '190px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                            }}
                            onDragOver={handleDragOverNIK}
                            onDrop={handleDropNIK}
                            onDragLeave={handleDragLeave}
                        >
                            {isDragging.isDraggingNIK && !isMobile ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                selectedImageNIK && selectedImageNIK.type === 'application/pdf' ? (
                                    // Handle PDF display logic
                                    <>
                                        {handleShowPDF(selectedImageNIK.name)}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleRemoveImageNIK}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : imageUrlNIK ? (
                                    // Handle image display logic
                                    <>
                                        <img
                                            src={imageUrlNIK}
                                            alt="selected-image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleRemoveImageNIK}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : (
                                    // Display upload options
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {selectedImageNIK ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={handleImageUploadNIK}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '17,64px',
                                                backgroundColor: bgColor
                                            }}
                                        >
                                            Pilih {selectedImageNIK ? 'Gambar' : 'Gambar/PDF'}
                                        </div>
                                    </>
                                )
                            }
                            <input
                                type="file"
                                accept="image/*,.pdf"  // Accept both image and PDF files
                                onChange={(event) => handleFileInputChangeNIK(event.target.files[0])}
                                ref={fileInputRefNIK}
                                style={{ display: 'none' }}
                            />
                        </Box>
                        {!isMobile && (
                            <CustomTooltip
                                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                padding='8px'
                            />
                        )}
                    </Box>
                </Grid>



                {/* Textfield KK */}
                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nomor Kartu Keluarga(KK)"
                            placeholder='Masukkan NIK'
                            value={newData.kk}
                            name="kk"
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperKK(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefKK}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperKK}
                                            anchorEl={anchorRefKK.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999 }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px',
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperKK(false)}>
                                                            <Tooltip title="Hanya menerima KK yang terdaftar sebagai penduduk Provinsi Papua Tengah." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        Hanya menerima KK yang terdaftar sebagai penduduk Provinsi Papua Tengah.
                                                                    </Typography>

                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>

                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                // marginBottom: isMobile ? '15px' : '14px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Hanya menerima KK yang terdaftar sebagai penduduk Provinsi Papua Tengah.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>

                {/* Upload Gambar KK */}
                <Grid item xs={12} sm={12} >
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            marginBottom: '10px',
                            marginTop: '34px'
                        }}
                    >
                        Gambar Scan/Foto atau PDF KK (Maks 1 MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '24px',
                            width: '100%',
                            // backgroundColor: 'orange'
                        }}
                    >
                        <Box
                            sx={{
                                maxWidth: '486px',
                                minWidth: '40%',
                                width: isMobile ? '100%' : '40%',
                                height: '190px',
                                backgroundColor: '#fff',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                            }}
                            onDragOver={handleDragOverKK}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDropKK}
                        >
                            {isDragging.isDraggingKK && !isMobile ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                selectedImageKK && selectedImageKK.type === 'application/pdf' ? (
                                    // Handle PDF display logic
                                    <>
                                        {handleShowPDF(selectedImageKK.name)}
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleRemoveImageKK}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : imageUrlKK ? (
                                    // Handle image display logic
                                    <>
                                        <img
                                            src={imageUrlKK}
                                            alt="selected-image"
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'contain',
                                                borderRadius: '4px',
                                            }}
                                        />
                                        <Box
                                            sx={{
                                                position: 'absolute',
                                                top: '4px',
                                                right: '4px',
                                                backgroundColor: '#fff',
                                                borderRadius: '50%',
                                                padding: '2px',
                                                cursor: 'pointer',
                                            }}
                                            onClick={handleRemoveImageKK}
                                        >
                                            <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                        </Box>
                                    </>
                                ) : (
                                    // Display upload options
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    {selectedImageKK ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={handleImageUploadKK}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                fontFamily: 'Jakarta Sans, sans-serif',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '400',
                                                lineHeight: '17,64px',
                                                backgroundColor: bgColor
                                            }}
                                        >
                                            Pilih {selectedImageKK ? 'Gambar' : 'Gambar/PDF'}
                                        </div>
                                    </>
                                )
                            }
                            <input
                                type="file"
                                accept="image/*,.pdf"  // Accept both image and PDF files
                                onChange={(event) => handleFileInputChangeKK(event.target.files[0])}
                                ref={fileInputRefKK}
                                style={{ display: 'none' }}
                            />
                        </Box>
                        {!isMobile && (
                            <CustomTooltip
                                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                                padding='8px'
                            />
                        )}
                    </Box>
                </Grid>

                {/* Textfield Father Fullname */}
                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px',
                        marginTop: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nama Ayah(Sesuai KTP)"
                            placeholder='Masukkan nama Ayah'
                            value={newData.daddyFullName}
                            name="daddyFullName"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperDaddy(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefDaddy}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperDaddy}
                                            anchorEl={anchorRefDaddy.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999 }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px',
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperDaddy(false)}>
                                                            <Tooltip title="Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.
                                                                    </Typography>

                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ayah sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>

                {/* Textfiled Mother Fullname */}
                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nama Ibu(Sesuai KTP)"
                            placeholder='Masukkan nama Ibu'
                            value={newData.mommyFullName}
                            name="mommyFullName"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperMommy(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefMommy}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperMommy}
                                            anchorEl={anchorRefMommy.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999 }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px',
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperMommy(false)}>
                                                            <Tooltip title="Pastikan memasukkan nama Ibu sesuai dengan KTP dan Kartu Keluarga." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        Pastikan memasukkan nama Ibu sesuai dengan KTP dan Kartu Keluarga
                                                                    </Typography>

                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan memasukkan nama Ibu sesuai dengan KTP dan Kartu Keluarga.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>

                {/* Textfield Email */}
                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Email"
                            placeholder='Masukkan email aktif'
                            value={newData.email}
                            name="email"
                            onChange={(e) => handleChange(e)}
                            error={newData.email === '' ? undefined : !!emailError}
                            helperText={emailError}
                            autoComplete="off"
                            required
                            autoFocus={autoFocus.email}
                            disabled={statusEmail === 'Ya' ? true : false}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperEmail(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefEmail}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperEmail}
                                            anchorEl={anchorRefEmail.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999 }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px',
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperEmail(false)}>
                                                            <Tooltip title="Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima atau mengirim email." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima atau mengirim email.
                                                                    </Typography>

                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    // WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan email yang dimasukkan masih dalam keadaan aktif dan dapat menerima dan mengirim email.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>

                {/* Textfield Nomor Whatsapp */}
                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nomor Whatsapp"
                            placeholder='Masukkan nomor Whatsapp'
                            value={newData.whatsapp}
                            name="whatsapp"
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperWhatsapp(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefWhatsapp}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperWhatsapp}
                                            anchorEl={anchorRefWhatsapp.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999 }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px',
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperWhatsapp(false)}>
                                                            <Tooltip title="Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center',
                                                                        }}
                                                                    >
                                                                        Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan.
                                                                    </Typography>
                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Pastikan nomor yang dimasukkan aktif serta dapat menerima dan melakukan panggilan.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>

                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="password"
                            label="Kata Sandi"
                            placeholder='Masukkan kata sandi'
                            value={newData.password}
                            name="password"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            error={newData.password === '' ? undefined : !!passwordError}
                            helperText={passwordError}
                            autoFocus={autoFocus.password}
                            required
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        {isMobile && (
                                            <Icon
                                                icon='ep:info-filled'
                                                onClick={() => setOpenPopperPassword(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                                ref={anchorRefKatasandi}
                                            />
                                        )}
                                        <Popper
                                            open={openPopperPassword}
                                            anchorEl={anchorRefKatasandi.current}
                                            placement="top"
                                            transition
                                            // disablePortal
                                            modifiers={[{ name: 'flip', enabled: false }]}
                                            style={{ zIndex: 9999 }}
                                        >
                                            {({ TransitionProps, placement }) => (
                                                <Grow
                                                    {...TransitionProps}
                                                    style={{
                                                        transformOrigin:
                                                            placement === 'top-end' ? 'right bottom' : 'right top',
                                                    }}
                                                >
                                                    <Paper
                                                        elevation={3}
                                                        sx={{
                                                            padding: '5px',
                                                            margin: '0px 10px',
                                                        }}
                                                    >
                                                        {/* Tooltip diletakkan di dalam ClickAwayListener */}
                                                        <ClickAwayListener onClickAway={() => setOpenPopperPassword(false)}>
                                                            <Tooltip title="Gunakan simbol, angka, gabungan huruf besar/kecil untuk keamanan kata sandi." arrow>
                                                                {/* Children yang dibutuhkan oleh Tooltip (misalnya, title) */}
                                                                <Box>
                                                                    <Typography
                                                                        sx={{
                                                                            textAlign: 'center'
                                                                        }}
                                                                    >
                                                                        Gunakan simbol, angka, gabungan huruf besar/kecil untuk keamanan kata sandi.
                                                                    </Typography>

                                                                </Box>
                                                            </Tooltip>
                                                        </ClickAwayListener>
                                                    </Paper>
                                                </Grow>
                                            )}
                                        </Popper>
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                marginBottom: isMobile ? '24px' : '34px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                            inputProps={{
                                maxLength: 20, // maksimal 20 karakter
                                minLength: 4, // minimal 8 karakter
                            }}
                        />
                    </Box>
                    {!isMobile && (
                        <CustomTooltip
                            message='Gunakan simbol, angka, gabungan huruf besar/kecil untuk keamanan kata sandi.'
                            padding='0px 8px'
                        />
                    )}
                </Grid>

                {/* Ulangi Kata Sandi */}
                <Grid item xs={12} md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        textAlign: 'center',
                        gap: '24px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%'
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="password"
                            label="Ulangi Kata Sandi"
                            placeholder='Masukkan kata sandi'
                            value={newData.konfirmasiPassword}
                            name="konfirmasiPassword"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            error={newData.konfirmasiPassword === '' ? undefined : !!konfirmasiPassword}
                            helperText={konfirmasiPassword}
                            required
                            sx={{
                                marginBottom: isMobile ? '10px' : '15px',
                                fontSize: isMobile ? '14px' : '13px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "white",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '13px',
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                    </Box>
                </Grid>

                {/* Button Save */}
                <Grid xs={12} md={12}
                    sx={{
                        marginBottom: isMobile ? '36px' : undefined,
                        marginTop: isMobile ? '20px' : '26px'
                    }}
                >
                    <Box
                        sx={{
                            width: isMobile ? '100%' : '40%',
                            maxWidth: '40%',
                            minWidth: isMobile ? '100%' : '40%',
                        }}
                    >
                        <Button
                            fullWidth
                            variant='contained'
                            size='medium'
                            type='submit'
                            sx={{
                                textTransform: 'none',
                                backgroundColor: btnBgColor,
                                color: mainColor,
                                fontWeight: 'bold',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                            disabled={loadingSimpanData ? true : false}
                        >
                            {loadingSimpanData ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Data'
                            }
                        </Button>

                    </Box>
                </Grid>
                <EmailActivationModal
                    open={openEmailActivationModal}
                    triggerOpenModal={() => setOpenEmailActivationModal(true)}
                    close={() => setOpenEmailActivationModal(false)}
                    isMobile={isMobile}
                    isTablet={isTablet}
                    newData={newData}
                    selectedImageNIK={selectedImageNIK}
                    selectedImageKK={selectedImageKK}
                    nextStep={nextStep}
                    countdownTimer={countdownTimer}
                    resetCountdown={() => setCountdownTimer(0)}
                    step3={step3}
                    errorMessage={errorMessage}
                />
                {isPageLoading ?
                    <LoadingData
                        open={isPageLoading}
                    // close={}
                    />
                    :
                    undefined
                }
            </Grid >
        </form>
    )
}

export default Form