import React, { useEffect, useState } from 'react'
import TopMenu from '../../components/Header/TopMenu'
import { Card, Grid, Paper, Typography, useMediaQuery } from '@mui/material'
import { Dark, DividerColor, TextGray, bgColor, mainColor } from '../../components/Config/Color';
import fileEmpty from '../../assets/Icon/file_empty.png'
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import { useNavigate } from 'react-router-dom';
import { getDataDashboardApplicant, getDataRiwayat } from '../../components/Config/Api';
import axios from 'axios';
import { FormatCurrency } from '../../components/FormatCurrency/FormatCurrency';
import moment from 'moment';
import { capitalizeFirstLetter } from '../../components/UppercaseCharacter/CapitalizeFirstLetter';

const Riwayat = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const navigate = useNavigate()

    const [dataRiwayatTransaksi, setDataRiwayatTransaksi] = useState([])
    const [dataUser, setDataUser] = useState(null)
    const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)

    const handleDataRiwayat = async (response) => {
        console.log('cek response', response.data?.data?.personal_information_id);
        await axios.get(`${getDataRiwayat}/${response.data?.data?.personal_information_id}`)
            .then((res) => {
                console.log('data riwayat', res.data);
                setDataRiwayatTransaksi(res.data)
            }).catch((err) => {
                console.log('err msg riwayat', err.response.data);
            })
    }

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email



            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log('complete data', response.data?.data);

                    setDataUser(response.data?.data?.full_name)

                    handleDataRiwayat(response)

                }).catch((error) => {
                    console.log('error msg', error.response.data);
                })
        }
    }

    const handleLoginSession = () => {
        const loginSessionExp = localStorage.getItem('LogInfo')
        const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
        if (res) {
            setOpenLoginSessionModal(true)
            localStorage.removeItem('LogInfo')
        }
    }

    const handleCloseLoginSessionModal = () => {
        setOpenLoginSessionModal(false)
        navigate('/login');
    }

    useEffect(() => {
        const dataLogin = localStorage.getItem('LogInfo')
        if (dataLogin) {
            handleLoginSession()
            getData()
        } else if (!dataLogin) {
            setOpenLoginSessionModal(true)
            setTimeout(() => {
                navigate('/login');
            }, 9000);
        }

    }, [])

    const formatAccountNumber = (number) => {
        return number.replace(/(\d{4})(?=\d)/g, '$1 ');
    };

    return (
        <>
            <TopMenu />
            <Grid
                container
                spacing={2}
                sx={{
                    padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                    backgroundColor: bgColor,
                    paddingBottom: '52px',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center', // Mengatur konten secara horizontal ke tengah
                    alignItems: 'center',
                    // textAlign:'center'
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: isMobile ? 'center' : 'flex-start',
                        // alignItems: 'center',
                        margin: isMobile ? '20px 0px 5px 0px' : '15px 0px 0px 0px',
                        gap: '20px'
                    }}
                >
                    {dataRiwayatTransaksi.length > 0 ? (
                        dataRiwayatTransaksi.map((data, index) => (
                            <Paper
                                elevation={3}
                                key={index}
                                sx={{
                                    padding: isMobile ? '15px' : '20px',
                                    // maxWidth: '1200px',
                                    width: isMobile ? undefined : '100%',
                                    // maxHeight: isMobile ? undefined : '56px',
                                    height: '100%',
                                    border: `1px solid ${DividerColor}`,
                                    overflow: isMobile ? 'auto' : 'hidden',
                                    // textAlign: 'center', // Mengatur konten secara horizontal ke tengah
                                    // display: 'flex',
                                    // flexDirection: 'column',
                                    // alignItems: 'center', 
                                }}
                            >
                                <Grid
                                    container
                                    // backgroundColor='orange'
                                    spacing={isMobile ? 3 : undefined}
                                    justifyContent="space-between"
                                >
                                    <Grid
                                        item
                                        xs={12}
                                        md={2.4}
                                        sx={{
                                            // backgroundColor: 'orange',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: isMobile ? '15px' : '20px',
                                            // backgroundColor: 'orange'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: TextGray,
                                                fontSize: isMobile ? '13px' : '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: '17.64px',
                                            }}
                                        >
                                            Tanggal
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '14px' : '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            {moment(data.payment_date).format('Do MMMM YYYY')}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2.4}
                                        sx={{
                                            // backgroundColor: 'orange',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: isMobile ? '15px' : '20px',
                                            // backgroundColor: 'orange'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: TextGray,
                                                fontSize: isMobile ? '13px' : '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: '17.64px',
                                            }}
                                        >
                                            Kategori Beasiswa
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '14px' : '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            {data.category}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2.4}
                                        sx={{
                                            // backgroundColor: 'orange',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: isMobile ? '15px' : '20px',
                                            // backgroundColor: 'orange'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: TextGray,
                                                fontSize: isMobile ? '13px' : '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: '17.64px',
                                            }}
                                        >
                                            Nama Penerima
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '14px' : '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            {capitalizeFirstLetter(data.full_name)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2.4}
                                        sx={{
                                            // backgroundColor: 'orange',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: isMobile ? '15px' : '20px',
                                            // backgroundColor: 'orange'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: TextGray,
                                                fontSize: isMobile ? '13px' : '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: '17.64px',
                                            }}
                                        >
                                            No. Rekening
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '14px' : '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            {formatAccountNumber(data.account_number)}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={2.4}
                                        sx={{
                                            // backgroundColor: 'orange',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'flex-start',
                                            gap: isMobile ? '15px' : '20px',
                                            // backgroundColor: 'orange'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: TextGray,
                                                fontSize: isMobile ? '13px' : '14px',
                                                fontStyle: 'normal',
                                                fontWeight: '500',
                                                lineHeight: '17.64px',
                                            }}
                                        >
                                            Jumlah diterima
                                        </Typography>
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: isMobile ? '14px' : '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: '20.16px',
                                            }}
                                        >
                                            Rp. {FormatCurrency(parseInt(data.amount))}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Paper>
                        ))
                    ) : (
                        <Paper
                            elevation={3}
                            sx={{
                                padding: isMobile ? '15px' : '20px',
                                // maxWidth: '1200px',
                                width: '100%',
                                minHeight: isMobile ? undefined : '256px',
                                height: '100%',
                                border: `1px solid ${DividerColor}`,
                                overflow: isMobile ? 'auto' : 'hidden',
                                // textAlign: 'center', // Mengatur konten secara horizontal ke tengah
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '20px'
                            }}
                        >
                            <img src={fileEmpty} alt='no-data' width='47px' height='47px' />
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: isMobile ? '14px' : '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: '22.68px',
                                }}
                            >
                                Data tidak ditemukan.
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: TextGray,
                                    fontSize: isMobile ? '14px' : '15px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: '20.16px',
                                    textAlign: 'center'
                                }}
                            >
                                Belum ada riwayat penerimaan beasiswa atas nama
                                {' '}'
                                <span
                                    style={{
                                        color: mainColor,
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {capitalizeFirstLetter(dataUser)}
                                </span>
                                '{' '} sejak tahun 2024.
                            </Typography>
                        </Paper>
                    )}
                </Grid>

            </Grid >
            <LoginSessionModal
                open={openLoginSessionModal}
                close={handleCloseLoginSessionModal}
            />
        </>
    )
}

export default Riwayat