import { Typography, useMediaQuery, Grid } from '@mui/material';
import React from 'react';
import Instagram from '../../../assets/Icon/Instagram.png';
import LinkedIn from '../../../assets/Icon/LinkedIn.png';
import Youtube from '../../../assets/Icon/YouTube.png';
import { mainColor } from '../../Config/Color';

const Top = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    return (
        <Grid
            container
            sx={{
                padding: isMobile ? '7px 10px' : '7px 83px',
                backgroundColor: mainColor,
            }}
        >
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isMobile ? 'center' : isTablet ? 'center' : 'flex-start',
                    alignItems: 'center',
                    marginBottom: isMobile ? '10px' : isTablet ? '10px' : undefined,
                }}
            >
                <Typography
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: '#fff',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: 'normal',
                    }}
                >
                    Kontak : dikbudpapuatengah@gmail.com
                </Typography>
            </Grid>
            <Grid
                item
                xs={12}
                md={6}
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: isMobile ? 'center' : isTablet ? 'center' : 'flex-end',
                    alignItems: 'center',
                    gap: '12px',
                }}
            >
                <img src={Youtube} style={{ width: '20px', height: '20px' }} alt="YouTube" />
                <img src={Instagram} style={{ width: '17px', height: '17px' }} alt="Instagram" />
                <img src={LinkedIn} style={{ width: '17px', height: '17px' }} alt="LinkedIn" />
            </Grid>
        </Grid>
    );
};

export default Top;