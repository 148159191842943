import { Box, Button, Divider, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import { url } from '../../Config/Api'
import { Dark, DividerColor, TextGray, mainColor } from '../../Config/Color'
import { Icon } from '@iconify/react'
import IconUrl from '../../../assets/Icon/url_icon.png'

const BantuanStudiAkhir = ({ biodata, isMobile, isTablet }) => {

  const [OpenUrlLoading, setOpenUrlLoading] = useState(false)

  const handleShowImage = (image) => {

    var imageUrl = `${url}${image}`
    const fileExtension = image ? image.split('.').pop().toLowerCase() : ''
    // console.log('tesadsad', fileExtension);

    // Extract filename from the URL
    const filename = image ? image.split('/').pop() : ''
    // console.log('tes', fileExtension);

    let desiredString = ''
    if (fileExtension === 'pdf') {
      // Extract everything after the last underscore in the filename
      const lastUnderscoreIndex = filename.lastIndexOf('_');
      desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
    }

// console.log('tesss',biodata.data?.link_pddikti);
    return (
      fileExtension === 'pdf' ?
        // Display PDF and download button
        <Button
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textTransform: 'none',
            maxWidth: isMobile ? '100%' : '277px',
            maxHeight: isMobile ? '100%' : '170px',
            height: '100%',
            width: '100%',
            // backgroundColor: 'orange'
          }}
        >
          <a
            href={biodata.data?.link_pddikti}
            // target="_blank"
            rel="noopener noreferrer"
            download
            style={{ textDecoration: 'none', color: 'inherit' }}
          >
            <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: mainColor,
                fontSize: '11px',
                fonStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: 'normal',
                cursor: 'pointer',
                textAlign: 'center',
                marginBottom: '5px'
              }}
            >
              {desiredString}
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                color: mainColor,
                fontSize: '11px',
                fonStyle: 'normal',
                fontWeight: 'bold',
                lineHeight: 'normal',
                cursor: 'pointer',
                textAlign: 'center'
              }}
            >
              Download PDF
            </Typography>
          </a>
        </Button>
        :
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            // backgroundColor: 'orange',
            gap: isMobile ? '0px' : '10px',
            maxWidth: isMobile ? '100%' : '1168px',
            maxHeight: isMobile ? '100%' : '1257px',
            height: '100%',
            width: '100%',
            // padding: '10px',
            // marginBottom: isMobile ? '20px' : undefined
          }}
        >

          <img
            src={biodata.data?.link_pddikti}
            alt="show-image"
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
              // borderRadius: '4px',
              // border: `1px solid ${DividerColor}`,
              // borderColor: Dark
            }}
          />
        </Box>
    )

  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        // padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
        // backgroundColor: bgColor,
        // paddingBottom: '52px',
        // height: '100%'
      }}
    >
      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          // borderBottom: `2px solid ${DividerColor}`,
          // borderRight: `2px solid ${DividerColor}`
          // alignItems: 'center',
          // backgroundColor: 'orange',
          // margin: isMobile ? '10px 0px' : '0px 0px 0px 0px',
          margin: '10px 20px',
          // gap: '5px'
        }}
      >

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%'
          }}
        >
          <Typography
            sx={{
              fontFamily: 'Plus Jakarta Sans',
              color: TextGray,
              fontSize: '14px',
              fonStyle: 'normal',
              fontWeight: '500',
              lineHeight: 'normal',
              // marginBottom: '10px'
            }}
          >
            URL/Link PDDikti
          </Typography>
          <Button
            size={isMobile ? 'small' : 'medium'}
            onClick={() => {
              setOpenUrlLoading(true)
              setTimeout(() => {
                const newWindow = window.open(biodata.data?.link_pddikti, '_blank')
                if (newWindow) {
                  newWindow.opener = null
                }
                setOpenUrlLoading(false)
              }, 500);
            }}
            variant='contained'
            sx={{
              mt: '5px',
              backgroundColor: '#BECAE8',
              color: mainColor,
              textTransform: 'none',
              padding: isMobile ? '5px 5px' : '5px 15px',
              minWidth: isMobile ? '110px' : '130px',
              '&:hover': {
                color: mainColor, // Change text color to white on hover
                backgroundColor: '#BECAE8'
              },
            }}
          >
            {OpenUrlLoading ?
              <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px', color: mainColor }} />
              :
              <>
                <Typography
                  sx={{
                    fontFamily: 'Plus Jakarta Sans',
                    color: mainColor,
                    fontSize: isMobile ? '13px' : '15px',
                    fonStyle: 'normal',
                    fontWeight: '600',
                    lineHeight: '20.16px',
                    mr: '5px',
                  }}
                >
                  Lihat Web
                </Typography>
                <img
                  src={IconUrl}
                  width={isMobile ? '15px' : '20px'}
                  height={isMobile ? '15px' : '20px'}
                  color={mainColor}
                />
              </>
            }
          </Button>
        </Box>
        <Typography
          sx={{
            fontFamily: 'Plus Jakarta Sans',
            color: '#000000',
            fontSize: '16px',
            fonStyle: 'normal',
            fontWeight: '500',
            lineHeight: '20.16px',
            marginTop: isMobile ? '10px' : undefined
          }}
        >
          {
            isMobile && biodata.data?.link_pddikti.length > 30 ? `${biodata.data?.link_pddikti.substring(0, 30)}...`
              :
              isTablet && biodata.data?.link_pddikti.length > 60 ? `${biodata.data?.link_pddikti.substring(0, 60)}...`
                :
                `${biodata.data?.link_pddikti.substring(0, 70)}...`
          }
        </Typography>

      </Grid>

      <Box
        sx={{
          width: '100%'
        }}
      >
        <Divider
          variant="string"
          style={{
            backgroundColor: "#fff",
            borderBottomWidth: 0.5,
            // marginTop: "2px",
          }}
        />
      </Box>

      <Grid
        item
        xs={12}
        md={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          // borderBottom: `2px solid ${DividerColor}`,
          // borderRight: `2px solid ${DividerColor}`
          // alignItems: 'center',
          // backgroundColor: 'orange',
          // margin: isMobile ? '10px 0px' : '0px 0px 0px 0px',
          margin: '10px 20px'
        }}
      >
        {handleShowImage(biodata.link_pddikti)}
      </Grid>
    </Grid>
  )
}

export default BantuanStudiAkhir