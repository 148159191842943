import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 'auto',
    // bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '5px',
    // boxShadow: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
    // p: 4,
};

const LightboxModal = ({
    open,
    close,
    imageUrl,
    option,
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    // console.log('option', option);
    return (
        <div>
            <Modal
                open={open}
                onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {imageUrl ?
                        <img
                            src={imageUrl}
                            // alt={option}
                            style={{
                                width: '100%',
                                height: isMobile ? '100%' : '500px',
                                objectFit: 'unset', // Mengubah properti object-fit menjadi 'contain'
                                borderRadius: '4px',
                            }}
                        />
                        :
                        undefined
                    }
                </Box>
            </Modal>
        </div>
    );
}

export default LightboxModal