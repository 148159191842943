import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Modal,
    useMediaQuery,
    Button,
    Divider,
    TextareaAutosize,
    Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { RegistrasiTahapII_and_TahapIII, createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'
import { useFormContext } from "../../../pages/Registrasi/FormContext";

const Tahap2BantuanStudiAkhirS2 = ({
    isMobile,
    isTablet,
    dataStep2Exist,
    selectedImageNIMExist,
    selectedImageKHSExist,
    selectedImageKRSExist,
}) => {
    return (
        <>
            {/* Column 1 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: isMobile ? '3px' : undefined,
                    marginBottom: '20px',
                    // backgroundColor: 'orange',
                    width: '100%',

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Kategori
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep2Exist.selectedCategoryExist ? dataStep2Exist.selectedCategoryExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Jenjang Pendidikan
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep2Exist.selectedJenjangPendidikanExist ? dataStep2Exist.selectedJenjangPendidikanExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Link PDDikti
                    </Typography>

                    {dataStep2Exist.buktiAktifKuliahExist && (
                        <Typography
                            sx={{
                                color: mainColor,
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '11px',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                            }}
                        >
                            <Link to={dataStep2Exist.buktiAktifKuliahExist} target="_blank" rel="noopener noreferrer">
                                {dataStep2Exist.buktiAktifKuliahExist.length > 18 ? `${dataStep2Exist.buktiAktifKuliahExist.slice(0, 18)}...` : dataStep2Exist.buktiAktifKuliahExist}
                            </Link>
                        </Typography>
                    )}
                </Box>
            </Box>

            {/* Column 2 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: isMobile ? '3px' : undefined,
                    marginBottom: '20px',
                    // backgroundColor: 'orange',
                    width: '100%',

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        NIM
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {dataStep2Exist.NIMExist ? dataStep2Exist.NIMExist : ''}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        File NIM
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageNIMExist && selectedImageNIMExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageNIMExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageNIMExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Kartu Hasil Studi
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageKHSExist && selectedImageKHSExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageKHSExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageKHSExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
            </Box>

            {/* Column 3 */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-center',
                    gap: isMobile ? '3px' : undefined,
                    marginBottom: '20px',
                    // backgroundColor: 'orange',
                    width: '100%',

                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Kartu Rencana Studi
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageKRSExist && selectedImageKRSExist.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageKRSExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageKRSExist)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    {/* <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Kartu Rencana Studi
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {NIM ? NIM : ''}
                    </Typography> */}
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '2px',
                        width: '33.3%'
                    }}
                >
                    {/* <Typography
                        sx={{
                            color: TextGray,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Kartu Hasil Studi
                    </Typography>

                    <Typography
                        sx={{
                            color: Dark,
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                        }}
                    >
                        {selectedImageKHS && selectedImageKHS.type === 'application/pdf' ?
                            <a
                                href={URL.createObjectURL(selectedImageKHS)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat PDF
                            </a>
                            :
                            <a
                                href={URL.createObjectURL(selectedImageKHS)}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontSize: '12px'
                                }}
                            >
                                Lihat Gambar
                            </a>
                        }
                    </Typography> */}
                </Box>
            </Box>
        </>
    )
}

export default Tahap2BantuanStudiAkhirS2