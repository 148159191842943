import React, { useEffect, useRef, useState } from "react";
import {
    Box,
    TextField,
    Typography,
    Modal,
    useMediaQuery,
    Button,
    Divider,
    TextareaAutosize,
    Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { RegistrasiTahapII_and_TahapIII, createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'
import { FormProvider, useFormContext } from "../../../pages/Registrasi/FormContext";
import Tahap1 from "./Tahap1";
import Tahap2 from "./Tahap2";
import Tahap2BeasiswaBerprestasiS2 from "./Tahap2BeasiswaBerprestasiS2";
import Tahap2BeasiswaBerprestasiS3 from "./Tahap2BeasiswaBerprestasiS3";
import { encrypt, decrypt } from 'n-krypta';
import { secretKey } from "../../Config/SecretKey";
import Tahap2BeasiswaKeluargaMiskin from "./Tahap2BeasiswaKeluargaMiskin";
import Tahap2IkatanDinas from "./Tahap2IkatanDinas";
import Tahap2BantuanStudiAkhirS1 from "./Tahap2BantuanStudiAkhirS1";
import Tahap2BantuanStudiAkhirS2 from "./Tahap2BantuanStudiAkhirS2";
import Tahap2BantuanStudiAkhirS3 from "./Tahap2BantuanStudiAkhirS3";
import Base64ToFile from "../../Base64ToFile/Base64ToFile";
import FileToBase64 from "../../FileToBase64/FileToBase64";


const VerificationDataModal = ({
    open,
    openModal,
    close,
    notificationStatus,
    backStep1,
    backStep2,
}) => {
    // const secretKey = '@BCTdev2024WebPapua';
    const { updateFormData } = useFormContext();
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [newData, setNewData] = useState({
        topikPengaduan: '',
    })
    const [pesanPengaduan, setPesanPengaduan] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoadingBackTahapI, setIsLoadingBackTahapI] = useState(false)
    const [isLoadingBackTahapII, setIsLoadingBackTahapII] = useState(false)

    // STATE STEP 1
    const [dataStep1Exist, setDataStep1Exist] = useState({
        userConfirmPasswordExist: '',
        userEmailExist: '',
        userFatherNameExist: '',
        userFullNameExist: '',
        userMotherNameExist: '',
        userNIKExist: '',
        userNoKKExist: '',
        userPasswordExist: '',
        userPersonalInformationId: '',
        userWhatsappNumberExist: '',
        userIdExist: ''
    })



    const [selectedImageNIKExist, setSelectedImageNIKExist] = useState(null)
    const [selectedImageKKExist, setSelectedImageKKExist] = useState(null)
    const [selectedImageNIKUrlExist, setSelectedImageNIKUrlExist] = useState(null)
    const [selectedImageKKUrlExist, setSelectedImageKKUrlExist] = useState(null)

    // STATE STEP 2
    const [dataStep2Exist, setDataStep2Exist] = useState({
        selectedCategoryExist: '',
        selectedJenjangPendidikanExist: '',
        nilaiGpaS1Exist: '',
        nilaiGpaS2Exist: '',
        nilaiRata2S1Exist: '',
        nilaiRata2S2Exist: '',
        jurusan1Exist: '',
        jurusan2Exist: '',
        jurusan3Exist: '',
        kategoriJurusanExist: '',
        buktiAktifKuliahExist: '',
        judulProposalExist: '',
        judulPenelitianExist: '',
        lokasiKKNExist: '',
        NIMExist: ''
    })

    const [selectedCertificate1Exist, setSelectedCertificate1Exist] = useState(null)
    const [selectedCertificate1UrlExist, setSelectedCertificate1UrlExist] = useState(null)

    const [selectedCertificate2Exist, setSelectedCertificate2Exist] = useState(null)
    const [selectedCertificate2UrlExist, setSelectedCertificate2UrlExist] = useState(null)

    const [selectedCertificate3Exist, setSelectedCertificate3Exist] = useState(null)
    const [selectedCertificate3UrlExist, setSelectedCertificate3UrlExist] = useState(null)

    const [selectedImageIjazahSMAExist, setSelectedImageIjazahSMAExist] = useState(null)
    const [selectedImageIjazahSMAUrlExist, setselectedImageIjazahSMAUrlExist] = useState(null)

    const [selectedImageRaportXExist, setSelectedImageRaportXExist] = useState(null)
    const [selectedImageUrlRaportXExist, setSelectedImageUrlRaportXExist] = useState(null)

    const [selectedImageRaportXIExist, setSelectedImageRaportXIExist] = useState(null)
    const [selectedImageUrlRaportXIExist, setSelectedImageUrlRaportXIExist] = useState(null)

    const [selectedImageRaportXIIExist, setSelectedImageRaportXIIExist] = useState(null)
    const [selectedImageUrlRaportXIIExist, setSelectedImageUrlRaportXIIExist] = useState(null)

    const [selectedToelfExist, setSelectedToelfExist] = useState(null)
    const [selectedToelfUrlExist, setSelectedToelfUrlExist] = useState(null)

    const [selectedImageIjazahS1Exist, setSelectedImageIjazahS1Exist] = useState(null);
    const [imageUrlIjazahS1Exist, setImageUrlIjazahS1Exist] = useState(null);

    const [selectedImageIjazahS2Exist, setSelectedImageIjazahS2Exist] = useState(null);
    const [imageUrlIjazahS2Exist, setImageUrlIjazahS2Exist] = useState(null);

    const [selectedImageTranskripNilaiS1Exist, setSelectedImageTranskripNilaiS1Exist] = useState(null);
    const [imageUrlTranskripNilaiS1Exist, setImageUrlTranskripNilaiS1Exist] = useState(null);

    const [selectedImageTranskripNilaiS2Exist, setSelectedImageTranskripNilaiS2Exist] = useState(null);
    const [imageUrlTranskripNilaiS2Exist, setImageUrlTranskripNilaiS2Exist] = useState(null);

    const [selectedImageHouse1Exist, setSelectedImageHouse1Exist] = useState(null);
    const [imageUrlHouse1Exist, setImageUrlHouse1Exist] = useState(null);

    const [selectedImageHouse2Exist, setSelectedImageHouse2Exist] = useState(null);
    const [imageUrlHouse2Exist, setImageUrlHouse2Exist] = useState(null);

    const [selectedImageHouse3Exist, setSelectedImageHouse3Exist] = useState(null);
    const [imageUrlHouse3Exist, setImageUrlHouse3Exist] = useState(null);

    const [selectedImageTranskripNilaiSMAExist, setSelectedImageTranskripNilaiSMAExist] = useState(null);
    const [imageUrlTranskripNilaiSMAExist, setImageUrlTranskripNilaiSMAExist] = useState(null);

    const [selectedImageBKMExist, setSelectedImageBKMExist] = useState(null);
    const [imageUrlBKMExist, setImageUrlBKMExist] = useState(null);

    const [selectedImageBKMKISExist, setSelectedImageBKMKISExist] = useState(null);
    const [imageUrlBKMKISExist, setImageUrlBKMKISExist] = useState(null);

    const [selectedImageNIMExist, setSelectedImageNIMExist] = useState(null);
    const [imageUrlNIMExist, setImageUrlNIMExist] = useState(null);

    const [selectedImageKHSExist, setSelectedImageKHSExist] = useState(null);
    const [imageUrlKHSExist, setImageUrlKHSExist] = useState(null);

    const [selectedImageKRSExist, setSelectedImageKRSExist] = useState(null);
    const [imageUrlKRSExist, setImageUrlKRSExist] = useState(null);

    // STEP 3
    const [dataStep3Exist, setDataStep3Exist] = useState({
        answer1Exist: '',
        answer2Exist: '',
        selectedUniversityExist: '',
        userLocationExist: '',
    })
    const [checkboxValuesExist, setCheckboxValuesExist] = useState({
        checkedExist: false
    })

    const [selectedImageTTDExist, setSelectedImageTTDExist] = useState(null)
    const [selectedImageUrlTTDExist, setSelectedImageUrlTTDExist] = useState(null)

    const navigate = useNavigate();

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: isMobile ? 340 : 500,
        width: isMobile ? '100%' : 500,
        // minWidth: 450,
        height: 'auto',
        bgcolor: "white",
        borderRadius: "10px",
        boxShadow: 24,
        // p: 2,
    };

    const data = localStorage.getItem('data')

    const getAlreadyExistData = (dataStep1, dataStep2, dataStep3, emailVerifData) => {
        // console.log('dataStep1.userIdExist', dataStep1?.userIdExist);

        // STEP 1
        if (dataStep1 && emailVerifData) {
            setDataStep1Exist({
                userConfirmPasswordExist: dataStep1.userConfirmPasswordExist,
                userEmailExist: dataStep1.userEmailExist,
                userFatherNameExist: dataStep1.userFatherNameExist,
                userFullNameExist: dataStep1.userFullNameExist,
                userMotherNameExist: dataStep1.userMotherNameExist,
                userNIKExist: dataStep1.userNIKExist,
                userNoKKExist: dataStep1.userNoKKExist,
                userPasswordExist: dataStep1.userPasswordExist,
                userPersonalInformationId: dataStep1.userPersonalInformationId,
                userWhatsappNumberExist: dataStep1.userWhatsappNumberExist,
                userIdExist: emailVerifData.userId,
            });

            if (dataStep1.selectedImageNIKExist) {
                const fileNIK = Base64ToFile(dataStep1.selectedImageNIKExist.base64, dataStep1.selectedImageNIKExist.name);
                setSelectedImageNIKUrlExist(dataStep1.selectedImageNIKExist.base64 || null);
                setSelectedImageNIKExist(fileNIK);
            }

            if (dataStep1.selectedImageKKExist) {
                const fileKK = Base64ToFile(dataStep1.selectedImageKKExist.base64, dataStep1.selectedImageKKExist.name);
                setSelectedImageKKUrlExist(dataStep1.selectedImageKKExist.base64 || null);
                setSelectedImageKKExist(fileKK);
            }
        }

        // STEP 2
        // Beasiswa Berprestasi
        if (dataStep2) {
            setDataStep2Exist({
                selectedCategoryExist: dataStep2.selectedCategoryExist || null,
                selectedJenjangPendidikanExist: dataStep2.selectedJenjangPendidikanExist || null,
                nilaiGpaS1Exist: dataStep2.nilaiGpaS1Exist || null,
                nilaiGpaS2Exist: dataStep2.nilaiGpaS2Exist || null,
                nilaiRata2S1Exist: dataStep2.nilaiRata2S1Exist || null,
                nilaiRata2S2Exist: dataStep2.nilaiRata2S2Exist || null,
                kategoriJurusanExist: dataStep2.kategoriJurusanExist || null,
                jurusan1Exist: dataStep2.jurusan1Exist || null,
                jurusan2Exist: dataStep2.jurusan2Exist || null,
                jurusan3Exist: dataStep2.jurusan3Exist || null,
                buktiAktifKuliahExist: dataStep2.buktiAktifKuliahExist || null,
                judulProposalExist: dataStep2.judulProposalExist || null,
                judulPenelitianExist: dataStep2.judulPenelitianExist || null,
                lokasiKKNExist: dataStep2.lokasiKKNExist || null,
                NIMExist: dataStep2.NIMExist || null,
            });

            if (dataStep2.selectedCertificate1Exist) {
                const fileCertificate1Exist = Base64ToFile(dataStep2.selectedCertificate1Exist.base64, dataStep2.selectedCertificate1Exist.name);
                setSelectedCertificate1UrlExist(dataStep2.selectedCertificate1Exist.base64 || null);
                setSelectedCertificate1Exist(fileCertificate1Exist);
            }

            if (dataStep2.selectedCertificate2Exist) {
                const fileCertificate2Exist = Base64ToFile(dataStep2.selectedCertificate2Exist.base64, dataStep2.selectedCertificate2Exist.name);
                setSelectedCertificate2UrlExist(dataStep2.selectedCertificate2Exist.base64 || null);
                setSelectedCertificate2Exist(fileCertificate2Exist);
            }

            if (dataStep2.selectedCertificate3Exist) {
                const fileCertificate3Exist = Base64ToFile(dataStep2.selectedCertificate3Exist.base64, dataStep2.selectedCertificate3Exist.name);
                setSelectedCertificate3UrlExist(dataStep2.selectedCertificate3Exist.base64 || null);
                setSelectedCertificate3Exist(fileCertificate3Exist);
            }

            if (dataStep2.selectedImageIjazahSMAExist) {
                const fileSelectedImageIjazahSMAExist = Base64ToFile(dataStep2.selectedImageIjazahSMAExist.base64, dataStep2.selectedImageIjazahSMAExist.name);
                setselectedImageIjazahSMAUrlExist(dataStep2.selectedImageIjazahSMAExist.base64 || null);
                setSelectedImageIjazahSMAExist(fileSelectedImageIjazahSMAExist);
            }

            if (dataStep2.selectedToelfExist) {
                const fileSelectedToelfExist = Base64ToFile(dataStep2.selectedToelfExist.base64, dataStep2.selectedToelfExist.name);
                setSelectedToelfUrlExist(dataStep2.selectedToelfExist.base64 || null);
                setSelectedToelfExist(fileSelectedToelfExist);
            }

            if (dataStep2.selectedImageIjazahS1Exist) {
                const fileSelectedImageIjazahS1Exist = Base64ToFile(dataStep2.selectedImageIjazahS1Exist.base64, dataStep2.selectedImageIjazahS1Exist.name);
                setImageUrlIjazahS1Exist(dataStep2.selectedImageIjazahS1Exist.base64 || null);
                setSelectedImageIjazahS1Exist(fileSelectedImageIjazahS1Exist);
            }

            if (dataStep2.selectedImageTranskripNilaiS1Exist) {
                const fileSelectedImageTranskripNilaiS1Exist = Base64ToFile(dataStep2.selectedImageTranskripNilaiS1Exist.base64, dataStep2.selectedImageTranskripNilaiS1Exist.name);
                setImageUrlTranskripNilaiS1Exist(dataStep2.selectedImageTranskripNilaiS1Exist.base64 || null);
                setSelectedImageTranskripNilaiS1Exist(fileSelectedImageTranskripNilaiS1Exist);
            }

            if (dataStep2.selectedImageTranskripNilaiS2Exist) {
                const fileSelectedImageTranskripNilaiS2Exist = Base64ToFile(dataStep2.selectedImageTranskripNilaiS2Exist.base64, dataStep2.selectedImageTranskripNilaiS2Exist.name);
                setImageUrlTranskripNilaiS2Exist(dataStep2.selectedImageTranskripNilaiS2Exist.base64 || null);
                setSelectedImageTranskripNilaiS2Exist(fileSelectedImageTranskripNilaiS2Exist);
            }

            if (dataStep2.selectedImageIjazahS2Exist) {
                const fileSelectedImageIjazahS2Exist = Base64ToFile(dataStep2.selectedImageIjazahS2Exist.base64, dataStep2.selectedImageIjazahS2Exist.name);
                setImageUrlIjazahS2Exist(dataStep2.selectedImageIjazahS2Exist.base64 || null);
                setSelectedImageIjazahS2Exist(fileSelectedImageIjazahS2Exist);
            }

            if (dataStep2.selectedImageXExist) {
                const fileSelectedImageXExist = Base64ToFile(dataStep2.selectedImageXExist.base64, dataStep2.selectedImageXExist.name);
                setSelectedImageUrlRaportXExist(dataStep2.selectedImageXExist.base64 || null);
                setSelectedImageRaportXExist(fileSelectedImageXExist);
            }


            if (dataStep2.selectedImageXIExist) {
                const fileSelectedImageXIExist = Base64ToFile(dataStep2.selectedImageXIExist.base64, dataStep2.selectedImageXIExist.name);
                setSelectedImageUrlRaportXIExist(dataStep2.selectedImageXIExist.base64 || null);
                setSelectedImageRaportXIExist(fileSelectedImageXIExist);
            }

            if (dataStep2.selectedImageXIIExist) {
                const fileSelectedImageXIIExist = Base64ToFile(dataStep2.selectedImageXIIExist.base64, dataStep2.selectedImageXIIExist.name);
                setSelectedImageUrlRaportXIIExist(dataStep2.selectedImageXIIExist.base64 || null);
                setSelectedImageRaportXIIExist(fileSelectedImageXIIExist);
            }

            if (dataStep2?.selectedImageBKMExist) {
                const fileSelectedImageBKMExist = Base64ToFile(dataStep2.selectedImageBKMExist.base64, dataStep2.selectedImageBKMExist.name);
                setSelectedImageBKMExist(fileSelectedImageBKMExist);
                setImageUrlBKMExist(dataStep2.selectedImageBKMExist.base64 || null);
            }

            if (dataStep2?.selectedImageBKMKISExist) {
                const fileselectedImageBKMKISExist = Base64ToFile(dataStep2.selectedImageBKMKISExist.base64, dataStep2.selectedImageBKMKISExist.name);
                setSelectedImageBKMKISExist(fileselectedImageBKMKISExist);
                setImageUrlBKMKISExist(dataStep2.selectedImageBKMKISExist.base64 || null);
            }

            if (dataStep2?.selectedImageTranskripNilaiSMAExist) {
                const fileSelectedImageTranskripNilaiSMAExist = Base64ToFile(dataStep2.selectedImageTranskripNilaiSMAExist.base64, dataStep2.selectedImageTranskripNilaiSMAExist.name);
                setSelectedImageTranskripNilaiSMAExist(fileSelectedImageTranskripNilaiSMAExist);
                setImageUrlTranskripNilaiSMAExist(dataStep2.selectedImageTranskripNilaiSMAExist.base64 || null);
            }

            if (dataStep2?.selectedImageHouse1Exist) {
                const fileSelectedImageHouse1Exist = Base64ToFile(dataStep2.selectedImageHouse1Exist.base64, dataStep2.selectedImageHouse1Exist.name);
                setSelectedImageHouse1Exist(fileSelectedImageHouse1Exist);
                setImageUrlHouse1Exist(dataStep2.selectedImageHouse1Exist.base64 || null);
            }

            if (dataStep2?.selectedImageHouse2Exist) {
                const fileSelectedImageHouse2Exist = Base64ToFile(dataStep2.selectedImageHouse2Exist.base64, dataStep2.selectedImageHouse2Exist.name);
                setSelectedImageHouse2Exist(fileSelectedImageHouse2Exist);
                setImageUrlHouse2Exist(dataStep2.selectedImageHouse2Exist.base64 || null);
            }

            if (dataStep2?.selectedImageHouse3Exist) {
                const fileSelectedImageHouse3Exist = Base64ToFile(dataStep2.selectedImageHouse3Exist.base64, dataStep2.selectedImageHouse3Exist.name);
                setSelectedImageHouse3Exist(fileSelectedImageHouse3Exist);
                setImageUrlHouse3Exist(dataStep2.selectedImageHouse3Exist.base64 || null);
            }

            if (dataStep2?.selectedImageNIMExist) {
                const fileSelectedImageNIMExist = Base64ToFile(dataStep2.selectedImageNIMExist.base64, dataStep2.selectedImageNIMExist.name);
                setSelectedImageNIMExist(fileSelectedImageNIMExist);
                setImageUrlNIMExist(dataStep2.selectedImageNIMExist.base64 || null);
            }

            if (dataStep2?.selectedImageKHSExist) {
                const fileSelectedImageKHSExist = Base64ToFile(dataStep2.selectedImageKHSExist.base64, dataStep2.selectedImageKHSExist.name);
                setSelectedImageKHSExist(fileSelectedImageKHSExist);
                setImageUrlKHSExist(dataStep2.selectedImageKHSExist.base64 || null);
            }

            if (dataStep2?.selectedImageKRSExist) {
                const fileSelectedImageKRSExist = Base64ToFile(dataStep2.selectedImageKRSExist.base64, dataStep2.selectedImageKRSExist.name);
                setSelectedImageKRSExist(fileSelectedImageKRSExist);
                setImageUrlKRSExist(dataStep2.selectedImageKRSExist.base64 || null);
            }

        }




        // STEP 3
        if (dataStep3) {
            setDataStep3Exist({
                answer1Exist: dataStep3.answer1Exist || '',
                answer2Exist: dataStep3.answer2Exist || '',
                selectedUniversityExist: dataStep3.selectedUniversityExist || '',
                userLocationExist: dataStep3.userLocation || '',
            });

            setCheckboxValuesExist({
                checkedExist: dataStep3.checkboxValues?.checked || false,
            });

            if (dataStep3.selectedImageTTDExist) {
                const fileSelectedImageTTD = Base64ToFile(dataStep3.selectedImageTTDExist.base64, dataStep3.selectedImageTTDExist.name);
                setSelectedImageUrlTTDExist(dataStep3.selectedImageTTDExist.base64 || null);
                setSelectedImageTTDExist(fileSelectedImageTTD);
            }
        }
    }


    useEffect(() => {
        if (data) {
            const { dataStep1, dataStep2, dataStep3, emailVerifData } = JSON.parse(data)
            if (dataStep1 || dataStep2 || dataStep3 || emailVerifData) {
                getAlreadyExistData(dataStep1, dataStep2, dataStep3, emailVerifData)
            }
        }
    }, [data]);



    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true)

        // const selectedImageRaportX = selectedImageRaportXExist ? await FileToBase64(selectedImageRaportXExist) : null;
        // const selectedImageRaportXI = selectedImageRaportXIExist ? await FileToBase64(selectedImageRaportXIExist) : null;
        // const selectedImageRaportXII = selectedImageRaportXIIExist ? await FileToBase64(selectedImageRaportXIIExist) : null;
        // const selectedImageIjazahSMA = selectedImageIjazahSMAExist ? await FileToBase64(selectedImageIjazahSMAExist) : null;
        // const selectedCertificate1 = selectedCertificate1Exist ? await FileToBase64(selectedCertificate1Exist) : null;
        // const selectedCertificate2 = selectedCertificate2Exist ? await FileToBase64(selectedCertificate2Exist) : null;
        // const selectedCertificate3 = selectedCertificate3Exist ? await FileToBase64(selectedCertificate3Exist) : null;
        // const selectedToelf = selectedToelfExist ? await FileToBase64(selectedToelfExist) : null;
        // const selectedImageTTD = selectedImageTTDExist ? await FileToBase64(selectedImageTTDExist) : null;

        // console.log('userid', dataStep1Exist.userIdExist);

        const formData = new FormData();

        if (dataStep2Exist.selectedCategoryExist === 'Beasiswa Berprestasi') {
            // console.log('Beasiswa Berprestasi');
            if (dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 1(S1)') {
                // console.log('Strata 1(S1)');
                formData.append('agreement', checkboxValuesExist.checkedExist);
                formData.append('answer_1', dataStep3Exist.answer1Exist);
                formData.append('answer_2', dataStep2Exist.answer2Exist);
                formData.append('user_id', dataStep1Exist.userIdExist);
                formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
                formData.append('status', 'pending');
                formData.append('category', dataStep2Exist.selectedCategoryExist);
                formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
                // formData.append('location', 'Dalam Negeri');
                formData.append('rapor_class_x', selectedImageRaportXExist);
                formData.append('rapor_class_xi', selectedImageRaportXIExist);
                formData.append('rapor_class_xii', selectedImageRaportXIIExist);
                formData.append('scan_ijazah_sma', selectedImageIjazahSMAExist);
                formData.append('scan_sertifikat_1', selectedCertificate1Exist);
                formData.append('scan_sertifikat_2', selectedCertificate2Exist);
                formData.append('scan_sertifikat_3', selectedCertificate3Exist);
                formData.append('scan_toefl', selectedToelfExist);
                formData.append('location', dataStep3Exist.userLocationExist);
                formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
                formData.append('signature', selectedImageTTDExist);
                // console.log('location', userLocation);
                // formData.append('avg_rapor_sma', nilaiSMA);
            } else if (dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 2(S2)') {

                formData.append('agreement', checkboxValuesExist.checkedExist);
                formData.append('answer_1', dataStep3Exist.answer1Exist);
                formData.append('answer_2', dataStep2Exist.answer2Exist);
                formData.append('user_id', dataStep1Exist.userIdExist);
                formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
                formData.append('status', 'pending');
                formData.append('category', dataStep2Exist.selectedCategoryExist);
                formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
                // formData.append('location', 'Dalam Negeri');
                formData.append('scan_ijazah_sma', selectedImageIjazahSMAExist);
                formData.append('scan_ijazah_s1', selectedImageIjazahS1Exist);
                formData.append('scan_transkip_s1', selectedImageTranskripNilaiS1Exist);
                formData.append('avg_s1 ', dataStep2Exist.nilaiRata2S1Exist);
                formData.append('gpa_s1', dataStep2Exist.nilaiGpaS1Exist);;
                formData.append('scan_ijazah_sma', selectedImageIjazahSMAExist);
                formData.append('scan_sertifikat_1', selectedCertificate1Exist);
                formData.append('scan_sertifikat_2', selectedCertificate2Exist);
                formData.append('scan_sertifikat_3', selectedCertificate3Exist);
                formData.append('scan_toefl', selectedToelfExist);
                formData.append('location', dataStep3Exist.userLocationExist);
                formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
                formData.append('signature', selectedImageTTDExist);
            } else if (dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 3(S3)') {
                // console.log('Strata 3(S3)');
                formData.append('agreement', checkboxValuesExist.checkedExist);
                formData.append('answer_1', dataStep3Exist.answer1Exist);
                formData.append('answer_2', dataStep2Exist.answer2Exist);
                formData.append('user_id', dataStep1Exist.userIdExist);
                formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
                formData.append('status', 'pending');
                formData.append('category', dataStep2Exist.selectedCategoryExist);
                formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
                // formData.append('location', 'Dalam Negeri');
                formData.append('scan_ijazah_sma', selectedImageIjazahSMAExist);
                formData.append('scan_ijazah_s1', selectedImageIjazahS1Exist);
                formData.append('scan_transkip_s1', selectedImageTranskripNilaiS1Exist);
                formData.append('avg_s1 ', dataStep2Exist.nilaiRata2S1Exist);
                formData.append('gpa_s1', dataStep2Exist.nilaiGpaS1Exist);;
                formData.append('avg_s2 ', dataStep2Exist.nilaiRata2S2Exist);
                formData.append('gpa_s2', dataStep2Exist.nilaiGpaS2Exist);
                formData.append('scan_ijazah_s2', selectedImageIjazahS2Exist);
                formData.append('scan_transkip_s2', selectedImageTranskripNilaiS2Exist);
                formData.append('scan_sertifikat_1', selectedCertificate1Exist);
                formData.append('scan_sertifikat_2', selectedCertificate2Exist);
                formData.append('scan_sertifikat_3', selectedCertificate3Exist);
                formData.append('scan_toefl', selectedToelfExist);
                formData.append('location', dataStep3Exist.userLocationExist);
                formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
                formData.append('signature', selectedImageTTDExist);
            }

        } else if (dataStep2Exist.selectedJenjangPendidikanExist === 'Beasiswa Keluarga Miskin') {
            formData.append('agreement', checkboxValuesExist.checkedExist);
            formData.append('answer_1', dataStep3Exist.answer1Exist);
            formData.append('answer_2', dataStep2Exist.answer2Exist);
            formData.append('user_id', dataStep1Exist.userIdExist);
            formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
            formData.append('category', dataStep2Exist.selectedCategoryExist);
            formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
            // formData.append('location', 'Dalam Negeri');
            formData.append('scan_skkd', selectedImageBKMExist);
            formData.append('scan_kis', selectedImageBKMKISExist);
            formData.append('foto_house_1', selectedImageHouse1Exist);
            formData.append('foto_house_2', selectedImageHouse2Exist);
            formData.append('foto_house_3', selectedImageHouse3Exist);
            formData.append('avg_rapor_sma', null);
            formData.append('avg_ijazah_s1', null);
            formData.append('education_level', 'Strata 1(S1)');
            formData.append('rapor_class_x', selectedImageRaportXExist);
            formData.append('rapor_class_xi', selectedImageRaportXIExist);
            formData.append('rapor_class_xii', selectedImageRaportXIExist);
            formData.append('scan_ijazah_sma', selectedImageIjazahSMAExist);
            formData.append('scan_transkip_sma', selectedImageTranskripNilaiSMAExist);
            formData.append('location', dataStep3Exist.userLocationExist);
            formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
            formData.append('signature', selectedImageTTDExist);
        } else if (dataStep2Exist.selectedCategoryExist === 'Ikatan Dinas') {
            // console.log('Ikatan Dinas');
            formData.append('agreement', checkboxValuesExist.checkedExist);
            formData.append('answer_1', dataStep3Exist.answer1Exist);
            formData.append('answer_2', dataStep2Exist.answer2Exist);
            // formData.append('user_id', user_id);
            // formData.append('personal_information_id', personal_information_id);
            formData.append('status', 'pending');
            formData.append('category', dataStep2Exist.dataStep2Exist.selectedJenjangPendidikanExistExist);
            formData.append('education_level', 'Strata 1(S1)');
            // formData.append('location', 'Dalam Negeri');
            formData.append('rapor_class_x', selectedImageRaportXExist);
            formData.append('rapor_class_xi', selectedImageRaportXIIExist);
            formData.append('rapor_class_xii', selectedImageRaportXIIExist);
            formData.append('scan_ijazah_sma', selectedImageIjazahS1Exist);
            formData.append('scan_sertifikat_1', selectedCertificate1Exist);
            formData.append('scan_sertifikat_2', selectedCertificate2Exist);
            formData.append('scan_sertifikat_3', selectedCertificate3Exist);
            formData.append('scan_toefl', selectedToelfExist);
            formData.append('selected_majors_1', dataStep3Exist.jurusan1Exist);
            formData.append('selected_majors_2', dataStep3Exist.jurusan2Exist);
            formData.append('selected_majors_3', dataStep3Exist.jurusan3Exist);
            formData.append('signature', selectedImageTTDExist);
            formData.append('location', dataStep3Exist.userLocationExist);
            formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
        } else if (dataStep2Exist.selectedCategoryExist === 'Bantuan Studi Akhir') {
            // console.log('Bantuan Studi Akhir');
            if (dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 1(S1)') {
                formData.append('agreement', checkboxValuesExist.checkedExist);
                formData.append('answer_1', dataStep3Exist.answer1Exist);
                formData.append('answer_2', dataStep2Exist.answer2Exist);
                formData.append('user_id', dataStep1Exist.userIdExist);
                formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
                formData.append('category', dataStep2Exist.selectedCategoryExist);
                formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
                formData.append('location', 'Dalam Neg  eri');
                formData.append('link_pddikti', dataStep2Exist.buktiAktifKuliahExist);
                formData.append('student_id_number', dataStep2Exist.NIMExist);
                formData.append('student_id_number_scan', selectedImageNIMExist);
                formData.append('proposal_title', dataStep2Exist.judulProposalExist);
                formData.append('kkn_location', dataStep2Exist.lokasiKKNExist);
                formData.append('research_title', dataStep2Exist.judulPenelitianExist);
                formData.append('location', dataStep3Exist.userLocationExist);
                formData.append('signature', selectedImageTTDExist);
                formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
            } else if (dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 2(S2)') {
                // console.log('Strata 2(S2)');
                formData.append('agreement', checkboxValuesExist.checkedExist);
                formData.append('answer_1', dataStep3Exist.answer1Exist);
                formData.append('answer_2', dataStep2Exist.answer2Exist);
                formData.append('user_id', dataStep1Exist.userIdExist);
                formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
                formData.append('category', dataStep2Exist.selectedCategoryExist);
                formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
                // formData.append('location', 'Dalam Negeri');
                formData.append('link_pddikti', dataStep2Exist.buktiAktifKuliahExist);
                formData.append('student_id_number', dataStep2Exist.NIMExist);
                formData.append('student_id_number_scan', selectedImageNIMExist);
                formData.append('scan_krs', selectedImageKRSExist);
                formData.append('scan_khs', selectedImageKHSExist);
                formData.append('signature', selectedImageTTDExist);
                formData.append('location', dataStep3Exist.userLocationExist);
                formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
            } else if (dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 3(S3)') {
                formData.append('agreement', checkboxValuesExist.checkedExist);
                formData.append('answer_1', dataStep3Exist.answer1Exist);
                formData.append('answer_2', dataStep2Exist.answer2Exist);
                formData.append('user_id', dataStep1Exist.userIdExist);
                formData.append('personal_information_id', dataStep1Exist.userPersonalInformationId);
                formData.append('category', dataStep2Exist.selectedCategoryExist);
                formData.append('education_level', dataStep2Exist.selectedJenjangPendidikanExist);
                // formData.append('location', 'Dalam Negeri');
                formData.append('link_pddikti', dataStep2Exist.buktiAktifKuliahExist);
                formData.append('location', dataStep3Exist.userLocationExist);
                formData.append('name_of_college', dataStep3Exist.selectedUniversityExist);
                formData.append('signature', selectedImageTTDExist);
            }
        }

        // Mencetak nilai FormData menggunakan console.log
        // for (let pair of formData.entries()) {
        //     console.log(pair[0] + ': ' + pair[1]);
        // }


        try {
            const response = await axios.post(RegistrasiTahapII_and_TahapIII, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status >= 200 && response.status < 300) {
                // console.log('RegistrasiTahapII_and_TahapIII',response.data);

                SuccessAlert({
                    message: 'Berhasil Menyimpan Data',
                    position: 'top-center'
                });

            
                setTimeout(() => {
                    notificationStatus()
                    localStorage.removeItem('item');
                    localStorage.removeItem('data');
                    setIsSubmitting(false)
                }, 2500);
            } else {
                // Handle non-successful response status
                console.error(`Unexpected response status: ${response.status}`);
                setIsSubmitting(false)
            }
        } catch (error) {
            console.error(error);
            // ErrorAlert({
            //     message: error.response?.data?.messages,
            //     position:'top-center'
            // })
            setIsSubmitting(false);
        }

    }

    const handleBack = (tahap) => {

        if (tahap === 'tahap 1') {
            setIsLoadingBackTahapI(true)
            // Ambil data yang sudah ada dari localStorage
            const existingData = JSON.parse(localStorage.getItem('data')) || {};

            // Tambahkan data baru ke dalam objek existingData
            existingData.cekData = 'Ya';
            existingData.bypass = 'tahap1';

            // Simpan kembali objek yang telah diperbarui ke dalam localStorage
            localStorage.setItem('data', JSON.stringify(existingData));

            setTimeout(() => {
                backStep1()
                setIsLoadingBackTahapI(false)
            }, 1500);
        } else {
            setIsLoadingBackTahapII(true)

            // Ambil data yang sudah ada dari localStorage
            const existingData = JSON.parse(localStorage.getItem('data')) || {};

            // Tambahkan data baru ke dalam objek existingData
            existingData.cekData = 'Ya';
            existingData.bypass = 'tahap2';

            // Simpan kembali objek yang telah diperbarui ke dalam localStorage
            localStorage.setItem('data', JSON.stringify(existingData));


            setTimeout(() => {
                backStep2()
                setIsLoadingBackTahapII(false)
            }, 1500);
        }

    }


    return (
        <Modal
            open={open}
            onClose={close}
        >
            <Box sx={style}>
                <Box
                    sx={{
                        // overflow:'hidden',
                        padding: 1.5
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: isMobile ? '12px' : '13px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                        }}
                    >
                        Pastikan data Anda sudah benar.
                    </Typography>
                </Box>
                <Box
                    sx={{
                        width: '100%'
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: `2px solid ${DividerColor}`,
                            borderBottomWidth: 1,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: 1.5
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '10px',
                                background: 'rgba(242, 201, 76, 0.20)',
                                borderRadius: '8px',
                                padding: '8px 10px',
                                // marginTop: '16px',
                                // maxWidth: '80px',
                                // width: '100%'
                            }}
                        >
                            {/* <Icon icon='el:file-edit-alt' style={{ fontSize: '20px', color: TextGray }} /> */}
                            <Typography
                                sx={{
                                    color: TextGray,
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Data Tahap I
                            </Typography>
                            <Button
                                onClick={() => handleBack('tahap 1')}
                                variant='text'
                                sx={{
                                    textTransform: 'none',
                                    padding: '0px',
                                    cursor: 'pointer',
                                }}
                                disabled={isLoadingBackTahapI || isLoadingBackTahapII ? true : false}
                            >
                                <Typography
                                    sx={{
                                        color: mainColor,
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textDecoration: 'underline'
                                    }}
                                >
                                    {isLoadingBackTahapI ? <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px' }} /> : 'Ubah Data'}
                                </Typography>
                            </Button>
                        </Box>

                        <Grid container mt='12px' m='10px'>
                            <Grid item xs={12} md={12}>
                                <FormProvider>
                                    <Tahap1
                                        isMobile={isMobile}
                                        isTablet={isTablet}
                                        selectedImageNIKExist={selectedImageNIKExist}
                                        selectedImageKKExist={selectedImageKKExist}
                                        selectedImageNIKUrlExist={selectedImageNIKUrlExist}
                                        selectedImageKKUrlExist={selectedImageKKUrlExist}
                                        dataStep1Exist={dataStep1Exist}
                                    />
                                </FormProvider>
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Tahap II */}
                    <Box
                        sx={{
                            padding: 1.5
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: '10px',
                                background: 'rgba(242, 201, 76, 0.20)',
                                borderRadius: '8px',
                                padding: '8px 10px',
                                // marginTop: '16px',
                                // maxWidth: '80px',
                                // width: '100%'
                            }}
                        >
                            {/* <Icon icon='el:file-edit-alt' style={{ fontSize: '20px', color: TextGray }} /> */}
                            <Typography
                                sx={{
                                    color: TextGray,
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Data Tahap II
                            </Typography>
                            <Button
                                onClick={() => handleBack('tahap 2')}
                                variant='text'
                                sx={{
                                    textTransform: 'none',
                                    padding: '0px',
                                    cursor: 'pointer',
                                }}
                                disabled={isLoadingBackTahapII || isLoadingBackTahapI ? true : false}
                            >
                                <Typography
                                    sx={{
                                        color: mainColor,
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '600',
                                        lineHeight: 'normal',
                                        textDecoration: 'underline'
                                    }}
                                >
                                    {isLoadingBackTahapII ? <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px' }} /> : 'Ubah Data'}
                                </Typography>
                            </Button>
                        </Box>
                        <Grid container mt='12px' m='10px'>
                            <Grid item xs={12} md={12}>
                                {dataStep2Exist.selectedCategoryExist === 'Beasiswa Berprestasi' ?
                                    dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 1(S1)' ?
                                        <Tahap2
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            selectedCertificate1Exist={selectedCertificate1Exist}
                                            selectedCertificate1UrlExist={selectedCertificate1UrlExist}
                                            selectedCertificate2Exist={selectedCertificate2Exist}
                                            selectedCertificate2UrlExist={selectedCertificate2UrlExist}
                                            selectedCertificate3Exist={selectedCertificate3Exist}
                                            selectedCertificate3UrlExist={selectedCertificate3UrlExist}
                                            selectedImageIjazahSMAExist={selectedImageIjazahSMAExist}
                                            selectedImageIjazahSMAUrlExist={selectedImageIjazahSMAUrlExist}
                                            selectedImageRaportXExist={selectedImageRaportXExist}
                                            selectedImageUrlRaportXExist={selectedImageUrlRaportXExist}
                                            selectedImageRaportXIExist={selectedImageRaportXIExist}
                                            selectedImageUrlRaportXIExist={selectedImageUrlRaportXIExist}
                                            selectedImageRaportXIIExist={selectedImageRaportXIIExist}
                                            selectedImageUrlRaportXIIExist={selectedImageUrlRaportXIIExist}
                                            selectedToelfExist={selectedToelfExist}
                                            selectedToelfUrlExist={selectedToelfUrlExist}
                                            dataStep2Exist={dataStep2Exist}
                                        />
                                        :
                                        dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 2(S2)' ?
                                            <Tahap2BeasiswaBerprestasiS2
                                                isMobile={isMobile}
                                                isTablet={isTablet}
                                                selectedCertificate1Exist={selectedCertificate1Exist}
                                                selectedCertificate2Exist={selectedCertificate2Exist}
                                                selectedCertificate3Exist={selectedCertificate3Exist}
                                                selectedImageIjazahSMAExist={selectedImageIjazahSMAExist}
                                                selectedImageIjazahS1Exist={selectedImageIjazahS1Exist}
                                                selectedImageTranskripNilaiS1Exist={selectedImageTranskripNilaiS1Exist}
                                                selectedToelfExist={selectedToelfExist}
                                                dataStep2Exist={dataStep2Exist}
                                            />
                                            :
                                            dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 3(S3)' ?
                                                <Tahap2BeasiswaBerprestasiS3
                                                    isMobile={isMobile}
                                                    isTablet={isTablet}
                                                    selectedCertificate1Exist={selectedCertificate1Exist}
                                                    selectedCertificate2Exist={selectedCertificate2Exist}
                                                    selectedCertificate3Exist={selectedCertificate3Exist}
                                                    selectedImageIjazahSMAExist={selectedImageIjazahSMAExist}
                                                    selectedImageIjazahS1Exist={selectedImageIjazahS1Exist}
                                                    selectedImageIjazahS2Exist={selectedImageIjazahS2Exist}
                                                    selectedImageTranskripNilaiS1Exist={selectedImageTranskripNilaiS1Exist}
                                                    selectedImageTranskripNilaiS2Exist={selectedImageTranskripNilaiS2Exist}
                                                    selectedToelfExist={selectedToelfExist}
                                                    dataStep2Exist={dataStep2Exist}
                                                />
                                                :
                                                undefined
                                    :
                                    dataStep2Exist.selectedCategoryExist === 'Beasiswa Keluarga Miskin' ?
                                        <Tahap2BeasiswaKeluargaMiskin
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                            dataStep2Exist={dataStep2Exist}
                                            selectedImageBKM={selectedImageBKMExist}
                                            selectedImageBKMKIS={selectedImageBKMKISExist}
                                            selectedImageX={selectedImageRaportXExist}
                                            selectedImageXI={selectedImageRaportXIExist}
                                            selectedImageXII={selectedImageRaportXIIExist}
                                            selectedImageHouse1={selectedImageHouse1Exist}
                                            selectedImageHouse2={selectedImageHouse2Exist}
                                            selectedImageHouse3={selectedImageHouse3Exist}
                                            selectedImageIjazahSMA={selectedImageIjazahSMAExist}
                                            selectedImageTranskripNilaiSMA={selectedImageTranskripNilaiSMAExist}
                                        />
                                        :
                                        dataStep2Exist.selectedCategoryExist === 'Ikatan Dinas' ?
                                            <Tahap2IkatanDinas
                                                isMobile={isMobile}
                                                isTablet={isTablet}
                                                jurusan1={dataStep2Exist.jurusan1Exist}
                                                jurusan2={dataStep2Exist.jurusan2Exist}
                                                jurusan3={dataStep2Exist.jurusan3Exist}
                                                kategoriJurusan={dataStep2Exist.kategoriJurusanExist}
                                                selectedCertificate1Exist={selectedCertificate1Exist}
                                                selectedCertificate2Exist={selectedCertificate2Exist}
                                                selectedCertificate3Exist={selectedCertificate3Exist}
                                                selectedImageIjazahSMAExist={selectedImageIjazahSMAExist}
                                                selectedImageRaportXExist={selectedImageRaportXExist}
                                                selectedImageRaportXIExist={selectedImageRaportXIExist}
                                                selectedImageRaportXIIExist={selectedImageRaportXIIExist}
                                                selectedToelfExist={selectedToelfExist}
                                                dataStep2Exist={dataStep2Exist}
                                            />
                                            :
                                            dataStep2Exist.selectedCategoryExist === 'Bantuan Studi Akhir' ?
                                                dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 1(S1)' ?
                                                    <Tahap2BantuanStudiAkhirS1
                                                        isMobile={isMobile}
                                                        isTablet={isTablet}
                                                        dataStep2Exist={dataStep2Exist}
                                                        selectedImageNIMExist={selectedImageNIMExist}
                                                    />
                                                    :
                                                    dataStep2Exist.selectedJenjangPendidikanExist === 'Strata 2(S2)' ?
                                                        <Tahap2BantuanStudiAkhirS2
                                                            isMobile={isMobile}
                                                            isTablet={isTablet}
                                                            dataStep2Exist={dataStep2Exist}
                                                            selectedImageNIMExist={selectedImageNIMExist}
                                                            selectedImageKHSExist={selectedImageKHSExist}
                                                            selectedImageKRSExist={selectedImageKRSExist}
                                                        />
                                                        :
                                                        <Tahap2BantuanStudiAkhirS3
                                                            isMobile={isMobile}
                                                            isTablet={isTablet}
                                                            dataStep2Exist={dataStep2Exist}
                                                        />
                                                :
                                                undefined
                                }
                            </Grid>

                        </Grid>
                    </Box>

                    <Box
                        sx={{
                            width: '100%'
                        }}
                    >
                        <Divider
                            variant="string"
                            style={{
                                backgroundColor: `2px solid ${DividerColor}`,
                                borderBottomWidth: 1,
                                // marginTop: "2px",
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            padding: 1.5
                        }}
                    >
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            size="small"
                            sx={{
                                backgroundColor: btnBgColor,
                                color: Dark,
                                textTransform: 'none',
                                // width: '130px',
                                // fontWeight: 'bold',
                                '&:hover': {
                                    // color: 'white', // Change text color to white on hover
                                    backgroundColor: btnBgColor,
                                }
                            }}
                        // disabled={isSubmitting ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                <Typography
                                    sx={{
                                        color: mainColor,
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        // fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        // lineHeight: 'normal',
                                    }}
                                >
                                    Simpan Data
                                </Typography>
                            }
                        </Button>
                    </Box>
                </form>
            </Box >
        </Modal >
    )
};

export default VerificationDataModal;
