import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, TextField, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import { useFormContext } from '../../../pages/Registrasi/FormContext';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import TooltipUploadGambar from '../../../assets/tooltip/uploadgambarkelas_x.png'
import CustomTooltip from '../../Modal/Tooltip/CustomTooltip';
import { decrypt } from 'n-krypta';
import { secretKey } from '../../Config/SecretKey';
import FileToBase64 from "../../FileToBase64/FileToBase64";
import Base64ToFile from '../../Base64ToFile/Base64ToFile';


const KuisionerS2 = ({ isMobile, isTablet, step3, selectedCategory, selectedJenjangPendidikan }) => {

  const { updateFormData } = useFormContext();
  const [newData, setNewData] = useState({
    buktiAktifKuliah: '',
    NIM: ''
  })
  const [loadingSimpanData, setLoadingSimpanData] = useState(false)

  const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

  const [selectedImageNIM, setSelectedImageNIM] = useState(null);
  const [imageUrlNIM, setImageUrlNIM] = useState(null);
  const fileInputRefNIM = useRef(null);

  const [selectedImageKHS, setSelectedImageKHS] = useState(null);
  const [imageUrlKHS, setImageUrlKHS] = useState(null);
  const fileInputRefKHS = useRef(null);

  const [selectedImageKRS, setSelectedImageKRS] = useState(null);
  const [imageUrlKRS, setImageUrlKRS] = useState(null);
  const fileInputRefKRS = useRef(null);

  const steps = [
    'Akses website PDDikti di',
    'Pada kolom pencarian masukkan nama lengkap anda.',
    'Setelah hasil pencarian muncul silakan pilih nama anda dan universitas yang sesuai dengan tempat anda menempuh pendidikan saat ini.',
    'Setelah diarahkan ke halaman detail mahasiswa, copy URL/link pada address bar browser yang anda gunakan.',
    'Paste URL/link yang ada copy ke isian di atas.',
  ];

  const [isDragging, setIsDragging] = useState({
    isDraggingNIM: false,
    isDraggingKHS: false,
    isDraggingKRS: false,
  });

  const data = localStorage.getItem('data')

  const getAlreadyExistData = (decryptedString) => {

    setNewData({
      buktiAktifKuliah: decryptedString.buktiAktifKuliahExist,
      NIM: decryptedString.NIMExist,
    })

    if (decryptedString?.selectedImageNIMExist) {
      const fileSelectedImageNIMExist = Base64ToFile(decryptedString.selectedImageNIMExist.base64, decryptedString.selectedImageNIMExist.name);
      setSelectedImageNIM(fileSelectedImageNIMExist);
      setImageUrlNIM(decryptedString.selectedImageNIMExist.base64 || null);
    }


    if (decryptedString?.selectedImageKHSExist) {
      const fileSelectedImageKHSExist = Base64ToFile(decryptedString.selectedImageKHSExist.base64, decryptedString.selectedImageKHSExist.name);
      setSelectedImageKHS(fileSelectedImageKHSExist);
      setImageUrlKHS(decryptedString.selectedImageKHSExist.base64 || null);
    }

    if (decryptedString?.selectedImageKRSExist) {
      const fileSelectedImageKRSExist = Base64ToFile(decryptedString.selectedImageKRSExist.base64, decryptedString.selectedImageKRSExist.name);
      setSelectedImageKRS(fileSelectedImageKRSExist);
      setImageUrlKRS(decryptedString.selectedImageKRSExist.base64 || null);
    }

  }

  useEffect(() => {
    if (data) {
      const { dataStep2, cekData } = JSON.parse(data)
      // console.log('key', secretKey);
      if (dataStep2) {
        // const decryptedString = decrypt(dataStep2, secretKey)
        console.log('dataStep2', dataStep2);
        if (cekData === 'Ya' && dataStep2) {
          getAlreadyExistData(dataStep2)
        }
      }
    }
  }, [data]);

  const handleImageUpload = (fileInput) => {
    fileInput.current.click();
  };

  const handleRemoveImage = (removeImage) => {
    if (removeImage === 'nim') {
      setSelectedImageNIM(null);
      setImageUrlNIM(null);
    } else if (removeImage === 'khs') {
      setSelectedImageKHS(null)
      setImageUrlKHS(null);
    } else if (removeImage === 'krs') {
      setSelectedImageKRS(null)
      setImageUrlKRS(null);
    }
  };

  const handleDragOver = (event, option) => {
    event.preventDefault();
    // console.log('handleDragOver', event);
    if (option === 'nim') {
      setIsDragging({
        isDraggingNIM: true
      })
    } else if (option === 'khs') {
      setIsDragging({
        isDraggingKHS: true
      })
    } else if (option === 'krs') {
      setIsDragging({
        isDraggingKRS: true
      })
    }
  };

  const handleDrop = (event, option) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    // console.log('handleDrop', file);
    handleFileInputChange(file, option);
    if (option === 'nim') {
      setIsDragging({
        isDraggingNIM: false
      })
    } else if (option === 'khs') {
      setIsDragging({
        isDraggingKHS: false
      })
    } else if (option === 'krs') {
      setIsDragging({
        isDraggingKRS: false
      })
    }
  };

  const handleDragLeave = () => {
    setIsDragging({
      isDraggingNIM: false,
      isDraggingKHS: false,
      isDraggingKRS: false,
    }) // Set state menjadi false saat dragleave
  };

  const handleFileInputChange = (file, fileInputChange) => {
    // const file = event.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
      if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
        ErrorAlert({
          message: 'Ukuran Maksimal Foto/Pdf 1 MB',
          position: 'top-center'
        })
        // // Optionally, clear the file input
        // event.target.value = null;
        // return;
      } else {
        // Check the file type
        const fileType = file.type;
        const allowedImageTypes = ['image/jpeg', 'image/png', 'image/gif'];
        const allowedPdfTypes = ['application/pdf'];

        if (allowedImageTypes.includes(fileType)) {
          // Handle Image File
          if (fileInputChange === 'nim') {
            setSelectedImageNIM(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlNIM(imageUrl);
          } else if (fileInputChange === 'khs') {
            setSelectedImageKHS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKHS(imageUrl);
          } else if (fileInputChange === 'krs') {
            setSelectedImageKRS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKRS(imageUrl);
          }
        } else if (allowedPdfTypes.includes(fileType)) {
          // Handle PDF file
          if (fileInputChange === 'nim') {
            setSelectedImageNIM(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlNIM(imageUrl);
          } else if (fileInputChange === 'khs') {
            setSelectedImageKHS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKHS(imageUrl);
          } else if (fileInputChange === 'krs') {
            setSelectedImageKRS(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrlKRS(imageUrl);
          }
        } else {
          // Unsupported file type
          console.error('Unsupported file type');
          ErrorAlert({
            message: 'Hanya menerima file jpeg, png, gif dan pdf.',
            position: 'top-center'
          })
          return;
        }
      }


    }
  };

  const handleShowPDF = (fileName) => {

    return (
      <>
        <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
        <Typography
          sx={{
            fontFamily: 'Plus Jakarta Sans',
            color: mainColor,
            fontSize: '11px',
            fonStyle: 'normal',
            fontWeight: 'bold',
            lineHeight: 'normal',
            cursor: 'pointer',
            textAlign: 'center',
            marginTop: '-10px'
          }}
        >
          {fileName}
        </Typography>
      </>
    )
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData(prev => {
      return { ...prev, [name]: value };
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoadingSimpanData(true)

    const buktiAktifKuliah = newData.buktiAktifKuliah
    const NIM = newData.NIM

    if (buktiAktifKuliah === '') {
      ErrorAlert({
        message: 'Url/Link PDDikti wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
    } else if (selectedImageNIM === null) {
      ErrorAlert({
        message: 'Silahkan Upload File Gambar/PDF Nomor Induk Mahasiswa.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
    } else if (selectedImageKHS === null) {
      ErrorAlert({
        message: 'Silahkan Upload File Gambar/PDF Kartu Hasil Studi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
    } else if (selectedImageKRS === null) {
      ErrorAlert({
        message: 'Silahkan Upload File Gambar/PDF Kartu Rencana Studi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
    } else {

      const selectedImageNIMExistBase64 = selectedImageNIM ? await FileToBase64(selectedImageNIM) : null;
      const selectedImageKHSExistBase64 = selectedImageKHS ? await FileToBase64(selectedImageKHS) : null;
      const selectedImageKRSExistBase64 = selectedImageKRS ? await FileToBase64(selectedImageKRS) : null;


      let values = {
        selectedCategoryExist: selectedCategory,
        selectedJenjangPendidikanExist: selectedJenjangPendidikan,
        buktiAktifKuliahExist: buktiAktifKuliah,
        selectedImageKRSExist: selectedImageKRS,
        selectedImageKHSExist: selectedImageKHS,
        NIMExist: NIM,
        selectedImageNIMExist: {
          lastModified: selectedImageNIM.lastModified,
          lastModifiedDate: selectedImageNIM.lastModifiedDate,
          name: selectedImageNIM.name,
          type: selectedImageNIM.type,
          size: selectedImageNIM.size,
          webkitRelativePath: selectedImageNIM.webkitRelativePath,
          url: selectedImageNIM ? URL.createObjectURL(selectedImageNIM) : null,
          base64: selectedImageNIMExistBase64
        },
        selectedImageKHSExist: {
          lastModified: selectedImageKHS.lastModified,
          lastModifiedDate: selectedImageKHS.lastModifiedDate,
          name: selectedImageKHS.name,
          type: selectedImageKHS.type,
          size: selectedImageKHS.size,
          webkitRelativePath: selectedImageKHS.webkitRelativePath,
          url: selectedImageKHS ? URL.createObjectURL(selectedImageKHS) : null,
          base64: selectedImageKHSExistBase64
        },
        selectedImageKRSExist: {
          lastModified: selectedImageKRS.lastModified,
          lastModifiedDate: selectedImageKRS.lastModifiedDate,
          name: selectedImageKRS.name,
          type: selectedImageKRS.type,
          size: selectedImageKRS.size,
          webkitRelativePath: selectedImageKRS.webkitRelativePath,
          url: selectedImageKRS ? URL.createObjectURL(selectedImageKRS) : null,
          base64: selectedImageKRSExistBase64
        },
      };

      // Ambil data yang sudah ada dari localStorage
      const existingData = JSON.parse(localStorage.getItem('data')) || {};

      // Tambahkan data baru ke dalam objek existingData
      existingData.dataStep2 = values;

      // Simpan kembali objek yang telah diperbarui ke dalam localStorage
      localStorage.setItem('data', JSON.stringify(existingData));


      setTimeout(() => {
        step3()
        setLoadingSimpanData(false)
      }, 2500);
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>

        {/* Textfield NIM */}
        <Grid item xs={12} sm={12} mb='15px'>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            label="Masukkan Nomor Induk Mahasiswa"
            // placeholder='Contoh : 2.75'
            value={newData.NIM}
            name="NIM"
            onChange={(e) => handleChange(e)}
            // error={newData.NIM === '' ? undefined : !!errorGpaS1}
            // helperText={errorGpaS1}
            onInput={(e) => {
              // Filter out non-numeric characters
              e.target.value = e.target.value.replace(/[^0-9]/g, '');
            }}
            autoComplete="off"
            required
            sx={{
              width: isMobile ? '100%' : '40%',
              maxWidth: '40%',
              minWidth: isMobile ? '100%' : '40px',
              fontSize: isMobile ? '14px' : '13px',
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "white",
              },
              "& .MuiFilledInput-root.Mui-disabled:hover": {
                "& fieldset": {
                  borderBottomColor: mainColor,
                },
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiInputLabel-root": {
                color: '#B3B3D4',
                fontWeight: 'bold',
                fontSize: '13px',
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: mainColor,
              },
              "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:hover:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:hover:after": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:after": {
                borderBottomColor: mainColor,
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
          />
        </Grid>

        {/* Gambar NIM */}
        <Grid item xs={12} sm={12} mb='15px'>
          <Typography
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'justify',
              marginBottom: '10px',
              // marginTop: '34px'
            }}
          >
            Gambar Scan/Foto atau PDF Nomor Induk Mahasiswa (Maks 1 MB)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                maxWidth: '486px',
                minWidth: '40%',
                width: isMobile ? '100%' : '40%',
                height: '190px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                position: 'relative',
                border: `solid 2px ${DividerColor}`,
                borderStyle: 'dashed',
              }}
              onDragOver={(e) => handleDragOver(e, 'nim')}
              onDrop={(e) => handleDrop(e, 'nim')}
              onDragLeave={handleDragLeave}
            >
              {isDragging.isDraggingNIM && !isMobile ?
                <Typography
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '17,64px',
                    textAlign: 'center',
                    color: mainColor
                  }}
                >
                  Lepaskan untuk mengunggah
                </Typography>
                :
                selectedImageNIM && selectedImageNIM.type === 'application/pdf' ? (
                  // Handle PDF display logic
                  <>
                    {handleShowPDF(selectedImageNIM.name)}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('nim')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : imageUrlNIM ? (
                  // Handle image display logic
                  <>
                    <img
                      src={imageUrlNIM}
                      alt="selected-image"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('nim')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : (
                  // Display upload options
                  <>
                    {!isMobile ?
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            textAlign: 'center'
                          }}
                        >
                          {imageUrlNIM ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            color: TextGray
                          }}
                        >
                          atau
                        </Typography>
                      </>
                      :
                      undefined
                    }
                    <div
                      onClick={() => handleImageUpload(fileInputRefNIM)}
                      style={{
                        cursor: 'pointer',
                        padding: '6px 12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        display: 'inline-block',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '17,64px',
                        backgroundColor: bgColor
                      }}
                    >
                      Pilih {imageUrlNIM ? 'Gambar' : 'Gambar/PDF'}
                    </div>
                  </>
                )}
              <input
                type="file"
                accept="image/*,.pdf"  // Accept both image and PDF files
                onChange={(e) => handleFileInputChange(e.target.files[0], 'nim')}
                ref={fileInputRefNIM}
                style={{ display: 'none' }}
              />
            </Box>
            {!isMobile && (
              <CustomTooltip
                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                padding='8px'
                customWidth='281px'
              />
            )}
          </Box>
        </Grid>

        {/* Gambar KHS */}
        <Grid item xs={12} sm={12} mb='15px'>
          <Typography
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'justify',
              marginBottom: '10px',
              // marginTop: '34px'
            }}
          >
            Gambar Scan/Foto atau PDF Kartu Hasil Studi (Maks 1 MB)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                maxWidth: '486px',
                minWidth: '40%',
                width: isMobile ? '100%' : '40%',
                height: '190px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                position: 'relative',
                border: `solid 2px ${DividerColor}`,
                borderStyle: 'dashed',
              }}
              onDragOver={(e) => handleDragOver(e, 'khs')}
              onDrop={(e) => handleDrop(e, 'khs')}
              onDragLeave={handleDragLeave}
            >
              {isDragging.isDraggingKHS && !isMobile ?
                <Typography
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '17,64px',
                    textAlign: 'center',
                    color: mainColor
                  }}
                >
                  Lepaskan untuk mengunggah
                </Typography>
                :
                selectedImageKHS && selectedImageKHS.type === 'application/pdf' ? (
                  // Handle PDF display logic
                  <>
                    {handleShowPDF(selectedImageKHS.name)}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('khs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : imageUrlKHS ? (
                  // Handle image display logic
                  <>
                    <img
                      src={imageUrlKHS}
                      alt="selected-image"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('khs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : (
                  // Display upload options
                  <>
                    {!isMobile ?
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            textAlign: 'center'
                          }}
                        >
                          {imageUrlKHS ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            color: TextGray
                          }}
                        >
                          atau
                        </Typography>
                      </>
                      :
                      undefined
                    }
                    <div
                      onClick={() => handleImageUpload(fileInputRefKHS)}
                      style={{
                        cursor: 'pointer',
                        padding: '6px 12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        display: 'inline-block',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '17,64px',
                        backgroundColor: bgColor
                      }}
                    >
                      Pilih {imageUrlKHS ? 'Gambar' : 'Gambar/PDF'}
                    </div>
                  </>
                )}
              <input
                type="file"
                accept="image/*,.pdf"  // Accept both image and PDF files
                onChange={(e) => handleFileInputChange(e.target.files[0], 'khs')}
                ref={fileInputRefKHS}
                style={{ display: 'none' }}
              />
            </Box>
            {!isMobile && (
              <CustomTooltip
                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                padding='8px'
                customWidth='281px'
              />
            )}
          </Box>
        </Grid>

        {/* Gambar KRS */}
        <Grid item xs={12} sm={12} mb='15px'>
          <Typography
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
              textAlign: 'justify',
              marginBottom: '10px',
              // marginTop: '34px'
            }}
          >
            Gambar Scan/Foto atau PDF Kartu Rencana Studi (Maks 1 MB)
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: '24px',
            }}
          >
            <Box
              sx={{
                maxWidth: '486px',
                minWidth: '40%',
                width: isMobile ? '100%' : '40%',
                height: '190px',
                backgroundColor: '#fff',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '12px',
                position: 'relative',
                border: `solid 2px ${DividerColor}`,
                borderStyle: 'dashed',
              }}
              onDragOver={(e) => handleDragOver(e, 'krs')}
              onDrop={(e) => handleDrop(e, 'krs')}
              onDragLeave={handleDragLeave}
            >
              {isDragging.isDraggingKRS && !isMobile ?
                <Typography
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontSize: '13px',
                    fontStyle: 'normal',
                    fontWeight: 'bold',
                    lineHeight: '17,64px',
                    textAlign: 'center',
                    color: mainColor
                  }}
                >
                  Lepaskan untuk mengunggah
                </Typography>
                :
                selectedImageKRS && selectedImageKRS.type === 'application/pdf' ? (
                  // Handle PDF display logic
                  <>
                    {handleShowPDF(selectedImageKRS.name)}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('krs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : imageUrlKRS ? (
                  // Handle image display logic
                  <>
                    <img
                      src={imageUrlKRS}
                      alt="selected-image"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain',
                        borderRadius: '4px',
                      }}
                    />
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '4px',
                        right: '4px',
                        backgroundColor: '#fff',
                        borderRadius: '50%',
                        padding: '2px',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleRemoveImage('krs')}
                    >
                      <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                    </Box>
                  </>
                ) : (
                  // Display upload options
                  <>
                    {!isMobile ?
                      <>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '13px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            textAlign: 'center'
                          }}
                        >
                          {imageUrlKRS ? 'Geser gambar ke area ini' : 'Geser gambar atau PDF ke area ini'}
                        </Typography>
                        <Typography
                          sx={{
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '11px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '17,64px',
                            color: TextGray
                          }}
                        >
                          atau
                        </Typography>
                      </>
                      :
                      undefined
                    }
                    <div
                      onClick={() => handleImageUpload(fileInputRefKRS)}
                      style={{
                        cursor: 'pointer',
                        padding: '6px 12px',
                        border: '1px solid #ccc',
                        borderRadius: '4px',
                        display: 'inline-block',
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: '12px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '17,64px',
                        backgroundColor: bgColor
                      }}
                    >
                      Pilih {imageUrlKRS ? 'Gambar' : 'Gambar/PDF'}
                    </div>
                  </>
                )}
              <input
                type="file"
                accept="image/*,.pdf"  // Accept both image and PDF files
                onChange={(e) => handleFileInputChange(e.target.files[0], 'krs')}
                ref={fileInputRefKRS}
                style={{ display: 'none' }}
              />
            </Box>
            {!isMobile && (
              <CustomTooltip
                message='Pastikan Gambar yang dipilih dapat terbaca dengan jelas.'
                padding='8px'
                customWidth='281px'
              />
            )}
          </Box>
        </Grid>

        {/* URL/Link PDDikti */}
        <Grid item xs={12} sm={12}>
          <Typography
            variant="body1"
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              marginBottom: 1,
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'justify'
            }}
          >
            Bukti aktif kuliah semester akhir. (URL/Link data mahasiswa dari website PDDikti).
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            // label="Bukti aktif kuliah semester akhir. (URL/Link data mahasiswa dari website PDDikti)."
            placeholder='contoh : https://pddikti.kemdikbud.go.id/data_mahasiswa/MDIwQzAwNTQtQkNFNS00NzNGLThEQUItMjhGQjdENTU0NDhE'
            value={newData.buktiAktifKuliah}
            name="buktiAktifKuliah"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
            required
            sx={{
              marginBottom: isMobile ? '15px' : '24px',
              fontSize: isMobile ? '14px' : '13px',
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "white",
              },
              "& .MuiFilledInput-root.Mui-disabled:hover": {
                "& fieldset": {
                  borderBottomColor: mainColor,
                },
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiInputLabel-root": {
                color: '#B3B3D4',
                fontWeight: 'bold',
                fontSize: '13px',
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: mainColor,
              },
              "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:hover:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:hover:after": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:after": {
                borderBottomColor: mainColor,
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
          />
          <Box sx={{ marginBottom: 2 }}>

            <Box
              sx={{
                backgroundColor: '#F8D70033',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="body1"
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Jakarta Sans, sans-serif',
                  fontWeight: 500, textAlign: isMobile ? 'center' : undefined,
                  marginBottom: '10px'
                }}>
                Cara mendapatkan URL/Link PDDikti.
              </Typography>
              {steps.map((step, index) => (
                <Typography
                  key={index + 1}
                  variant="body1"
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontWeight: 500,
                    fontSize: '14px',
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    textAlign: 'justify'
                  }}
                >
                  {index + 1}. {index === 0 ? (
                    <>
                      Akses website PDDikti di{' '}
                      <span style={{ color: 'blue', textDecoration: 'underline' }}>
                        https://pddikti.kemdikbud.go.id
                      </span>
                      {' '}
                      menggunakan Google Chrome/Firefox/Edge ataupun web browser lainnya.
                    </>
                  ) : (
                    step
                  )}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            align='center'
            sx={{
              width: '100%',
              marginTop: '24px',
              marginBottom: '26px',
            }}>
            <Button
              // fullWidth
              variant='contained'
              size='medium'
              type='submit'
              sx={{
                minWidth: isMobile ? '100%' : '350px',
                fontSize: isMobile ? '12px' : undefined,
                backgroundColor: btnBgColor,
                color: mainColor,
                padding: '10px 76px',
                // fontWeight:'bold',
                '&:hover': {
                  color: 'white', // Change text color to white on hover
                  backgroundColor: mainColor,
                  // fontWeight:'bold',
                },
              }}
            // disabled={loadingSimpanData ? true : false}
            >
              {loadingSimpanData ?
                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                :
                'Simpan Data & Lanjutkan'
              }
            </Button>
          </Box>
        </Grid>

      </Grid>
    </form>
  )
}

export default KuisionerS2