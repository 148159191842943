import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { RegistrasiTahapII_and_TahapIII, createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'
import { useFormContext } from "../../../pages/Registrasi/FormContext";

const Tahap2BeasiswaBerprestasiS3 = ({
  isMobile,
  isTablet,
  open,
  close,
  selectedCertificate1Exist,
  selectedCertificate2Exist,
  selectedCertificate3Exist,
  selectedImageIjazahSMAExist,
  selectedImageIjazahS1Exist,
  selectedImageIjazahS2Exist,
  selectedImageTranskripNilaiS1Exist,
  selectedImageTranskripNilaiS2Exist,
  selectedToelfExist,
  dataStep2Exist,
}) => {
  return (
    <>
      {/* Column 1 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Kategori
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.selectedCategoryExist ? dataStep2Exist.selectedCategoryExist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Jenjang Pendidikan
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.selectedJenjangPendidikanExist ? dataStep2Exist.selectedJenjangPendidikanExist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Ijazah SMA
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageIjazahSMAExist && selectedImageIjazahSMAExist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageIjazahSMAExist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageIjazahSMAExist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 2 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Nilai GPA S1
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.nilaiGpaS1Exist ? dataStep2Exist.nilaiGpaS1Exist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Nilai Rata-rata S1
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.nilaiRata2S1Exist ? dataStep2Exist.nilaiRata2S1Exist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Ijazah S1
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageIjazahS1Exist && selectedImageIjazahS1Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageIjazahS1Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageIjazahS1Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 3 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Nilai GPA S2
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.nilaiGpaS2Exist ? dataStep2Exist.nilaiGpaS2Exist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Nilai Rata-rata S2
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.nilaiRata2S2Exist ? dataStep2Exist.nilaiRata2S2Exist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Ijazah S2
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageIjazahS2Exist && selectedImageIjazahS2Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageIjazahS2Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageIjazahS2Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 4 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Transkrip Nilai S1
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageTranskripNilaiS1Exist && selectedImageTranskripNilaiS1Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageTranskripNilaiS1Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageTranskripNilaiS1Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Transkrip Nilai S2
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageTranskripNilaiS2Exist && selectedImageTranskripNilaiS2Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageTranskripNilaiS2Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageTranskripNilaiS2Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Sertifikat Toefl
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedToelfExist && selectedToelfExist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedToelfExist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedToelfExist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 5 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Sertifikat 1
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedCertificate1Exist && selectedCertificate1Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedCertificate1Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedCertificate1Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Sertifikat 2
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedCertificate2Exist && selectedCertificate2Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedCertificate2Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedCertificate2Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Sertifikat 3
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedCertificate3Exist && selectedCertificate3Exist.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedCertificate3Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedCertificate3Exist)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Tahap2BeasiswaBerprestasiS3