import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  TextField,
  Typography,
  Modal,
  useMediaQuery,
  Button,
  Divider,
  TextareaAutosize,
  Grid,
} from "@mui/material";
import { Icon } from "@iconify/react";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Dark, DividerColor, TextGray, btnBgColor, mainColor } from "../../Config/Color";
import axios from "axios";
import { RegistrasiTahapII_and_TahapIII, createTicket } from "../../Config/Api";
import SuccessAlert from '../../../components/ToastyAlert/SuccessAlert'
import ErrorAlert from '../../../components/ToastyAlert/ErrorAlert'
import { useFormContext } from "../../../pages/Registrasi/FormContext";

const Tahap2BeasiswaKeluargaMiskin = ({
  isMobile,
  isTablet,
  dataStep2Exist,
  selectedImageBKM,
  selectedImageBKMKIS,
  selectedImageX,
  selectedImageXI,
  selectedImageXII,
  selectedImageHouse1,
  selectedImageHouse2,
  selectedImageHouse3,
  selectedImageIjazahSMA,
  selectedImageTranskripNilaiSMA,
}) => {
  return (
    <>
      {/* Column 1 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%',

        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Kategori
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.selectedCategoryExist ? dataStep2Exist.selectedCategoryExist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Jenjang Pendidikan
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {dataStep2Exist.selectedJenjangPendidikanExist ? dataStep2Exist.selectedJenjangPendidikanExist : ''}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Ijazah SMA
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageIjazahSMA && selectedImageIjazahSMA.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageIjazahSMA)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageIjazahSMA)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 2 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Raport Kelas X
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageX && selectedImageX.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageX)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageX)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Raport Kelas XI
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageXI && selectedImageXI.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageXI)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageXI)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Raport Kelas XII
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageXII && selectedImageXII.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageXII)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageXII)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 3 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Surat Keterangan Kepala Distrik
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageBKM && selectedImageBKM.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageBKM)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageBKM)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Kartu Indonesia Sehat
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageBKMKIS && selectedImageBKMKIS.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageBKMKIS)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageBKMKIS)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Transkrip Nilai SMA
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageTranskripNilaiSMA && selectedImageTranskripNilaiSMA.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageTranskripNilaiSMA)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageTranskripNilaiSMA)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>

      {/* Column 4 */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          alignItems: 'flex-center',
          gap: isMobile ? '3px' : undefined,
          marginBottom: '20px',
          // backgroundColor: 'orange',
          width: '100%'
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Foto Kondisi Rumah 1
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageHouse1 && selectedImageHouse1.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageHouse1)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageHouse1)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Foto Kondisi Rumah 2
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageHouse2 && selectedImageHouse2.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageHouse2)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageHouse2)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '2px',
            width: '33.3%'
          }}
        >
          <Typography
            sx={{
              color: TextGray,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '11px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: 'normal',
            }}
          >
            Foto Kondisi Rumah 3
          </Typography>

          <Typography
            sx={{
              color: Dark,
              fontFamily: 'Jakarta Sans, sans-serif',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 'bold',
              lineHeight: 'normal',
            }}
          >
            {selectedImageHouse3 && selectedImageHouse3.type === 'application/pdf' ?
              <a
                href={URL.createObjectURL(selectedImageHouse3)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat PDF
              </a>
              :
              <a
                href={URL.createObjectURL(selectedImageHouse3)}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  color: mainColor,
                  fontSize: '12px'
                }}
              >
                Lihat Gambar
              </a>
            }
          </Typography>
        </Box>
      </Box>
    </>
  )
}

export default Tahap2BeasiswaKeluargaMiskin