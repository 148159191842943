import React, { useEffect, useRef, useState } from 'react'
import { Dark, Green, bgColor, mainColor } from '../Config/Color'
import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import Top from '../Register/Top/Top';
import Footer from '../Register/Footer/Footer';
import { useFormContext } from '../../pages/Registrasi/FormContext';

const EmailActivation = ({verification_email_id, verification_link}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [countdownTimer, setCountdownTimer] = useState(10)
  const timerId = useRef()

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountdownTimer(prev => prev - 1)
    }, 1000)
    return () => clearInterval(timerId.current)
  }, [countdownTimer])

  useEffect(() => {
    if (countdownTimer <= 0) {
      clearInterval(timerId.current)
    }
  }, [countdownTimer])

  const getEmailActivation = async () => {
    console.log('verification_email_id', verification_email_id);
    console.log('verification_link', verification_link);
  }

  // useEffect(() => {
  //   getEmailActivation()
  // }, [])



  return (
    <>
      <Top />
      <Grid
        container
        sx={{
          backgroundColor: bgColor,
          padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
          height: 'auto', // Change height to auto
          overflow: 'hidden',
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            // maxHeight:isMobile ? '8%' : '500px',
            height: isMobile ? '100%' : '330px', // Adjust height based on screen size
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: isMobile ? '30px' : isTablet ? '13px' : '23px',
            marginBottom: isMobile ? '30px' : isTablet ? '13px' : '23px',

          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: "center",
              alignItems: 'center',
              width: '100%',
              gap: isMobile ? '12px' : isTablet ? '20px' : '10px'
              // backgroundColor: ' orange'
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '18px',
                fontWeight: 'bold',
                lineHeight: '20.16px',
                color: Green,
              }}
            >
              Berhasil Aktivasi Email.
            </Typography>
            <Icon icon='iconamoon:check-circle-1-fill' style={{ fontSize: '80px', color: Green }} />
            <Typography
              sx={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '18px',
                // fontWeight: 'bold',
                lineHeight: '20.16px',
                color: Dark,
                textAlign: 'center',
              }}
            >
              Anda dapat melanjutkan pendaftaran ke tahap berikutnya.{<br></br>}
            </Typography>
            <Box
              // align='center'
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                // backgroundColor: "orange",
              }}
            >
              <Typography sx={{
                fontFamily: 'Plus Jakarta Sans',
                fontSize: '16px',
                fontWeight: '400',
                lineHeight: '20.16px',
                fontWeight: '400',
                // fontStyle:'italic'
              }}>Diarahkan otomatis {' '}
                <span
                  style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '16px',
                    fontWeight: '400',
                    lineHeight: '20.16px',
                    fontWeight: 'bold',
                    color: mainColor
                    // fontStyle:'italic'
                  }}
                >
                  {countdownTimer}
                </span>
              </Typography>
            </Box>
            <Button
              variant='contained'
              onClick={getEmailActivation}
            >
              Click Here
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}

export default EmailActivation