import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getDataDashboardApplicant, updateBiodata, updateDataKontakUniversitas } from '../../Config/Api';
import { Blue, Dark, DividerColor, Maroon, TextGray, White, btnBgColor, mainColor } from '../../Config/Color'
import ErrorAlert from '../../ToastyAlert/ErrorAlert'
import InfoAlert from '../../ToastyAlert/InfoAlert';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { red } from '@mui/material/colors';
import LoginSessionModal from '../../Session/LoginSessionModal';

const Contact = ({ biodata, validasiData, getDataValidasi }) => {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    const [dataCompleteness, setDataCompleteness] = useState(0)
    const [switchButton, setSwitchButton] = useState(false)
    const [asalKabupaten, setAsalKabupaten] = useState('')
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [newData, setNewData] = useState({
        lokasiPerguruanTinggi: '',
        emailPerguruanTinggi: '',
        nomorPerguruanTinggi: ''
    })
    const [selectedImage, setSelectedImage] = useState(null);
    const [phoneError, setPhoneError] = useState('');
    const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)


    const getData = async () => {
        setNewData({
            lokasiPerguruanTinggi: biodata.data?.location_of_university,
            emailPerguruanTinggi: biodata.data?.college_email,
            nomorPerguruanTinggi: biodata.data?.college_telephone_number,
        });
    };

    useEffect(() => {
        getData();
    }, [biodata]);

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            setIsLoading(false)
        }, 1500);
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

    };

    const handlePhoneChange = (value, country, e, formattedValue) => {
        // const phoneCode = country.dialCode;
        // console.log('phonecode', phoneCode);
        // console.log('country.',country);

        if (!value.startsWith(country.dialCode)) {
            setPhoneError(`Nomor telepon tidak valid, kode negara ${country.name} (+${country.dialCode})`);
        } else {
            setPhoneError('');
        }
        setNewData(prev => ({ ...prev, nomorPerguruanTinggi: value }));
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true)

        // console.log(newData.lokasiPerguruanTinggi);
        // console.log(newData.emailPerguruanTinggi);
        // alert(newData.nomorPerguruanTinggi);

        if (!phoneError) {
            axios.put(`${updateDataKontakUniversitas}/${biodata.data?.personal_information_id}`, {
                location_of_university: newData.lokasiPerguruanTinggi,
                college_email: newData.emailPerguruanTinggi,
                college_telephone_number: newData.nomorPerguruanTinggi
            }).then((response) => {
                console.log(response.data);
                InfoAlert({
                    message: 'Berhasil Update Data',
                    position: 'top-center'
                })
                getDataValidasi()
                setSwitchButton(false)
                setIsSubmitting(false)
            }).catch((error) => {
                console.log(error.response.data);
                if (error.response.data.messages) {
                    ErrorAlert({
                        message: error.response.data.messages.college_email,
                        position: 'top-center'
                    })
                    setIsSubmitting(false)
                } else if (error.response?.data?.message === 'Unauthenticated.') {
                    setOpenLoginSessionModal(true)
                    setIsSubmitting(false)
                }
            })
        } else {
            ErrorAlert({
                message: `Nomor telepon tidak valid`,
                position: 'top-center'
            })
            setIsSubmitting(false)
        }

    }

    const handleCloseLoginSessionModal = () => {
        setOpenLoginSessionModal(false)
        navigate('/login');
    }



    return (
        <>
            {/* Contact */}
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : phoneError ? '370px' : '360px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            Kontak
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {validasiData ? `${Math.round(validasiData)}%` : '0%'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Lokasi Perguruan Tinggi(Negara)"
                            placeholder='Lokasi perguruan tinggi'
                            value={newData.lokasiPerguruanTinggi}
                            name="lokasiPerguruanTinggi"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Email Perguruan Tinggi"
                            placeholder='Contoh email@universitas.com'
                            value={newData.emailPerguruanTinggi}
                            name="emailPerguruanTinggi"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                flexShrink: switchButton ? true : false,
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '10px',
                                fonStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                color: switchButton ? mainColor : TextGray,
                                marginBottom: '-5px',
                                // marginTop:'5px'
                            }}
                        >
                            Nomor Telepon Perguruan Tinggi
                        </Typography>
                        <PhoneInput
                            // country={'id'}
                            value={newData.nomorPerguruanTinggi}
                            onChange={handlePhoneChange}
                            placeholder=''
                            inputProps={{
                                name: 'nomorPerguruanTinggi',
                                required: true,
                                autoComplete: 'off',
                                disabled: !switchButton,
                            }}
                            containerStyle={{
                                width: '100%',
                                // marginBottom: isMobile ? '5px' : '8px',
                                marginBottom: phoneError ? '-10px' : '10px',
                                // marginTop: '5px'
                            }}
                            inputStyle={{
                                width: '100%',
                                fontSize: isMobile ? '12px' : '14px',
                                color: 'black',
                                borderColor: mainColor,
                                border: 'none',
                                borderBottom: '1px solid gray',
                                borderBottomColor: phoneError ? red[700] : undefined
                            }}
                            buttonStyle={{
                                fontSize: isMobile ? '11px' : '12px',
                                border: 'none',
                                borderBottom: '1px solid gray',
                                borderBottomColor: phoneError ? red[700] : undefined
                            }}

                            disabled={!switchButton}
                        />
                        {phoneError && (
                            <Typography color="error" variant="body2" sx={{ fontSize: '12px' }}>
                                {phoneError}
                            </Typography>
                        )}

                        {/* <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nomor Telepon Perguruan Tinggi"
                            placeholder='Contoh : +62123736286'
                            value={newData.nomorPerguruanTinggi}
                            name="nomorPerguruanTinggi"
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        /> */}
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                color: mainColor,
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                            disabled={isSubmitting || !switchButton ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Data'
                            }
                        </Button>
                    </Box>
                </form>
            </Card>
            <LoginSessionModal
                open={openLoginSessionModal}
                close={handleCloseLoginSessionModal}
            />
        </>
    )
}

export default Contact