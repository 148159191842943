import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { red } from '@mui/material/colors';
import { bgColor, btnBgColor, mainColor } from '../../Config/Color';
import LocationIcon from '../../../assets/Icon/location.png'
import RefreshTabImage from '../../../assets/Icon/refresh_tab.png'

const LocationDetectorModal = ({
  open,
  close,
  location,
  askForLocationPermission,
  isLoading
}) => {
  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: isMobile ? '330px' : '400px',
    // minWidth: isMobile ? '330px' : '600px',
    width: '100%',
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    borderRadius: '5px',
    boxShadow: 24,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '5px',
    p: isMobile ? 2 : 2.2,
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        sx={{
          margin: '0px 30px'
        }}
      >
        <Box sx={style}>
          {location ? (
            // <Box>
            //   <Typography
            //     sx={{
            //       fontFamily: 'Jakarta Sans, sans-serif',
            //       fontSize: '11px',
            //       fontStyle: 'normal',
            //       fontWeight: '600',
            //       lineHeight: 'normal',
            //     }}
            //   >
            //     Lokasi Anda: {location.city}, {location.countryName}
            //   </Typography>
            // </Box>
            undefined
          ) : (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '20px'
              }}
            >
              <img src={LocationIcon} width={isMobile ? '90px' : '100px'} height={isMobile ? '90px' : '100px'} />
              {/* <Icon icon='fa6-solid:map-location' width='80px' height='80px' color={mainColor} /> */}
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  fontSize: isMobile ? '14px' : '15px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '21px',
                  textAlign: 'center'
                }}
              >
                Untuk melanjutkan pendaftaran silahkan ijinkan akses lokasi dengan menekan tombol dibawah,
                atau berikan akses melalui {' '}
                <span style={{ fontWeight: 'bold', color: mainColor, fontSize: '14px' }}>Pengaturan Browser</span> {'>'}{' '}
                <span style={{ fontWeight: 'bold', color: mainColor, fontSize: '14px' }}>Privacy and Security</span> {'>'}{' '}
                <span style={{ fontWeight: 'bold', color: mainColor, fontSize: '14px' }}>Location</span>.{' '}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  fontSize: isMobile ? '13px' : '14px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '18px',
                  letterSpacing: '0.5px',
                  textAlign: 'center',
                  color: red[700],
                }}
              >
                Note : Abaikan permintaan {' '}
                <span style={{ fontWeight: 'bold' }}>Refresh Tab</span> {''}
                atau {' '}
                <span style={{ fontWeight: 'bold' }}>Reload</span> saat mengaktifkan izin lokasi.
              </Typography>
              <Button
                fullWidth
                onClick={() => {
                  askForLocationPermission()
                }}
                variant='contained'
                size='small'
                sx={{
                  backgroundColor: btnBgColor,
                  color: mainColor,
                  // padding: '10px 30px',
                  width: '100%',
                  maxWidth: isMobile ? '100%' : '384px',
                  fontWeight: 'bold',
                  textTransform: 'none',
                  '&:hover': {
                    color: 'white', // Change text color to white on hover
                    backgroundColor: mainColor,
                  },
                }}
                disabled={isLoading ? true : false}
              >
                {isLoading ?
                  <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                  :
                  'Izinkan Akses Lokasi'
                }
              </Button>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default LocationDetectorModal;
