import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import React, { useState } from 'react'
import LightboxModal from '../../Modal/LightboxModal'
import { url } from '../../Config/Api'
import { Dark, Green, Maroon, TextGray, mainColor } from '../../Config/Color'
import { Icon } from '@iconify/react'
import { blue } from '@mui/material/colors'
import { Navigate, useNavigate } from 'react-router-dom'

const CategoryBantuanStudiAkhirS2 = ({ dataBerkasPersyaratanCompleteness, isMobile, isTablet, biodata }) => {

    const [imageRaporX, setImageRaporX] = useState('')
    const [imageRaporXI, setImageRaporXI] = useState('')
    const [imageRaporXII, setImageRaporXII] = useState('')
    const [ijazahSMA, setIjazahSMA] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    const navigate = useNavigate()

    const handleShowImage = (value) => {

        // let value = null
        var imageUrl = `${url}${value}`
        // console.log('asdasdsad', imageUrl);

        const fileExtension = value ? value.split('.').pop().toLowerCase() : ""
        // console.log('tes', fileExtension);

        return (
            <>
                {value ?
                    fileExtension === 'pdf' ?
                        // Display PDF and download button
                        <Button
                            sx={{
                                padding: '0px',
                                margin: '0px',
                                // backgroundColor:'orange',
                                textTransform: 'none'
                            }}
                        >
                            <a
                                href={imageUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{
                                    color: mainColor,
                                    fontFamily: 'Plus Jakarta Sans',
                                    // color: blue,
                                    fontSize: '13px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    textDecoration: 'underline',
                                    textAlign: 'left'
                                }}
                            >
                                Unduh PDF
                            </a>

                        </Button>
                        :
                        fileExtension === 'png' || fileExtension === 'jpg' || fileExtension === 'jpeg' ?
                            <>
                                <Button
                                    onClick={() => {
                                        setSelectedImage(imageUrl)
                                        setOpenLightbox(true)
                                    }}
                                    sx={{
                                        padding: '0px',
                                        margin: '0px',
                                        // backgroundColor:'orange',
                                        textTransform: 'none',
                                        color: mainColor,
                                        fontFamily: 'Plus Jakarta Sans',
                                        // color: blue,
                                        fontSize: '13px',
                                        fonStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        textDecoration: 'underline',
                                        textAlign: 'left'
                                    }}
                                >
                                    Lihat Gambar
                                </Button>
                            </>
                            :
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '14px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                    wordWrap: 'break-word', // Menambahkan word-wrap untuk memecah kata
                                    whiteSpace: 'pre-line' // Mengatur white-space untuk mempertahankan spasi dan pemecahan baris
                                }}
                            >
                                {value}
                            </Typography>
                    :
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: value ? Green : Maroon,
                            fontSize: '13px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        {value ? 'Memenuhi syarat' : 'Belum memenuhi syarat'}
                    </Typography>
                }
            </>
        )
    }

    return (

        <Grid
            container
            spacing={2}
            sx={{
                padding: '16px',
            }}
        >

            {/* NIM */}
            <Grid
                item
                xs={12}
                md={12}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        NIM
                    </Typography>
                    {handleShowImage(biodata.student_id_number)}
                </Box>
            </Grid>

            {/*Foto NIM*/}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Foto NIM
                    </Typography>
                    {handleShowImage(biodata.student_id_number_scan)}
                </Box>
            </Grid>

            {/* Foto KHS */}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Foto KHS
                    </Typography>
                    {handleShowImage(biodata.scan_khs)}
                </Box>
            </Grid>

            {/*Foto KRS*/}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Foto KRS
                    </Typography>
                    {handleShowImage(biodata.scan_krs)}
                </Box>
            </Grid>

            {/*Link PDDikti*/}
            <Grid
                item
                xs={6}
                md={6}
                sx={{
                    // padding: '16px',
                    // display: 'flex',
                    // flexDirection: 'column',
                    // justifyContent: 'center',
                    // alignItems: 'flex-start',
                    // gap: '20px'
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        // gap: '3px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: TextGray,
                            fontSize: '12px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Link PDDikti
                    </Typography>
                    <Typography
                        onClick={() => {
                            const newWindow = window.open(biodata.link_pddikti, '_blank')
                            if (newWindow) {
                                newWindow.opener = null
                            }
                        }}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: 'blue',
                            fontSize: '14px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                            wordWrap: 'break-word', // Menambahkan word-wrap untuk memecah kata
                            whiteSpace: 'pre-line', // Mengatur white-space untuk mempertahankan spasi dan pemecahan baris
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                    >
                        {biodata.link_pddikti.length > 10 ? `${biodata.link_pddikti.substring(0, 18)}...` : biodata.link_pddikti}
                    </Typography>
                </Box>
            </Grid>
            <LightboxModal
                open={openLightbox}
                close={() => {
                    setOpenLightbox(false)
                }}
                imageUrl={selectedImage}
            />
        </Grid>
    )
}

export default CategoryBantuanStudiAkhirS2