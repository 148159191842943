import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, Divider, FormControl, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Dark, DividerColor, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import { Icon } from '@iconify/react/dist/iconify.js';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { getDataDashboardApplicant, updateDataAcademic, updateDataBiayaPendidikan, url } from '../../Config/Api';
import LightboxModal from '../../Modal/LightboxModal';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import InfoAlert from '../../ToastyAlert/InfoAlert';

const DataAkademik = ({ completeData, handleExpired, dataCompletenessDataAkademik }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [switchButton, setSwitchButton] = useState(false)

    const [showImageKRS, setShowImageKRS] = useState('')
    const [showImageKHS, setShowImageKHS] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)

    const [currentImageKRS, setCurrentImageKRS] = useState(null);
    const [selectedImageKRS, setSelectedImageKRS] = useState(null);
    const [imageUrlKRS, setImageUrlKRS] = useState(null);
    const fileInputRefKRS = useRef(null);

    const [currentImageKHS, setCurrentImageKHS] = useState(null);
    const [selectedImageKHS, setSelectedImageKHS] = useState(null);
    const [imageUrlKHS, setImageUrlKHS] = useState(null);
    const fileInputRefKHS = useRef(null);

    const [selectedSkalaIPK, setSelectedSkalaIPK] = useState('')
    const [newData, setNewData] = useState({
        nilaiIPK: ''
    });

    const [isDragging, setIsDragging] = useState({
        isDraggingPictureKRS: false,
        isDraggingPictureKHS: false,
    });

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const refreshData = async () => {
        // console.log(switchButton);
        if (switchButton) {
            const getDataLogin = localStorage.getItem('LogInfo')
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    setNewData({
                        nilaiIPK: response?.data?.data?.gpa,
                    })
                    setSelectedSkalaIPK(response?.data?.data?.gpa_scale)
                    setCurrentImageKHS(response?.data?.data?.khs)
                    setCurrentImageKRS(response?.data?.data?.krs)
                }).catch((error) => {
                    console.log(error.response);
                })
        }
    }

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log('tes', response?.data?.data);
                    setNewData({
                        nilaiIPK: response?.data?.data?.gpa,
                    })
                    setSelectedSkalaIPK(response?.data?.data?.gpa_scale)
                    setCurrentImageKHS(response?.data?.data?.khs)
                    setCurrentImageKRS(response?.data?.data?.krs)
                }).catch((error) => {
                    console.log(error.response);
                })
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleImageUpload = (fileInputRef, option) => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e, option) => {
        e.preventDefault();
        // console.log('handleDragOver', e);
        if (option === 'krs') {
            setIsDragging({
                isDraggingPictureKRS: true
            })
        } else if (option === 'khs') {
            setIsDragging({
                isDraggingPictureKHS: true
            })
        }
    };

    const handleDrop = (e, option) => {
        e.preventDefault();
        // console.log('handledrop');
        const file = e.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'krs') {
            setIsDragging({
                isDraggingPictureKRS: false
            })
        } else if (option === 'khs') {
            setIsDragging({
                isDraggingPictureKHS: false
            })
        }
    };

    const handleDragLeave = (e, option) => {
        // console.log('dragleave');
        e.preventDefault();
        if (option === 'krs') {
            setIsDragging({
                isDraggingPictureKRS: true
            })
        } else if (option === 'khs') {
            setIsDragging({
                isDraggingPictureKHS: true
            })
        }
    };

    const handleFileInputChange = (file, option) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    if (option === 'krs') {
                        setSelectedImageKRS(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlKRS(imageUrl);
                    } else if (option === 'khs') {
                        setSelectedImageKHS(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlKHS(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    if (option === 'krs') {
                        setSelectedImageKRS(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlKRS(imageUrl);
                    } else if (option === 'khs') {
                        setSelectedImageKHS(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlKHS(imageUrl);
                    }
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleRemoveImage = (option) => {
        if (option === 'krs') {
            setSelectedImageKRS(null);
            setImageUrlKRS(null);
            setCurrentImageKRS(null)
        } else if (option === 'khs') {
            setSelectedImageKHS(null);
            setImageUrlKHS(null);
            setCurrentImageKHS(null)
        }
    };

    const handleShowPDF = (currentFile, imageUrl) => {
        const fileExtension = currentFile ? currentFile.type.split('/').pop().toLowerCase() : ''
        // console.log('handleShowPDF', currentFile);

        return (
            // Display PDF and download button
            fileExtension === 'pdf' ?
                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'none',
                        gap: '10px',

                        // backgroundColor:'orange'
                    }}
                >
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {currentFile.name}
                    </Typography>
                </Button>
                :
                <img
                    src={imageUrl}
                    alt="selected-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />

        )
    }


    const handleShowImage = (currentImage) => {
        // console.log('currentImage show image', currentImage);

        var image = `${url}${currentImage}`
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = currentImage ? currentImage.split('/').pop() : ''
        // console.log('tes', filename);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }

        return (
            fileExtension === 'pdf' ?
                // Display PDF and download button
                <>
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {desiredString ? desiredString : ''}
                    </Typography>
                </>
                :
                <img
                    src={image}
                    alt="show-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />
        )
    }

    const handleSelectedSkala = (e) => {
        setSelectedSkalaIPK(e.target.value)
    }

    const handleShowImageClick = (currentImage, option) => {
        // console.log('currentImage', currentImage);
        var image = `${url}${currentImage}`;
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''

        if (fileExtension === 'pdf') {
            // Extract filename from the URL
            const filename = currentImage ? currentImage.split('/').pop() : ''

            // Handle PDF file download with the correct filename
            const downloadLink = document.createElement('a');
            downloadLink.href = image;
            // downloadLink.target = '_blank';
            downloadLink.download = filename;
            downloadLink.click();
        } else {
            if (option === 'krs') {
                setShowImageKRS(image)
                setOpenLightbox(true);
            } else if (option === 'khs') {
                setShowImageKHS(image)
                setOpenLightbox(true);
            }
        }
    };

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            setIsLoading(false)
        }, 1500);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const personalID = completeData?.personal_information_id
        setIsSubmitting(true)

        // console.log(completeData);
        const formData = new FormData()

        if (selectedImageKHS || selectedImageKRS) {
            formData.append('_method', 'PUT')
            formData.append('latest_study_plan_card', selectedImageKRS)
            formData.append('study_results_card', selectedImageKHS)
            formData.append('gpa_scale', selectedSkalaIPK)
            formData.append('gpa', newData.nilaiIPK)
        } else {
            formData.append('_method', 'PUT')
            formData.append('gpa_scale', selectedSkalaIPK)
            formData.append('gpa', newData.nilaiIPK)
        }

        if ((selectedImageKHS || selectedImageKRS) || (currentImageKHS || currentImageKRS)) {
            await axios.post(`${updateDataAcademic}/${personalID}`, formData)
                .then((response) => {
                    console.log(response.data);
                    if (response.data.message === "Informasi personal berhasil diperbarui") {
                        refreshData()
                        setSwitchButton(false)
                        InfoAlert({
                            message: 'Berhasil mengubah data',
                            position: 'top-center'
                        })
                    }
                }).catch((error) => {
                    console.log(error.response.data);
                    if (error?.response?.data?.message === 'Unauthenticated.') {
                        handleExpired()
                    } else {
                        ErrorAlert({
                            message: error?.response?.data?.message,
                            position: 'top-center'
                        })
                    }
                })
        } else if (selectedImageKRS === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto KRS',
                position: 'top-center'
            })
        } else if (selectedImageKHS === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto KHS',
                position: 'top-center'
            })
        }
        setIsSubmitting(false)
    }

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    return (
        <>
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : '800px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            Data Akademik
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {dataCompletenessDataAkademik ? `${Math.round(dataCompletenessDataAkademik)}%` : '0%'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>

                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        {/* Upload KRS */}
                        <Typography
                            sx={{
                                color: '#1E222F',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                // marginBottom: '10px',
                            }}
                        >
                            Gambar Scan/Foto KRS(Maks 1MB)
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                // flexDirection: 'row',
                                // gap: '24px',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                                marginTop: '-8px',
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: isMobile ? '100%' : '190px',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                marginBottom: '5px'
                            }}
                            onDragOver={(e) => handleDragOver(e, 'krs')}
                            onDrop={(e) => handleDrop(e, 'krs')}
                            onDragLeave={(e) => handleDragLeave(e, 'krs')}
                        >
                            <Box
                                sx={{
                                    maxWidth: isMobile ? '350px' : '347px',
                                    maxHeight: '190px',
                                    width: '100%',
                                    height: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px',
                                    position: 'relative',
                                }}
                            >
                                {isDragging.isDraggingPictureKRS && !isMobile && switchButton ?
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            lineHeight: '17,64px',
                                            textAlign: 'center',
                                            color: mainColor
                                        }}
                                    >
                                        Lepaskan untuk mengunggah
                                    </Typography>
                                    :
                                    imageUrlKRS || currentImageKRS ? (
                                        <>
                                            {currentImageKRS ?
                                                handleShowImage(currentImageKRS)
                                                :
                                                handleShowPDF(selectedImageKRS, imageUrlKRS)
                                            }
                                            {switchButton ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={(e) => handleRemoveImage('krs')}
                                                >
                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#fff',
                                                    }}
                                                    onClick={() => handleShowImageClick(currentImageKRS, 'krs')}
                                                >
                                                    {currentImageKRS && currentImageKRS.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                        :
                                                        <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                    }
                                                </Box>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {!isMobile ?
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '13px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Geser gambar atau PDF ke area ini
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            color: TextGray
                                                        }}
                                                    >
                                                        atau
                                                    </Typography>
                                                </>
                                                :
                                                undefined
                                            }
                                            <div
                                                onClick={() => handleImageUpload(fileInputRefKRS, 'krs')}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '6px 12px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    display: 'inline-block',
                                                    backgroundColor: switchButton ? undefined : bgColor,
                                                    color: switchButton ? undefined : DividerColor
                                                }}
                                            >
                                                Pilih Gambar/PDF
                                            </div>
                                        </>
                                    )}
                                <input
                                    type="file"
                                    accept="image/*,.pdf"   // Accept both image and PDF files
                                    onChange={(event) => handleFileInputChange(event.target.files[0], 'krs')}
                                    ref={fileInputRefKRS}
                                    style={{ display: 'none' }}
                                    disabled={switchButton ? false : true}
                                />
                            </Box>
                        </Box>

                        {/* Upload Data KHS */}
                        <Typography
                            sx={{
                                color: '#1E222F',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                // marginBottom: '10px',
                            }}
                        >
                            Gambar Scan/Foto KHS(Maks 1MB)
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                // flexDirection: 'row',
                                // gap: '24px',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                                marginTop: '-8px',
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: isMobile ? '100%' : '190px',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                                marginBottom: '5px'
                            }}
                            onDragOver={(e) => handleDragOver(e, 'khs')}
                            onDrop={(e) => handleDrop(e, 'khs')}
                            onDragLeave={(e) => handleDragLeave(e, 'khs')}
                        >
                            <Box
                                sx={{
                                    maxWidth: isMobile ? '350px' : '347px',
                                    maxHeight: '190px',
                                    width: '100%',
                                    height: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px',
                                    position: 'relative',
                                }}
                            >
                                {isDragging.isDraggingPictureKHS && !isMobile && switchButton ?
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            lineHeight: '17,64px',
                                            textAlign: 'center',
                                            color: mainColor
                                        }}
                                    >
                                        Lepaskan untuk mengunggah
                                    </Typography>
                                    :
                                    imageUrlKHS || currentImageKHS ? (
                                        <>
                                            {currentImageKHS ?
                                                handleShowImage(currentImageKHS)
                                                :
                                                handleShowPDF(selectedImageKHS, imageUrlKHS)
                                            }
                                            {switchButton ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={(e) => handleRemoveImage('khs')}
                                                >
                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#fff',
                                                    }}
                                                    onClick={() => handleShowImageClick(currentImageKHS, 'khs')}
                                                >
                                                    {currentImageKHS && currentImageKHS.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                        :
                                                        <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                    }
                                                </Box>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {!isMobile ?
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '13px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Geser gambar atau PDF ke area ini
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            color: TextGray
                                                        }}
                                                    >
                                                        atau
                                                    </Typography>
                                                </>
                                                :
                                                undefined
                                            }
                                            <div
                                                onClick={() => handleImageUpload(fileInputRefKHS, 'khs')}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '6px 12px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    display: 'inline-block',
                                                    backgroundColor: switchButton ? undefined : bgColor,
                                                    color: switchButton ? undefined : DividerColor
                                                }}
                                            >
                                                Pilih Gambar/PDF
                                            </div>
                                        </>
                                    )}
                                <input
                                    type="file"
                                    accept="image/*,.pdf"   // Accept both image and PDF files
                                    onChange={(event) => handleFileInputChange(event.target.files[0], 'khs')}
                                    ref={fileInputRefKHS}
                                    style={{ display: 'none' }}
                                    disabled={switchButton ? false : true}
                                />
                            </Box>
                        </Box>

                        {/* Dropdown Skala IPK */}
                        <FormControl required variant="standard" fullWidth disabled={switchButton ? false : true} sx={{ marginBottom: '5px' }}>
                            <InputLabel id="skala-ipk-labelId">Pilih Skala IPK</InputLabel>
                            <Select
                                label="Pilih Skala IPK"
                                labelId="skala-ipk-labelId"
                                id="skala-ipk-Id"
                                defaultValue=''
                                value={selectedSkalaIPK}
                                onChange={(e) => handleSelectedSkala(e)}
                                required
                            >
                                <MenuItem value='Skala A-F'>
                                    Skala A-F
                                </MenuItem>
                                <MenuItem value='Skala 5.0-1.0'>
                                    Skala 5.0-1.0
                                </MenuItem>
                                <MenuItem value='Skala 0-20'>
                                    Skala 0-20
                                </MenuItem>
                                <MenuItem value='Skala 0.0-4.0'>
                                    Skala 0.0-4.0
                                </MenuItem>
                                <MenuItem value='Skala 1.0-5.0'>
                                    Skala 1.0-5.0
                                </MenuItem>
                                <MenuItem value='Skala 1-7'>
                                    Skala 1-7
                                </MenuItem>
                            </Select>
                        </FormControl>

                        {/* Nilai IPk */}
                        <TextField
                            // fullWidth
                            variant="standard"
                            type="text"
                            label="Nilai IPK"
                            placeholder='Masukkan nilai IPK'
                            value={newData.nilaiIPK}
                            name="nilaiIPK"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '20px' : '15px',
                                fontSize: isMobile ? '11px' : '12px',
                                width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />

                        {/* Button Simpan Perubahan */}
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                color: mainColor,
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                            disabled={isSubmitting || !switchButton ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Perubahan'
                            }
                        </Button>
                    </Box>
                </form>
            </Card>
            <LightboxModal
                open={openLightbox}
                close={() => {
                    setOpenLightbox(false)
                    setShowImageKHS('')
                    setShowImageKRS('')
                }}
                imageUrl={
                    showImageKRS ?
                        showImageKRS
                        :
                        showImageKHS ?
                            showImageKHS
                            :
                            undefined
                }
            />
        </>
    )
}

export default DataAkademik