import React, { useEffect, useRef, useState } from 'react'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { getDataDashboardApplicant, updateBiodata, updateDataKontakPribadi } from '../../Config/Api';
import { Blue, Dark, DividerColor, Maroon, TextGray, White, btnBgColor, mainColor } from '../../Config/Color'
import InfoAlert from '../../ToastyAlert/InfoAlert';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';

const Contact = ({ biodata, validasiData, getDataValidasi }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [switchButton, setSwitchButton] = useState(false)
    const [asalKabupaten, setAsalKabupaten] = useState('')
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [newData, setNewData] = useState({
        nomorWhatsapp: '',
        emailPribadi: '',
        emailPelajar: ''
    })
    const [selectedImage, setSelectedImage] = useState(null);

    const getData = async () => {
        setNewData({
            nomorWhatsapp: biodata.data?.whatsapp_number,
            emailPribadi: biodata.data?.personal_email,
            emailPelajar: biodata.data?.student_email
        });

    };

    useEffect(() => {
        getData();
    }, [biodata])

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            setIsLoading(false)
        }, 1500);
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsSubmitting(true)

        await axios.put(`${updateDataKontakPribadi}/${biodata.data.personal_information_id}`, {
            whatsapp_number: newData.nomorWhatsapp,
            personal_email: newData.emailPribadi,
            student_email: newData.emailPelajar
        }).then((response) => {
            console.log(response.data);
            InfoAlert({
                message: 'Berhasil Update Data.',
                position: 'top-center'
            })
            getDataValidasi()
            setSwitchButton(false)
            setIsSubmitting(false)
        }).catch((error) => {
            console.log(error.response.data);
            if (error.response.data.messages) {
                ErrorAlert({
                    message: error.response.data.messages.student_email || error.response.data.messages.personal_email,
                    position: 'top-center'
                })
            }
            setIsSubmitting(false)
        })
    }


    return (
        <>
            {/* Contact */}
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : '344px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            Kontak
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {validasiData ? `${Math.round(validasiData)}%` : '0%'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nomor Whatsapp Aktif"
                            placeholder='Masukkan nomor whatsapp'
                            value={newData.nomorWhatsapp}
                            name="nomorWhatsapp"
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Email Pribadi"
                            placeholder='Masukkan email'
                            value={newData.emailPribadi}
                            name="emailPribadi"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                flexShrink: switchButton ? true : false,
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Email Pelajar"
                            placeholder='Masukkan email'
                            value={newData.emailPelajar}
                            name="emailPelajar"
                            onChange={(e) => handleChange(e)}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '5px' : '8px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                            <Button
                                fullWidth
                                type='submit'
                                variant='contained'
                                size='medium'
                                sx={{
                                    backgroundColor: btnBgColor,
                                    textTransform: 'none',
                                    color: mainColor,
                                    '&:hover': {
                                        color: mainColor, // Change text color to white on hover
                                        backgroundColor: btnBgColor
                                    },
                                }}
                                disabled={isSubmitting || !switchButton ? true : false}
                            >
                                {isSubmitting ?
                                    <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                    :
                                    'Simpan Data'
                                }
                            </Button>
                    </Box>
                </form>
            </Card>
        </>
    )
}

export default Contact