import React, { useEffect, useRef, useState } from 'react'
import { Blue, Dark, DividerColor, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../Config/Color'
import { Box, Button, Card, Divider, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { getDataDashboardApplicant, listDataKabupaten, updateBiodata, updateDataKTP, url } from '../../Config/Api';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ErrorAlert from '../../ToastyAlert/ErrorAlert'
import InfoAlert from '../../ToastyAlert/InfoAlert'
import LightboxModal from '../../Modal/LightboxModal';

const KTP = ({ biodata, validasiData, getDataValidasi }) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [switchButton, setSwitchButton] = useState(false)
    const [showImageKTP, setShowImageKTP] = useState('')
    const [KTPErrorMessage, setKTPErrorMessage] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [newData, setNewData] = useState({
        nik: '',
    })
    const [selectedImage, setSelectedImage] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const [currentImage, setCurrentImage] = useState(null);
    const fileInputRef = useRef(null);
    const [dataWilayah91, setDataWilayah91] = useState([]);
    const [dataWilayah94, setDataWilayah94] = useState([]);
    const [dataWilayahKabupaten91, setDataWilayahKabupaten91] = useState([]);
    const [dataWilayahKabupaten94, setDataWilayahKabupaten94] = useState([]);


    const [isDragging, setIsDragging] = useState({
        isDraggingKTP: false,
    });

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const handleImageUpload = () => {
        fileInputRef.current.click();
    };

    const handleFileInputChange = (file) => {
        // const file = event.target.files[0];
        if (file) {
            // Check the file size
            const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to megabytes
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                })
                // Optionally, clear the file input
                // event.target.value = null;
                // return;
            }

            setSelectedImage(file);
            const imageUrl = URL.createObjectURL(file);
            setImageUrl(imageUrl);
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        setImageUrl(null);
        setCurrentImage(null)
    };

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            refreshData()
            setIsLoading(false)
        }, 1500);
    }

    const handleDragOver = (event, option) => {
        event.preventDefault();
        // console.log('handleDragOver', event);
        setIsDragging({
            isDraggingKTP: true
        })
    };

    const handleDrop = (event, option) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file);
        setIsDragging({
            isDraggingKTP: false
        })
    };

    const handleDragLeave = () => {
        setIsDragging({
            isDraggingKTP: false,
        }) // Set state menjadi false saat dragleave
    };

    const getDataWilayah = async () => {
        let uniqueCodes91 = new Set();
        let uniqueCodes94 = new Set();
        let uniqueCodesWilayah91 = new Set();
        let uniqueCodesWilayah94 = new Set();
        await axios.get(`${listDataKabupaten}/${91}`)
            .then((response) => {
                console.log('wilayah91', response.data);

                const res = response.data?.data
                // console.log(res);
                res.forEach((data) => {
                    uniqueCodes91.add(data.code.substring(3, 5));
                    uniqueCodesWilayah91.add(data.code.substring(0, 2));
                })

            }).catch((error) => {
                console.log(error.response.data);
            });

        await axios.get(`${listDataKabupaten}/${94}`)
            .then((response) => {
                // console.log('wilayah94', response.data);
                const res = response.data?.data
                res.forEach((data) => {
                    uniqueCodes94.add(data.code.substring(3, 5));
                    uniqueCodesWilayah94.add(data.code.substring(0, 2));
                });
                // console.log('res 94', res);
            }).catch((error) => {
                console.log(error.response.data);
            });

        // Konversi Set kembali ke array
        const uniqueArray91 = Array.from(uniqueCodes91);
        const uniqueArray94 = Array.from(uniqueCodes94);
        // const uniqueArrayWilayah91 = Array.from(uniqueCodesWilayah91);
        // const uniqueArrayWilayah94 = Array.from(uniqueCodesWilayah94);
        console.log('uniqueArray91', uniqueArray91);
        // console.log('uniqueCodesWilayah91', uniqueCodesWilayah91);
        console.log('uniqueArray94', uniqueArray94);
        // console.log('uniqueCodesWilayah94', uniqueCodesWilayah94);
        setDataWilayahKabupaten91(uniqueArray91)
        // setDataWilayah91(uniqueCodesWilayah91)
        // setDataWilayah94(uniqueCodesWilayah94)
        setDataWilayahKabupaten94(uniqueArray94)
    };

    const refreshData = async () => {
        // console.log(switchButton);
        if (switchButton) {
            const getDataLogin = localStorage.getItem('LogInfo')
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log('data switchbutton', response.data);
                    setNewData({
                        nik: response?.data?.data?.nik_ktp
                    });
                    setCurrentImage(response?.data?.data?.ktp_scan)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    // console.log('data switchbutton', response.data);
                    setNewData({
                        nik: response?.data?.data?.nik_ktp
                    });
                    setCurrentImage(response?.data?.data?.ktp_scan)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    };

    useEffect(() => {
        getData();
        getDataWilayah()
    }, []);


    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)

        const kodeProvinsi = newData.nik.substring(0, 2);
        const kodeKabupaten = newData.nik.substring(2, 4);

        if (
            (newData.nik !== '' || newData.nik) &&
            (kodeProvinsi === '91' && [kodeKabupaten].some(kode => dataWilayahKabupaten91.includes(kode))) ||
            (kodeProvinsi === '94' && [kodeKabupaten].some(kode => dataWilayahKabupaten94.includes(kode)))
        ) {
            try {
                const getDataLogin = localStorage.getItem('LogInfo');
                const user_id = JSON.parse(getDataLogin).Id;
                const token = JSON.parse(getDataLogin).token;
                const email = JSON.parse(getDataLogin).email;

                const formData = new FormData();

                // console.log(biodata.data?.personal_information_id);

                if (selectedImage) {
                    formData.append('_method', 'PUT');
                    formData.append('nik_ktp', newData.nik);
                    formData.append('ktp_scan', selectedImage);
                } else {
                    formData.append('_method', 'PUT');
                    formData.append('nik_ktp', newData.nik);
                    // formData.append('ktp_scan', selectedImage);
                }

                if (selectedImage || currentImage) {

                    axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                    const response = await axios.post(`${updateDataKTP}/${biodata.data?.personal_information_id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });
                    if (response.status >= 200 && response.status < 300) {
                        // console.log('response', response.data);
                        InfoAlert({
                            message: 'Berhasil Update Data',
                            position: 'top-center'
                        })
                        getDataValidasi()
                        refreshData()
                        setSwitchButton(false)
                        setIsSubmitting(false)
                    } else {
                        console.log('Update failed with status:', response.status);
                        // Handle errors, show appropriate messages to the user
                        setIsSubmitting(false)
                    }
                } else {
                    ErrorAlert({
                        message: 'Silahkan Upload Gambar Scan/Foto KTP',
                        position: 'top-center',
                    });
                    setIsSubmitting(false)
                }
            } catch (error) {
                console.error('An error occurred while updating data:', error);
                setIsSubmitting(false)
                // Handle errors, show appropriate messages to the user
            }
        } else {
            ErrorAlert({
                message: 'KTP bukan dari kabupaten yang ada di Papua Tengah.',
                position: 'top-center'
            })
            setIsSubmitting(false)
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

        // Validate NIK format
        if (name === 'nik') {
            const kodeProvinsi = value.substring(0, 2);
            const kodeKabupaten = value.substring(2, 4);

            // console.log(kodeKabupaten);

            if (value !== '' && (kodeProvinsi !== '94' && kodeProvinsi !== '91')) {
                setKTPErrorMessage('KTP bukan dari provinsi Papua Tengah.');
            } else if (value !== '' && (kodeProvinsi === '91' && [kodeKabupaten].some(kode => !dataWilayahKabupaten91.includes(kode)))) {
                setKTPErrorMessage('KTP tidak berasal dari kabupaten yang ada di Papua Tengah.');
            } else if (value !== '' && (kodeProvinsi === '94' && [kodeKabupaten].some(kode => !dataWilayahKabupaten94.includes(kode)))) {
                setKTPErrorMessage('KTP tidak berasal dari kabupaten yang ada di Papua Tengah.');
            } else {
                setKTPErrorMessage('');
            }
        }
    };

    const handleShowPDF = () => {
        const fileExtension = selectedImage ? selectedImage.type.split('/').pop().toLowerCase() : ''
        // console.log('tes', selectedImage);

        return (
            // Display PDF and download button
            fileExtension === 'pdf' ?
                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'none',
                        gap: '10px'
                        // backgroundColor:'orange'
                    }}
                >
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {selectedImage.name}
                    </Typography>
                </Button>
                :
                <img
                    src={imageUrl}
                    alt="selected-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />

        )
    }

    const handleShowImage = () => {

        var image = `${url}${currentImage}`
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', image);

        // Extract filename from the URL
        const filename = currentImage ? currentImage.split('/').pop() : ''
        // console.log('tes', fileExtension);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }

        // console.log('desired',desiredString);

        return (
            fileExtension === 'pdf' ?
                // Display PDF and download button
                <>
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {desiredString ? desiredString : ''}
                    </Typography>
                </>
                :
                <img
                    src={image}
                    alt="show-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />
        )
    }

    const handleShowImageClick = () => {
        var image = `${url}${currentImage}`;
        const fileExtension = currentImage.split('.').pop().toLowerCase();
        setIsDownloading(true)
        if (fileExtension === 'pdf') {
            // Extract filename from the URL
            const filename = currentImage.split('/').pop();

            // Handle PDF file download with the correct filename
            const downloadLink = document.createElement('a');
            downloadLink.href = image;
            // downloadLink.target = '_blank';
            downloadLink.download = filename;
            downloadLink.click();
            setIsDownloading(false)
        } else {
            setShowImageKTP(image);
            setOpenLightbox(true);
            setIsDownloading(false)
        }
    };


    return (
        <>
            {/* KTP */}
            <Card
                elevation={3}
                sx={{
                    maxWidth: isMobile || isTablet ? '100%' : '384px',
                    maxHeight: isMobile || isTablet ? '100%' : '500px',
                    width: '100%',
                    height: '100%',
                    border: `1px solid ${DividerColor}`
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '16px',
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '10px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            KTP
                        </Typography>
                        <Box
                            sx={{
                                padding: '4px 8px',
                                backgroundColor: 'rgba(248, 215, 0, 0.20)',
                                borderRadius: '4px',
                                border: `1px solid ${DividerColor}`
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: Dark,
                                    fontSize: '16px',
                                    fonStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                {validasiData ? `${Math.round(validasiData)}%` : '0%'}
                            </Typography>
                        </Box>
                    </Box>
                    <Button
                        onClick={handleSwitchButton}
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: switchButton ? TextGray : mainColor,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '600',
                            lineHeight: 'normal',
                            textTransform: 'none',
                            padding: '0px'
                        }}
                    >
                        {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                    </Button>
                </Box>
                <Box
                    sx={{
                        width: '100%',
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: DividerColor,
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <form onSubmit={handleSubmit}>
                    <Box
                        sx={{
                            padding: '16px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            type="text"
                            label="Nomor Induk Kependudukan (KTP)"
                            placeholder='Masukkan NIK KTP'
                            value={newData.nik}
                            name="nik"
                            error={newData.nik === '' ? undefined : !!KTPErrorMessage}
                            helperText={KTPErrorMessage}
                            onChange={(e) => handleChange(e)}
                            onInput={(e) => {
                                // Filter out non-numeric characters
                                e.target.value = e.target.value.replace(/[^0-9]/g, '');
                            }}
                            autoComplete="off"
                            required
                            disabled={switchButton ? false : true}
                            sx={{
                                marginBottom: isMobile ? '13px' : '16px',
                                fontSize: isMobile ? '11px' : '12px',
                                "& .MuiInputBase-input.Mui-disabled": {
                                    WebkitTextFillColor: "black",
                                },
                                "& .MuiFilledInput-root.Mui-disabled:hover": {
                                    "& fieldset": {
                                        borderBottomColor: mainColor,
                                    },
                                },
                                "& .MuiInputLabel-root.Mui-disabled": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiInputLabel-root": {
                                    color: '#B3B3D4',
                                    fontWeight: 'bold',
                                    fontSize: '12px'
                                },
                                "& .MuiInputLabel-root.Mui-focused": {
                                    color: mainColor,
                                },
                                "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                    color: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:hover:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:before": {
                                    borderBottomColor: mainColor,
                                },
                                "& .MuiFilledInput-underline:focus:after": {
                                    borderBottomColor: mainColor,
                                },
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                {
                                    "-webkit-appearance": "none",
                                    margin: 0,
                                },
                                "& input[type=number]": {
                                    "-moz-appearance": "textfield",
                                },
                            }}
                        />
                        <Typography
                            sx={{
                                color: '#1E222F',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: 'normal',
                                // marginBottom: '10px',
                            }}
                        >
                            Gambar Scan/Foto KTP(Maks 1 MB)
                        </Typography>
                        <Box
                            sx={{
                                display: 'flex',
                                // flexDirection: 'row',
                                // gap: '24px',
                                border: `solid 2px ${DividerColor}`,
                                borderStyle: 'dashed',
                                marginTop: '-8px',
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: isMobile ? '100%' : '190px',
                                width: '100%',
                                height: '100%',
                                overflow: 'hidden',
                            }}
                            onDragOver={(e) => handleDragOver(e)}
                            onDrop={(e) => handleDrop(e)}
                            onDragLeave={handleDragLeave}
                        >
                            <Box
                                sx={{
                                    maxWidth: isMobile ? '350px' : '347px',
                                    maxHeight: '190px',
                                    width: '100%',
                                    height: '190px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    gap: '12px',
                                    position: 'relative',
                                }}
                            >
                                {isDragging.isDraggingKTP && !isMobile && switchButton ?
                                    <Typography
                                        sx={{
                                            fontFamily: 'Jakarta Sans, sans-serif',
                                            fontSize: '13px',
                                            fontStyle: 'normal',
                                            fontWeight: 'bold',
                                            lineHeight: '17,64px',
                                            textAlign: 'center',
                                            color: mainColor
                                        }}
                                    >
                                        Lepaskan untuk mengunggah
                                    </Typography>
                                    :
                                    imageUrl || currentImage ? (
                                        <>
                                            {currentImage ?
                                                handleShowImage()
                                                :
                                                handleShowPDF()
                                            }
                                            {switchButton ?
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        backgroundColor: '#fff',
                                                        borderRadius: '3px',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={handleRemoveImage}
                                                >
                                                    <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                                </Box>
                                                :
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        position: 'absolute',
                                                        top: '4px',
                                                        right: '4px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',
                                                        backgroundColor: '#fff',
                                                    }}
                                                    onClick={handleShowImageClick}
                                                >
                                                    {currentImage && currentImage.split('.').pop().toLowerCase() === 'pdf' ?
                                                        <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                        :
                                                        <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                    }
                                                </Box>
                                            }
                                        </>
                                    ) : (
                                        <>
                                            {!isMobile ?
                                                <>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '13px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            textAlign: 'center'
                                                        }}
                                                    >
                                                        Geser gambar atau PDF ke area ini
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            fontFamily: 'Jakarta Sans, sans-serif',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '400',
                                                            lineHeight: '17,64px',
                                                            color: TextGray
                                                        }}
                                                    >
                                                        atau
                                                    </Typography>
                                                </>
                                                :
                                                undefined
                                            }
                                            <div
                                                onClick={handleImageUpload}
                                                style={{
                                                    cursor: 'pointer',
                                                    padding: '6px 12px',
                                                    border: '1px solid #ccc',
                                                    borderRadius: '4px',
                                                    display: 'inline-block',
                                                    backgroundColor: switchButton ? undefined : bgColor,
                                                    color: switchButton ? undefined : DividerColor,
                                                }}

                                            >
                                                Pilih Gambar/PDF
                                            </div>
                                        </>
                                    )}
                                <input
                                    type="file"
                                    accept="image/*,.pdf"
                                    onChange={(e) => handleFileInputChange(e.target.files[0])}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                    disabled={switchButton ? false : true}
                                />
                            </Box>
                        </Box>
                        <Button
                            fullWidth
                            type='submit'
                            variant='contained'
                            size='medium'
                            sx={{
                                backgroundColor: btnBgColor,
                                textTransform: 'none',
                                color: mainColor,
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: btnBgColor
                                },
                            }}
                            disabled={isSubmitting || !switchButton ? true : false}
                        >
                            {isSubmitting ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Simpan Data'
                            }
                        </Button>
                    </Box>
                </form>
            </Card >
            <LightboxModal
                open={openLightbox}
                close={() => {
                    setOpenLightbox(false)
                    setShowImageKTP('')
                }}
                imageUrl={showImageKTP}
            />
        </>
    )
}

export default KTP