import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useMediaQuery } from '@mui/material';
import { Icon } from '@iconify/react';
import { btnBgColor, mainColor } from '../../Config/Color';
import { useState } from 'react';



const Notification = ({
    open,
    close,
    label,
    message,
    chooseIcon
}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');
    // console.log('selectedCategory', selectedCategory);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        maxWidth: isMobile ? '330px' : '400px',
        // minWidth: isMobile ? '330px' : '600px',
        width: '100%',
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '5px',
        p: 2,
    };

    const [isLoading, setIsLoading] = useState(false)

    return (
        <Modal
            open={open}
            // onClose={close}
            sx={{
                margin:'0px 30px'
            }}
        >
            <Box sx={style}>
                {chooseIcon === 'check' ?
                    <Icon icon='ph:seal-check-fill' style={{ fontSize: '90px', color: mainColor }} />
                    :
                    chooseIcon === 'email' ?
                        <Icon icon='ic:round-mark-email-read' style={{ fontSize: '90px', color: mainColor }} />
                        :
                        <Icon icon='material-symbols:error' style={{ fontSize: '90px', color: mainColor }} />
                }
                <Typography
                    sx={{
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '14px' : '16px',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 'normal',
                        color: mainColor
                    }}
                >
                    {label}
                </Typography>
                <Typography
                    sx={{
                        fontFamily: 'Jakarta Sans, sans-serif',
                        fontSize: isMobile ? '14px' : '16px',
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '25px',
                        color: 'black',
                        textAlign: 'center',
                        margin: '10px 0px',
                    }}
                >
                    {message}
                </Typography>
                <Box
                    sx={{
                        marginTop: '10px',
                        // backgroundColor: 'orange',
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <Button
                        fullWidth
                        onClick={() => {
                            setIsLoading(true)
                            setTimeout(() => {
                                close()
                                setIsLoading(false)
                            }, 1000);
                        }}
                        variant='contained'
                        sx={{
                            backgroundColor: btnBgColor,
                            textTransform: 'none',
                            width: '100%',
                            // maxWidth: '100px',
                            color: 'black', // Change text color to white on hover
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor,
                            },
                        }}
                        disabled={isLoading ? true : false}
                    >
                        {isLoading ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                Tutup
                            </Typography>
                        }
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
}

export default Notification