import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Router from './routes';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import idLocale from 'date-fns/locale/id';

function App() {
  return (
    <LocalizationProvider
      className="maincontainer"
      dateAdapter={AdapterDateFns}
      adapterLocale={idLocale}
    >
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </LocalizationProvider>
  );
}

export default App;
