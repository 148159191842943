import { Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { bgFooter } from '../../Config/Color';
import Logo from '../../../assets/Logo/Logo.png'

const Footer = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    return (
        <>
            <Grid
                container
                sx={{
                    backgroundColor: bgFooter,
                    padding: isMobile ? '7px 10px' : isTablet ? '7px 20px' : '7px 83px',
                    width: '100%',
                    minHeight: '260px', // Change height to minHeight
                    flexShrink: '0',
                }}
            >
                {/* Your footer content goes here */}
                <Grid item xs={12} md={7}>
                    <Box
                        sx={{
                            marginTop: '64px',
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            gap: '16px'
                        }}
                    >
                        <img
                            src={Logo}
                            alt="logo-dinas-pendidikan-&-kebudayaan-papua-tengah"
                            style={{
                                width: ' 40.47px',
                                height: '56px'
                            }}
                        />
                        <Box>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#E6ECFC',
                                    fontSize: '12px',
                                    fonStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                    letterSpacing: '-0.06px',
                                    marginBottom: '2px'
                                }}
                            >
                                Dinas Pendidikan & Kebudayaan
                            </Typography>
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#E6ECFC',
                                    fontSize: '18px',
                                    fonStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: 'normal',
                                    letterSpacing: '4.23px',
                                }}
                            >
                                PAPUA TENGAH
                            </Typography>
                        </Box>
                    </Box>
                    <Box
                        sx={{
                            marginTop: '24px'
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: '#fff',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                            }}
                        >
                            @ dikbudpapuatengah@gmail.com
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: '#fff',
                                fontSize: '14px',
                                fontStyle: 'normal',
                                fontWeight: '400',
                                lineHeight: '20px',
                                marginTop: '16px'
                            }}
                        >
                            Jl. Pepera No. 17 Kel. Karang Mulia Kab. Nabire, Provinsi Papua Tengah
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={2.5} sx={{ marginTop: '64px' }} >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#fff',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                            marginBottom: '20px'
                        }}
                    >
                        Tautan
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'normal',
                            gap: '12px'
                        }}
                    >
                        <a
                            href="https://beasiswapapuatengah.id/tentang-kami/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#fff',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                            >
                                About Us
                            </Typography>
                        </a>

                        <a
                            href="https://beasiswapapuatengah.id/faq/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#fff',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                            >
                                FAQ
                            </Typography>
                        </a>

                        <a
                            href="#"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#fff',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                            >
                                Contact
                            </Typography>
                        </a>

                    </Box>
                </Grid>
                <Grid item xs={12} md={2.5} sx={{ marginTop: '64px', marginBottom: '32px' }} >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#fff',
                            fontSize: '18px',
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '20px',
                            marginBottom: '20px'
                        }}
                    >
                        Website Terkait
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-start',
                            alignItems: 'normal',
                            gap: '12px'
                        }}
                    >
                        <a
                            href="https://disdikpapuatengah.id/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#fff',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                            >
                                Dinas Pendidikan & Kebudayaan Papua Tengah
                            </Typography>
                        </a>

                        <a
                            href="https://papuatengahprov.go.id/"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                                textDecoration: 'none'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    color: '#fff',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '400',
                                    lineHeight: '20px',
                                }}
                            >
                                Provinsi Papua Tengah
                            </Typography>
                        </a>

                    </Box>
                </Grid>

            </Grid >
            <Grid
                container
                sx={{
                    backgroundColor: bgFooter,
                    // padding: isMobile ? '7px 10px' : isTablet ? '7px 20px' : '7px 83px',
                    width: '100%',
                    minHeight: '68px', // Change height to minHeight
                    flexShrink: '0',
                }}
            >
                <Box
                    sx={{
                        width: '100%'
                    }}
                >
                    <Divider
                        variant="string"
                        style={{
                            backgroundColor: "#fff",
                            borderBottomWidth: 0.5,
                            // marginTop: "2px",
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        margin: isMobile ? '14px auto' : '24px auto',
                        padding: isMobile ? '7px 10px' : isTablet ? '7px 20px' : '7px 83px',
                        width: '100%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        gap: isMobile ? '10px' : undefined,
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#fff',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                        }}
                    >
                        Copyright 2023
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: '#fff',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: '20px',
                        }}
                    >
                        Dinas Pendidikan dan Kebudayaan Papua Tengah
                    </Typography>
                </Box>
            </Grid>
        </>
    );
};

export default Footer;