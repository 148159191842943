import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, Card, Button, TextField, Grid } from '@mui/material';
import { Icon } from '@iconify/react';
import { DividerColor, btnBgColor, mainColor } from '../../Config/Color';
import { useFormContext } from '../../../pages/Registrasi/FormContext';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import TooltipUploadGambar from '../../../assets/tooltip/uploadgambarkelas_x.png'
import { decrypt } from 'n-krypta';
import { secretKey } from '../../Config/SecretKey';



const KuisionerS2 = ({ isMobile, isTablet, step3, selectedCategory, selectedJenjangPendidikan }) => {

  const [newData, setNewData] = useState({
    buktiAktifKuliah: '',
  })
  const [loadingSimpanData, setLoadingSimpanData] = useState(false)

  const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

  const steps = [
    'Akses website PDDikti di',
    'Pada kolom pencarian masukkan nama lengkap anda.',
    'Setelah hasil pencarian muncul silakan pilih nama anda dan universitas yang sesuai dengan tempat anda menempuh pendidikan saat ini.',
    'Setelah diarahkan ke halaman detail mahasiswa, copy URL/link pada address bar browser yang anda gunakan.',
    'Paste URL/link yang ada copy ke isian di atas.',
  ];

  const data = localStorage.getItem('data')

  const getAlreadyExistData = (decryptedString) => {

    setNewData({
      buktiAktifKuliah: decryptedString.buktiAktifKuliahExist,
    })

  }

  useEffect(() => {
    if (data) {
      const { dataStep2, cekData } = JSON.parse(data)
      // console.log('key', secretKey);
      if (dataStep2) {
        // console.log('dataStep2', dataStep2);
        if (cekData === 'Ya' && dataStep2) {
          getAlreadyExistData(dataStep2)
        }
      }
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewData(prev => {
      return { ...prev, [name]: value };
    });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    setLoadingSimpanData(true)

    const buktiAktifKuliah = newData.buktiAktifKuliah

    if (buktiAktifKuliah === '') {
      ErrorAlert({
        message: 'Url/Link PDDikti wajib diisi.',
        position: 'top-center'
      })
      setLoadingSimpanData(false)
    } else {
      let values = {
        selectedCategoryExist: selectedCategory,
        selectedJenjangPendidikanExist: selectedJenjangPendidikan,
        buktiAktifKuliahExist: buktiAktifKuliah,
      };

      // Ambil data yang sudah ada dari localStorage
      const existingData = JSON.parse(localStorage.getItem('data')) || {};
      // const { cekData, bypass } = existingData;

      // if ((cekData && cekData === 'Ya') && (bypass && bypass === 'tahap2')) {
      //   existingData.bypass = '';
      // }

      // Tambahkan data baru ke dalam objek existingData
      existingData.dataStep2 = values;

      // Simpan kembali objek yang telah diperbarui ke dalam localStorage
      localStorage.setItem('data', JSON.stringify(existingData));

      setTimeout(() => {
        step3()
        setLoadingSimpanData(false)
      }, 2500);
    }
  }


  return (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={2}>

        {/* URL/Link PDDikti */}
        <Grid item xs={12} sm={12}>
          <Typography
            variant="body1"
            sx={{
              color: '#1E222F',
              fontFamily: 'Jakarta Sans, sans-serif',
              marginBottom: 1,
              fontSize: '14px',
              lineHeight: '18px',
              textAlign: 'justify'
            }}
          >
            Bukti aktif kuliah semester akhir. (URL/Link data mahasiswa dari website PDDikti).
          </Typography>
          <TextField
            fullWidth
            variant="standard"
            type="text"
            // label="Bukti aktif kuliah semester akhir. (URL/Link data mahasiswa dari website PDDikti)."
            placeholder='contoh : https://pddikti.kemdikbud.go.id/data_mahasiswa/MDIwQzAwNTQtQkNFNS00NzNGLThEQUItMjhGQjdENTU0NDhE'
            value={newData.buktiAktifKuliah}
            name="buktiAktifKuliah"
            onChange={(e) => handleChange(e)}
            autoComplete="off"
            required
            sx={{
              marginBottom: isMobile ? '15px' : '24px',
              fontSize: isMobile ? '14px' : '13px',
              "& .MuiInputBase-input.Mui-disabled": {
                WebkitTextFillColor: "white",
              },
              "& .MuiFilledInput-root.Mui-disabled:hover": {
                "& fieldset": {
                  borderBottomColor: mainColor,
                },
              },
              "& .MuiInputLabel-root.Mui-disabled": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiInputLabel-root": {
                color: '#B3B3D4',
                fontWeight: 'bold',
                fontSize: '13px',
              },
              "& .MuiInputLabel-root.Mui-focused": {
                color: mainColor,
              },
              "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                color: mainColor,
              },
              "& .MuiFilledInput-underline:hover:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:hover:after": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:before": {
                borderBottomColor: mainColor,
              },
              "& .MuiFilledInput-underline:focus:after": {
                borderBottomColor: mainColor,
              },
              "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                "-webkit-appearance": "none",
                margin: 0,
              },
              "& input[type=number]": {
                "-moz-appearance": "textfield",
              },
            }}
          />
          <Box sx={{ marginBottom: 2 }}>

            <Box
              sx={{
                backgroundColor: '#F8D70033',
                padding: 2,
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              }}
            >
              <Typography variant="body1"
                sx={{
                  fontSize: '14px',
                  fontFamily: 'Jakarta Sans, sans-serif',
                  fontWeight: 500, textAlign: isMobile ? 'center' : undefined,
                  marginBottom: '10px'
                }}>
                Cara mendapatkan URL/Link PDDikti.
              </Typography>
              {steps.map((step, index) => (
                <Typography
                  key={index + 1}
                  variant="body1"
                  sx={{
                    fontFamily: 'Jakarta Sans, sans-serif',
                    fontWeight: 500,
                    fontSize: '14px',
                    fontStyle: 'normal',
                    lineHeight: '18px',
                    textAlign: 'justify'
                  }}
                >
                  {index + 1}. {index === 0 ? (
                    <>
                      Akses website PDDikti di{' '}
                      <span style={{ color: 'blue', textDecoration: 'underline' }}>
                        https://pddikti.kemdikbud.go.id
                      </span>
                      {' '}
                      menggunakan Google Chrome/Firefox/Edge ataupun web browser lainnya.
                    </>
                  ) : (
                    step
                  )}
                </Typography>
              ))}
            </Box>
          </Box>
          <Box
            align='center'
            sx={{
              width: '100%',
              marginTop: '24px',
              marginBottom: '26px',
            }}>
            <Button
              // fullWidth
              variant='contained'
              size='medium'
              type='submit'
              sx={{
                minWidth: isMobile ? '100%' : '350px',
                fontSize: isMobile ? '12px' : undefined,
                backgroundColor: btnBgColor,
                color: mainColor,
                padding: '10px 76px',
                // fontWeight:'bold',
                '&:hover': {
                  color: 'white', // Change text color to white on hover
                  backgroundColor: mainColor,
                  // fontWeight:'bold',
                },
              }}
              disabled={loadingSimpanData ? true : false}
            >
              {loadingSimpanData ?
                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                :
                'Simpan Data & Lanjutkan'
              }
            </Button>
          </Box>
        </Grid>

      </Grid>
    </form>
  )
}

export default KuisionerS2