import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import TopMenu from '../../components/Header/TopMenu'
import { Icon } from '@iconify/react';
import { Blue, Dark, DividerColor, Green, Maroon, TextGray, White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { dataAggrement, getDataDashboardApplicant, url } from '../../components/Config/Api';
import LoginSessionModal from '../../components/Session/LoginSessionModal';
import { yellow } from '@mui/material/colors';
import LightboxModal from '../../components/Modal/LightboxModal';
import { ToastContainer } from 'react-toastify';
import Raport from '../../components/Dasboard/BerkasPersyaratan/Raport';
import DataBeasiswaKeluargaMiskin from '../../components/Dasboard/BerkasPersyaratan/DataBeasiswaKeluargaMiskin';
import BantuanStudiAkhir from '../../components/Dasboard/BerkasPersyaratan/BantuanStudiAkhir';

const BerkasPersyaratan = () => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const navigate = useNavigate()

    const [biodata, setBiodata] = useState('')
    const [openLoginSessionModal, setOpenLoginSessionModal] = useState(false)
    const [openImageModal, setOpenImageModal] = useState(false)
    const [selectedImage, setSelectedImage] = useState('')

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    console.log(response.data);
                    setBiodata(response.data)
                }).catch((error) => {
                    console.log(error.response.data);
                })

            await axios.get(`${dataAggrement}`)
                .then((response) => {
                    console.log('agreement', response.data);
                    // setBiodata(response.data)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }

    }

    const handleLoginSession = () => {
        const loginSessionExp = localStorage.getItem('LogInfo')
        const res = (new Date()).getTime() > JSON.parse(loginSessionExp).LogSession
        if (res) {
            setOpenLoginSessionModal(true)
            localStorage.removeItem('LogInfo')
        }

    }

    useEffect(() => {
        const dataLogin = localStorage.getItem('LogInfo')
        if (dataLogin) {
            handleLoginSession()
            getData()
        } else if (!dataLogin) {
            setOpenLoginSessionModal(true)
            setTimeout(() => {
                navigate('/login');
            }, 9000);
        }

    }, [])

    const handleCloseLoginSessionModal = () => {
        setOpenLoginSessionModal(false)
        navigate('/login');
    }

    const handleShow = (image) => {
        var imageUrl = `${url}${image}`
        const fileExtension = image ? image.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = image ? image.split('/').pop() : ''
        // console.log('tes', fileExtension);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }


        return (

            fileExtension === 'pdf' ?
                // Display PDF and download button
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        // backgroundColor: 'orange',
                        width: '200px',
                    }}
                >
                    <a
                        href={imageUrl}
                        // target="_blank"
                        rel="noopener noreferrer"
                        download
                        style={{
                            textDecoration: 'none',
                            color: 'inherit',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: "100%"
                        }}
                    >
                        <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '11px',
                                fonStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                textAlign: 'center',
                                marginBottom: '5px'
                            }}
                        >
                            {desiredString}
                        </Typography>
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: mainColor,
                                fontSize: '11px',
                                fonStyle: 'normal',
                                fontWeight: 'bold',
                                lineHeight: 'normal',
                                cursor: 'pointer',
                                textAlign: 'center'
                            }}
                        >
                            Download PDF
                        </Typography>
                    </a>
                </Box>
                :
                <img
                    onClick={() => {
                        setSelectedImage(imageUrl)
                        setOpenImageModal(true)
                    }}
                    src={imageUrl}
                    alt="show-image"
                    style={{
                        // backgroundColor: 'orange',
                        maxWidth: '350px',
                        maxHeight: '200px',
                        height: '100%',
                        width: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                        cursor: 'pointer',
                    }}
                />

        )
    }



    return (
        <>
            <TopMenu />
            <Grid
                container
                spacing={2}
                sx={{
                    padding: isMobile ? '7px 10px' : isTablet ? '7px 33px' : '7px 83px',
                    backgroundColor: bgColor,
                    paddingBottom: '52px',
                    height: '100%'
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // justifyContent: isMobile || isTablet ? 'center' : 'flex-start',
                        // alignItems: 'center',
                        margin: isMobile || isTablet ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Card
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            // justifyContent: isMobile ? 'center' : 'flex-start',
                            // alignItems: 'center',
                            backgroundColor: '#fff',
                            // padding: '0px 10px',
                            // gap: '10px',
                            maxWidth: '100%',
                            width: '100%',
                            height: '300px',
                            // margin:'10px 0px',
                            borderRadius: '8px',
                            border: `1px solid ${DividerColor}`,
                            padding: '10px 20px'
                        }}
                    >
                        <Grid
                            item
                            xs={12}
                            md={5}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                // justifyContent: isMobile ? 'center' : 'flex-start',
                                // borderBottom: `2px solid ${DividerColor}`,
                                // borderRight: `2px solid ${DividerColor}`
                                // alignItems: 'center',
                                // backgroundColor: 'orange',
                                margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // justifyContent: 'flex-start',
                                    // alignItems: 'flex-start',
                                    gap: '16px',
                                    // backgroundColor: 'orange',
                                    width: '100%',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: TextGray,
                                            fontSize: '15px',
                                            fonStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        NIK
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            // justifyContent: 'flex-start',
                                            // alignItems: 'center',
                                            gap: '5px',
                                            marginTop: '3px'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: '14px',
                                                fonStyle: 'normal',
                                                fontWeight: 'bolder',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            {biodata.data?.nik_ktp}
                                        </Typography>
                                        <Icon icon='lets-icons:check-fill' style={{ fontSize: '20px', color: mainColor }} />
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        color: TextGray,
                                        fontSize: '14px',
                                        fonStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginBottom: '-10px'
                                    }}
                                >
                                    Foto KTP
                                </Typography>
                                {handleShow(biodata.data?.ktp_scan)}
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            md={4}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                // justifyContent: 'flex-start',
                                // alignItems: 'flex-start',
                                // borderBottom: `2px solid ${DividerColor}`,
                                // borderRight: `2px solid ${DividerColor}`
                                // backgroundColor: 'orange',
                                margin: isMobile ? '10px 0px' : '10px 0px 0px 0px',
                            }}
                        >
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    // justifyContent: 'flex-start',
                                    // alignItems: 'flex-start',
                                    gap: '16px',
                                    // backgroundColor: 'orange',
                                    width: '100%',
                                }}
                            >
                                <Box>
                                    <Typography
                                        sx={{
                                            fontFamily: 'Plus Jakarta Sans',
                                            color: TextGray,
                                            fontSize: '14px',
                                            fonStyle: 'normal',
                                            fontWeight: '500',
                                            lineHeight: 'normal',
                                        }}
                                    >
                                        Nomor Kartu Keluarga
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            // justifyContent: 'flex-start',
                                            // alignItems: 'center',
                                            gap: '5px',
                                            marginTop: '3px'
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                color: Dark,
                                                fontSize: '14px',
                                                fonStyle: 'normal',
                                                fontWeight: 'bolder',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            {biodata.data?.family_card_number}
                                        </Typography>
                                        <Icon icon='lets-icons:check-fill' style={{ fontSize: '20px', color: mainColor }} />
                                    </Box>
                                </Box>
                                <Typography
                                    sx={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        color: TextGray,
                                        fontSize: '14px',
                                        fonStyle: 'normal',
                                        fontWeight: '500',
                                        lineHeight: 'normal',
                                        marginBottom: '-10px'
                                    }}
                                >
                                    Foto Kartu Keluarga
                                </Typography>
                                {handleShow(biodata.data?.family_card_scan)}
                            </Box>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        // justifyContent: 'flex-start',
                        // alignItems: 'flex-start',
                        margin: isMobile || isTablet ? '10px 0px' : '10px 0px 0px 0px',
                    }}
                >
                    <Card
                        elevation={3}
                        sx={{
                            display: 'flex',
                            flexDirection: isMobile ? 'column' : 'row',
                            // justifyContent: 'flex-start',
                            // alignItems: 'center',
                            backgroundColor: '#fff',
                            // padding: '0px 10px',
                            // gap: '10px',
                            maxWidth: isMobile ? '100%' : '100%',
                            maxHeight: isMobile ? '5000px' : '1800px',
                            width: '100%',
                            height: '100%',
                            // margin:'10px 0px',
                            borderRadius: '8px',
                            border: `1px solid ${DividerColor}`,
                            padding: '20px'
                        }}
                    >
                        {/* Category */}
                        {biodata.data?.category === 'Beasiswa Berprestasi' ?
                            biodata.data?.education_level === 'Strata 1(S1)' ?
                                <Raport
                                    rapor_class_x={biodata.data?.rapor_class_x}
                                    rapor_class_xi={biodata.data?.rapor_class_xi}
                                    rapor_class_xii={biodata.data?.rapor_class_xii}
                                    scan_ijazah_sma={biodata.data?.scan_ijazah_sma}
                                    scan_sertifikat_1={biodata.data?.scan_sertifikat_1}
                                    scan_sertifikat_2={biodata.data?.scan_sertifikat_2}
                                    scan_sertifikat_3={biodata.data?.scan_sertifikat_3}
                                    scan_toefl={biodata.data?.scan_toefl}
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                />
                                :
                                biodata.data?.education_level === 'Strata 2(S2)' ?
                                    <Raport
                                        scan_ijazah_sma={biodata.data?.scan_ijazah_sma}
                                        scan_ijazah_s1={biodata.data?.scan_ijazah_s1}
                                        scan_transkip_s1={biodata.data?.scan_transkip_s1}
                                        scan_sertifikat_1={biodata.data?.scan_sertifikat_1}
                                        scan_sertifikat_2={biodata.data?.scan_sertifikat_2}
                                        scan_sertifikat_3={biodata.data?.scan_sertifikat_3}
                                        scan_toefl={biodata.data?.scan_toefl}
                                        isMobile={isMobile}
                                        isTablet={isTablet}
                                    />
                                    :
                                    biodata.data?.education_level === 'Strata 3(S3)' ?
                                        <Raport
                                            scan_ijazah_sma={biodata.data?.scan_ijazah_sma}
                                            scan_ijazah_s1={biodata.data?.scan_ijazah_s1}
                                            scan_ijazah_s2={biodata.data?.scan_ijazah_s2}
                                            scan_transkip_s1={biodata.data?.scan_transkip_s1}
                                            scan_transkip_s2={biodata.data?.scan_transkip_s2}
                                            scan_sertifikat_1={biodata.data?.scan_sertifikat_1}
                                            scan_sertifikat_2={biodata.data?.scan_sertifikat_2}
                                            scan_sertifikat_3={biodata.data?.scan_sertifikat_3}
                                            scan_toefl={biodata.data?.scan_toefl}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                        />
                                        :
                                        undefined
                            :
                            biodata.data?.category === 'Beasiswa Keluarga Miskin' ?
                                <Raport
                                    scan_skkd={biodata.data?.scan_skkd}
                                    scan_kis={biodata.data?.scan_kis}
                                    rapor_class_x={biodata.data?.rapor_class_x}
                                    rapor_class_xi={biodata.data?.rapor_class_xi}
                                    rapor_class_xii={biodata.data?.rapor_class_xii}
                                    scan_ijazah_sma={biodata.data?.scan_ijazah_sma}
                                    scan_transkip_sma={biodata.data?.scan_transkip_sma}
                                    foto_house_1={biodata.data?.foto_house_1}
                                    foto_house_2={biodata.data?.foto_house_2}
                                    foto_house_3={biodata.data?.foto_house_3}
                                    isMobile={isMobile}
                                    isTablet={isTablet}
                                />
                                :
                                biodata.data?.category === 'Ikatan Dinas' ?
                                    <Raport
                                        rapor_class_x={biodata.data?.rapor_class_x}
                                        rapor_class_xi={biodata.data?.rapor_class_xi}
                                        rapor_class_xii={biodata.data?.rapor_class_xii}
                                        scan_ijazah_sma={biodata.data?.scan_ijazah_sma}
                                        scan_sertifikat_1={biodata.data?.scan_sertifikat_1}
                                        scan_sertifikat_2={biodata.data?.scan_sertifikat_2}
                                        scan_sertifikat_3={biodata.data?.scan_sertifikat_3}
                                        scan_toefl={biodata.data?.scan_toefl}
                                        isMobile={isMobile}
                                        isTablet={isTablet}
                                    />
                                    :
                                    biodata.data?.category === 'Bantuan Studi Akhir' ?
                                        <BantuanStudiAkhir
                                            biodata={biodata}
                                            isMobile={isMobile}
                                            isTablet={isTablet}
                                        />
                                        :
                                        undefined
                        }
                    </Card>
                </Grid>
            </Grid>
            <LoginSessionModal
                open={openLoginSessionModal}
                close={handleCloseLoginSessionModal}
            />
            <LightboxModal
                open={openImageModal}
                close={() => setOpenImageModal(false)}
                imageUrl={selectedImage}
            />
            <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
        </>
    )
}

export default BerkasPersyaratan