import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useState } from 'react';
import { Maroon, TextGray, mainColor } from '../../Config/Color';
import LogoEmail from '../../../assets/Logo/Logo_Email.png'
import { Icon } from '@iconify/react';
import { RegistrasiTahapI, activationEmail, cekVerifikasiEmail, updateDataRegistrasiTahapI } from '../../Config/Api';
import axios from 'axios';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import { useEffect } from 'react';
import { useFormContext } from '../../../pages/Registrasi/FormContext';


const EmailActivationModal = ({
    open,
    close,
    isMobile,
    isTablet,
    newData,
    emailVerifData,
    selectedImageNIK,
    selectedImageKK,
    nextStep,
    countdownTimer,
    errorMessage
}) => {

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? 290 : 350,
        bgcolor: 'background.paper',
        // border: '2px solid #000',
        borderRadius: '5px',
        boxShadow: 24,
        p: 3,
    };

    const [isLoadingBack, setIsLoadingBack] = useState(false)
    const [isLoadingSubmitData, setIsLoadingSubmitData] = useState(false)
    const [otp, setOtp] = useState('')

    const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    const data = localStorage.getItem('data');

    const handleInsertData = async (verification_status) => {
        const formData = new FormData();

        if (data) {

            const { emailVerifData } = JSON.parse(data)

            formData.append('user_id', emailVerifData.userId)
            formData.append('full_name', newData.fullName)
            formData.append('whatsapp_number', newData.whatsapp)
            formData.append('personal_email', newData.email)
            formData.append('nik_ktp', newData.nik)
            formData.append('ktp_scan', selectedImageNIK)
            formData.append('family_card_number', newData.kk)
            formData.append('family_card_scan', selectedImageKK)
            formData.append('mother_name', newData.mommyFullName)
            formData.append('father_name', newData.daddyFullName)
            formData.append('password', newData.password)
            formData.append('password2', newData.konfirmasiPassword)

            if (verification_status === true) {
                try {
                    const response = await axios.post(RegistrasiTahapI, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data',
                        },
                    });

                    if (response.status >= 200 && response.status < 300) {
                        console.log('personalinformation', response.data);
                        const nikBase64 = selectedImageNIK ? await fileToBase64(selectedImageNIK) : null;
                        const kkBase64 = selectedImageKK ? await fileToBase64(selectedImageKK) : null;

                        let cekData = 'Tidak'

                        let values = {
                            userEmailExist: newData.email,
                            userNIKExist: newData.nik,
                            userFullNameExist: newData.fullName,
                            userNoKKExist: newData.kk,
                            userFatherNameExist: newData.daddyFullName,
                            userMotherNameExist: newData.mommyFullName,
                            userWhatsappNumberExist: newData.whatsapp,
                            userPasswordExist: newData.password,
                            userConfirmPasswordExist: newData.konfirmasiPassword,
                            userPersonalInformationId: response.data.data.personal_information_id,
                            selectedImageNIKExist: {
                                lastModified: selectedImageNIK.lastModified,
                                lastModifiedDate: selectedImageNIK.lastModifiedDate,
                                name: selectedImageNIK.name,
                                type: selectedImageNIK.type,
                                size: selectedImageNIK.size,
                                webkitRelativePath: selectedImageNIK.webkitRelativePath,
                                url: selectedImageNIK ? URL.createObjectURL(selectedImageNIK) : null,
                                base64: nikBase64,
                            },
                            selectedImageKKExist: {
                                lastModified: selectedImageKK.lastModified,
                                lastModifiedDate: selectedImageKK.lastModifiedDate,
                                name: selectedImageKK.name,
                                type: selectedImageKK.type,
                                size: selectedImageKK.size,
                                webkitRelativePath: selectedImageKK.webkitRelativePath,
                                url: selectedImageKK ? URL.createObjectURL(selectedImageKK) : null,
                                base64: kkBase64,
                            },
                        }

                        // Ambil data yang sudah ada dari localStorage
                        const existingData = JSON.parse(localStorage.getItem('data')) || {};

                        // Tambahkan data baru ke dalam objek existingData
                        existingData.cekData = cekData;
                        existingData.dataStep1 = values;

                        // Simpan kembali objek yang telah diperbarui ke dalam localStorage
                        localStorage.setItem('data', JSON.stringify(existingData));

                        SuccessAlert({
                            message: 'Aktivasi email berhasil',
                            position: 'top-center'
                        })

                        setTimeout(() => {
                            setOtp('')
                            setIsLoadingSubmitData(false);
                            nextStep()
                        }, 3000);

                    } else {
                        // Handle non-successful response status
                        console.error(`Unexpected response status: ${response.status}`);
                        setIsLoadingSubmitData(false);
                    }
                } catch (error) {
                    console.error(error?.response?.data?.messages);
                    setIsLoadingSubmitData(false);
                    if (error?.response?.data?.messages?.nik_ktp) {
                        ErrorAlert({
                            message: error?.response.data?.messages?.nik_ktp,
                            position: 'top-center'
                        });
                        setIsLoadingSubmitData(false);
                    } else if (error?.response?.data?.messages?.personal_email) {
                        ErrorAlert({
                            message: error?.response.data?.messages?.personal_email,
                            position: 'top-center'
                        });
                        setIsLoadingSubmitData(false);
                    }
                    setOtp('')
                    close()
                }
            } else {
                ErrorAlert({
                    message: 'Silahkan periksa email Anda untuk aktivasi email.',
                    position: 'top-center'
                })
                setIsLoadingSubmitData(false)
            }
        }

    }

    useEffect(() => {
        if (countdownTimer === 0) {
            setOtp('')
        }
    }, [countdownTimer])



    const handleSubmitKonfirmasiEmail = async () => {
        // e.preventDefault();
        setIsLoadingSubmitData(true)

        const data = localStorage.getItem('data')

        if (data) {

            const { emailVerifData } = JSON.parse(data)

            if (otp === emailVerifData.verification_link) {
                await axios.put(`${activationEmail}/${emailVerifData.userId}`, {
                    verification_link: otp
                }).then((response) => {
                    console.log('response activationEmail', response.data);
                    // console.log('status ', response.data?.data?.verification_status);

                    handleInsertData(response.data?.data?.verification_status)

                }).catch((error) => {
                    console.log(error.response.data);
                    setIsLoadingSubmitData(false)

                })
            } else if (otp !== emailVerifData.verification_link) {
                ErrorAlert({
                    message: 'Kode aktivasi salah.',
                    position: 'top-center'
                })
                setIsLoadingSubmitData(false)
            }
            else {
                ErrorAlert({
                    message: 'Kode aktivasi wajib diisi.',
                    position: 'top-center'
                })
                setIsLoadingSubmitData(false)

            }
        }

    }

    const handleChange = (newValue) => {
        setOtp(newValue)
    }

    return (
        <div>
            <Modal
                open={open}
                // onClose={close}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    {errorMessage && errorMessage === 'The email has already been taken.' ?
                        <>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '15px' : '16px',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                Gunakan kode aktivasi yang telah dikirimkan ke email {' '}
                                <span style={{ fontFamily: 'Jakarta Sans, sans-serif', fontStyle: 'italic', fontWeight: 'bold', color: mainColor }}>{newData.email}</span>,
                                silahkan periksa mailbox atau spambox Anda.

                            </Typography>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px'
                                }}
                            >
                                <img src={LogoEmail} alt='logo-email' style={{ width: '90px', height: '90px' }} />
                            </Box>

                            {/* <Icon icon='email' style={{ fontSize: '50px', color: mainColor }} /> */}
                            <MuiOtpInput
                                value={otp}
                                length={5}
                                onChange={handleChange}
                                // validateChar={matchIsNumeric}
                                autoFocus
                                TextFieldsProps={{
                                    // disabled: true,
                                    size: 'small'
                                }}
                            />
                        </>
                        :
                        <>
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: isMobile ? '15px' : '16px',
                                    fontWeight: '500',
                                    lineHeight: '24px',
                                    textAlign: 'center',
                                    marginBottom: '10px'
                                }}
                            >
                                Kami telah mengirimkan kode aktivasi ke email {' '}
                                <span style={{ fontFamily: 'Jakarta Sans, sans-serif', fontStyle: 'italic', fontWeight: 'bold', color: mainColor }}>{newData.email}</span>,
                                Masukkan kode untuk aktifkan email Anda.

                            </Typography>
                            {/* <Icon icon='email' style={{ fontSize: '50px', color: mainColor }} /> */}
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    marginBottom: '15px'
                                }}
                            >
                                <img src={LogoEmail} alt='logo-email' style={{ width: '90px', height: '90px' }} />
                            </Box>
                            <MuiOtpInput
                                value={otp}
                                length={5}
                                onChange={handleChange}
                                // validateChar={matchIsNumeric}
                                autoFocus
                                TextFieldsProps={{
                                    // disabled: true,
                                    size: 'small'
                                }}
                            />
                        </>

                    }
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            gap: isMobile ? '30px' : '80px',
                            marginTop: "30px"
                        }}
                    >
                        <Button
                            fullWidth
                            size='medium'
                            variant='contained'
                            onClick={() => {
                                setIsLoadingBack(true)
                                // setIsLoadingSubmitData(true)
                                setTimeout(() => {
                                    // resetCountdown()
                                    setIsLoadingBack(false)
                                    // setIsLoadingSubmitData(false)
                                    setOtp('')
                                    close()
                                }, 1500);
                            }}
                            sx={{
                                backgroundColor: Maroon,
                                textTransform: 'none',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: Maroon,
                                },
                            }}
                            disabled={isLoadingBack || isLoadingSubmitData ? true : false}
                        >
                            {isLoadingBack ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Kembali'
                            }
                        </Button>
                        <Button
                            fullWidth
                            size='medium'
                            variant='contained'
                            onClick={handleSubmitKonfirmasiEmail}
                            sx={{

                                backgroundColor: mainColor,
                                textTransform: 'none',
                                fontFamily: 'Jakarta Sans, sans-serif',
                                fontSize: '12px',
                                '&:hover': {
                                    color: 'white', // Change text color to white on hover
                                    backgroundColor: mainColor,
                                },
                            }}
                            disabled={isLoadingBack || isLoadingSubmitData ? true : false}
                        >
                            {isLoadingSubmitData ?
                                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                :
                                'Aktivasi'

                            }

                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}

export default EmailActivationModal