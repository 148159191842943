import React, { useEffect, useRef, useState } from 'react';
import { Grid, useMediaQuery, Typography, Box, Button, Card, TextField, Tooltip, IconButton, ClickAwayListener, InputAdornment, Popper, Paper, Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { White, bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import LogoGoogle from '../../assets/Logo/Google_Logo.png'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserPersonalInformation, userLogin } from '../../components/Config/Api';
import SuccessAlert from '../../components/ToastyAlert/SuccessAlert';
import Top from '../../components/Register/Top/Top';
import Footer from '../../components/Register/Footer/Footer';
import ErrorAlert from '../../components/ToastyAlert/ErrorAlert';

const Login = () => {

    const navigate = useNavigate()

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingForgotPassword, setIsLoadingForgotPassword] = useState(false)
    const [isLoadingGoogle, setIsLoadingGoogle] = useState(false)
    const [isLoadingDaftarBeasiswa, setIsLoadingDaftarBeasiswa] = useState(false)
    const [newData, setNewData] = useState({
        email: '',
        nik: '',
        password: ''
    })
    const [showPassword, setShowPassword] = useState(false);

    useEffect(() => {

        const dataLogin = localStorage.getItem('LogInfo')

        if (dataLogin) {
            navigate('/dashboard-applicant')
        } else {
            navigate('/login')
        }

    }, [])

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword);
    };

    const handleClickDaftarBeasiswa = () => {
        setIsLoadingDaftarBeasiswa(true)
        setTimeout(() => {
            setIsLoadingDaftarBeasiswa(false)
            navigate('/register')
        }, 1500);
    }

    const getUserInformationId = async (token, userId, responseUserLogin) => {
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;

        await axios.get(getUserPersonalInformation)
            .then((response) => {
                // const filteredData = response.data.filter(user => user.user_id === userId);
                // console.log('filteredData', filteredData);
                console.log('response', response.data);


                // Simpan data ke dalam localStorage
                localStorage.setItem('LogInfo', JSON.stringify({
                    userName: responseUserLogin.user.name,
                    role: responseUserLogin.user.role,
                    email: responseUserLogin.user.email,
                    Id: responseUserLogin.user.user_id,
                    token: responseUserLogin.authorization.token,
                    userPersonalInformationId: response.data.personal_information_id
                }));
                SuccessAlert({
                    message: 'Berhasil Login',
                    position: 'top-center'
                });
                setTimeout(() => {
                    navigate('/dashboard-applicant')
                    setIsLoading(false)
                }, 3000);
            })
            .catch((error) => {
                console.log(error.response.data);
            });
    };

    const handleSubmitAkun = async (e) => {
        e.preventDefault()
        setIsLoading(true)


        await axios.post(userLogin, {
            email: newData.email,
            password: newData.password
        }).then((response) => {
            console.log(response.data);

            // if (response.data.authorization.token) {
            //     getUserInformationId(response.data.authorization.token, response.data.user.user_id, response.data)
            // }

            const loginSession = new Date().setMinutes(new Date().getMinutes() + 30)
            // console.log('tes', response.data.sup);
            // Simpan data ke dalam localStorage
            localStorage.setItem('LogInfo', JSON.stringify({
                userName: response.data.user.name,
                role: response.data.user.role,
                email: response.data.user.email,
                Id: response.data.user.user_id,
                token: response.data.authorization.token,
                LogSession: loginSession,
                sup: response.data.user.sup,
                // userPersonalInformationId: response.data.personal_information_id
            }));
            SuccessAlert({
                message: 'Berhasil Login',
                position: 'top-center'
            });
            setTimeout(() => {
                navigate('/dashboard-applicant')
                setIsLoading(false)
            }, 2500);

        }).catch((error) => {
            console.log(error.response.data);
            if (error.response.data.message === "The given data was invalid.") {
                ErrorAlert({
                    message: 'Format Email Tidak Valid.',
                    position: 'top-center'
                })
                setIsLoading(false)
            } else if (error.response.data.message === "Unauthorized") {
                ErrorAlert({
                    message: 'Email/Password Salah.',
                    position: 'top-center'
                })
                setIsLoading(false)
            } else if (error.response.data.message) {
                ErrorAlert({
                    message: error.response.data.message,
                    position: 'top-center'
                })
                setIsLoading(false)
            }
        })
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setNewData(prev => {
            return { ...prev, [name]: value };
        });

    };


    return (
        <>
            <Top />
            <Grid
                container
                sx={{
                    backgroundColor: bgColor,
                    padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
                    height: 'auto', // Change height to auto
                    overflow: 'hidden',
                    minHeight: 'calc(100vh - 300px)',
                }}
            >
                <Grid
                    item
                    xs={12}
                    md={12}
                    sx={{
                        height: isMobile ? '8%' : '10%', // Adjust height based on screen size
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
                        // backgroundColor:' orange'
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            // gap: '10px'
                        }}
                    >
                        <Button
                            onClick={() => {
                                window.location.href = 'https://beasiswapapuatengah.id'
                            }}
                            sx={{
                                textTransform: 'none',
                                color: 'black',
                                '&:hover': {
                                    color: mainColor, // Change text color to white on hover
                                    backgroundColor: bgColor
                                },
                            }}
                        >
                            <Icon icon='material-symbols:arrow-back' />
                            <Typography
                                sx={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontSize: '13px',
                                    fontWeight: '400',
                                    lineHeight: '20.16px',
                                    cursor: 'pointer',
                                    marginLeft: '10px'
                                }}
                            >
                                Kembali ke Beranda
                            </Typography>
                        </Button>
                    </Box>
                    <Button
                        onClick={handleClickDaftarBeasiswa}
                        variant='contained'
                        size='small'
                        sx={{
                            minWidth: '150px',
                            backgroundColor: btnBgColor,
                            color: mainColor,
                            fontSize: '13px',
                            fontWeight: 'bold',
                            textTransform: 'none',
                            padding: isMobile ? '0x 10px' : undefined,
                            '&:hover': {
                                color: 'white', // Change text color to white on hover
                                backgroundColor: mainColor
                            },
                        }}
                    >
                        {isLoadingDaftarBeasiswa ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px', color: White }} />
                            :
                            'Daftar Beasiswa'
                        }
                    </Button>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={12}
                >
                    <form onSubmit={handleSubmitAkun}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '47px',
                            width: '100%'
                            //    backgroundColor:'orange'
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: isMobile ? '27px' : '47px',
                                // width: '100%'
                            }}
                        >
                            <Typography
                                sx={{
                                    fontFamily: 'Jakarta Sans, sans-serif',
                                    fontSize: '22px',
                                    fontStyle: 'normal',
                                    fontWeight: '600',
                                    lineHeight: 'normal',
                                }}
                            >
                                Masuk
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                // maxWidth: '400px',
                                // backgroundColor: 'orange',
                                width: isMobile ? '100%' : isTablet ? '50%' : '30%',
                                gap: '10px'
                            }}
                        >
                            <TextField
                                fullWidth
                                variant="standard"
                                type="text"
                                label="Email / NIK"
                                placeholder='Masukkan email atau NIK'
                                value={newData.email}
                                name="email"
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                required
                                sx={{
                                    marginBottom: isMobile ? '15px' : '24px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="standard"
                                type={showPassword ? 'text' : 'password'}
                                label="Kata Sandi"
                                placeholder='Masukkan kata sandi'
                                value={newData.password}
                                name="password"
                                onChange={(e) => handleChange(e)}
                                autoComplete="off"
                                required
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Icon
                                                onClick={handleClickShowPassword}
                                                icon={showPassword ? 'streamline:eye-optic' : 'humbleicons:eye-close'}
                                                // onClick={() => setOpenPopperNIK(true)}
                                                style={{ fontSize: '22px', cursor: 'pointer', color: mainColor }}
                                            // ref={anchorRefNIK}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    marginBottom: isMobile ? '15px' : '24px',
                                    fontSize: isMobile ? '14px' : '13px',
                                    "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "white",
                                    },
                                    "& .MuiFilledInput-root.Mui-disabled:hover": {
                                        "& fieldset": {
                                            borderBottomColor: mainColor,
                                        },
                                    },
                                    "& .MuiInputLabel-root.Mui-disabled": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: '#B3B3D4',
                                        fontWeight: 'bold',
                                        fontSize: '13px'
                                    },
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: mainColor,
                                    },
                                    "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                        color: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:hover:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:before": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& .MuiFilledInput-underline:focus:after": {
                                        borderBottomColor: mainColor,
                                    },
                                    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                    {
                                        "-webkit-appearance": "none",
                                        margin: 0,
                                    },
                                    "& input[type=number]": {
                                        "-moz-appearance": "textfield",
                                    },
                                }}
                            />
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'end',
                                textAlign: 'center',
                                width: isMobile ? '100%' : isTablet ? '50%' : '30%',
                                marginBottom: '14px'
                                // backgroundColor:'orange',
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setIsLoadingForgotPassword(true)
                                    setTimeout(() => {
                                        navigate('/lupa-kata-sandi')
                                        setIsLoadingForgotPassword(false)
                                    }, 1000);
                                }}
                                sx={{
                                    padding: '0px',
                                    textTransform: 'none',
                                    color: mainColor,
                                    // font-family: Plus Jakarta Sans;
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                                disabled={isLoadingForgotPassword ? true : false}
                            >
                                {isLoadingForgotPassword ?
                                    <Icon icon='svg-spinners:3-dots-move' style={{ fontSize: '20px', marginLeft: '30px' }} />
                                    :
                                    'Lupa Kata Sandi?'
                                }
                            </Button>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                width: isMobile ? '100%' : isTablet ? '50%' : '30%',
                                marginTop: '14px',
                                marginBottom: '56px',
                                gap: '16px'
                            }}>
                            <Button
                                fullWidth
                                variant='contained'
                                size='medium'
                                type='submit'
                                // onClick={() => handleSubmitAkun()}
                                sx={{
                                    fontSize: isMobile ? '12px' : undefined,
                                    backgroundColor: btnBgColor,
                                    color: mainColor,
                                    padding: '10px 0px',
                                    textTransform: 'none',
                                    fontWeight: 'bold',
                                    '&:hover': {
                                        color: 'white', // Change text color to white on hover
                                        backgroundColor: mainColor,
                                        // fontWeight:'bold',
                                    },
                                }}
                                disabled={isLoading || isLoadingForgotPassword ? true : false}
                            >
                                {isLoading ?
                                    <Icon icon='svg-spinners:tadpole' style={{ fontSize: isMobile ? '21px' : '24.5px' }} />
                                    :
                                    'Masuk Akun'
                                }
                            </Button>
                            {/* <Typography
                                sx={{
                                    color: mainColor,
                                    fontSize: '16px',
                                    fontStyle: 'normal',
                                    fontWeight: '500',
                                    lineHeight: 'normal',
                                }}
                            >
                                Atau
                            </Typography>
                            <Button
                                fullWidth
                                variant='contained'
                                size='medium'
                                // onClick={() => handleSubmit()}
                                sx={{
                                    fontSize: isMobile ? '12px' : undefined,
                                    backgroundColor: 'white',
                                    textTransform: 'none',
                                    padding: '10px',
                                    // fontWeight:'bold',
                                    '&:hover': {
                                        color: 'white', // Change text color to white on hover
                                        backgroundColor: '#fff',
                                        // fontWeight:'bold',
                                    },
                                }}
                            >
                                {isLoadingGoogle ?
                                    <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                                    :
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '15px'
                                        }}
                                    >
                                        <img src={LogoGoogle} alt='logo-google' />
                                        <Typography
                                            sx={{
                                                color: 'rgba(0, 0, 0, 0.54)',
                                                // fontFamily: 'Roboto',
                                                fontSize: '15px',
                                                fontStyle: 'normal',
                                                fontWeight: '600',
                                                lineHeight: 'normal',
                                            }}
                                        >
                                            Lanjutkan dengan Google
                                        </Typography>
                                    </Box>
                                }
                            </Button> */}
                        </Box>
                    </form>
                </Grid>
            </Grid >
            <Footer />
            <ToastContainer style={{ width: 'auto', minWidth: '300px' }} />
        </>
    )
}

export default Login