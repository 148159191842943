import React, { useEffect, useRef, useState } from 'react';
import { bgColor, btnBgColor, mainColor } from '../../components/Config/Color';
import { Grid, useMediaQuery, Typography, Box, Button, Card, TextField, Tooltip, IconButton, ClickAwayListener, InputAdornment, Popper, Paper, Grow } from '@mui/material';
import { Icon } from '@iconify/react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Form from './Form';
import FormTahap2 from './FormTahap2';
import FormTahap3 from './FormTahap3';
import { FormProvider } from './FormContext';
import { useNavigate } from 'react-router-dom';
import Footer from '../../components/Register/Footer/Footer';
import Top from '../../components/Register/Top/Top';
import axios from 'axios';
import { deletepersonalinformation } from '../../components/Config/Api';

const Registrasi = () => {

  const navigate = useNavigate()

  const isMobile = useMediaQuery('(max-width:600px)');
  const isTablet = useMediaQuery('(max-width:900px)');

  const [isLoading, setIsLoading] = useState(false)
  const [hideButton, setHideButton] = useState(false)

  const [registrasi, setRegistrasi] = useState({
    step1: true,
    step2: false,
    step3: false,
  })

  useEffect(() => {
    const dataRegistered = localStorage.getItem('data')
    if (dataRegistered) {
      if (JSON.parse(dataRegistered).cekData === 'Ya') {
        setHideButton(true)
        // console.log('hideButton true');
      }
    }
    // console.log('logic', ((registrasi.step1 || registrasi.step2 || registrasi.step3) && hideButton) || ((registrasi.step2 || registrasi.step3)))
  }, [registrasi.step1, registrasi.step2, registrasi.step3])

  useEffect(() => {
    const dataLogin = localStorage.getItem('LogInfo')
    if (dataLogin) {
      navigate('/dashboard-applicant')
    } else {
      navigate('/register')
    }

  }, [])

  const handleClickMasukAkun = () => {
    setIsLoading(true)

    const item = localStorage.getItem('item');

    setTimeout(() => {
      setIsLoading(false)
      navigate('/login')
    }, 1500);

    // if (item) {
    //   const user_id = JSON.parse(item).user_id;

    //   console.log('user_id', user_id);

    //   const formData = new FormData();
    //   formData.append('user_id', user_id);

    //   axios.delete(`${deletepersonalinformation}/${user_id}`, formData)

    //   // Remove 'item' key after sending the data
    //   localStorage.removeItem('item');

    //   setTimeout(() => {
    //     setIsLoading(false)
    //     navigate('/login')
    //   }, 1500);
    // } else {
    //   setTimeout(() => {
    //     setIsLoading(false)
    //     navigate('/login')
    //   }, 1500);
    // }

  }

  return (
    <>
      <Top />
      <Grid
        container
        sx={{
          backgroundColor: bgColor,
          padding: isMobile ? '7px 15px' : isTablet ? '7px 20px' : '7px 83px',
          height: 'auto', // Change height to auto
          overflow: 'hidden',
          minHeight: 'calc(100vh - 300px)', // Ensure content takes at least the full viewport height minus header/footer
        }}
      >
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            height: isMobile ? '8%' : '10%', // Adjust height based on screen size
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: isMobile ? '13px' : isTablet ? '13px' : '23px',
            // backgroundColor:' orange'
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // gap: '10px'
            }}
          >
            <Button
              // variant='outlined'
              onClick={() => {
                window.location.href = 'https://beasiswapapuatengah.id'
              }}
              sx={{
                textTransform: 'none',
                color: 'black',
                '&:hover': {
                  color: mainColor, // Change text color to white on hover
                  backgroundColor: bgColor
                },
              }}
            >
              <Icon icon='material-symbols:arrow-back' />
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  fontSize: isMobile ? '12px' : '13px',
                  fontWeight: '400',
                  lineHeight: '20.16px',
                  cursor: 'pointer',
                  marginLeft: '10px'
                }}
              >
                Kembali ke Beranda
              </Typography>
            </Button>
          </Box>
          {((registrasi.step1 || registrasi.step2 || registrasi.step3) && hideButton) || ((registrasi.step2 || registrasi.step3) && !hideButton) ?
            undefined
            :
            <Button
              onClick={handleClickMasukAkun}
              variant='contained'
              size='small'
              sx={{
                minWidth: isMobile ? '120px' : '150px',
                // maxWidth:'100px',
                backgroundColor: btnBgColor,
                color: mainColor,
                fontSize: '13px',
                fontWeight: 'bold',
                textTransform: 'none',
                padding: isMobile ? '0x 10px' : undefined,
                '&:hover': {
                  color: 'white', // Change text color to white on hover
                  backgroundColor: mainColor
                },
              }}
            // disabled={isLoading ? true : false}
            >
              {isLoading ?
                <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                :
                'Masuk Akun'
              }
            </Button>
          }
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            margin: isMobile ? '40px 0px 0px 0px' : '30px 0px 34px 0px',
            height: '5%', // You can adjust the height of other content items if needed
            // backgroundColor: 'orange',
          }}
        >
          <Box
            sx={{
              marginBottom: isMobile ? '20px' : '10px'
            }}
          >
            <Typography
              sx={{
                fontFamily: 'Jakarta Sans, sans-serif',
                fontSize: isMobile ? '18px' : '22px',
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: 'normal',
                color: '#1E222F'
              }}
            >
              Pendaftaran Beasiswa Papua Tengah
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={12}
          sx={{
            height: '70%', // You can adjust the height of other content items if needed
            overflowY: 'auto'
            // backgroundColor: 'orange',
          }}
        >
          <Card
            sx={{
              display: 'flex',
              // justifyContent:'center',
              // alignItems:'center'
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '10px',
                padding: isMobile ? '10px' : '15px',
                backgroundColor: registrasi.step1 ? mainColor : 'white',
                width: '33.3%',
                // cursor: 'pointer',
                borderRadius: '5px'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: registrasi.step1 ? 'white' : 'black',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Tahap I
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: registrasi.step1 ? 'white' : 'black',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: isMobile ? '13px' : '14px',
                  lineHeight: isMobile ? '21px' : 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Registrasi Akun
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '10px',
                padding: '15px',
                backgroundColor: registrasi.step2 ? mainColor : 'white',
                width: '33.3%',
                // cursor: 'pointer',
                borderRadius: '5px'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: registrasi.step2 ? 'white' : 'black',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Tahap II
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: registrasi.step2 ? 'white' : 'black',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: isMobile ? '13px' : '14px',
                  lineHeight: isMobile ? '21px' : 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Lengkapi Persyaratan
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                gap: '10px',
                padding: '15px',
                backgroundColor: registrasi.step3 ? mainColor : 'white',
                width: '33.3%',
                // cursor: 'pointer',
                borderRadius: '5px'
              }}
            >
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: registrasi.step3 ? 'white' : 'black',
                  fontSize: '12px',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Tahap III
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Jakarta Sans, sans-serif',
                  color: registrasi.step3 ? 'white' : 'black',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  fontSize: isMobile ? '13px' : '14px',
                  lineHeight: isMobile ? '21px' : 'normal',
                  textAlign: 'left', // Set text alignment to left
                }}
              >
                Menunggu Verifikasi Data
              </Typography>
            </Box>
          </Card>
          <Box
            sx={{
              margin: isMobile ? '46px 0px 12px 0px' : '56px 0px 24px 0px',
              padding: isMobile ? '0px 0px' : isTablet ? '0px 50px' : '0px 160px',
            }}
          >
            <FormProvider>
              {registrasi.step1 ? (
                <Form
                  isMobile={isMobile}
                  isTablet={isTablet}
                  nextStep={() => setRegistrasi({ step1: false, step2: true, step3: false })}
                  step3={() => setRegistrasi({ step1: false, step2: false, step3: true })}
                />
              ) : registrasi.step2 ? (
                <FormTahap2
                  isMobile={isMobile}
                  isTablet={isTablet}
                  step3={() => setRegistrasi({ step1: false, step2: false, step3: true })}
                />
              ) : (
                <FormTahap3
                  isMobile={isMobile}
                  isTablet={isTablet}
                  backStep1={() => setRegistrasi({ step1: true, step2: false, step3: false })}
                  backStep2={() => setRegistrasi({ step1: false, step2: true, step3: false })}
                />
              )}
            </FormProvider>
          </Box>
        </Grid>
        <ToastContainer style={{ width: '100%', maxWidth: isMobile ? '100%' : '350px' }} />
      </Grid>
      <Footer />
    </>
  );
};

export default Registrasi;
