import React, { useRef, useState, useEffect } from 'react'

const Countdown = ({time}) => {
    const [countdownTimer, setCountdownTimer] = useState(time)
    const timerId = useRef()

    useEffect(() => {
      timerId.current = setInterval(() => {
        setCountdownTimer(prev => prev -1)
      }, 1000)
      return () => clearInterval(timerId.current)
    }, [])
    
    useEffect(() => {
      if(countdownTimer <= 0){
        clearInterval(timerId.current)
      }
    }, [countdownTimer])

  return (
    <>{countdownTimer}</>
  )
}

export default Countdown