import { Box, Button, Card, Divider, TextField, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react'
import { Dark, DividerColor, Green, TextGray, bgColor, btnBgColor, mainColor } from '../../Config/Color';
import { Icon } from '@iconify/react/dist/iconify.js';
import ErrorAlert from '../../ToastyAlert/ErrorAlert';
import { getDataDashboardApplicant, updateDataPasspotrAndVisa, url } from '../../Config/Api';
import LightboxModal from '../../Modal/LightboxModal';
import axios from 'axios';
import SuccessAlert from '../../ToastyAlert/SuccessAlert';
import InfoAlert from '../../ToastyAlert/InfoAlert';
import { yellow } from '@mui/material/colors';

const PassportAndVisa = ({ completeData, handleExpired, dataCompletenessPassportAndVisa }) => {

    const isMobile = useMediaQuery('(max-width:600px)');
    const isTablet = useMediaQuery('(max-width:900px)');

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [switchButton, setSwitchButton] = useState(false)

    const [showImagePassport, setShowImagePassport] = useState('')
    const [showImageVisa, setShowImageVisa] = useState('')
    const [showImageStudyPermit, setShowImageStudyPermit] = useState('')
    const [openLightbox, setOpenLightbox] = useState(false)

    const [currentImagePassport, setCurrentImagePassport] = useState(null);
    const [selectedImagePassport, setSelectedImagePassport] = useState(null);
    const [imageUrlPassport, setImageUrlPassport] = useState(null);
    const fileInputRefPassport = useRef(null);

    const [currentImageVisa, setCurrentImageVisa] = useState(null);
    const [selectedImageVisa, setSelectedImageVisa] = useState(null);
    const [imageUrlVisa, setImageUrlVisa] = useState(null);
    const fileInputRefVisa = useRef(null);

    const [currentImageStudyPermit, setCurrentImageStudyPermit] = useState(null);
    const [selectedImageStudyPermit, setSelectedImageStudyPermit] = useState(null);
    const [imageUrlStudyPermit, setImageUrlStudyPermit] = useState(null);
    const fileInputRefStudyPermit = useRef(null);

    const [isDragging, setIsDragging] = useState({
        isDraggingPicturePassport: false,
        isDraggingPictureVisa: false,
        isDraggingPictureStudyPermit: false,
    });

    const [newData, setNewData] = useState({
        nomorPassport: '',
        nomorVisa: ''
    });

    const MAX_IMAGE_SIZE_MB = 1; // Set your maximum image size in megabytes

    const refreshData = async () => {
        // console.log(switchButton);
        if (switchButton) {
            const getDataLogin = localStorage.getItem('LogInfo')
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    setNewData({
                        nomorPassport: response?.data?.data?.no_passport,
                        nomorVisa: response?.data?.data?.no_visa
                    })
                    setCurrentImagePassport(response?.data?.data?.scan_passport)
                    setCurrentImageVisa(response?.data?.data?.scan_visa)
                    setCurrentImageStudyPermit(response?.data?.data?.scan_study_permit)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    }

    const getData = async () => {
        const getDataLogin = localStorage.getItem('LogInfo')
        if (getDataLogin) {
            const user_id = JSON.parse(getDataLogin).Id
            const token = JSON.parse(getDataLogin).token
            const email = JSON.parse(getDataLogin).email

            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`

            await axios.get(`${getDataDashboardApplicant}/${user_id}`)
                .then((response) => {
                    setNewData({
                        nomorPassport: response?.data?.data?.no_passport,
                        nomorVisa: response?.data?.data?.no_visa
                    })
                    setCurrentImagePassport(response?.data?.data?.scan_passport)
                    setCurrentImageVisa(response?.data?.data?.scan_visa)
                    setCurrentImageStudyPermit(response?.data?.data?.scan_study_permit)
                }).catch((error) => {
                    console.log(error.response.data);
                })
        }
    };

    useEffect(() => {
        getData();
    }, []);

    const handleImageUpload = (fileInputRef, option) => {
        fileInputRef.current.click();
    };

    const handleDragOver = (e, option) => {
        e.preventDefault();
        // console.log('handleDragOver', e);
        if (option === 'passport') {
            setIsDragging({
                isDraggingPicturePassport: true
            })
        } else if (option === 'visa') {
            setIsDragging({
                isDraggingPictureVisa: true
            })
        } else if (option === 'study permit') {
            setIsDragging({
                isDraggingPictureStudyPermit: true
            })
        }
    };

    const handleDrop = (e, option) => {
        e.preventDefault();
        // console.log('handledrop');
        const file = e.dataTransfer.files[0];
        // console.log('handleDrop', file);
        handleFileInputChange(file, option);
        if (option === 'passport') {
            setIsDragging({
                isDraggingPicturePassport: false
            })
        } else if (option === 'visa') {
            setIsDragging({
                isDraggingPictureVisa: false
            })
        } else if (option === 'study permit') {
            setIsDragging({
                isDraggingPictureStudyPermit: false
            })
        }
    };

    const handleDragLeave = (e, option) => {
        // console.log('dragleave');
        e.preventDefault();
        if (option === 'passport') {
            setIsDragging({
                isDraggingPicturePassport: true
            })
        } else if (option === 'visa') {
            setIsDragging({
                isDraggingPictureVisa: true
            })
        } else if (option === 'study permit') {
            setIsDragging({
                isDraggingPictureStudyPermit: true
            })
        }
    };

    const handleFileInputChange = (file, option) => { // Menyesuaikan nama fungsi
        // console.log(file);
        if (file) {
            const fileType = file.type;
            const allowedImageTypes = ['image/jpeg', 'image/png'];
            const allowedPdfTypes = ['application/pdf'];

            const fileSizeInMB = file.size / (1024 * 1024);
            if (fileSizeInMB > MAX_IMAGE_SIZE_MB) {
                ErrorAlert({
                    message: 'Ukuran Maksimal Foto 1 MB',
                    position: 'top-center'
                });
                return;
            } else {
                if (allowedImageTypes.includes(fileType)) {
                    if (option === 'passport') {
                        setSelectedImagePassport(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlPassport(imageUrl);
                    } else if (option === 'visa') {
                        setSelectedImageVisa(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlVisa(imageUrl);
                    } else if (option === 'study permit') {
                        setSelectedImageStudyPermit(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlStudyPermit(imageUrl);
                    }
                } else if (allowedPdfTypes.includes(fileType)) {
                    if (option === 'passport') {
                        setSelectedImagePassport(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlPassport(imageUrl);
                    } else if (option === 'visa') {
                        setSelectedImageVisa(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlVisa(imageUrl);
                    } else if (option === 'study permit') {
                        setSelectedImageStudyPermit(file);
                        const imageUrl = URL.createObjectURL(file);
                        setImageUrlStudyPermit(imageUrl);
                    }
                } else {
                    console.error('Unsupported file type');
                    ErrorAlert({
                        message: 'Hanya menerima file jpeg, png dan pdf.',
                        position: 'top-center'
                    })
                    return;
                }
            }
        }
    };

    const handleRemoveImage = (option) => {
        if (option === 'passport') {
            setSelectedImagePassport(null);
            setImageUrlPassport(null);
            setCurrentImagePassport(null)
        } else if (option === 'visa') {
            setSelectedImageVisa(null);
            setImageUrlVisa(null);
            setCurrentImageVisa(null)
        } else if (option === 'study permit') {
            setSelectedImageStudyPermit(null);
            setImageUrlStudyPermit(null);
            setCurrentImageStudyPermit(null)
        }
    };

    const handleShowPDF = (currentFile, imageUrl) => {
        const fileExtension = currentFile ? currentFile.type.split('/').pop().toLowerCase() : ''
        // console.log('handleShowPDF', currentFile);

        return (
            // Display PDF and download button
            fileExtension === 'pdf' ?
                <Button
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        textTransform: 'none',
                        gap: '10px',

                        // backgroundColor:'orange'
                    }}
                >
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {currentFile.name}
                    </Typography>
                </Button>
                :
                <img
                    src={imageUrl}
                    alt="selected-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />

        )
    }


    const handleShowImage = (currentImage) => {
        // console.log('currentImage show image', currentImage);

        var image = `${url}${currentImage}`
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''
        // console.log('tesadsad', fileExtension);

        // Extract filename from the URL
        const filename = currentImage ? currentImage.split('/').pop() : ''
        // console.log('tes', filename);

        let desiredString = ''
        if (fileExtension === 'pdf') {
            // Extract everything after the last underscore in the filename
            const lastUnderscoreIndex = filename.lastIndexOf('_');
            desiredString = lastUnderscoreIndex !== -1 ? filename.slice(lastUnderscoreIndex + 1) : '';
        }

        return (
            fileExtension === 'pdf' ?
                // Display PDF and download button
                <>
                    <Icon icon='bxs:file-pdf' style={{ fontSize: '100px', color: mainColor }} />
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: mainColor,
                            fontSize: '11px',
                            fonStyle: 'normal',
                            fontWeight: 'bold',
                            lineHeight: 'normal',
                            cursor: 'pointer',
                            textAlign: 'center',
                            marginTop: '-10px'
                        }}
                    >
                        {desiredString ? desiredString : ''}
                    </Typography>
                </>
                :
                <img
                    src={image}
                    alt="show-image"
                    style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'contain', // Mengubah properti object-fit menjadi 'contain'
                        borderRadius: '4px',
                    }}
                />
        )
    }

    const handleShowImageClick = (currentImage, option) => {
        // console.log('currentImage', currentImage);
        var image = `${url}${currentImage}`;
        const fileExtension = currentImage ? currentImage.split('.').pop().toLowerCase() : ''

        if (fileExtension === 'pdf') {
            // Extract filename from the URL
            const filename = currentImage ? currentImage.split('/').pop() : ''

            // Handle PDF file download with the correct filename
            const downloadLink = document.createElement('a');
            downloadLink.href = image;
            // downloadLink.target = '_blank';
            downloadLink.download = filename;
            downloadLink.click();
        } else {
            if (option === 'passport') {
                setShowImagePassport(image)
                setOpenLightbox(true);
            } else if (option === 'visa') {
                setShowImageVisa(image)
                setOpenLightbox(true);
            } else if (option === 'study permit') {
                setShowImageStudyPermit(image)
                setOpenLightbox(true);
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        setNewData(prev => {
            return { ...prev, [name]: value };
        });
    };

    const handleSwitchButton = () => {
        setIsLoading(true)
        setTimeout(() => {
            setSwitchButton(!switchButton)
            refreshData()
            setIsLoading(false)
        }, 1500);
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true)
        const personalID = completeData?.personal_information_id

        const formData = new FormData()
        formData.append('_method', 'PUT')
        formData.append('scan_passport', selectedImagePassport)
        formData.append('scan_visa', selectedImageVisa)
        formData.append('no_passport', newData.nomorPassport)
        formData.append('no_visa', newData.nomorVisa)
        formData.append('scan_study_permit', selectedImageStudyPermit)

        if (
            (selectedImagePassport || selectedImageVisa || selectedImageStudyPermit) ||
            (currentImagePassport || currentImageVisa || currentImageStudyPermit)
        ) {
            await axios.post(`${updateDataPasspotrAndVisa}/${personalID}`, formData)
                .then((response) => {
                    // console.log(response.data);
                    if (response.data.message === "Informasi personal berhasil diperbarui") {
                        setSwitchButton(false)
                        refreshData()
                        InfoAlert({
                            message: 'Berhasil mengubah data',
                            position: 'top-center'
                        })
                    }
                }).catch((error) => {
                    console.log(error.response.data);
                    if (error?.response?.data?.message === 'Unauthenticated.') {
                        handleExpired()
                    }
                })
        } else if (selectedImagePassport === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Passport',
                position: 'top-center'
            })
        } else if (selectedImageVisa === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Visa',
                position: 'top-center'
            })
        } else if (selectedImageStudyPermit === null) {
            ErrorAlert({
                message: 'Silakan Upload Foto Study Permit',
                position: 'top-center'
            })
        } else {


        }

        setIsSubmitting(false)
    }

    return (
        <Card
            elevation={3}
            sx={{
                maxWidth: isMobile || isTablet ? '100%' : '384px',
                maxHeight: isMobile || isTablet ? '100%' : '1010px',
                width: '100%',
                height: '1010px',
                border: `1px solid ${DividerColor}`
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '16px',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: '10px'
                    }}
                >
                    <Typography
                        sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            color: Dark,
                            fontSize: '16px',
                            fonStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: 'normal',
                        }}
                    >
                        Passport & Visa
                    </Typography>
                    <Box
                        sx={{
                            padding: '4px 8px',
                            backgroundColor: 'rgba(248, 215, 0, 0.20)',
                            borderRadius: '4px',
                            border: `1px solid ${DividerColor}`
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: 'Plus Jakarta Sans',
                                color: Dark,
                                fontSize: '16px',
                                fonStyle: 'normal',
                                fontWeight: '500',
                                lineHeight: 'normal',
                            }}
                        >
                            {dataCompletenessPassportAndVisa ? `${Math.round(dataCompletenessPassportAndVisa)}%` : '0%'}
                        </Typography>
                    </Box>
                </Box>
                <Button
                    onClick={handleSwitchButton}
                    sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        color: switchButton ? TextGray : mainColor,
                        fontSize: '16px',
                        fonStyle: 'normal',
                        fontWeight: '600',
                        lineHeight: 'normal',
                        textTransform: 'none',
                        padding: '0px'
                    }}
                >
                    {isLoading ? <Icon icon='svg-spinners:3-dots-fade' style={{ fontSize: '25px', color: mainColor }} /> : 'Ubah Data'}
                </Button>
            </Box>
            <Box
                sx={{
                    width: '100%',
                }}
            >
                <Divider
                    variant="string"
                    style={{
                        backgroundColor: DividerColor,
                        borderBottomWidth: 0.5,
                        // marginTop: "2px",
                    }}
                />
            </Box>

            <form onSubmit={handleSubmit}>
                <Box
                    sx={{
                        padding: '16px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                        gap: '16px',
                    }}
                >
                    <TextField
                        // fullWidth
                        variant="standard"
                        type="text"
                        label="Nomor Passport"
                        placeholder='Masukkan nomor passport'
                        value={newData.nomorPassport}
                        name="nomorPassport"
                        onChange={(e) => handleChange(e)}
                        onInput={(e) => {
                            // Filter out non-numeric characters
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        autoComplete="off"
                        required
                        disabled={switchButton ? false : true}
                        sx={{
                            marginBottom: isMobile ? '5px' : '10px',
                            fontSize: isMobile ? '11px' : '12px',
                            width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                            },
                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                "& fieldset": {
                                    borderBottomColor: mainColor,
                                },
                            },
                            "& .MuiInputLabel-root.Mui-disabled": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiInputLabel-root": {
                                color: '#B3B3D4',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: mainColor,
                            },
                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:after": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:after": {
                                borderBottomColor: mainColor,
                            },
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                                "-webkit-appearance": "none",
                                margin: 0,
                            },
                            "& input[type=number]": {
                                "-moz-appearance": "textfield",
                            },
                        }}
                    />

                    <TextField
                        // fullWidth
                        variant="standard"
                        type="text"
                        label="Nomor Visa"
                        placeholder='Masukkan nomor visa Anda'
                        value={newData.nomorVisa}
                        name="nomorVisa"
                        onChange={(e) => handleChange(e)}
                        onInput={(e) => {
                            // Filter out non-numeric characters
                            e.target.value = e.target.value.replace(/[^0-9]/g, '');
                        }}
                        autoComplete="off"
                        required
                        disabled={switchButton ? false : true}
                        sx={{
                            marginBottom: isMobile ? '13px' : '16px',
                            fontSize: isMobile ? '11px' : '12px',
                            width: isMobile ? '100%' : isTablet ? '350px' : '100%',
                            "& .MuiInputBase-input.Mui-disabled": {
                                WebkitTextFillColor: "black",
                            },
                            "& .MuiFilledInput-root.Mui-disabled:hover": {
                                "& fieldset": {
                                    borderBottomColor: mainColor,
                                },
                            },
                            "& .MuiInputLabel-root.Mui-disabled": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiInputLabel-root": {
                                color: '#B3B3D4',
                                fontWeight: 'bold',
                                fontSize: '12px'
                            },
                            "& .MuiInputLabel-root.Mui-focused": {
                                color: mainColor,
                            },
                            "& .MuiInputLabel-root.Mui-focused.Mui-error": {
                                color: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:hover:after": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:before": {
                                borderBottomColor: mainColor,
                            },
                            "& .MuiFilledInput-underline:focus:after": {
                                borderBottomColor: mainColor,
                            },
                            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                            {
                                "-webkit-appearance": "none",
                                margin: 0,
                            },
                            "& input[type=number]": {
                                "-moz-appearance": "textfield",
                            },
                        }}
                    />

                    {/* Upload Passport */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Passport(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '5px'
                        }}
                        onDragOver={(e) => handleDragOver(e, 'passport')}
                        onDrop={(e) => handleDrop(e, 'passport')}
                        onDragLeave={(e) => handleDragLeave(e, 'passport')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPicturePassport && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlPassport || currentImagePassport ? (
                                    <>
                                        {currentImagePassport ?
                                            handleShowImage(currentImagePassport)
                                            :
                                            handleShowPDF(selectedImagePassport, imageUrlPassport)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('passport')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImagePassport, 'passport')}
                                            >
                                                {currentImagePassport && currentImagePassport.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefPassport, 'passport')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'passport')}
                                ref={fileInputRefPassport}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>

                    {/* Upload Visa */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Visa(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '15px'

                        }}
                        onDragOver={(e) => handleDragOver(e, 'visa')}
                        onDrop={(e) => handleDrop(e, 'visa')}
                        onDragLeave={(e) => handleDragLeave(e, 'visa')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPictureVisa && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlVisa || currentImageVisa ? (
                                    <>
                                        {currentImageVisa ?
                                            handleShowImage(currentImageVisa)
                                            :
                                            handleShowPDF(selectedImageVisa, imageUrlVisa)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('visa')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImageVisa, 'passport')}
                                            >
                                                {currentImageVisa && currentImageVisa.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefVisa, 'visa')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'visa')}
                                ref={fileInputRefVisa}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>


                    {/* Upload Study Permit */}
                    <Typography
                        sx={{
                            color: '#1E222F',
                            fontFamily: 'Jakarta Sans, sans-serif',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: '400',
                            lineHeight: 'normal',
                            // marginBottom: '10px',
                        }}
                    >
                        Gambar Scan/Foto Study Permit(Maks 1MB)
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            // flexDirection: 'row',
                            // gap: '24px',
                            border: `solid 2px ${DividerColor}`,
                            borderStyle: 'dashed',
                            marginTop: '-8px',
                            maxWidth: isMobile ? '350px' : '347px',
                            maxHeight: isMobile ? '100%' : '190px',
                            width: '100%',
                            height: '100%',
                            overflow: 'hidden',
                            marginBottom: '5px'
                        }}
                        onDragOver={(e) => handleDragOver(e, 'study permit')}
                        onDrop={(e) => handleDrop(e, 'study permit')}
                        onDragLeave={(e) => handleDragLeave(e, 'study permit')}
                    >
                        <Box
                            sx={{
                                maxWidth: isMobile ? '350px' : '347px',
                                maxHeight: '190px',
                                width: '100%',
                                height: '190px',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '12px',
                                position: 'relative',
                            }}
                        >
                            {isDragging.isDraggingPictureStudyPermit && !isMobile && switchButton ?
                                <Typography
                                    sx={{
                                        fontFamily: 'Jakarta Sans, sans-serif',
                                        fontSize: '13px',
                                        fontStyle: 'normal',
                                        fontWeight: 'bold',
                                        lineHeight: '17,64px',
                                        textAlign: 'center',
                                        color: mainColor
                                    }}
                                >
                                    Lepaskan untuk mengunggah
                                </Typography>
                                :
                                imageUrlStudyPermit || currentImageStudyPermit ? (
                                    <>
                                        {currentImageStudyPermit ?
                                            handleShowImage(currentImageStudyPermit)
                                            :
                                            handleShowPDF(selectedImageStudyPermit, imageUrlStudyPermit)
                                        }
                                        {switchButton ?
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    backgroundColor: '#fff',
                                                    borderRadius: '3px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleRemoveImage('study permit')}
                                            >
                                                <Icon icon="bi:trash" style={{ fontSize: '16px', color: 'red' }} />
                                            </Box>
                                            :
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    position: 'absolute',
                                                    top: '4px',
                                                    right: '4px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                    backgroundColor: '#fff',
                                                }}
                                                onClick={() => handleShowImageClick(currentImageStudyPermit, 'study permit')}
                                            >
                                                {currentImageStudyPermit && currentImageStudyPermit.split('.').pop().toLowerCase() === 'pdf' ?
                                                    <Icon icon="line-md:download-loop" style={{ fontSize: '25px', color: mainColor }} />
                                                    :
                                                    <Icon icon="zondicons:view-show" style={{ fontSize: '20px', color: mainColor }} />
                                                }
                                            </Box>
                                        }
                                    </>
                                ) : (
                                    <>
                                        {!isMobile ?
                                            <>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '13px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    Geser gambar atau PDF ke area ini
                                                </Typography>
                                                <Typography
                                                    sx={{
                                                        fontFamily: 'Jakarta Sans, sans-serif',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '400',
                                                        lineHeight: '17,64px',
                                                        color: TextGray
                                                    }}
                                                >
                                                    atau
                                                </Typography>
                                            </>
                                            :
                                            undefined
                                        }
                                        <div
                                            onClick={() => handleImageUpload(fileInputRefStudyPermit, 'study permit')}
                                            style={{
                                                cursor: 'pointer',
                                                padding: '6px 12px',
                                                border: '1px solid #ccc',
                                                borderRadius: '4px',
                                                display: 'inline-block',
                                                backgroundColor: switchButton ? undefined : bgColor,
                                                color: switchButton ? undefined : DividerColor
                                            }}
                                        >
                                            Pilih Gambar/PDF
                                        </div>
                                    </>
                                )}
                            <input
                                type="file"
                                accept="image/*,.pdf"   // Accept both image and PDF files
                                onChange={(event) => handleFileInputChange(event.target.files[0], 'study permit')}
                                ref={fileInputRefStudyPermit}
                                style={{ display: 'none' }}
                                disabled={switchButton ? false : true}
                            />
                        </Box>
                    </Box>
                    <Button
                        fullWidth
                        type='submit'
                        variant='contained'
                        size='medium'
                        sx={{
                            backgroundColor: btnBgColor,
                            textTransform: 'none',
                            color: mainColor,
                            '&:hover': {
                                color: mainColor, // Change text color to white on hover
                                backgroundColor: btnBgColor
                            },
                        }}
                        disabled={isSubmitting || !switchButton ? true : false}
                    >
                        {isSubmitting ?
                            <Icon icon='svg-spinners:tadpole' style={{ fontSize: '20px' }} />
                            :
                            'Simpan Perubahan'
                        }
                    </Button>
                </Box>
                <LightboxModal
                    open={openLightbox}
                    close={() => {
                        setOpenLightbox(false)
                        setShowImagePassport('')
                        setShowImageVisa('')
                        setShowImageStudyPermit('')
                    }}
                    imageUrl={
                        showImagePassport ?
                            showImagePassport
                            :
                            showImageVisa ?
                                showImageVisa
                                :
                                showImageStudyPermit ?
                                    showImageStudyPermit
                                    :
                                    undefined
                    }
                />
            </form>
        </Card>
    )
}

export default PassportAndVisa